import { PATHOLOGIST_TYPE_ID, PATHOLOGIST_TYPE_NAME } from '@/core/constants';
import { sortDropDown, startDay } from '@/core/helpers';
// import { sortDropDown } from '@/core/helpers';

export const isAvailablePathologist = (pathologistId, pathologistList) => {
  if (!pathologistId) {
    return false;
  }
  let result = false;
  pathologistList.forEach((pathologistGroup) => {
    if (pathologistGroup.pathologistUsers.find((item) => item.pathologistId == pathologistId)) {
      result = true;
    }
  });
  return result;
};

export const createNewPathologist = (data, isEditMode = false) => {
  // several cases
  // empty
  if (!data.pathologistId && data.pathologistType === PATHOLOGIST_TYPE_ID.None) {
    return null;
  }
  // or Pull
  if (data.pathologistType === PATHOLOGIST_TYPE_ID.Pull) {
    if (isEditMode) {
      return {
        pathologistId: data.pathologistId || PATHOLOGIST_TYPE_NAME.Pull,
        pathologistType: PATHOLOGIST_TYPE_ID.Pull,
        pathologistTypeName: PATHOLOGIST_TYPE_NAME.Pull,
        pathologistName: data.pathologistName || PATHOLOGIST_TYPE_NAME.Pull,
        pathologistTitle: data.pathologistName || PATHOLOGIST_TYPE_NAME.Pull,
        isDefault: data.isDefault || false,
      };
    } else {
      return {
        pathologistId: data.pathologistId || PATHOLOGIST_TYPE_NAME.Pull,
        pathologistType: PATHOLOGIST_TYPE_ID.Pull,
        pathologistTypeName: PATHOLOGIST_TYPE_NAME.Pull,
        pathologistName: data.pathologistName || PATHOLOGIST_TYPE_NAME.Pull,
        pathologistTitle: data.pathologistName || PATHOLOGIST_TYPE_NAME.Pull,
        isDefault: data.isDefault || false,
      };
    }
  }
  return {
    pathologistId: data.pathologistId || null,
    pathologistType: data.pathologistType || 0,
    pathologistName: data.pathologistName,
    pathologistTypeName: data.pathologistTypeName,
    pathologistTitle: data.pathologistTitle || '',
    isDefault: data.isDefault || false,
  };
};

export const mappingGroupPathologists = (groupPathologists, isEditMode = false) => {
  let pathologistOptions = [];
  let defaultPathologist = null;
  // and another requirement is we have to
  // sort pathologist by fullname
  // find the default pathologist if existed : we'll use this flag "isDefault"
  if (groupPathologists && groupPathologists.length > 0) {
    pathologistOptions = groupPathologists.map((g) => {
      const { pathologistGroupId, pathologistGroupName } = g;

      const pathologistUsers = sortDropDown(
        (Reflect.get(g, 'pathologistUsers') || []).map((pathologistUser) => {
          const pathologist = createNewPathologist({
            pathologistId: pathologistUser.userId,
            pathologistType: pathologistGroupId,
            pathologistTypeName: pathologistGroupName,
            pathologistName: pathologistUser?.firstName + ' ' + pathologistUser?.lastName,
            pathologistTitle: pathologistUser?.title,
            isDefault: pathologistUser?.isDefault || false,
          });
          if (pathologistUser.isDefault) {
            defaultPathologist = pathologist;
          }
          return pathologist;
        }),
        'pathologistName',
      );

      if (pathologistGroupId === PATHOLOGIST_TYPE_ID.Pull) {
        pathologistUsers.push(
          createNewPathologist({
            pathologistId: null,
            pathologistType: pathologistGroupId,
            pathologistTypeName: pathologistGroupName,
            pathologistName: PATHOLOGIST_TYPE_NAME.Pull,
            pathologistTitle: PATHOLOGIST_TYPE_NAME.Pull,
            isEditMode,
          }),
        );
      }
      return {
        ...g,
        pathologistUsers,
      };
    });
  }
  return {
    pathologistOptions,
    defaultPathologist,
  };
};

export const calculatePatientAge = ({ procedure, birth }) => {
  if (!procedure || !birth) {
    return 0;
  }
  let procedureDate = new Date(procedure);
  let birthDate = new Date(birth);
  let age = procedureDate.getFullYear() - birthDate.getFullYear();
  let m = procedureDate.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && procedureDate.getDate() < birthDate.getDate())) {
    age--;
  }
  return age < 0 ? 0 : age;
};

// verify if case has at least 01 specimen that does NOT have one of the following: Snomed T, Snomed P, Snomed M, Microscopy
// export const isCaseAllowedToBeAuthorized = (caseData) => {

// }

// Date of birth should be less than Procedure Date and Lab Entry Date
export const validateDob = (dob, { labEntryDate, procedureDate }) => {
  if (!dob) {
    return true;
  }
  if (!labEntryDate && !procedureDate) {
    return true;
  }
  if (labEntryDate && procedureDate) {
    return startDay(dob) < startDay(labEntryDate) && startDay(dob) < startDay(procedureDate);
  }
  if (labEntryDate) {
    return startDay(dob) < startDay(labEntryDate);
  }
  if (procedureDate) {
    return startDay(dob) < startDay(procedureDate);
  }
  return true;
};

// Procedure Date should be greater than Date of Birth and less than Lab Entry Date
export const validateProcedureDate = (procedureDate, { labEntryDate, dob }) => {
  if (!procedureDate) {
    return true;
  }
  if (!labEntryDate && !dob) {
    return true;
  }
  if (labEntryDate && dob) {
    return startDay(procedureDate) > startDay(dob) && startDay(procedureDate) <= startDay(labEntryDate);
  }
  if (labEntryDate) {
    return startDay(procedureDate) <= startDay(labEntryDate);
  }
  if (dob) {
    return startDay(procedureDate) > startDay(dob);
  }
  return true;
};

// Lab Entry Date must greater than DOB and Procedure Date
export const validateLabEntryDate = (labEntryDate, { dob, procedureDate }) => {
  if (!labEntryDate) {
    return true;
  }
  if (!dob && !procedureDate) {
    return true;
  }
  if (dob && procedureDate) {
    return startDay(labEntryDate) > startDay(dob) && startDay(labEntryDate) >= startDay(procedureDate);
  }
  if (dob) {
    return startDay(labEntryDate) > startDay(dob);
  }
  if (procedureDate) {
    return startDay(labEntryDate) >= startDay(procedureDate);
  }
  return true;
};

<template>
  <form class="md-layout lims-form" v-if="formData">
    <lims-block class="patient-data-block">
      <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/patientData/blockTitle') }}</h4>
      <lims-collapse slot="blockContent" :collapse="['Case Collapse']" icon="keyboard_arrow_down">
        <patient-data-view slot="md-collapse-pane-1" :dataEdit="formData"></patient-data-view>
      </lims-collapse>
    </lims-block>
    <!-- Begin document viewer -->
    <div class="document-and-go-to-case-form">
      <md-button
        class="md-button lims-form-button md-theme-default"
        @click="isShowDocumentViewer = !isShowDocumentViewer"
      >
        <span v-if="isShowDocumentViewer">{{ $t('pages/case/form/DocumentViewer/hideDocumentViewer') }}</span>
        <span v-else>{{ $t('pages/case/form/DocumentViewer/showDocumentViewer') }}</span>
      </md-button>
      <md-button
        class="md-button lims-form-button md-theme-default go-to-case-form"
        @click="onGoToCaseForm()"
        v-if="isShowGoToCaseFormBtn()"
      >
        <span v-if="isGoToCaseFormBtn">{{ $t('entities/entities/case/form/RespondToIssue/goToCaseForm') }}</span>
        <span v-else>{{ $t('entities/entities/case/form/RespondToIssue/goToCaseReport') }}</span>
      </md-button>
    </div>
    <lims-block id="split-wrapper" v-if="isShowDocumentViewer" class="block-not-title">
      <div slot="blockContent">
        <div class="range-custom">
          <md-radio v-model="rangeSliderVal" :value="0" class="position-25">
            <md-icon>format_align_left</md-icon>
          </md-radio>
          <md-radio v-model="rangeSliderVal" :value="50" class="position-50">
            <md-icon>format_align_center</md-icon>
          </md-radio>
          <md-radio v-model="rangeSliderVal" :value="100" class="position-75">
            <md-icon>format_align_right</md-icon>
          </md-radio>
        </div>
        <div class="md-layout lims-form-row">
          <vue-split :elements="['#slotOne', '#slotTwo']" direction="horizontal" :min-size="0" :gutter-size="10">
            <div id="slotOne" :style="{ width: rangeSliderVal + '%' }">
              <document-view :data-edit="formData" @openNewWindow="rangeSliderVal = 0"></document-view>
            </div>
            <div id="slotTwo" class="case-form-block" :style="{ width: 100 - rangeSliderVal + '%' }">
              <!-- Begin issue log -->
              <lims-block id="issueLogBlock">
                <h4 class="title" slot="blockTitle">{{ $t('pages/case/form/issueLog/blockTitle') }}</h4>
                <lims-collapse slot="blockContent" :collapse="['Case Collapse']" icon="keyboard_arrow_down">
                  <div slot="md-collapse-pane-1" class="form-wrapper">
                    <div class="md-layout lims-form-row">
                      <div class="md-layout-item md-size-100 md-small-size-100">
                        <label>{{ $t('entities/case/form/issue') }}</label>
                        <div class="md-static-field">
                          <md-field>
                            <md-input disabled :value="formData.issueName" type="text"></md-input>
                          </md-field>
                        </div>
                      </div>
                      <div class="md-layout-item md-size-100 md-small-size-100">
                        <label>{{ $t('entities/case/form/commentIssue') }}</label>
                        <div class="md-static-field">
                          <md-field>
                            <md-input disabled :value="formData.issueComment" type="text"></md-input>
                          </md-field>
                        </div>
                      </div>
                      <div
                        class="md-layout-item md-size-100 md-small-size-100"
                        v-for="(item, index) in formData.caseIssueFields"
                        :key="index"
                      >
                        <div class="md-layout-item md-size-100 md-small-size-100">
                          <div
                            class="md-layout-item md-size-50 md-small-size-100"
                            v-if="item.uiControl === 'Datepicker'"
                          >
                            <lims-field class="textarea-field" :model="formData" :schema="schema" field="dob">
                              <date-picker slot="field" v-model="formData.dob" format="DD/MM/YYYY"></date-picker>
                            </lims-field>
                          </div>
                        </div>
                        <div class="md-layout-item md-size-100 md-small-size-100">
                          <div
                            class="md-layout-item md-size-50 md-small-size-100"
                            v-if="item.uiControl === 'Dropdown Menu' && item.caseIssueValueName === 'Billing Entity'"
                          >
                            <lims-field class="textarea-field" :model="formData" :schema="schema" field="billingEntity">
                              <v-select
                                slot="field"
                                :options="billingEntityList"
                                v-model="formData.billingEntity"
                                :reduce="(item) => item.entityBillingId"
                                label="fieldItemName"
                              >
                                <template #option="{ fieldItemName }">{{ fieldItemName }}</template>
                                <template #selected-option="{ fieldItemName }">{{ fieldItemName }}</template>
                              </v-select>
                            </lims-field>
                          </div>
                        </div>
                        <div class="md-layout-item md-size-100 md-small-size-100">
                          <div
                            class="md-layout-item md-size-50 md-small-size-100"
                            v-if="item.uiControl === 'Dropdown Menu' && item.caseIssueValueName === 'Country'"
                          >
                            <lims-field class="textarea-field" :model="formData" :schema="schema" field="country">
                              <v-select
                                slot="field"
                                :options="countries"
                                v-model="formData.country"
                                :reduce="(item) => item.value"
                                label="label"
                              >
                                <template #option="{ label }">{{ label }}</template>
                                <template #selected-option="{ label }">{{ label }}</template>
                              </v-select>
                            </lims-field>
                          </div>
                        </div>
                        <div class="md-layout-item md-size-100 md-small-size-100">
                          <div
                            class="md-layout-item md-size-50 md-small-size-100"
                            v-if="item.uiControl === 'Dropdown Menu' && item.caseIssueValueName === 'Insurance Company'"
                          >
                            <lims-field
                              class="textarea-field"
                              :model="formData"
                              :schema="schema"
                              field="insuranceCompany"
                            >
                              <v-select
                                slot="field"
                                :options="insuranceCompanyList"
                                v-model="formData.insuranceCompany"
                                :reduce="(item) => item.fieldItemId"
                                label="fieldItemName"
                              >
                                <template #option="{ fieldItemName }">{{ fieldItemName }}</template>
                                <template #selected-option="{ fieldItemName }">{{ fieldItemName }}</template>
                              </v-select>
                            </lims-field>
                          </div>
                        </div>
                        <div class="md-layout-item md-size-100 md-small-size-100">
                          <div
                            class="md-layout-item md-size-50 md-small-size-100"
                            v-if="
                              item.uiControl === 'Dropdown Menu' &&
                              item.caseIssueValueName === 'Specimen Type (of 1st specimen)'
                            "
                          >
                            <lims-field class="textarea-field" :model="formData" :schema="schema" field="specimenType">
                              <v-select
                                slot="field"
                                :options="specimenTypeList"
                                v-model="formData.specimenType"
                                :reduce="(item) => item.fieldItemId"
                                label="fieldItemName"
                              >
                                <template #option="{ fieldItemName }">{{ fieldItemName }}</template>
                                <template #selected-option="{ fieldItemName }">{{ fieldItemName }}</template>
                              </v-select>
                            </lims-field>
                          </div>
                        </div>
                        <div class="md-layout-item md-size-100 md-small-size-100">
                          <div
                            class="md-layout-item md-size-50 md-small-size-100"
                            v-if="
                              item.uiControl === 'Text Input' &&
                              item.caseIssueValueName === 'Insurance Authorisation Number'
                            "
                          >
                            <lims-field :model="formData" :schema="schema" field="insuranceAuthorisationNumber">
                              <md-input
                                slot="field"
                                v-model="formData.insuranceAuthorisationNumber"
                                type="text"
                                maxlength="250"
                              ></md-input>
                            </lims-field>
                          </div>
                        </div>
                        <div class="md-layout-item md-size-100 md-small-size-100">
                          <div
                            class="md-layout-item md-size-50 md-small-size-100"
                            v-if="item.uiControl === 'Text Input' && item.caseIssueValueName === 'Insurance Number'"
                          >
                            <lims-field :model="formData" :schema="schema" field="insuranceNumber">
                              <md-input
                                slot="field"
                                v-model="formData.insuranceNumber"
                                type="text"
                                maxlength="250"
                              ></md-input>
                            </lims-field>
                          </div>
                        </div>
                        <div class="md-layout-item md-size-100 md-small-size-100">
                          <div
                            class="md-layout-item md-size-50 md-small-size-100"
                            v-if="item.uiControl === 'Text Input' && item.caseIssueValueName === 'Insurance Note'"
                          >
                            <lims-field :model="formData" :schema="schema" field="insuranceNote">
                              <md-input
                                slot="field"
                                v-model="formData.insuranceNote"
                                type="text"
                                maxlength="250"
                              ></md-input>
                            </lims-field>
                          </div>
                        </div>
                        <div class="md-layout-item md-size-100 md-small-size-100">
                          <div
                            class="md-layout-item md-size-50 md-small-size-100"
                            v-if="item.uiControl === 'Text Input' && item.caseIssueValueName === 'Postal Code'"
                          >
                            <lims-field :model="formData" :schema="schema" field="postalCode">
                              <md-input
                                slot="field"
                                v-model="formData.postalCode"
                                type="text"
                                maxlength="250"
                              ></md-input>
                            </lims-field>
                          </div>
                        </div>
                        <div class="md-layout-item md-size-100 md-small-size-100">
                          <div
                            class="md-layout-item md-size-50 md-small-size-100"
                            v-if="item.uiControl === 'Text Input' && item.caseIssueValueName === 'Town'"
                          >
                            <lims-field :model="formData" :schema="schema" field="town">
                              <md-input slot="field" v-model="formData.town" type="text" maxlength="250"></md-input>
                            </lims-field>
                          </div>
                        </div>
                        <div class="md-layout-item md-size-100 md-small-size-100">
                          <div
                            class="md-layout-item md-size-100 md-small-size-100"
                            v-if="item.uiControl === 'Textarea' && item.caseIssueValueName === 'Address 1'"
                          >
                            <lims-field class="textarea-field" :model="formData" :schema="schema" field="address1">
                              <md-textarea slot="field" v-model="formData.address1" maxlength="250"></md-textarea>
                            </lims-field>
                          </div>
                        </div>
                        <div class="md-layout-item md-size-100 md-small-size-100">
                          <div
                            class="md-layout-item md-size-100 md-small-size-100"
                            v-if="item.uiControl === 'Textarea' && item.caseIssueValueName === 'Address 2'"
                          >
                            <lims-field class="textarea-field" :model="formData" :schema="schema" field="address2">
                              <md-textarea slot="field" v-model="formData.address2" maxlength="250"></md-textarea>
                            </lims-field>
                          </div>
                        </div>
                      </div>
                      <div class="md-layout-item md-size-100 md-small-size-100">
                        <lims-field
                          class="textarea-field"
                          :model="formData"
                          :schema="schema"
                          field="commentOnIssueResponse"
                        >
                          <md-textarea slot="field" v-model="formData.commentOnIssueRespond"></md-textarea>
                        </lims-field>
                      </div>
                    </div>
                    <div class="md-layout lims-form-row">
                      <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions case-block-action">
                        <md-button @click="onCancel()" class="lims-form-button">
                          {{ $t('global/button/button.cancel') }}
                        </md-button>
                        <md-button
                          @click="onRespond()"
                          :disabled="isProcessing"
                          class="md-button md-primary lims-form-button md-theme-default"
                        >
                          {{ $t('global/button/button.respond') }}
                        </md-button>
                      </div>
                    </div>
                  </div>
                </lims-collapse>
              </lims-block>
              <!-- End issue log -->
            </div>
          </vue-split>
        </div>
      </div>
    </lims-block>
    <!-- End document viewer -->
  </form>
</template>
<script>
import { FormMixins } from '@/core/mixins';
import LimsCollapse from '@/components/Lims/LimsCollapse';
import LimsBlock from '@/components/Lims/LimsBlock.vue';
import { CaseIssueService, EntityService } from '@/services';
import { APP_ROUTES, CASE_STATUS, DROPDOWN_SHORT_NAME } from '@/core/constants';
import { mapActions, mapGetters } from 'vuex';
import dropdownService from '@/services/dropdown.service';
import DocumentView from '@/pages/Case/CaseManagement/Components/DocumentView';
import PatientDataView from '@/pages/Case/CaseManagement/Components/PatientDataView.vue';

export default {
  mixins: [FormMixins],
  components: {
    LimsCollapse,
    LimsBlock,
    DocumentView,
    PatientDataView,
  },
  props: {
    caseIssueId: {
      require: true,
    },
    token: {
      require: true,
    },
  },
  created() {
    this.userType = this.$store.getters['auth/userType'];
    this.fetchData();
  },
  data() {
    return {
      formData: null,
      billingEntityList: [],
      countries: [],
      insuranceCompanyList: [],
      specimenTypeList: [],
      isShowDocumentViewer: true,
      rangeSliderVal: 50,
      userType: null,
      isProcessing: false,
    };
  },
  computed: {
    ...mapGetters('app/data', ['getDatasetByKey']),
    schema() {
      return {
        entity: 'entities/case/form/RespondToIssue',
        fields: {
          commentOnIssueResponse: '',
          dob: '',
          billingEntity: '',
          country: '',
          insuranceCompany: '',
          specimenType: '',
          insuranceAuthorisationNumber: '',
          insuranceNumber: '',
          insuranceNote: '',
          postalCode: '',
          town: '',
          address1: '',
          address2: '',
        },
      };
    },
    isGoToCaseFormBtn() {
      return (
        this.userType === this.USER_TYPES().Administrator ||
        this.userType === this.USER_TYPES().LabTechnician ||
        this.userType === this.USER_TYPES().Pathologist
      );
    },
  },
  methods: {
    ...mapActions('app/data', ['updateDataset']),
    async prepareData() {
      const dropdownOptions = await dropdownService.getDropdownByShortNames([
        DROPDOWN_SHORT_NAME.COUNTRY,
        DROPDOWN_SHORT_NAME.SPICEMEN_TYPE,
        DROPDOWN_SHORT_NAME.INSURANCE_COMPANY,
        DROPDOWN_SHORT_NAME.BILLING_ENTITY,
      ]);
      this.updateDataset({
        ...dropdownOptions,
      });
    },
    async fetchData() {
      const { error, data } = await CaseIssueService.getCaseIssueResponse(this.caseIssueId, this.token);
      if (error) {
        this.$alertError(error);
      } else {
        await this.prepareData();
        this.billingEntityList = await this.loadBillingEntityList(data.clinicId);
        this.countries = this.getDatasetByKey(DROPDOWN_SHORT_NAME.COUNTRY);
        this.insuranceCompanyList = this.getDatasetByKey(DROPDOWN_SHORT_NAME.INSURANCE_COMPANY);
        this.specimenTypeList = this.getDatasetByKey(DROPDOWN_SHORT_NAME.SPICEMEN_TYPE);

        this.formData = {
          ...data,
        };
      }
    },
    async loadBillingEntityList(clinicId) {
      const { data } = await EntityService.getEntityBilling(clinicId);
      if (data) {
        return data;
      }
    },
    onCancel() {
      this.$router.back();
    },
    async onRespond() {
      this.isProcessing = true;
      const { error, data } = await CaseIssueService.updateCaseIssueResponse(
        this.caseIssueId,
        this.token,
        this.prepareCaseIssueData(),
      );
      this.isProcessing = false;
      if (error) {
        this.$alertError(error);
      } else {
        if (data) {
          this.$alertSuccess(
            this.$t('pages/case/CaseManagement/component/respondIssue/alert/respondSuccess', {
              issue: this.formData.issueName,
            }),
          );
          await this.$router.push(APP_ROUTES.CASE);
        }
      }
    },
    onGoToCaseForm() {
      if (this.isGoToCaseFormBtn) {
        this.$router.push(`${APP_ROUTES.CASE_EDIT}/${this.formData.caseId}`);
      } else {
        this.$router.push(`${APP_ROUTES.REPORT_CASE}/${this.formData.caseId}`);
      }
    },
    prepareCaseIssueData() {
      let caseIssueFields = [];
      this.formData.caseIssueFields.forEach((caseIssueField) => {
        caseIssueFields.push({
          caseIssueFieldId: caseIssueField.caseIssueFieldId,
          caseIssueValue: this.getCaseIssueValueByCaseIssueValueName(caseIssueField.caseIssueValueName),
          caseIssueValueName: caseIssueField.caseIssueValueName,
        });
      });
      return {
        caseIssueFields: caseIssueFields,
        commentOnResponse: this.formData.commentOnIssueRespond,
      };
    },
    getCaseIssueValueByCaseIssueValueName(caseIssueValueName) {
      if (caseIssueValueName === 'DOB') {
        return this.formData.dob;
      } else {
        if (caseIssueValueName === 'Billing Entity') {
          return this.formData.billingEntity.toString();
        } else {
          if (caseIssueValueName === 'Country') {
            return this.formData.country.toString();
          } else {
            if (caseIssueValueName === 'Insurance Company') {
              return this.formData.insuranceCompany.toString();
            } else {
              if (caseIssueValueName === 'Specimen Type (of 1st specimen)') {
                return this.formData.specimenType.toString();
              } else {
                if (caseIssueValueName === 'Insurance Authorisation Number') {
                  return this.formData.insuranceAuthorisationNumber;
                } else {
                  if (caseIssueValueName === 'Insurance Note') {
                    return this.formData.insuranceNote;
                  } else {
                    if (caseIssueValueName === 'Postal Code') {
                      return this.formData.postalCode;
                    } else {
                      if (caseIssueValueName === 'Town') {
                        return this.formData.town;
                      } else {
                        if (caseIssueValueName === 'Address 1') {
                          return this.formData.address1;
                        } else {
                          if (caseIssueValueName === 'Address 2') {
                            return this.formData.address2;
                          } else {
                            if (caseIssueValueName === 'Insurance Number') {
                              return this.formData.insuranceNumber;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },

    isShowGoToCaseFormBtn() {
      if (this.userType === this.USER_TYPES().Administrator) {
        return true;
      }
      if (this.userType === this.USER_TYPES().LabTechnician) {
        return this.formData?.status === CASE_STATUS.LAB || this.formData?.status === CASE_STATUS.REPORTED;
      }
      if (this.userType === this.USER_TYPES().Pathologist) {
        return (
          this.formData?.status === CASE_STATUS.PATH ||
          this.formData?.status === CASE_STATUS.PROVISIONALLY_REPORTED ||
          this.formData?.status === CASE_STATUS.REPORTED
        );
      }
      if (
        this.userType === this.USER_TYPES().ClinicAssociate ||
        this.userType === this.USER_TYPES().Clinician ||
        this.userType === this.USER_TYPES().ClinicianAssociate
      ) {
        return (
          this.formData?.status === CASE_STATUS.PROVISIONALLY_REPORTED || this.formData?.status === CASE_STATUS.REPORTED
        );
      }
    },
  },
};
</script>
<style lang="scss">
.document-and-go-to-case-form {
  width: 100%;
  padding-right: 10px;
  .go-to-case-form {
    float: right;
  }
}
</style>

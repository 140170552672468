<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }" v-if="isReady">
    <md-dialog
      :md-close-on-esc="true"
      :md-active.sync="isVisible"
      @md-clicked-outside="close"
      class="lims-modal large-modal modal-create-alw-request"
    >
      <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
      <md-dialog-title> {{ $t('components/ModalCreateAlwRequest.rescanAlwRequests') }} </md-dialog-title>
      <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
        <lims-block class="block-not-title">
          <div slot="blockContent">
            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-33 md-small-size-100"></div>
              <div class="md-layout-item md-size-33 md-small-size-100">
                <lims-field :model="formData" :schema="schema" field="slide">
                  <multiselect
                    slot="field"
                    v-model="formData.items"
                    :options="slideList"
                    :multiple="true"
                    :show-labels="false"
                    group-values="nodes"
                    group-label="text"
                    track-by="text"
                    label="text"
                    :hide-selected="true"
                    :group-select="true"
                    :close-on-select="true"
                    :placeholder="$t('components/ModalCaseBatchChange.placeholderSlide')"
                  >
                  </multiselect>
                </lims-field>
              </div>
            </div>
            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-100 md-small-size-100">
                <lims-field class="textarea-field" :model="formData" :schema="schema" field="comment">
                  <md-textarea
                    slot="field"
                    v-model="formData.comment"
                    :placeholder="$t('components/ModalCreateAlwRequest.placeholderComment')"
                  ></md-textarea>
                </lims-field>
              </div>
            </div>
          </div>
        </lims-block>
        <md-dialog-actions>
          <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
          <md-button @click="onCreate" :disabled="invalid || isProcessing" class="lims-form-button md-primary">{{
            $t('global/button/button.create')
          }}</md-button>
        </md-dialog-actions>
      </form>
    </md-dialog>
  </ValidationObserver>
</template>

<script>
import modalMixins from './modal.mixins';
import { pathCaseService } from '@/services';
import { mapActions } from 'vuex';
import { APP_EVENTS, newAppEvent, USER_TYPE } from '@/core/constants';
import { filterDropdownListByHiddenField } from '@/core/helpers';

export default {
  mixins: [modalMixins],
  props: {
    caseId: {
      type: String,
      require: false,
    },
    laboratoryId: {
      type: String,
      require: false,
    },
    isPauseCountReturnToPull: {
      type: Boolean,
      require: false,
    },
  },
  data() {
    return {
      formData: {
        alwRequestTypeId: 0,
        items: null,
        comment: '',
      },
      slideList: null,
      rowVersion: null,
      isReady: false,
    };
  },
  computed: {
    schema() {
      return {
        entity: 'modals/ModalCreateAlwRequest',
        fields: {
          slide: 'required',
          comment: '',
        },
      };
    },
  },
  methods: {
    ...mapActions('app/event', ['addEvent']),
    async open() {
      this.isVisible = true;
      this.slideList = await this.loadDataSlideList();
      this.isReady = true;
    },
    async loadDataSlideList() {
      const { data } = await pathCaseService.getAlwRequestBlockStain({
        caseId: this.caseId,
        laboratoryId: this.laboratoryId,
      });
      const dataSlide = data.map((item) => {
        return {
          ...item,
          id: item.stainId,
        };
      });
      return [
        {
          text: 'Select All',
          nodes: filterDropdownListByHiddenField(dataSlide),
        },
      ];
    },
    dataCaseAlwRequest() {
      return {
        ...this.formData,
      };
    },
    onCreate() {
      this.userType = this.$store.getters['auth/userType'];
      this.$refs.form.validate().then(async (success) => {
        if (success) {
          this.isProcessing = true;
          try {
            const dataForm = this.dataCaseAlwRequest();
            const res = await pathCaseService.createCaseAlwRequestRescan(this.caseId, dataForm);
            this.isProcessing = false;
            if (res.err) {
              return this.$alertError(res.err + ' error');
            } else {
              this.$emit('onChangeRowVersion', {
                rowVersion: res.data.rowVersion,
              });
              this.close();
              this.$alertSuccess(this.$t('components/ModalCreateAlwRequest.rescanAlwRequests.created.successMessage'));
              this.addEvent(
                newAppEvent(APP_EVENTS.EVT_ON_CREATED_ALW_REQUEST, {
                  caseId: this.caseId,
                }),
              );
              if (this.userType === USER_TYPE.Pathologist && !this.isPauseCountReturnToPull) {
                this.addEvent(
                  newAppEvent(APP_EVENTS.EVT_ON_CASE_HIDE_COUNT_DOWN, {
                    onHideCountDown: true,
                  }),
                );
              }
            }
          } catch (errors) {
            this.isProcessing = false;
            this.$alertError(errors);
          }
        } else {
          this.isProcessing = false;
          this.$alertError(this.$t(`global/errors/message`));
        }
      });
    },
    close() {
      this.isVisible = false;
      this.$nextTick(() => {
        this.formData = {
          slideId: '',
          comment: '',
        };
      });
    },
    setRequestTypeId(value) {
      this.formData.alwRequestTypeId = value;
    },
  },
};
</script>

<style lang="scss">
.modal-create-alw-request {
  .multiselect {
    margin: 10px 0 10px 0;
  }
}
</style>

<template>
  <div>
    <modal-confirm-slide-block
      ref="confirmSlideBlockModal"
      :title="$t('components/lims/modals/ModalConfirmation.title')"
      :description="confirmSlideBlockModalDescription"
      @onConfirm="onConfirmActionInSlideBlock"
      @onCancel="onCancelActionInSlideBlock"
    />
    <v-select
      multiple
      v-model="formData"
      :appendToBody="true"
      :options="options"
      :disabled="disabled"
      tabenable="yes"
      :tabindex="tabindex"
      @option:selected="onAddSlide"
      @option:deselected="onRemoveSlide"
    >
      <template #option="{ label }">{{ label }}</template>
      <template #selected-option="{ label }">{{ label }}</template>
    </v-select>
  </div>
</template>

<script>
import { ACTION_IN_SLIDE_BLOCK } from '@/core/constants';
import caseSlideMixins from '../Edit/caseSlide.mixins';
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'SpecimenDetailSlides',
  mixins: [caseSlideMixins],
  props: {
    value: {
      required: true,
    },
    options: {},
    disabled: {},
    tabindex: {},
    dataEdit: {},
    specimenIndex: {},
    blockIndex: {},
  },
  created() {
    this.formData = cloneDeep(this.value);
  },
  watch: {
    value: {
      deep: true,
      handler: function (newValue) {
        this.formData = cloneDeep(newValue);
      },
    },
  },
  data: function () {
    return {
      formData: [],
      newSlide: null,
      removeSlide: null,
      isConfirmed: false,
      confirmSlideBlockModalDescription: '',
    };
  },
  methods: {
    onAddSlide(newSlide) {
      if (this.$isStatusShowModalConfirmSlideBlock) {
        this.newSlide = newSlide;
        this.$showModalConfirmSlideBlock(ACTION_IN_SLIDE_BLOCK.ADD_SLIDE);
        return;
      }
      // default allow changes
      this.emitSlideChanged();
    },
    async onRemoveSlide(removeSlide) {
      if (this.$isStatusShowModalConfirmSlideBlock) {
        this.removeSlide = removeSlide;
        // call api check pending alw
        const { valid, error } = await this.$checkSlideInPendingAlwRequest({
          specimenIndex: this.specimenIndex,
          blockIndex: this.blockIndex,
          slideIds: [removeSlide.fieldItemId],
          caseId: this.dataEdit.caseId,
        });
        if (valid) {
          return this.$showModalConfirmSlideBlock(ACTION_IN_SLIDE_BLOCK.DELETE_SLIDE);
        }
        // revert if not valid then show error
        this.revertSlideChanged();
        this.$alertError(error);
        return;
      }
      // default allow changes
      this.emitSlideChanged();
    },
    onCancelActionInSlideBlock() {
      // revert changes
      this.revertSlideChanged();
    },
    onConfirmActionInSlideBlock() {
      this.emitSlideChanged();
    },
    revertSlideChanged() {
      this.formData = cloneDeep(this.value);
    },
    emitSlideChanged() {
      this.$emit('input', this.formData);
      this.newSlide = null;
      this.removeSlide = null;
    },
  },
};
</script>

<style lang="scss" scoped></style>

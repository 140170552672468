import Vue from 'vue';
import data from './app/data';
import event from './app/event';
import { deleteCookie, setCookie } from '@/core/helpers';
import { LOCAL_STORAGE_KEYS } from '@/core/constants';

// initial state
const state = () => ({
  isLoading: false,
  appVersion: '0.1',
  appError: null,
  leftTimeForPasswordCheck: 0,
  enablePasswordCheck: false,
  changeDetection: {
    changed: false,
    // confirmed to leave without saving data
    confirmed: false,
    // to : toUrl
    toUrl: null,
  },
  headingTitle: '',
  numberOfUnreadMessages: 0,
  redirectName: null,
  appSettings: {
    sessionTimeout: 600,
    maxNumberCasesForBulkAction: 300,
    maxNumberCasesForSB: 200,
    maxNumberCasesForExport: 300,
    maxSelectAll: 1000,
  },
  isModalGenericLoadingOpen: false,
});

// getters
const getters = {
  isModalGenericLoadingOpen: (state) => {
    return state.isModalGenericLoadingOpen;
  },
  headingTitle: (state) => {
    return state.headingTitle;
  },
  numberOfUnreadMessages: (state) => {
    return state.numberOfUnreadMessages;
  },
  redirectName: (state) => {
    return state.redirectName;
  },
  maxNumberCasesForBulkAction: (state) => {
    return state.appSettings?.maxNumberCasesForBulkAction || 300;
  },
  maxNumberCasesForSB: (state) => {
    return state.appSettings?.maxNumberCasesForSB || 200;
  },
  maxSelectAll: (state) => {
    return state.appSettings?.maxSelectAll || 1000;
  },
  maxNumberCasesForExport: (state) => {
    return state.appSettings?.maxNumberCasesForExport || 300;
  },
};

// actions
const actions = {
  $openModalGenericLoading({ commit }) {
    commit('setIsModalGenericLoadingOpen', true);
  },
  $closeModalGenericLoading({ commit }) {
    commit('setIsModalGenericLoadingOpen', false);
  },
  $setAppSettings({ commit }, values) {
    commit('setAppSettings', values);
  },
  $setNumberOfUnreadMessages({ commit }, numberOfUnreadMessages) {
    commit('setNumberOfUnreadMessages', numberOfUnreadMessages);
  },
  $setHeadingTitle({ commit }, headingTitle) {
    commit('setHeadingTitle', headingTitle);
  },
  $confirmUnSaveChanges({ commit }) {
    commit('confirmUnSaveChanges');
  },
  $setChangeDetection({ commit }) {
    commit('setChangeDetection');
  },
  $resetChangeDetection({ commit }) {
    commit('resetChangeDetection');
  },
  $setRedirectName({ commit }, redirectName) {
    commit('setRedirectName', redirectName);
  },
  $setToUrl({ commit }, { toUrl }) {
    commit('setToUrl', {
      toUrl,
    });
  },
  $showLoading({ commit }) {
    commit('toggleLoading', { isLoading: true });
  },
  $hideLoading({ commit }) {
    commit('toggleLoading', { isLoading: false });
  },
  changeAppVersion({ commit }, appVersion) {
    commit('changeAppVersion', appVersion);
  },
  $showAppError({ commit }, { error }) {
    commit('toggleAppError', { error });
  },
  $clearAppError({ commit }) {
    commit('toggleAppError', { error: null });
  },
  $enablePasswordCheck({ commit }, { leftTimeForPasswordCheck }) {
    commit('enablePasswordCheck', { leftTimeForPasswordCheck });
  },
  $updateLeftTimeForPasswordCheck({ commit }, { leftTimeForPasswordCheck }) {
    commit('updateLeftTimeForPasswordCheck', { leftTimeForPasswordCheck });
  },
  $disablePasswordCheck({ commit }) {
    commit('disablePasswordCheck');
  },
};

// mutations
const mutations = {
  setIsModalGenericLoadingOpen(state, isOpen) {
    state.isModalGenericLoadingOpen = isOpen;
  },
  setHeadingTitle(state, headingTitle) {
    state.headingTitle = headingTitle;
  },
  setRedirectName(state, redirectName) {
    state.redirectName = redirectName;
  },
  toggleLoading(state, payload) {
    state.isLoading = payload.isLoading;
  },
  changeAppVersion(state, appVersion) {
    state.appVersion = appVersion;
  },
  toggleAppError(state, { error }) {
    state.appError = error;
  },
  enablePasswordCheck(state, { leftTimeForPasswordCheck }) {
    state.leftTimeForPasswordCheck = leftTimeForPasswordCheck;
    state.enablePasswordCheck = true;
    // persist data
    setCookie(LOCAL_STORAGE_KEYS.ENABLE_PASSWORD_CHECK, 1);
    setCookie(LOCAL_STORAGE_KEYS.LEFT_TIME_FOR_PASSWORD_CHECK, leftTimeForPasswordCheck);
  },
  disablePasswordCheck(state) {
    state.leftTimeForPasswordCheck = 0;
    state.enablePasswordCheck = false;
    deleteCookie(LOCAL_STORAGE_KEYS.ENABLE_PASSWORD_CHECK);
    deleteCookie(LOCAL_STORAGE_KEYS.LEFT_TIME_FOR_PASSWORD_CHECK);
  },
  updateLeftTimeForPasswordCheck(state, { leftTimeForPasswordCheck }) {
    state.leftTimeForPasswordCheck = leftTimeForPasswordCheck;
    // persist data
    setCookie(LOCAL_STORAGE_KEYS.LEFT_TIME_FOR_PASSWORD_CHECK, leftTimeForPasswordCheck);
  },
  confirmUnSaveChanges(state) {
    const { changeDetection } = state;
    Vue.set(state, 'changeDetection', {
      ...changeDetection,
      confirmed: true,
    });
  },
  setChangeDetection(state) {
    const { changeDetection } = state;
    Vue.set(state, 'changeDetection', {
      ...changeDetection,
      changed: true,
      confirmed: false,
      toUrl: null,
    });
  },
  resetChangeDetection(state) {
    Vue.set(state, 'changeDetection', {
      changed: false,
      confirmed: false,
      toUrl: null,
    });
  },
  setToUrl(state, { toUrl }) {
    const { changeDetection } = state;
    Vue.set(state, 'changeDetection', {
      ...changeDetection,
      toUrl,
    });
  },
  setNumberOfUnreadMessages(state, numberOfUnreadMessages) {
    state.numberOfUnreadMessages = numberOfUnreadMessages;
  },
  setAppSettings(state, values) {
    state.appSettings = values;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    data,
    event,
  },
};

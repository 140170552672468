<template>
  <ValidationObserver>
    <div class="p-specimen diagnosisSpecimenBlock" v-if="formData && componentData">
      <collapse
        :wrapperClass="'case-collapse diagnosis-collapse'"
        :collapse="[collapseSpecimenName(number)]"
        icon="keyboard_arrow_down"
      >
        <template slot="md-collapse-pane-1">
          <audio
            v-if="isPathologistView && !disabled"
            ref="finishAudio"
            :src="require(`@/assets/media/finish.mp3`)"
            type="audio/mpeg"
          ></audio>
          <audio
            v-if="isPathologistView && !disabled"
            ref="startAudio"
            :src="require(`@/assets/media/start.mp3`)"
            type="audio/mpeg"
          ></audio>
          <div
            v-if="isPathologistView && assignButtonList.length > 0 && !disabled"
            class="md-layout lims-form-row assigned-buttons-list"
          >
            <md-button
              @click="viewMode ? '' : onApplyPersonalReport(assignedButton.reportId)"
              v-for="(assignedButton, assignedButtonIndex) in assignButtonList"
              :key="assignedButtonIndex"
              class="assigned-button"
              :disabled="assignedButton.buttonColorName === 'silver'"
              :style="{ 'background-color': rgbColor[assignedButton.buttonColorName] + '!important', width: '100px' }"
            >
              <span class="assigned-button-title">{{ assignedButton.title }}</span>
              <span class="assigned-button-subtitle">{{ assignedButton.subTitle }}</span>
            </md-button>
          </div>
          <div
            class="md-layout lims-form-row personalised-reports"
            v-if="isPathologistView && personalisedReports.length > 0 && !disabled"
          >
            <div class="md-layout-item md-size-70"></div>
            <div class="md-layout-item md-size-30">
              <v-select
                :options="buildPersonalisedReportOptions(personalisedReports)"
                label="label"
                :reduce="(option) => option.value"
                v-model="casePersonalizedReportId"
                :disabled="viewMode || isAdminView || disabled"
                tabenable="yes"
                :tabindex="1"
                :clearable="false"
              >
                <template #option="{ label }">{{ label }}</template>
                <template #selected-option="{ label }">{{ label }}</template>
              </v-select>
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-33 md-small-size-100">
              <lims-field :model="formData" :schema="schema" field="snomedT">
                <v-select
                  slot="field"
                  :options="snomedTList"
                  :placeholder="$t('entities/case/form/diagnosis/placeHolder/selectAValue')"
                  label="fieldItemName"
                  :reduce="(option) => option.fieldItemId"
                  v-model="formData.snomed.snomedT"
                  :disabled="viewMode || isAdminView || disabled"
                  tabenable="yes"
                  :tabindex="1"
                >
                  <template #option="{ fieldItemName }">{{ fieldItemName }}</template>
                  <template #selected-option="{ fieldItemName }">{{ fieldItemName }}</template>
                </v-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <lims-field :model="formData" :schema="schema" field="snomedP">
                <v-select
                  slot="field"
                  :options="snomedPList"
                  :placeholder="$t('entities/case/form/diagnosis/placeHolder/selectAValue')"
                  label="fieldItemName"
                  :reduce="(option) => option.fieldItemId"
                  v-model="formData.snomed.snomedP"
                  :disabled="viewMode || disabled || isAdminView"
                  tabenable="yes"
                  :tabindex="1"
                >
                  <template #option="{ fieldItemName }">{{ fieldItemName }}</template>
                  <template #selected-option="{ fieldItemName }">{{ fieldItemName }}</template>
                </v-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-33 md-small-size-100">
              <lims-field :model="formData" :schema="schema" field="snomedM">
                <v-select
                  slot="field"
                  :options="snomedMList"
                  :placeholder="$t('entities/case/form/diagnosis/placeHolder/selectAValue')"
                  label="fieldItemName"
                  :reduce="(option) => option.fieldItemId"
                  v-model="formData.snomed.snomedM"
                  :disabled="viewMode || disabled || isAdminView"
                  tabenable="yes"
                  :tabindex="1"
                >
                  <template #option="{ fieldItemName }">{{ fieldItemName }}</template>
                  <template #selected-option="{ fieldItemName }">{{ fieldItemName }}</template>
                </v-select>
              </lims-field>
            </div>
          </div>
          <div
            v-if="componentData.datasetList.length > 0"
            class="md-layout lims-form-row dataset-list"
            :class="{ 'dataset-list-single': componentData.datasetList.length === 1 }"
          >
            <md-button
              v-for="(dataset, datasetIndex) in componentData.datasetList"
              :key="datasetIndex"
              class="md-button"
              @click="switchActiveDataset(dataset)"
              :class="{ 'md-primary': isActiveDataset(dataset) }"
              :disabled="viewMode || disabled || isAdminView"
            >
              {{ dataset.title }}
            </md-button>
          </div>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100">
              <label>{{ $t('entities/case/form/diagnosis/microscopy') }}</label>
            </div>
          </div>
          <div v-if="formData.caseDataset" class="caseDataset">
            <div
              v-for="(control, controlIndex) in formData.caseDataset.controls"
              :key="controlIndex"
              class="md-layout lims-form-row"
            >
              <div class="md-layout-item md-size-30">
                <label :class="[{ required: control.isRequired }]">{{ control.label }}</label>
              </div>
              <div v-if="control.controlType === FIELD_TYPES.DROPDOWN" class="md-layout-item md-size-50">
                <lims-field
                  :viewMode="'only'"
                  :translatedLabel="control.label"
                  :model="formData"
                  :schema="schema"
                  :field="`field_${control.controlId}`"
                >
                  <v-select
                    slot="field"
                    :options="buildOptions(control.value)"
                    :placeholder="$t('entities/case/form/diagnosis/placeHolder/selectAValue')"
                    label="label"
                    :reduce="(option) => option.value"
                    v-model="formData.values[`field_${control.controlId}`]"
                    :disabled="viewMode || disabled || isAdminView"
                    tabenable="yes"
                    :tabindex="1"
                  >
                    <template #option="{ label }">{{ label }}</template>
                    <template #selected-option="{ label }">{{ label }}</template>
                  </v-select>
                </lims-field>
              </div>
              <div v-else class="md-layout-item md-size-70">
                <lims-field
                  :viewMode="'only'"
                  :translatedLabel="control.label"
                  :model="formData"
                  :schema="schema"
                  :field="`field_${control.controlId}`"
                >
                  <md-input
                    slot="field"
                    v-model="formData.values[`field_${control.controlId}`]"
                    type="text"
                    :disabled="viewMode || disabled || isAdminView"
                    tabenable="yes"
                  ></md-input>
                </lims-field>
              </div>
            </div>
          </div>
          <div class="md-layout lims-form-row" v-if="!formData.caseDataset">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <lims-field
                :viewMode="'only'"
                class="textarea-field"
                :model="formData"
                :schema="schema"
                field="microscopy"
              >
                <md-textarea
                  @keydown="onKeyDown($event)"
                  slot="field"
                  v-model="formData.microscopy"
                  :disabled="viewMode || disabled || isAdminView"
                  tabenable="yes"
                ></md-textarea>
              </lims-field>
            </div>
          </div>
        </template>
      </collapse>
    </div>
  </ValidationObserver>
</template>

<script>
import { Collapse } from '@/components';
import datasetService from '@/services/dataset.service';
import cloneDeep from 'lodash/cloneDeep';
import { DatasetFieldTypes, RGB_COLORS } from '@/core/constants';
import debounce from 'lodash/debounce';
import CaseMixins from '@/pages/Case/CaseManagement/Case.mixins';
import CaseBlockMixins from '@/pages/Case/CaseManagement/CaseBlock.mixins';
import CaseSpecimenMixins, { generateSpecimenName } from '@/pages/Case/CaseManagement/CaseSpecimen.mixins';
import { mapActions, mapGetters } from 'vuex';

const DEFAULT_SCHEMA = {
  entity: 'case/form/diagnosis',
  fields: {
    snomedT: '',
    snomedP: '',
    snomedM: '',
    microscopy: '',
  },
};

export default {
  mixins: [CaseMixins, CaseBlockMixins, CaseSpecimenMixins],
  components: { Collapse },
  props: {
    pathologistId: {
      type: String,
      require: true,
    },
    entityId: {
      type: String,
      require: true,
    },
    number: {
      type: Number,
      require: true,
    },
    snomedTList: {
      type: Array,
      require: true,
    },
    snomedPList: {
      type: Array,
      require: true,
    },
    snomedMList: {
      type: Array,
      require: true,
    },
    caseSpecimen: {
      type: Object,
      require: true,
    },
    assignedButtons: {
      type: Array,
      require: true,
    },
    personalisedReports: {
      type: Array,
      require: true,
    },
    formMode: {
      require: true,
    },
    clinicName: {
      require: false,
    },
    clinicId: {
      require: false,
    },
    disabled: {
      require: false,
      type: Boolean,
      default: false,
    },
    isOneMicroscopyForAllSpecimens: {
      require: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('caseData', ['caseData']),
    schema() {
      return this.componentData.schema;
    },
    FIELD_TYPES() {
      return DatasetFieldTypes;
    },
    collapseSpecimenName() {
      const name = 'pages/case/CaseManagement/SpecimenDetail/Specimen/blockTitle';

      return (index) => {
        if (this.isOneMicroscopyForAllSpecimens) {
          return this.$translate(name);
        }
        return this.$translate(name) + ' ' + generateSpecimenName(index - 1, this.blockNamingRuleSetting);
      };
    },
    isPathologistView() {
      return this.isPathologist();
    },
    isAdminView() {
      return this.userType === this.USER_TYPES().Administrator;
    },
    rgbColor() {
      return RGB_COLORS;
    },
  },
  async created() {
    if (this.caseData && this.caseData.laboratoryId) {
      this.blockNamingRuleSetting = await this.$getBlockNamingRuleSetting(this.caseData.laboratoryId);
    } else {
      this.blockNamingRuleSetting = await this.$getBlockNamingRuleSetting(this.entityId);
    }
    this.userType = this.$store.getters['auth/userType'];
    const snomedTfieldId = this.snomedTList[0]?.fieldId;
    const snomedPfieldId = this.snomedPList[0]?.fieldId;
    const snomedMfieldId = this.snomedMList[0]?.fieldId;

    // save case ref
    this.caseSpecimenRef = cloneDeep(this.caseSpecimen);
    // prepare form data
    const formData = {
      snomed: {
        snomedT: '',
        snomedP: '',
        snomedM: '',
      },
      microscopy: '',
      caseDataset: null,
      values: null,
    };

    // set snomed
    this.caseSpecimen.caseSpecimenDiagnosis.map((item) => {
      if (item) {
        const { fieldId, fieldItemId } = item;
        if (fieldId === snomedTfieldId) {
          formData.snomed.snomedT = fieldItemId;
        }
        if (fieldId === snomedPfieldId) {
          formData.snomed.snomedP = fieldItemId;
        }
        if (fieldId === snomedMfieldId) {
          formData.snomed.snomedM = fieldItemId;
        }
      }
    });
    // set caseDataset
    formData.caseDataset = this.caseSpecimen.caseDataset;
    formData.microscopy = this.caseSpecimen.microscopy;
    // use mock data for test

    // prepare component data
    const componentData = {
      datasetList: [],
      schema: null,
    };
    // datasets list should be preloaded
    // TODO: replace with data from this.caseSpecimen.datasetList
    const datasetList = await this.loadTriggerDatasets(formData.snomed);

    componentData.datasetList = datasetList;

    if (componentData.datasetList.length > 0 && !formData.caseDataset) {
      formData.caseDataset = componentData.datasetList[0];
    }
    // build schema
    const { schema, values } = this.buildFormSchema(formData.caseDataset);
    componentData.schema = schema;
    formData.values = values;

    this.$set(this, 'formData', formData);
    this.$set(this, 'componentData', componentData);
    this.$emit('onDiagnosisSpecimenReady', { t: Date.now() });

    const emptyButton = {
      buttonColorName: 'silver',
    };
    let assignedButtons = cloneDeep(this.assignedButtons);

    for (let i = 0; i < 20; i++) {
      const buttonItem = assignedButtons.find((item) => item.displayOrder === i);
      if (buttonItem) {
        this.assignButtonList.push(buttonItem);
      } else {
        this.assignButtonList.push(emptyButton);
      }
    }
  },
  watch: {
    caseSpecimenRef: {
      deep: true,
      handler: function (value) {
        this.$emit('onChangeCaseSpecimen', {
          number: this.number,
          value,
        });
      },
    },
    'formData.snomed': {
      deep: true,
      handler: async function (snomed, before) {
        if (before) {
          const datasetList = await this.loadTriggerDatasets(snomed);

          this.$set(this.componentData, 'datasetList', datasetList);
          this.switchActiveDataset(datasetList[0] || null);
          const caseSpecimenDiagnosis = [];

          if (snomed.snomedT) {
            const snomedT = this.snomedTList.filter((field) => field.fieldItemId === snomed.snomedT);
            if (snomedT.length) {
              caseSpecimenDiagnosis.push(snomedT[0]);
            }
          }
          if (snomed.snomedP) {
            const snomedP = this.snomedPList.filter((field) => field.fieldItemId === snomed.snomedP);
            if (snomedP.length) {
              caseSpecimenDiagnosis.push(snomedP[0]);
            }
          }
          if (snomed.snomedM) {
            const snomedM = this.snomedMList.filter((field) => field.fieldItemId === snomed.snomedM);
            if (snomedM.length) {
              caseSpecimenDiagnosis.push(snomedM[0]);
            }
          }
          this.$set(this.caseSpecimenRef, 'caseSpecimenDiagnosis', caseSpecimenDiagnosis);
        }
      },
    },
    'formData.caseDataset': {
      deep: true,
      handler: function (val) {
        // this.caseSpecimenRef.microscopy = microscopy;
        this.$set(this.caseSpecimenRef, 'caseDataset', val);
      },
    },
    'formData.values': {
      deep: true,
      handler: function (values) {
        // this.caseSpecimenRef.microscopy = microscopy;
        this.$set(this.caseSpecimenRef, 'caseDatasetValues', values);
      },
    },
    'formData.microscopy': {
      deep: true,
      handler: function (microscopy) {
        this.caseSpecimenRef.microscopy = microscopy;
      },
    },
    casePersonalizedReportId: {
      deep: true,
      handler: function (casePersonalizedReportId) {
        // find matched personalize report and apply
        // childrenReport: use parentReport id to check logic
        // parentReport: use its casePersonalizedReportId to check logic
        // both: submit its casePersonalizedReportId

        // search as children report first
        let matchedReport = this.personalisedReports.find((p) => {
          return p.casePersonalizedReportParentId === casePersonalizedReportId && p.clinicId === this.clinicId;
        });
        // search as parent report after if children report not existed
        if (!matchedReport) {
          matchedReport = this.personalisedReports.find((p) => {
            return p.casePersonalizedReportId === casePersonalizedReportId;
          });
        }
        // apply report if found
        if (matchedReport) {
          this.applyPersonalReport(matchedReport);
          this.$emit('onChangePersonalizedReportId', matchedReport.casePersonalizedReportId);
        }
      },
    },
    enabledShortcut: {
      deep: true,
      handler: function (enabledShortcut) {
        if (enabledShortcut) {
          this.$refs.startAudio.play();
          this.keyStrokeTimeout = setTimeout(() => {
            this.endKeystrokeWatch();
          }, 5000);
        }
      },
    },
    keyStrokes: {
      deep: true,
      handler: debounce(async function (keyStrokes) {
        if (keyStrokes && keyStrokes.length > 0) {
          const matchedReport = this.findMatchedReport(keyStrokes.join(''));
          if (matchedReport) {
            this.casePersonalizedReportId = matchedReport.casePersonalizedReportId;
            clearTimeout(this.keyStrokeTimeout);
            this.endKeystrokeWatch();
          }
        }
      }, 500),
    },
  },
  data() {
    return {
      caseSpecimenRef: null,
      formData: null,
      componentData: null,
      enabledShortcut: false,
      keyStrokes: [],
      keyStrokeTimeout: null,
      casePersonalizedReportId: null,
      assignButtonList: [],
      blockNamingRuleSetting: { enable1ANamingConvention: false, skippedIO: false },
      isConfictSnomed: false,
      formDataSnomedOrgin: null,
    };
  },
  methods: {
    ...mapActions('caseForm', ['setConfictSnomed']),
    isPathologist() {
      return this.userType === this.USER_TYPES().Pathologist;
    },
    endKeystrokeWatch() {
      if (this.enabledShortcut) {
        this.enabledShortcut = false;
        this.$set(this, 'keyStrokes', []);
        this.$refs.finishAudio.play();
      }
    },
    onKeyDown($event) {
      if (!this.isPathologist()) {
        return;
      }
      if (this.enabledShortcut) {
        // push keys
        this.keyStrokes.push($event.key);
        $event.preventDefault();
      }
      if (!this.enabledShortcut && $event.key === 's' && ($event.altKey || $event.ctrlKey)) {
        this.enabledShortcut = true;
        this.$set(this, 'keyStrokes', []);
      }
    },
    applyPersonalReport(report) {
      let snomedName = '';
      const formDataSnomed = {
        snomedM: this.formData.snomed.snomedM ? this.formData.snomed.snomedM : report.snomedMFieldItem?.fieldItemId,
        snomedT: this.formData.snomed.snomedT ? this.formData.snomed.snomedT : report.snomedTFieldItem?.fieldItemId,
        snomedP: this.formData.snomed.snomedP ? this.formData.snomed.snomedP : report.snomedPFieldItem?.fieldItemId,
      };
      const checkSnomedM = this.checkItemIncludedInSnomedList(formDataSnomed.snomedM, this.snomedMList);
      const checkSnomedP = this.checkItemIncludedInSnomedList(formDataSnomed.snomedP, this.snomedPList);
      const checkSnomedT = this.checkItemIncludedInSnomedList(formDataSnomed.snomedT, this.snomedTList);
      // validate snomed values
      if (checkSnomedM) {
        formDataSnomed.snomedM = null;
        snomedName = 'M';
      }
      if (checkSnomedP) {
        formDataSnomed.snomedP = null;
        if (snomedName === '') {
          snomedName += 'P';
        } else {
          snomedName += ', P';
        }
      }
      if (checkSnomedT) {
        formDataSnomed.snomedT = null;
        if (snomedName === '') {
          snomedName += 'T';
        } else {
          snomedName += ', T';
        }
      }
      if (checkSnomedM || checkSnomedT || checkSnomedP) {
        this.isConfictSnomed = true;
        this.$alertSuccess(
          this.$t('entities/personalised-report/form/report/warning', {
            casesClinic: this.clinicName,
            snomedName: snomedName,
          }),
          1000 * 300,
        );
      } else {
        this.isConfictSnomed = false;
      }
      this.setConfictSnomed({ specimen: this.number, isConfictSnomed: this.isConfictSnomed });

      // change snomed
      const microscopyNew = report.microscopy ? '\n' + report.microscopy : '';
      this.formData.microscopy = this.formData.microscopy
        ? this.formData.microscopy + microscopyNew
        : microscopyNew.replace(/^[\r\n]/, '');

      this.$set(this.formData, 'snomed', formDataSnomed);
      // change comment
      this.$emit('onChangeCaseCommentOrDiagnosisSummary', report.comment);
    },

    async loadTriggerDatasets(snomed) {
      const { data, error } = await datasetService.getTriggerDataset(this.entityId, snomed);
      if (error) {
        this.$alertError(error);
        return [];
      }
      return data;
    },
    checkItemIncludedInSnomedList(snomedId, snomedList) {
      return snomedId ? !this.findSnomedInSnomedList(snomedId, snomedList) : false;
    },
    findSnomedInSnomedList(snomedId, snomedList) {
      return snomedList.find((snomedItem) => snomedItem.fieldItemId == snomedId);
    },
    buildOptions(value) {
      const values = value.split(',').map((v) => v.trim());
      return values.map((v) => {
        return {
          label: v,
          value: v,
        };
      });
    },
    switchActiveDataset(dataset) {
      this.applyDataset(dataset);
    },
    isActiveDataset(dataset) {
      return this.formData.caseDataset && this.formData.caseDataset.datasetId == dataset.datasetId;
    },
    applyDataset(dataset) {
      // reset active
      const { schema, values } = this.buildFormSchema(dataset);
      if (dataset) {
        this.$set(this.formData, 'caseDataset', dataset);
        this.$set(this.formData, 'values', values);
        this.$set(this.componentData, 'schema', schema);
      } else {
        this.$set(this.formData, 'caseDataset', null);
        this.$set(this.formData, 'values', null);
        this.$set(this.componentData, 'schema', schema);
      }
    },
    buildFormSchema(dataset) {
      if (dataset) {
        const { controls } = dataset;
        const schema = cloneDeep(DEFAULT_SCHEMA);
        const values = {};
        controls.map((c) => {
          schema.fields[`field_${c.controlId}`] = c.isRequired ? 'required' : '';
          values[`field_${c.controlId}`] = c.selectValue || '';
        });
        return { schema, values };
      }
      return { schema: cloneDeep(DEFAULT_SCHEMA), values: null };
    },
    findMatchedReport(keystrokes) {
      const matchedReports = this.personalisedReports.filter((p) => {
        return p.shortcut && p.shortcut.toLowerCase() === keystrokes.toLowerCase();
      });
      if (matchedReports && matchedReports.length > 0) {
        return matchedReports.find((matchedReport) => matchedReport.isParent === true);
      }
    },
    buildPersonalisedReportOptions(personalisedReports) {
      let filterPrListByPathologistId = personalisedReports.filter(
        (pr) => pr.isParent === true && pr.pathologistId === this.pathologistId,
      );
      return filterPrListByPathologistId.map((p) => {
        return {
          value: p.casePersonalizedReportId,
          label: p.title,
        };
      });
    },
    onApplyPersonalReport(reportId) {
      if (this.casePersonalizedReportId !== reportId) {
        const matchedReports = this.personalisedReports.filter((p) => {
          return p.casePersonalizedReportId === reportId;
        });

        if (matchedReports.length > 0) {
          this.casePersonalizedReportId = reportId;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.diagnosisSpecimenBlock {
  .dataset-list {
    display: flex;
    gap: 10px;
  }
  .dataset-list {
    display: flex;
    gap: 10px;
  }
  .dataset-list-single {
    display: none;
  }
  .assigned-buttons-list {
    display: flex;
    gap: 10px;
  }
  .md-button.assigned-button .md-button-content {
    flex-direction: column;
    white-space: nowrap;
  }
  .md-button.assigned-button {
    width: 9% !important;
    min-height: 64px;
  }
  .assigned-button-title {
    font-size: 1rem;
    white-space: normal;
  }
  .assigned-button-subtitle {
    font-size: 0.7rem;
    white-space: normal;
  }
}
</style>

<template>
  <form ref="form" v-if="filters && filterValues">
    <div class="list-filter normal-list-filter">
      <div class="normal-list-filter-wrapper">
        <slot name="rowsPerPage"></slot>
        <div class="filter-input">
          <div class="custom-select">
            <div class="custom-select-input" @click="toggleSelectList()">
              <md-field>
                <md-input
                  v-model="valueFilter"
                  autocomplete="off"
                  :placeholder="$t('component/lims/normalList/placeholder/SelectFilter')"
                  @input="searchOnFilter"
                ></md-input>
              </md-field>
              <span :class="!isOpen ? 'is-hide icon' : 'icon'"></span>
            </div>
            <div v-if="isOpen" class="custom-select-content">
              <md-list :md-expand-single="false">
                <div v-if="valueFilter && valueFilter.length > 0">
                  <template v-if="searchFilter.length > 0">
                    <div v-for="(i, index) in searchFilter" :key="`item-${index}`" @click="isOpen = !isOpen">
                      <md-checkbox v-model="selectFilter" :value="i">{{ i.text }}</md-checkbox>
                    </div>
                  </template>
                  <div v-else class="no-result">Sorry, no matching options.</div>
                </div>
                <md-list-item
                  v-else
                  v-for="(item, index) in filterList"
                  :key="`select-${index}`"
                  md-expand
                  :md-expanded.sync="expandItem[item.id]"
                >
                  <span class="md-list-item-text">{{ item.groupName }}</span>
                  <md-list slot="md-expand">
                    <div v-for="(i, index) in item.groupValues" :key="`item-${index}`" @click="isOpen = !isOpen">
                      <md-checkbox v-model="selectFilter" :value="i">{{ i.text }}</md-checkbox>
                    </div>
                  </md-list>
                </md-list-item>
              </md-list>
            </div>
          </div>

          <md-field class="input-search">
            <md-icon>search</md-icon>
            <md-input
              v-model="filters.search"
              :placeholder="$t('component/lims/normalList/placeholder/search')"
              maxlength="250"
              @keyup.enter="onSearch"
            ></md-input>
          </md-field>

          <md-checkbox
            v-if="isShowCheckboxIncludedReportedCase"
            v-model="filterValues.IsIncludedReportedCase"
            class="lims-checkbox checkbox-field checkbox-include-report-case"
          >
            {{ $t('pages/case/CaseManagement/List/NormalSearch/checkbox.IncludedReportedCase') }}
          </md-checkbox>
        </div>
      </div>

      <div v-if="selectFilter && selectFilter.length > 0" class="lims-form advance-search-wrapper">
        <div class="md-layout lims-form-row">
          <div
            v-for="(item, index) in selectFilter.filter((i) => i !== null)"
            :key="`case-filter-${index}`"
            class="md-layout-item md-size-50 md-small-size-100"
          >
            <div class="advance-search-inner">
              <md-checkbox v-model="checkboxValue[item.key]"></md-checkbox>
              <template v-if="isTextInput(item)">
                <span class="label-item">{{ item.text }}</span>
                <md-field>
                  <md-input
                    v-model="filterValues[item.key]"
                    :disabled="isShowAdvancedCaseRef && item.key == 'CaseReference'"
                  ></md-input>
                </md-field>
                <div v-if="item.key == 'CaseReference'" @click="onShowAdvancedCaseRef()" class="advanced-child-label">
                  {{ $t('component/lims/normalList/AdvancedCaseRef') }}
                </div>
              </template>

              <template v-if="isDropdownMenuSingle(item)">
                <span class="label-item">{{ item.text }}</span>
                <lims-select
                  v-model="filterValues[item.key]"
                  label="text"
                  :options="dataSource[item.key]"
                  :translated="true"
                  :placeholder="''"
                  :class="filterValues[item.key] && filterValues[item.key].length > 0 ? 'is-disabled' : ''"
                ></lims-select>
              </template>

              <template v-if="isDropdownMenuMulti(item)">
                <span class="label-item">{{ item.text }}</span>
                <lims-select
                  v-model="filterValues[item.key]"
                  label="text"
                  :options="dataSource[item.key]"
                  :placeholder="''"
                  :translated="true"
                ></lims-select>
              </template>

              <template v-if="isDropdownMenuMultiGroup(item)">
                <span class="label-item">{{ item.text }}</span>
                <lims-multi-select
                  :options="dataSource[item.key]"
                  :groupOptions="dataSource[`group${item.key}`]"
                  v-model="filterValues[item.key]"
                  itemName="items"
                  groupName="itemName"
                  :placeholder="''"
                  style="width: 100%"
                ></lims-multi-select>
              </template>

              <template v-if="IsDatePicker(item)">
                <span class="label-item">{{ item.text }}</span>
                <date-picker v-model="filterValues[item.key]" format="DD/MM/YYYY"></date-picker>
              </template>

              <template v-if="IsDateRangePicker(item)">
                <span class="label-item">{{ item.text }}</span>
                <date-picker
                  range
                  v-model="filterValues[item.key]"
                  format="DD/MM/YYYY"
                  :placeholder="$t('global/placeholder.dateRangePicker')"
                ></date-picker>
              </template>

              <template v-if="IsTimeRange(item)">
                <span class="label-item">{{ item.text }}</span>
                <date-picker
                  type="time"
                  v-model="filterValues[item.key].From"
                  format="HH:mm:ss"
                  :placeholder="$t('component/lims/normalList/placeholder/From')"
                  style="width: 100px; margin-right: 10px"
                ></date-picker>
                <date-picker
                  type="time"
                  v-model="filterValues[item.key].To"
                  format="HH:mm:ss"
                  :placeholder="$t('component/lims/normalList/placeholder/To')"
                  style="width: 100px"
                ></date-picker>
              </template>

              <template v-if="isToggle(item)">
                <span class="label-item">{{ item.text }}</span>
                <md-switch v-model="filterValues[item.key]" class="md-primary"></md-switch>
              </template>

              <template v-if="IsSpinbox(item)">
                <span class="label-item">{{ item.text }}</span>
                <md-field style="width: 100px">
                  <md-input
                    type="number"
                    min="0"
                    v-model="filterValues[item.key]"
                    @keypress="isNumber($event)"
                    style="width: 100%"
                  ></md-input>
                </md-field>
              </template>
            </div>

            <div v-show="isShowAdvancedCaseRef && item.key == 'CaseReference'" class="advanced-child-content">
              <div v-for="(item, index) in caseRefAdvancedList" :key="`caseRef-${index}`" class="advanced-child-inner">
                {{ $t('component/lims/normalList/AdvancedCaseRef/Year') }}
                <v-select multiple slot="field" :options="yearsList" v-model="item.year" class="year-dropdown">
                  <template #option="{ label }">{{ label }}</template>
                  <template #selected-option="{ label }">{{ label }}</template>
                </v-select>

                <v-select
                  slot="field"
                  :options="searchMethodList"
                  :reduce="(item) => item.value"
                  :clearable="false"
                  v-model="item.searchMethod"
                  class="search-method"
                >
                  <template #option="{ label }">{{ label }}</template>
                  <template #selected-option="{ label }">{{ label }}</template>
                </v-select>

                <md-field style="width: 150px">
                  <md-input
                    type="number"
                    min="0"
                    v-model="item.fromMonth"
                    @keypress="isNumber($event)"
                    class="textbox-value"
                  ></md-input>
                  <md-input
                    v-if="item.searchMethod == 2"
                    type="number"
                    min="0"
                    v-model="item.toMonth"
                    @keypress="isNumber($event)"
                    class="textbox-value"
                  ></md-input>
                </md-field>
                <span class="removeBtn" @click="removeCaseRefAdvanced(item)"><md-icon>close</md-icon></span>
                <span class="addBtn" @click="addNewCaseRefAdvanced()"><md-icon>add</md-icon></span>
              </div>
            </div>
          </div>
        </div>

        <lims-chip-area :options="chipOptions" @onRemoveChip="$onRemoveChip"></lims-chip-area>

        <div class="filter-action">
          <md-button @click="onSearch" class="md-button md-primary lims-form-button md-theme-default">
            {{ $t('global/button/button.search') }}
          </md-button>
        </div>
      </div>

      <div class="filter-action">
        <md-button @click="onClear">
          {{ $t('global/button/button.clear') }}
        </md-button>
      </div>
    </div>
  </form>
</template>

<script>
/* eslint-disable security/detect-object-injection */
import { mapActions, mapGetters } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import { ConfigMixins, ListFilterMixins } from '@/core/mixins';
import LimsSelect from '@/components/Lims/LimsSelect.vue';
import LimsChipArea from '@/components/Lims/LimsChipArea.vue';
import { startDay, endDay, isPerfectEmpty, convertFromDateTimezoneToIsoString } from '@/core/helpers';
import {
  CASE_LIST_DROPDOWN,
  CASE_SELECT_FILTER_KEY,
  CASE_STATUSES,
  DROPDOWN_SHORT_NAME,
  FILTER_CASE_LIST_ADMIN,
  FILTER_CASE_LIST_CLINIC,
  FILTER_CASE_LIST_LABTECHNICIAN,
  FILTER_CASE_LIST_PATHOLOGIST,
} from '@/core/constants';
import { caseFormService, caseListService, DropdownService } from '@/services';
import MyAccountService from '@/services/myAccount.service';
import { sortDropDown } from '@/core/helpers';
import { searchOnFilterList, searchOnFilterListByKey } from '@/query/utils';
import LimsMultiSelect from '@/components/Lims/LimsMultiSelect.vue';
import { newAppEvent } from '@/core/constants';
import { APP_EVENTS } from '@/core/constants';

export default {
  mixins: [ConfigMixins, ListFilterMixins],
  components: {
    LimsSelect,
    LimsChipArea,
    LimsMultiSelect,
  },
  props: {
    fields: {
      type: Array,
      require: true,
    },
    defaultValues: {
      type: Object,
      require: true,
    },
    value: {
      type: Object,
      require: true,
    },
  },
  async created() {
    await this.fetchData();
    this.userType = this.$store.getters['auth/userType'];
    this.searchFilter = this.filterList;
  },
  data() {
    return {
      valueFilter: null,
      searchFilter: [],
      filters: {},
      filterValues: null,
      chipOptions: [],
      selectFilter: [],
      isOpen: false,
      checkboxValue: {},
      expandItem: {},
      dataSource: {},
      isAnonymised: null,
      searchMethodList: [
        {
          value: 1,
          label: 'Is',
        },
        {
          value: 2,
          label: 'Between',
        },
      ],

      caseRefAdvancedList: [],
      isShowAdvancedCaseRef: false,
      specimenTypeList: [],
    };
  },
  computed: {
    ...mapGetters('auth', ['userId']),
    filterList() {
      let filterList;
      if (this.userType === this.USER_TYPES().Administrator) {
        filterList = FILTER_CASE_LIST_ADMIN;
      } else if (this.userType === this.USER_TYPES().LabTechnician) {
        filterList = FILTER_CASE_LIST_LABTECHNICIAN;
      } else if (this.userType === this.USER_TYPES().Pathologist) {
        filterList = FILTER_CASE_LIST_PATHOLOGIST;
      } else {
        filterList = FILTER_CASE_LIST_CLINIC;
      }

      if (this.isAnonymised) {
        const groupValueKeyAnonymise = ['PatientFirstName', 'PatientLastName', 'DoB', 'AddressTypeId'];
        return filterList.map((filterList) => {
          return {
            ...filterList,
            groupValues: filterList.groupValues.filter((item) => !groupValueKeyAnonymise.includes(item.key)),
          };
        });
      } else {
        return filterList;
      }
    },
    isShowCheckboxIncludedReportedCase() {
      if (this.userType === this.USER_TYPES().Administrator || this.userType === this.USER_TYPES().LabTechnician) {
        return false;
      } else {
        return true;
      }
    },
    CaseSelectFilterKey() {
      return CASE_SELECT_FILTER_KEY;
    },
    yearsList() {
      const year = new Date().getFullYear();
      const yearList = Array.from({ length: year - 1990 }, (value, index) => 1991 + index);
      return yearList;
    },
    ...mapGetters('app/data', ['getDatasetByKey']),
  },
  watch: {
    filterValues: {
      handler(filterValues) {
        this.$material.locale.dateFormat = 'dd/MM/yyyy';
        // this.filters = filterValues;
        this.$updateChipList({ filterValues, dataSource: this.dataSource, filterFields: this.fields });
      },
      deep: true,
    },
    'filterValues.CaseStatusIds': {
      handler(val) {
        if (this.isShowCheckboxIncludedReportedCase && val && val.length > 0) {
          const caseStatuses = val.map((v) => this.getCaseStatusNameById(v));
          // automatically check the checkbox "Included Reported"
          if (caseStatuses.includes(CASE_STATUSES.REPORTED)) {
            this.filterValues.IsIncludedReportedCase = true;
          }
        }
      },
      deep: true,
    },
    'filterValues.IsSearchAllCase': {
      handler(val) {
        if (this.userType === this.USER_TYPES().Pathologist && val !== null) {
          this.filterValues.IsIncludedReportedCase = val === true ? true : false;
        }
      },
      deep: true,
    },
    'filters.search': {
      handler(val) {
        if (val && val.length > 2) {
          this.onSearch();
        }
      },
      deep: true,
    },
    selectFilter: {
      handler(val) {
        if (val) {
          val.forEach((item) => {
            if (item && this.IsTimeRange(item) && !this.filterValues[item.key]) {
              if (this.IsTimeRange(item)) {
                this.$set(this.filterValues, item.key, {
                  From: '',
                  To: '',
                });
              }
            }
            if (item && this.isToggle(item) && this.filterValues[item.key] === null) {
              if (
                item.text == CASE_SELECT_FILTER_KEY.IsUrgent ||
                item.text == CASE_SELECT_FILTER_KEY.ClinicalIncident ||
                item.text == CASE_SELECT_FILTER_KEY.HasALW ||
                item.text == CASE_SELECT_FILTER_KEY.HasSecondOpinion ||
                item.text == CASE_SELECT_FILTER_KEY.IsDeleted ||
                item.text == CASE_SELECT_FILTER_KEY.IsImported ||
                item.text == CASE_SELECT_FILTER_KEY.NoUpdateAfterRetrieved ||
                item.text == CASE_SELECT_FILTER_KEY.IsExportedForBilling ||
                item.text == CASE_SELECT_FILTER_KEY.SearchAllCases ||
                item.text == CASE_SELECT_FILTER_KEY.HasRF ||
                item.text == CASE_SELECT_FILTER_KEY.DoubleReporting ||
                item.text == CASE_SELECT_FILTER_KEY.TissueRemaining ||
                item.text == CASE_SELECT_FILTER_KEY.TissueDiscarded ||
                item.text == CASE_SELECT_FILTER_KEY.IsRead ||
                item.text == CASE_SELECT_FILTER_KEY.IsCaseOfInterest ||
                item.text == CASE_SELECT_FILTER_KEY.IsSOCase ||
                item.text == CASE_SELECT_FILTER_KEY.IsPullCase ||
                item.text == CASE_SELECT_FILTER_KEY.HasVirtualSlides ||
                item.text == CASE_SELECT_FILTER_KEY.HasUnexpectedFindings ||
                item.text == CASE_SELECT_FILTER_KEY.UnknowBillingEntity ||
                item.text == CASE_SELECT_FILTER_KEY.IncompleteInsuranceDetails ||
                item.text == CASE_SELECT_FILTER_KEY.IncompleteAddressDetails ||
                item.text == CASE_SELECT_FILTER_KEY.HasDraftDiagnosis ||
                item.text == CASE_SELECT_FILTER_KEY.Mdm
              ) {
                this.$set(this.filterValues, item.key, true);
              } else {
                this.$set(this.filterValues, item.key, false);
              }
            }

            if (item && this.checkboxValue[item.key] === undefined) {
              this.$set(this.checkboxValue, item.key, true);
            }
            if (item && this.dataSource[item.key] === undefined) {
              this.$set(this.dataSource, 'SpecialtyIds', this.dataSource[DROPDOWN_SHORT_NAME.SPECIALITY]);
              this.$set(this.dataSource, 'BillingEntityIds', this.dataSource[DROPDOWN_SHORT_NAME.BILLING_ENTITY]);
              this.$set(this.dataSource, 'SnomedT', this.dataSource[DROPDOWN_SHORT_NAME.SNOMED_T]);
              this.$set(this.dataSource, 'SnomedP', this.dataSource[DROPDOWN_SHORT_NAME.SNOMED_P]);
              this.$set(this.dataSource, 'SnomedM', this.dataSource[DROPDOWN_SHORT_NAME.SNOMED_M]);
              this.$set(this.dataSource, 'ClinicTypeIds', this.dataSource[DROPDOWN_SHORT_NAME.CLINIC_TYPE]);
              this.$set(this.dataSource, 'DocumentTypeIds', this.dataSource[DROPDOWN_SHORT_NAME.CASE_DOCUMENT_TYPE]);
              this.$set(this.dataSource, 'FromSourceIds', this.dataSource[DROPDOWN_SHORT_NAME.CASE_SOURCE]);
              this.$set(this.dataSource, 'PmiIds', this.dataSource[DROPDOWN_SHORT_NAME.INSURANCE_COMPANY]);
              this.$set(this.dataSource, 'AddressTypeId', this.dataSource[CASE_LIST_DROPDOWN.ADDRESS]);
              this.$set(this.dataSource, 'CaseStatusIds', this.dataSource[CASE_LIST_DROPDOWN.CASE_STATUS]);
              this.$set(this.dataSource, 'ClinicIds', this.dataSource[CASE_LIST_DROPDOWN.CLINIC]);
              this.$set(this.dataSource, 'LaboratoryIds', this.dataSource[CASE_LIST_DROPDOWN.LABORATORY]);
              this.$set(this.dataSource, 'ClinicianIds', this.dataSource[CASE_LIST_DROPDOWN.CLINICIAN]);
              this.$set(this.dataSource, 'PathologistIds', this.dataSource[CASE_LIST_DROPDOWN.PATHOLOGIST]);
              this.$set(this.dataSource, 'QualityControlIds', this.dataSource[CASE_LIST_DROPDOWN.QUALITY_CONTROL]);
              this.$set(this.dataSource, 'Technician1Ids', this.dataSource[CASE_LIST_DROPDOWN.TECHNICIAN1]);
              this.$set(this.dataSource, 'Technician2Ids', this.dataSource[CASE_LIST_DROPDOWN.TECHNICIAN2]);
              this.$set(this.dataSource, 'CaseIssues', this.dataSource['CaseIssues']);
              this.$set(this.dataSource, 'CaseIssueStatusIds', this.dataSource['CaseIssueStatusIds']);
              this.$set(this.dataSource, 'IssueTypeIds', this.dataSource['IssueTypeIds']);
              this.$set(this.dataSource, 'CaseImportBatchId', this.dataSource['CaseImportBatchId']);
              this.$set(this.dataSource, 'WorkStreamIds', this.dataSource['WorkStreamIds']);
              this.$set(this.dataSource, 'SpecimenTypeIds', this.specimenTypeList.options);
              this.$set(this.dataSource, `groupSpecimenTypeIds`, this.specimenTypeList.groupOptions);
            }
          });
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('app/event', ['addEvent']),
    async fetchData() {
      this.userType = this.$store.getters['auth/userType'];
      this.filters = this.value;
      this.isAnonymised = await this.getIsAnonymizedInfo();

      const dropdownOptions = await DropdownService.getDropdownByShortNames([
        DROPDOWN_SHORT_NAME.SPECIALITY,
        DROPDOWN_SHORT_NAME.BILLING_ENTITY,
        DROPDOWN_SHORT_NAME.WORK_STREAM,
        DROPDOWN_SHORT_NAME.SNOMED_T,
        DROPDOWN_SHORT_NAME.SNOMED_P,
        DROPDOWN_SHORT_NAME.SNOMED_M,
        DROPDOWN_SHORT_NAME.CLINIC_TYPE,
        DROPDOWN_SHORT_NAME.SPICEMEN_TYPE,
        DROPDOWN_SHORT_NAME.INSURANCE_COMPANY,
        DROPDOWN_SHORT_NAME.CASE_DOCUMENT_TYPE,
        DROPDOWN_SHORT_NAME.CASE_SOURCE,
      ]);

      const dropdownOptionsList = await caseListService.getDropdown(
        [
          CASE_LIST_DROPDOWN.ADDRESS,
          CASE_LIST_DROPDOWN.CASE_STATUS,
          CASE_LIST_DROPDOWN.CLINIC,
          CASE_LIST_DROPDOWN.LABORATORY,
          CASE_LIST_DROPDOWN.CLINICIAN,
          CASE_LIST_DROPDOWN.PATHOLOGIST,
          CASE_LIST_DROPDOWN.QUALITY_CONTROL,
          CASE_LIST_DROPDOWN.TECHNICIAN1,
          CASE_LIST_DROPDOWN.TECHNICIAN2,
          CASE_LIST_DROPDOWN.CASE_ISSUE,
          CASE_LIST_DROPDOWN.ISSUE_STATUS,
          CASE_LIST_DROPDOWN.ISSUE_TYPE,
          CASE_LIST_DROPDOWN.SO_REQUEST_STATUS,
          CASE_LIST_DROPDOWN.ALW_REQUEST_STATUS,
          CASE_LIST_DROPDOWN.DR_RESPONSE_STATUS,
          CASE_LIST_DROPDOWN.DR_PARTNER,
          CASE_LIST_DROPDOWN.WORK_STREAM,
        ],
        true,
      );

      const dropdownStainOptions = await caseFormService.getStainByEntityIdInCaseForm(null, [
        CASE_LIST_DROPDOWN.CASE_IMPORT_BATCH_ID,
      ]);

      const specimenTypeListReq = await DropdownService.getDropdownByShortNameTemporary([
        DROPDOWN_SHORT_NAME.SPECIMEN_TYPE_PER_WORK_STREAM,
      ]);
      const specimenTypeListTmp = specimenTypeListReq.data || [];
      this.specimenTypeList = this.mappingSpecimenTypeList(specimenTypeListTmp);
      this.$set(this.dataSource, 'SpecimenTypeIds', this.specimenTypeList.options);
      this.$set(this.dataSource, `groupSpecimenTypeIds`, this.specimenTypeList.groupOptions);

      this.dataSource[DROPDOWN_SHORT_NAME.SPECIALITY] = dropdownOptions[DROPDOWN_SHORT_NAME.SPECIALITY];
      this.dataSource[DROPDOWN_SHORT_NAME.BILLING_ENTITY] = dropdownOptions[DROPDOWN_SHORT_NAME.BILLING_ENTITY];
      this.dataSource[DROPDOWN_SHORT_NAME.SNOMED_T] = dropdownOptions[DROPDOWN_SHORT_NAME.SNOMED_T];
      this.dataSource[DROPDOWN_SHORT_NAME.SNOMED_P] = dropdownOptions[DROPDOWN_SHORT_NAME.SNOMED_P];
      this.dataSource[DROPDOWN_SHORT_NAME.SNOMED_M] = dropdownOptions[DROPDOWN_SHORT_NAME.SNOMED_M];
      this.dataSource[DROPDOWN_SHORT_NAME.CLINIC_TYPE] = dropdownOptions[DROPDOWN_SHORT_NAME.CLINIC_TYPE];
      this.dataSource[DROPDOWN_SHORT_NAME.CASE_DOCUMENT_TYPE] = dropdownOptions[DROPDOWN_SHORT_NAME.CASE_DOCUMENT_TYPE];
      this.dataSource[DROPDOWN_SHORT_NAME.CASE_SOURCE] = dropdownOptions[DROPDOWN_SHORT_NAME.CASE_SOURCE];
      this.dataSource[DROPDOWN_SHORT_NAME.INSURANCE_COMPANY] = dropdownOptions[DROPDOWN_SHORT_NAME.INSURANCE_COMPANY];
      this.dataSource[CASE_LIST_DROPDOWN.ADDRESS] = dropdownOptionsList[CASE_LIST_DROPDOWN.ADDRESS];
      this.dataSource[CASE_LIST_DROPDOWN.CASE_STATUS] = dropdownOptionsList[CASE_LIST_DROPDOWN.CASE_STATUS];
      this.dataSource[CASE_LIST_DROPDOWN.CLINIC] = sortDropDown(dropdownOptionsList[CASE_LIST_DROPDOWN.CLINIC], 'text');
      this.dataSource[CASE_LIST_DROPDOWN.LABORATORY] = sortDropDown(
        dropdownOptionsList[CASE_LIST_DROPDOWN.LABORATORY],
        'text',
      );
      this.dataSource[CASE_LIST_DROPDOWN.CLINICIAN] = sortDropDown(
        dropdownOptionsList[CASE_LIST_DROPDOWN.CLINICIAN],
        'text',
      );
      this.dataSource[CASE_LIST_DROPDOWN.PATHOLOGIST] = sortDropDown(
        dropdownOptionsList[CASE_LIST_DROPDOWN.PATHOLOGIST],
        'text',
      );
      this.dataSource[CASE_LIST_DROPDOWN.QUALITY_CONTROL] = sortDropDown(
        dropdownOptionsList[CASE_LIST_DROPDOWN.QUALITY_CONTROL],
        'text',
      );
      this.dataSource[CASE_LIST_DROPDOWN.TECHNICIAN1] = sortDropDown(
        dropdownOptionsList[CASE_LIST_DROPDOWN.TECHNICIAN1],
        'text',
      );
      this.dataSource[CASE_LIST_DROPDOWN.TECHNICIAN2] = sortDropDown(
        dropdownOptionsList[CASE_LIST_DROPDOWN.TECHNICIAN2],
        'text',
      );
      this.dataSource['CaseIssues'] = sortDropDown(dropdownOptionsList[CASE_LIST_DROPDOWN.CASE_ISSUE], 'text');

      this.dataSource['CaseIssueStatusIds'] = sortDropDown(
        dropdownOptionsList[CASE_LIST_DROPDOWN.ISSUE_STATUS],
        'text',
      );
      this.dataSource['IssueTypeIds'] = dropdownOptionsList[CASE_LIST_DROPDOWN.ISSUE_TYPE].map((item) => {
        return {
          ...item,
          value: item.fieldItemId,
          label: item.fieldItemName,
        };
      });

      this.dataSource[CASE_LIST_DROPDOWN.SO_REQUEST_STATUS] = sortDropDown(
        dropdownOptionsList[CASE_LIST_DROPDOWN.SO_REQUEST_STATUS],
        'text',
      );
      this.dataSource[CASE_LIST_DROPDOWN.ALW_REQUEST_STATUS] = sortDropDown(
        dropdownOptionsList[CASE_LIST_DROPDOWN.ALW_REQUEST_STATUS],
        'text',
      );
      this.dataSource['DRResponseStatusIds'] = sortDropDown(
        dropdownOptionsList[CASE_LIST_DROPDOWN.DR_RESPONSE_STATUS],
        'text',
      );
      this.dataSource['DRPartnerIds'] = sortDropDown(dropdownOptionsList[CASE_LIST_DROPDOWN.DR_PARTNER], 'text');

      if (dropdownStainOptions && dropdownStainOptions[CASE_LIST_DROPDOWN.CASE_IMPORT_BATCH_ID]) {
        this.dataSource['CaseImportBatchId'] = dropdownStainOptions[CASE_LIST_DROPDOWN.CASE_IMPORT_BATCH_ID].map(
          (item) => {
            return {
              ...item,
              value: item.id,
              label: item.text,
            };
          },
        );
      }

      this.dataSource['WorkStreamIds'] = sortDropDown(
        dropdownOptionsList[CASE_LIST_DROPDOWN.WORK_STREAM].map((item) => {
          return {
            ...item,
            value: item.fieldItemId,
            label: item.fieldItemName,
          };
        }),
      );

      this.$set(this.dataSource, 'SpecialtyIds', this.dataSource[DROPDOWN_SHORT_NAME.SPECIALITY]);
      this.$set(this.dataSource, 'BillingEntityIds', this.dataSource[DROPDOWN_SHORT_NAME.BILLING_ENTITY]);
      this.$set(this.dataSource, 'SnomedT', this.dataSource[DROPDOWN_SHORT_NAME.SNOMED_T]);
      this.$set(this.dataSource, 'SnomedP', this.dataSource[DROPDOWN_SHORT_NAME.SNOMED_P]);
      this.$set(this.dataSource, 'SnomedM', this.dataSource[DROPDOWN_SHORT_NAME.SNOMED_M]);
      this.$set(this.dataSource, 'ClinicTypeIds', this.dataSource[DROPDOWN_SHORT_NAME.CLINIC_TYPE]);
      this.$set(this.dataSource, 'DocumentTypeIds', this.dataSource[DROPDOWN_SHORT_NAME.CASE_DOCUMENT_TYPE]);
      this.$set(this.dataSource, 'FromSourceIds', this.dataSource[DROPDOWN_SHORT_NAME.CASE_SOURCE]);
      this.$set(this.dataSource, 'PmiIds', this.dataSource[DROPDOWN_SHORT_NAME.INSURANCE_COMPANY]);
      this.$set(this.dataSource, 'AddressTypeId', this.dataSource[CASE_LIST_DROPDOWN.ADDRESS]);
      this.$set(this.dataSource, 'CaseStatusIds', this.dataSource[CASE_LIST_DROPDOWN.CASE_STATUS]);
      this.$set(this.dataSource, 'ClinicIds', this.dataSource[CASE_LIST_DROPDOWN.CLINIC]);
      this.$set(this.dataSource, 'LaboratoryIds', this.dataSource[CASE_LIST_DROPDOWN.LABORATORY]);
      this.$set(this.dataSource, 'ClinicianIds', this.dataSource[CASE_LIST_DROPDOWN.CLINICIAN]);
      this.$set(this.dataSource, 'PathologistIds', this.dataSource[CASE_LIST_DROPDOWN.PATHOLOGIST]);
      this.$set(this.dataSource, 'QualityControlIds', this.dataSource[CASE_LIST_DROPDOWN.QUALITY_CONTROL]);
      this.$set(this.dataSource, 'Technician1Ids', this.dataSource[CASE_LIST_DROPDOWN.TECHNICIAN1]);
      this.$set(this.dataSource, 'Technician2Ids', this.dataSource[CASE_LIST_DROPDOWN.TECHNICIAN2]);
      this.$set(this.dataSource, 'CaseIssues', this.dataSource['CaseIssues']);
      this.$set(this.dataSource, 'CaseIssueStatusIds', this.dataSource['CaseIssueStatusIds']);
      this.$set(this.dataSource, 'IssueTypeIds', this.dataSource['IssueTypeIds']);
      this.$set(this.dataSource, 'SoRequestStatus', this.dataSource[CASE_LIST_DROPDOWN.SO_REQUEST_STATUS]);
      this.$set(this.dataSource, 'AlwRequestStatus', this.dataSource[CASE_LIST_DROPDOWN.ALW_REQUEST_STATUS]);
      this.$set(this.dataSource, 'AlwRequestBatchStatusIds', this.dataSource[CASE_LIST_DROPDOWN.ALW_REQUEST_STATUS]);
      this.$set(this.dataSource, 'DRResponseStatusIds', this.dataSource['DRResponseStatusIds']);
      this.$set(this.dataSource, 'DRPartnerIds', this.dataSource['DRPartnerIds']);
      this.$set(this.dataSource, 'CaseImportBatchId', this.dataSource['CaseImportBatchId']);
      this.$set(this.dataSource, 'WorkStreamIds', this.dataSource['WorkStreamIds']);

      const optionFields = this.fields.filter((f) => f.listKey).map((f) => f.key);

      Object.keys(this.filters).map((k) => {
        if (k && !['search', 'IsIncludedReportedCase'].includes(k)) {
          // eslint-disable-next-line security/detect-object-injection
          const value = this.filters[k];
          // option field type
          if (!isPerfectEmpty(value) && optionFields.includes(k)) {
            const filterItem = searchOnFilterListByKey(this.filterList, k);
            this.selectFilter.push(filterItem);
            // other field type
          } else if (!isPerfectEmpty(value)) {
            const filterItem = searchOnFilterListByKey(this.filterList, k);
            this.selectFilter.push(filterItem);
          }

          if (k == 'AdvancedCaseRef' && value) {
            const filterItem = searchOnFilterListByKey(this.filterList, 'CaseReference');
            this.selectFilter.push(filterItem);
            this.isShowAdvancedCaseRef = true;
            this.convertAdvancedCaseRef(value);
          }
        }
      });
      const filterValues = {
        ...cloneDeep(this.defaultValues),
        ...cloneDeep(this.filters),
      };
      this.$set(this, 'filterValues', filterValues);
    },

    convertAdvancedCaseRef(value) {
      const arrAdvancedCaseRef = value.split('-');
      arrAdvancedCaseRef.forEach((item) => {
        const itemArr = item.split('|');
        this.caseRefAdvancedList.push({
          year: itemArr[0].split(','),
          searchMethod: parseInt(itemArr[1]),
          fromMonth: parseInt(itemArr[2]),
          toMonth: parseInt(itemArr[3]) ? parseInt(itemArr[3]) : null,
        });
      });
    },

    toggleSelectList() {
      this.isOpen = !this.isOpen;
      if (this.filterList && this.filterList.length > 0) {
        this.filterList.forEach((item) => {
          this.expandItem[item.id] = true;
        });
      }
    },

    isTextInput(item) {
      return (
        item.text == CASE_SELECT_FILTER_KEY.CaseRef ||
        item.text == CASE_SELECT_FILTER_KEY.HospitalRef ||
        item.text == CASE_SELECT_FILTER_KEY.PatientNhsNumber ||
        item.text == CASE_SELECT_FILTER_KEY.LabRef ||
        item.text == CASE_SELECT_FILTER_KEY.PatientFirstName ||
        item.text == CASE_SELECT_FILTER_KEY.PatientLastName
      );
    },

    isDropdownMenuSingle(item) {
      return item.text == CASE_SELECT_FILTER_KEY.Address;
    },

    isDropdownMenuMulti(item) {
      return (
        item.text == CASE_SELECT_FILTER_KEY.SnomedM ||
        item.text == CASE_SELECT_FILTER_KEY.SnomedP ||
        item.text == CASE_SELECT_FILTER_KEY.SnomedT ||
        item.text == CASE_SELECT_FILTER_KEY.DocumentType ||
        item.text == CASE_SELECT_FILTER_KEY.IssueType ||
        item.text == CASE_SELECT_FILTER_KEY.DRResponseStatus ||
        item.text == CASE_SELECT_FILTER_KEY.DRPartnerPathologist ||
        item.text == CASE_SELECT_FILTER_KEY.SoRequestStatus ||
        item.text == CASE_SELECT_FILTER_KEY.AlwRequestStatus ||
        item.text == CASE_SELECT_FILTER_KEY.AlwRequestBatchStatusIds ||
        item.text == CASE_SELECT_FILTER_KEY.CaseStatus ||
        item.text == CASE_SELECT_FILTER_KEY.Speciality ||
        item.text == CASE_SELECT_FILTER_KEY.Clinic ||
        item.text == CASE_SELECT_FILTER_KEY.ClinicType ||
        item.text == CASE_SELECT_FILTER_KEY.Clinician ||
        item.text == CASE_SELECT_FILTER_KEY.Laboratory ||
        item.text == CASE_SELECT_FILTER_KEY.Pathologist ||
        item.text == CASE_SELECT_FILTER_KEY.QualityControl ||
        item.text == CASE_SELECT_FILTER_KEY.Technician1 ||
        item.text == CASE_SELECT_FILTER_KEY.Technician2 ||
        item.text == CASE_SELECT_FILTER_KEY.CaseIssue ||
        item.text == CASE_SELECT_FILTER_KEY.IssueStatus ||
        item.text == CASE_SELECT_FILTER_KEY.BillingEntity ||
        item.text == CASE_SELECT_FILTER_KEY.PMI ||
        item.text == CASE_SELECT_FILTER_KEY.Source ||
        item.text == CASE_SELECT_FILTER_KEY.ImportID ||
        item.text == CASE_SELECT_FILTER_KEY.WorkStream
      );
    },

    isDropdownMenuMultiGroup(item) {
      return item.text == CASE_SELECT_FILTER_KEY.SpecimenType;
    },

    IsDatePicker(item) {
      return item.text == CASE_SELECT_FILTER_KEY.DOB || item.text == CASE_SELECT_FILTER_KEY.ReturnToClinicDate;
    },
    IsDateRangePicker(item) {
      return (
        item.text == CASE_SELECT_FILTER_KEY.LabEntryDate ||
        item.text == CASE_SELECT_FILTER_KEY.ProcedureDate ||
        item.text == CASE_SELECT_FILTER_KEY.IssueRespondedAt ||
        item.text == CASE_SELECT_FILTER_KEY.ReportedDate ||
        item.text == CASE_SELECT_FILTER_KEY.LabSlideDate ||
        item.text == CASE_SELECT_FILTER_KEY.WithPath ||
        item.text == CASE_SELECT_FILTER_KEY.AlwRespondedDate ||
        item.text == CASE_SELECT_FILTER_KEY.SentToPathDate ||
        item.text == CASE_SELECT_FILTER_KEY.ReturnToLabDate ||
        item.text == CASE_SELECT_FILTER_KEY.ExportedForBillingDate
      );
    },

    IsTimeRange(item) {
      return item.text == CASE_SELECT_FILTER_KEY.TAT || item.text == CASE_SELECT_FILTER_KEY.TATWoWe;
    },

    isToggle(item) {
      return (
        item.text == CASE_SELECT_FILTER_KEY.IsUrgent ||
        item.text == CASE_SELECT_FILTER_KEY.ClinicalIncident ||
        item.text == CASE_SELECT_FILTER_KEY.DOBNotProvided ||
        item.text == CASE_SELECT_FILTER_KEY.HasALW ||
        item.text == CASE_SELECT_FILTER_KEY.HasVirtualSlides ||
        item.text == CASE_SELECT_FILTER_KEY.HasUnexpectedFindings ||
        item.text == CASE_SELECT_FILTER_KEY.IsPullCase ||
        item.text == CASE_SELECT_FILTER_KEY.HasSecondOpinion ||
        item.text == CASE_SELECT_FILTER_KEY.IsCaseOfInterest ||
        item.text == CASE_SELECT_FILTER_KEY.IsDeleted ||
        item.text == CASE_SELECT_FILTER_KEY.IsImported ||
        item.text == CASE_SELECT_FILTER_KEY.IsRead ||
        item.text == CASE_SELECT_FILTER_KEY.IsSOCase ||
        item.text == CASE_SELECT_FILTER_KEY.NoUpdateAfterRetrieved ||
        item.text == CASE_SELECT_FILTER_KEY.IsExportedForBilling ||
        item.text == CASE_SELECT_FILTER_KEY.SearchAllCases ||
        item.text == CASE_SELECT_FILTER_KEY.DoubleReporting ||
        item.text == CASE_SELECT_FILTER_KEY.TissueRemaining ||
        item.text == CASE_SELECT_FILTER_KEY.TissueDiscarded ||
        item.text == CASE_SELECT_FILTER_KEY.HasRF ||
        item.text == CASE_SELECT_FILTER_KEY.HasSlides ||
        item.text == CASE_SELECT_FILTER_KEY.UnknowBillingEntity ||
        item.text == CASE_SELECT_FILTER_KEY.IncompleteInsuranceDetails ||
        item.text == CASE_SELECT_FILTER_KEY.IncompleteAddressDetails ||
        item.text == CASE_SELECT_FILTER_KEY.HasDraftDiagnosis ||
        item.text == CASE_SELECT_FILTER_KEY.Mdm ||
        item.text == CASE_SELECT_FILTER_KEY.HasComment
      );
    },

    IsSpinbox(item) {
      return (
        item.text == CASE_SELECT_FILTER_KEY.Block ||
        item.text == CASE_SELECT_FILTER_KEY.DocumentQuantity ||
        item.text == CASE_SELECT_FILTER_KEY.NoOfOriginalSlides ||
        item.text == CASE_SELECT_FILTER_KEY.NoOfALWSlides ||
        item.text == CASE_SELECT_FILTER_KEY.NoOfSpecimens
      );
    },

    isNumber(evt) {
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    covertTimeToseconds(time) {
      if (time) {
        var seconds = +time.getHours() * 60 * 60 + +time.getMinutes() * 60 + +time.getSeconds();
        return seconds;
      } else {
        return null;
      }
    },

    getCaseStatusNameById(caseStatusId) {
      const caseStatus =
        this.dataSource[CASE_LIST_DROPDOWN.CASE_STATUS].filter((item) => item.value === caseStatusId)[0] || null;

      if (caseStatus) {
        return caseStatus.label;
      }
      return '';
    },

    searchOnFilter() {
      this.isOpen = true;
      this.searchFilter = searchOnFilterList(this.filterList, this.valueFilter);
    },

    onSearch() {
      const filter = {
        search: this.filters.search,
        AdvancedCaseRef: [],
      };

      Object.keys(this.checkboxValue).map((key) => {
        if (!isPerfectEmpty(this.filterValues[key]) && this.checkboxValue[key]) {
          filter[key] = this.filterValues[key];
        }
      });

      if (!isPerfectEmpty(this.filters.DashboardRedirectEnum)) {
        filter.DashboardRedirectEnum = this.filters.DashboardRedirectEnum;
      }
      if (this.filterValues && this.filterValues.IsIncludedReportedCase) {
        filter.IsIncludedReportedCase = this.filterValues.IsIncludedReportedCase;
      }
      if (filter.DoB) {
        filter.DoB = convertFromDateTimezoneToIsoString(this.filterValues.DoB);
      }
      if (filter.ReportedDate) {
        filter.ReportedDateFrom = startDay(this.filterValues.ReportedDate[0]);
        filter.ReportedDateTo = endDay(this.filterValues.ReportedDate[1]);
      }
      if (filter.LabSlideDate) {
        filter.LabSlideDateFrom = startDay(this.filterValues.LabSlideDate[0]);
        filter.LabSlideDateTo = endDay(this.filterValues.LabSlideDate[1]);
      }
      if (!isPerfectEmpty(filter.TatNumber)) {
        filter.TatNumberFrom = this.covertTimeToseconds(this.filterValues.TatNumber.From);
        filter.TatNumberTo = this.covertTimeToseconds(this.filterValues.TatNumber.To);
      }
      if (filter.TatWoWeNumber) {
        filter.TatWoWeNumberFrom = this.covertTimeToseconds(this.filterValues.TatWoWeNumber.From);
        filter.TatWoWeNumberTo = this.covertTimeToseconds(this.filterValues.TatWoWeNumber.To);
      }
      if (filter.WithPath) {
        filter.WithPathFrom = this.filterValues.WithPath[0];
        filter.WithPathTo = this.filterValues.WithPath[1];
      }
      if (filter.AlwRespondedDate) {
        filter.AlwRespondDateFrom = startDay(this.filterValues.AlwRespondedDate[0]);
        filter.AlwRespondDateTo = endDay(this.filterValues.AlwRespondedDate[1]);
      }
      if (filter.SentToPathDate) {
        filter.SlideSentToPathFrom = startDay(this.filterValues.SentToPathDate[0]);
        filter.SlideSentToPathTo = endDay(this.filterValues.SentToPathDate[1]);
      }
      if (filter.ReturnToLabDate) {
        filter.SlideReturnToLabFrom = startDay(this.filterValues.ReturnToLabDate[0]);
        filter.SlideReturnToLabTo = endDay(this.filterValues.ReturnToLabDate[1]);
      }
      if (filter.IssueRespondedAt) {
        filter.IssueRespondedAtFrom = startDay(this.filterValues.IssueRespondedAt[0]);
        filter.IssueRespondedAtTo = endDay(this.filterValues.IssueRespondedAt[1]);
      }
      if (filter.ProcedureDate) {
        filter.ProcedureDateFrom = startDay(this.filterValues.ProcedureDate[0]);
        filter.ProcedureDateTo = endDay(this.filterValues.ProcedureDate[1]);
      }
      if (filter.ExportedForBillingDate) {
        filter.ExportedForBillingDateFrom = startDay(this.filterValues.ExportedForBillingDate[0]);
        filter.ExportedForBillingDateTo = endDay(this.filterValues.ExportedForBillingDate[1]);
      }

      if (filter.CaseReference || (this.caseRefAdvancedList && this.caseRefAdvancedList.length > 0)) {
        if (this.caseRefAdvancedList && this.caseRefAdvancedList.length > 0) {
          const advancedCaseRefList = [];
          this.caseRefAdvancedList.forEach((item) => {
            const caseRefItem = [];
            Object.keys(item).map((key) => {
              if (item && item[key] != null) {
                caseRefItem.push(item[key]);
              }
            });
            const caseRefItemCovert = caseRefItem.join('|');
            advancedCaseRefList.push(caseRefItemCovert);
          });
          if (filter.CaseReference) {
            filter.CaseReference = '';
          }
          if (this.checkboxValue['CaseReference']) {
            filter.AdvancedCaseRef = advancedCaseRefList.join('-');
          }
        }
      }

      this.$emit('input', { ...filter, onSearch: true });
      this.emitAdvancedFilterChangedEvent();
    },
    onClear() {
      this.$nextTick(() => {
        this.$refs.form.reset();
        this.selectFilter = [];

        //clear caseRef advanced search
        this.filterValues.CaseReference = '';
        this.caseRefAdvancedList = [];
        this.isShowAdvancedCaseRef = false;
      });

      Object.keys(this.filters).map((k) => {
        // eslint-disable-next-line security/detect-object-injection
        this.filters[k] = this.defaultValues[k];
        this.filterValues[k] = this.defaultValues[k];
      });
      this.chipOptions.map((chip) => {
        this.$onRemoveChip(chip);
      });
      this.$emit('onResetFilters');
      this.emitAdvancedFilterChangedEvent();
    },
    emitAdvancedFilterChangedEvent() {
      this.addEvent(newAppEvent(APP_EVENTS.EVT_ON_ADVANCED_FILTER_CHANGED, Date.now()));
    },

    onShowAdvancedCaseRef() {
      this.isShowAdvancedCaseRef = !this.isShowAdvancedCaseRef;
      if (this.caseRefAdvancedList.length == 0) {
        this.caseRefAdvancedList.push({
          year: new Date().getFullYear(),
          searchMethod: 1,
          fromMonth: null,
          toMonth: null,
        });
      }
    },

    addNewCaseRefAdvanced() {
      this.caseRefAdvancedList.push({
        year: new Date().getFullYear(),
        searchMethod: 1,
        fromMonth: null,
        toMonth: null,
      });
    },
    removeCaseRefAdvanced(item) {
      const idx = this.caseRefAdvancedList.indexOf(item);
      this.caseRefAdvancedList.splice(idx, 1);
    },
    async getIsAnonymizedInfo() {
      if (this.userId) {
        const { data } = await MyAccountService.getMyProfile(this.userId);
        if (data) {
          return data.isAnonymised;
        }
      }
    },

    mappingSpecimenTypeList(specimenTypeList) {
      const groupOptions = [];
      const options = [];
      specimenTypeList.forEach((specimenTypeItem) => {
        const { itemId, itemName, items } = specimenTypeItem;
        let fullPropItemList = items.map((item) => {
          return {
            ...item,
            value: `${item.fieldItemId}_${itemId}`,
            label: item.fieldItemName,
          };
        });
        groupOptions.push({
          itemName,
          items: fullPropItemList,
        });
      });
      groupOptions.forEach((i) => {
        options.push(...i.items);
      });
      return { groupOptions, options };
    },
  },
};
</script>
<style lang="scss">
.mx-datepicker-range {
  margin-top: 10px;
}
</style>

<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
      <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
      <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
        <div class="mg-top-24">
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <lims-field class="textarea-field" :model="formData" :schema="schema" field="comment">
                <md-textarea slot="field" v-model="formData.comment" maxlength="250"></md-textarea>
              </lims-field>
            </div>
          </div>
        </div>
        <md-dialog-actions>
          <md-button @click="close" class="lims-form-button">
            {{ $t('global/button/button.cancel') }}
          </md-button>
          <md-button @click="onRequest" :disabled="isProcessing" class="lims-form-button md-primary">{{
            $t('global/button/button.request')
          }}</md-button>
        </md-dialog-actions>
      </form>
    </md-dialog>
  </ValidationObserver>
</template>

<script>
import modalMixins from './modal.mixins';
import { FormMixins } from '@/core/mixins';

export default {
  name: 'ModalRequestViewAnonymized',
  mixins: [modalMixins, FormMixins],
  computed: {
    schema() {
      return {
        entity: 'modals/ModalRequestViewAnonymized',
        fields: {
          comment: '',
        },
      };
    },
  },
  data() {
    return {
      formData: {},
    };
  },
  methods: {
    onRequest() {
      this.isProcessing = true;
      this.$emit('onRequest', this.formData.comment);
      this.close();
    },
  },
};
</script>

<style lang="scss"></style>

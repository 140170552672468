<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <br />
    <div class="lims-form dialog-content">
      <p class="text-center">
        {{
          $t('components/lims/modals/ModalConfirmSwitchToDocumentViewerMode.loseYourUpdate', {
            viewMode: viewMode,
          })
        }}
      </p>
    </div>
    <md-dialog-actions>
      <md-button @click="onSaveChanges" class="lims-form-button md-primary">{{
        $t('components/lims/modals/ModalConfirmSwitchToDocumentViewerMode.saveChanges')
      }}</md-button>
      <md-button @click="onDiscardChanges" class="lims-form-button md-danger">{{
        $t('components/lims/modals/ModalConfirmSwitchToDocumentViewerMode.discardChanges')
      }}</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import modalMixins from '@/components/Lims/modals/modal.mixins';

export default {
  mixins: [modalMixins],
  methods: {
    onSaveChanges() {
      this.$emit('onSaveChanges');
    },
    onDiscardChanges() {
      this.close();
      this.$emit('onDiscardChanges');
    },
  },
  data() {
    return {
      viewMode: null,
    };
  },
};
</script>

<style lang="scss"></style>

import httpClient from './httpClient';

const RESOURCE = 'case-issue';

export default {
  getListCaseIssue(statusId) {
    return httpClient.get(`/${RESOURCE}/list-issue`, {
      statusId: statusId,
    });
  },
  createCaseIssue(caseId, issueData) {
    return httpClient.post(`/${RESOURCE}/create`, issueData, {
      caseId: caseId,
    });
  },
  updateCaseIssue(caseIssueId, issueData) {
    return httpClient.put(`/${RESOURCE}/update/${caseIssueId}`, issueData);
  },
  deleteCaseIssue(caseIssueId) {
    return httpClient.put(`/${RESOURCE}/delete/${caseIssueId}`);
  },
  resendMail(caseIssueId) {
    return httpClient.put(`/${RESOURCE}/resend-mail/${caseIssueId}`);
  },
  getCaseIssueResponse(caseIssueId, token) {
    return httpClient.get(`/${RESOURCE}/case-issue-response/${caseIssueId}/${token}`);
  },
  updateCaseIssueResponse(caseIssueId, token, caseIssueData) {
    return httpClient.put(`/${RESOURCE}/case-issue-response/${caseIssueId}/${token}`, caseIssueData);
  },
  approveCaseIssue(caseIssueId) {
    return httpClient.put(`/${RESOURCE}/approve/${caseIssueId}`);
  },
  rejectCaseIssue(caseIssueId) {
    return httpClient.put(`/${RESOURCE}/reject/${caseIssueId}`);
  },
};

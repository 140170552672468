<template>
  <lims-block :cardText="false" class="list-page case-list">
    <div slot="blockContent" v-if="dataSource">
      <div class="toolbar-row">
        <div class="field-search">
          <advance-search
            v-model="query.filters"
            :dataSource="dataSource"
            :defaultValues="defaultValues"
            :fields="fields"
            @onResetFilters="onResetFilters"
          >
            <lims-row-per-page
              slot="rowsPerPage"
              :pageSize="query.pagination.pageSize"
              @pageSizeChanged="$pageSizeChanged"
            />
          </advance-search>
        </div>
      </div>
      <div class="md-layout lims-form-row">
        <legend-search></legend-search>
      </div>
      <alw-list-admin-lab-technician @action="onRowAction" :alwList="items">
        <lims-pagination
          slot="pagination"
          ref="pager"
          :total-item="totalItem"
          :pagination="query.pagination"
        ></lims-pagination>
      </alw-list-admin-lab-technician>
    </div>
  </lims-block>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { ConfigMixins, ListWithFilterMixins } from '@/core/mixins';
import { alwQuery } from '@/query';
import { caseAlwService, caseFormService, caseListService, DropdownService } from '@/services';
import { buildFilterFromQuery, buildFilterQueryParams } from '@/query/utils';

import cloneDeep from 'lodash/cloneDeep';

import AdvanceSearch from './List/AdvanceSearch.vue';
import LegendSearch from './List/LegendSearch.vue';
import LimsRowPerPage from '@/components/Lims/LimsRowPerPage';
import LimsPagination from '@/components/Lims/LimsPagination';
import AlwListAdminLabTechnician from '@/pages/Case/AlwManagement/List/AlwListAdminLabTechnician.vue';
import {
  FORM_ACTIONS,
  APP_EVENTS,
  newAppEvent,
  NUMBER_OF_CHARACTERS_SEARCH,
  DROPDOWN_SHORT_NAME,
  CASE_LIST_DROPDOWN,
  APP_ROUTES,
} from '@/core/constants';
import {
  FF_ClinicTypeIds,
  FF_SpecimenTypeIds,
  FF_ALW_AlwRequestTypeIds,
  FF_ALW_CaseSpecimentBlocks,
  FF_CaseStatusIds,
  FF_ClinicIds,
  FF_ClinicianIds,
  FF_LaboratoryIds,
  FF_PathologistIds,
  FF_ALW_AlwRequestStatusIds,
  FF_ALW_AlwRequestStainIds,
  FF_ALW_AlwRespondStainIds,
} from '@/query/filter-fields';
import { sortDropDown } from '@/core/helpers';

export default {
  components: {
    AdvanceSearch,
    LegendSearch,
    LimsRowPerPage,
    AlwListAdminLabTechnician,
    LimsPagination,
  },
  mixins: [ConfigMixins, ListWithFilterMixins],
  async created() {
    const dataSource = await this.fetchData();
    this.$set(this, 'dataSource', dataSource);
    this.initState();
  },
  computed: {
    fields() {
      return alwQuery.filters.fields;
    },
    defaultValues() {
      return alwQuery.filters.defaultValues;
    },
    ...mapGetters('app/event', [APP_EVENTS.EVT_ON_ALW_FORM_ON_REFRESH]),
  },
  data() {
    return {
      items: [],
      totalItem: null,
      query: null,
      dataSource: null,
    };
  },
  watch: {
    [APP_EVENTS.EVT_ON_ALW_FORM_ON_REFRESH]: {
      deep: true,
      handler: async function (val) {
        // reload data
        if (val) {
          this.initState();
          this.removeEvent(newAppEvent(APP_EVENTS.EVT_ON_ALW_FORM_ON_REFRESH));
        }
      },
    },
  },
  methods: {
    // common methods
    ...mapActions('app/event', ['removeEvent']),
    ...mapActions('app/data', ['updateDataset']),
    async fetchData() {
      const [dropDownOptions, caseDropDownOptions, staintListSource, specimenTypeSource] = await Promise.all([
        DropdownService.getDropdownByShortNames([
          DROPDOWN_SHORT_NAME.CLINIC_TYPE,
          DROPDOWN_SHORT_NAME.SPICEMEN_TYPE,
          DROPDOWN_SHORT_NAME.CaseAWLRequestType,
          DROPDOWN_SHORT_NAME.CASE_SPECIMEN_BLOCK,
        ]),
        caseListService.getDropdown(
          [
            CASE_LIST_DROPDOWN.CASE_STATUS,
            CASE_LIST_DROPDOWN.CLINIC,
            CASE_LIST_DROPDOWN.CLINICIAN,
            CASE_LIST_DROPDOWN.LABORATORY,
            CASE_LIST_DROPDOWN.PATHOLOGIST,
            CASE_LIST_DROPDOWN.ALW_REQUEST_STATUS,
          ],
          true,
        ),
        this.loadStainList(),
        this.mappingSpecimenTypeList(),
      ]);

      //const specimenTypeList = await this.mappingSpecimenTypeList();

      // mapping dataSource here
      const dataSource = {};
      Reflect.set(dataSource, FF_ClinicTypeIds, dropDownOptions[DROPDOWN_SHORT_NAME.CLINIC_TYPE]);
      Reflect.set(dataSource, FF_SpecimenTypeIds, dropDownOptions[DROPDOWN_SHORT_NAME.SPICEMEN_TYPE]);
      Reflect.set(dataSource, FF_ALW_AlwRequestTypeIds, dropDownOptions[DROPDOWN_SHORT_NAME.CaseAWLRequestType]);
      Reflect.set(dataSource, FF_ALW_CaseSpecimentBlocks, dropDownOptions[DROPDOWN_SHORT_NAME.CASE_SPECIMEN_BLOCK]);
      Reflect.set(dataSource, FF_CaseStatusIds, caseDropDownOptions[CASE_LIST_DROPDOWN.CASE_STATUS]);
      Reflect.set(dataSource, FF_ClinicIds, caseDropDownOptions[CASE_LIST_DROPDOWN.CLINIC]);
      Reflect.set(dataSource, FF_ClinicianIds, sortDropDown(caseDropDownOptions[CASE_LIST_DROPDOWN.CLINICIAN], 'text'));
      Reflect.set(
        dataSource,
        FF_LaboratoryIds,
        sortDropDown(caseDropDownOptions[CASE_LIST_DROPDOWN.LABORATORY], 'text'),
      );
      Reflect.set(
        dataSource,
        FF_PathologistIds,
        sortDropDown(caseDropDownOptions[CASE_LIST_DROPDOWN.PATHOLOGIST], 'text'),
      );
      Reflect.set(dataSource, FF_ALW_AlwRequestStatusIds, caseDropDownOptions[CASE_LIST_DROPDOWN.ALW_REQUEST_STATUS]);
      Reflect.set(dataSource, FF_ALW_AlwRequestStainIds, staintListSource.options);
      Reflect.set(dataSource, `group${FF_ALW_AlwRequestStainIds}`, staintListSource.groupOptions);
      Reflect.set(dataSource, FF_ALW_AlwRespondStainIds, staintListSource.options);
      Reflect.set(dataSource, `group${FF_ALW_AlwRespondStainIds}`, staintListSource.groupOptions);
      Reflect.set(dataSource, FF_SpecimenTypeIds, specimenTypeSource.options);
      Reflect.set(dataSource, `group${FF_SpecimenTypeIds}`, specimenTypeSource.groupOptions);

      // save datasource to state
      this.updateDataset({
        ...dropDownOptions,
        ...caseDropDownOptions,
        ...dataSource,
      });

      return dataSource;
    },
    async loadStainList() {
      const data = await caseFormService.getStainByEntityId(null, [
        DROPDOWN_SHORT_NAME.H_AND_E,
        DROPDOWN_SHORT_NAME.IMMUNOS,
        DROPDOWN_SHORT_NAME.SPECIAL_STAINS,
        DROPDOWN_SHORT_NAME.ADDITIONAL_TECHNIQUE,
      ]);
      // unify data
      const options = [];
      const groupOptions = data.map((item) => {
        const { items, ...rest } = item;
        const groupItems = items.map((slide) => {
          const { fieldItemName, fieldItemId, ...restItems } = slide;
          return {
            value: fieldItemId,
            label: fieldItemName,
            ...restItems,
          };
        });
        options.push(...groupItems);
        return {
          groupItems,
          ...rest,
        };
      });
      return {
        groupOptions,
        options,
      };
    },

    async mappingSpecimenTypeList() {
      const groupOptions = [];
      const options = [];

      const specimenTypeListReq = await DropdownService.getDropdownByShortNameTemporary([
        DROPDOWN_SHORT_NAME.SPECIMEN_TYPE_PER_WORK_STREAM,
      ]);
      const specimenTypeList = specimenTypeListReq.data || [];

      specimenTypeList.forEach((specimenTypeItem) => {
        const { itemId, itemName, items } = specimenTypeItem;
        let fullPropItemList = items.map((item) => {
          return {
            ...item,
            value: `${item.fieldItemId}_${itemId}`,
            label: item.fieldItemName,
          };
        });
        groupOptions.push({
          itemName,
          groupItems: fullPropItemList,
        });
      });
      groupOptions.forEach((i) => {
        options.push(...i.groupItems);
      });
      return { groupOptions, options };
    },

    initState() {
      const queryParams = this.$route.query;
      const filters = buildFilterFromQuery(alwQuery.filters, queryParams);
      const sort = buildFilterFromQuery(alwQuery.sort, queryParams);
      const pagination = buildFilterFromQuery(alwQuery.pagination, {
        pageSize: this.$appConfig.itemPerPageDefault,
        ...queryParams,
      });
      const query = { filters, pagination, sort };
      this.query = query;
    },

    async search(queryObject) {
      const queryObjNew = cloneDeep(queryObject);
      if (
        queryObjNew.filters.search.length > 0 &&
        queryObjNew.filters.search.length < NUMBER_OF_CHARACTERS_SEARCH &&
        !queryObjNew.filters.onSearch
      ) {
        return;
      }

      const { error, data } = await caseAlwService.getAlwCaseList({
        filters: buildFilterQueryParams(queryObject.filters),
        pagination: buildFilterQueryParams({
          pageSize: queryObject.pagination.pageSize,
          pageNumber: queryObject.pagination.pageNumber,
        }),
      });
      if (error) {
        this.$alertErrorServerSide(error);
        return;
      }
      this.items = data.alwRequests.items;
      this.totalItem = data.alwRequests.pagination.totalItems;
      // update router
      const query = {
        ...buildFilterQueryParams(queryObject.filters),
        ...buildFilterQueryParams(queryObject.pagination),
      };
      if (JSON.stringify(query) != JSON.stringify(this.$route.query)) {
        this.$router
          .push({
            query,
          })
          .catch((error) => {
            if (
              error.name === 'NavigationDuplicated' &&
              error.message.includes('Avoided redundant navigation to current location')
            ) {
              history.pushState({}, '', new URL(new URL(window.location)));
            }
          });
      }
    },
    onResetFilters() {
      this.query.filters = cloneDeep(alwQuery.filters.defaultValues);
    },
    onRowAction({ action, item }) {
      if (action === FORM_ACTIONS.EDIT) {
        this.$router.push(`${APP_ROUTES.ALW_MANAGEMENT_EDIT}/${item.caseALWRequestId}`);
      }
      if (action === FORM_ACTIONS.VIEW) {
        this.$router.push(`${APP_ROUTES.ALW_MANAGEMENT_VIEW}/${item.caseALWRequestId}`);
      }
    },
  },
};
</script>
<style lang="scss"></style>

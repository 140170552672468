<template>
  <lims-block class="c-block01">
    <h4 class="title" slot="blockTitle">
      {{ $t('pages/entity/components/RestrictedPathologist/blockTitle') }}
    </h4>
    <div slot="blockContent">
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-50 md-small-size-100">
          <label>{{ $t('pages/entity/components/RestrictedPathologist/selectLabel') }}</label>
          <v-select
            multiple
            slot="field"
            label="text"
            v-model="restrictedPathologistDropdown"
            :options="clinicRestrictedPathologistList"
            :placeholder="$t('pages/entity/components/RestrictedPathologist/placeholder.selectPathologist')"
            :disabled="disabled"
            @input="valRestrictedPathSelected"
            tabenable="yes"
            :tabindex="1"
          >
            <template #option="{ text }">
              {{ text }}
            </template>
            <template #selected-option="{ text }">
              {{ text }}
            </template>
          </v-select>
        </div>
        <div class="md-layout-item md-size-50 md-small-size-100">
          <md-button
            type="button"
            class="lims-form-button block01-btn md-primary"
            @click="addRestrictedPathologists"
            :disabled="disabled || isDisableAddRestrictedPathBtn"
          >
            {{ $t('pages/entity/components/RestrictedPathologist/button.addRestrictedPathologist') }}
          </md-button>
        </div>
      </div>
      <div class="md-layout">
        <div class="md-layout-item md-size-100 md-small-size-100 textarea-field">
          <label>{{ $t('pages/entity/components/RestrictedPathologist/labelComments') }}</label>
          <md-field>
            <md-textarea v-model="restrictedComments" :disabled="disabled" tabenable="yes"></md-textarea>
          </md-field>
        </div>
      </div>
      <div class="md-layout lims-form-row">
        <md-table class="custom-tbl" md-card>
          <md-table-row>
            <md-table-head width="30%">
              {{ $t('pages/entity/components/RestrictedPathologist/column.Name') }}
            </md-table-head>
            <md-table-head>
              {{ $t('pages/entity/components/RestrictedPathologist/column.Comment') }}
            </md-table-head>
            <md-table-head class="th-center cell-action">
              {{ $t('global/pages/list.actions') }}
            </md-table-head>
          </md-table-row>
          <md-table-row v-for="(item, idx) in restrictedPathologistSelected" :key="`${item.value}-${idx}`">
            <md-table-cell class="cell-clinicType">{{ item.fullname }}</md-table-cell>
            <md-table-cell>
              <template v-if="!isMyEntityView">{{ item.comment }}</template>
              <template v-else>{{
                !item.isCreatedByAdmin ? item.comment : $t('pages/entity/components/restrictedCommentCreatedByAdmin')
              }}</template>
            </md-table-cell>
            <md-table-cell class="cell-action">
              <div v-if="!isMyEntityView" @click="!disabled ? delRestrictedPathologists(item) : ''">
                <md-icon>close</md-icon>
              </div>
              <div v-else>
                <template v-if="!item.isCreatedByAdmin">
                  <div @click="!disabled ? delRestrictedPathologists(item) : ''"><md-icon>close</md-icon></div>
                </template>
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </div>
    </div>
  </lims-block>
</template>
<script>
import { EntityService, MyEntityService } from '@/services';
import { sortDropDown } from '@/core/helpers';
export default {
  name: 'RestrictedPathologist',
  created() {
    this.fetchData();
  },
  props: {
    entityId: {
      type: String,
      require: true,
    },
    value: {
      type: Array,
      require: true,
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false,
    },
    preferredPathologist: {
      type: Array,
      require: true,
    },
    isMyEntityView: {
      type: Boolean,
      require: false,
    },
  },
  data() {
    return {
      restrictedPathologistDropdown: [],
      clinicRestrictedPathologistList: [],
      isDisableAddRestrictedPathBtn: true,
      restrictedComments: '',
      restrictedPathologistSelected: [],
      restrictedPathologistSelectedTemp: [],
    };
  },

  watch: {
    entityId() {
      this.fetchData();
    },
    value() {
      this.restrictedPathologistSelected = this.value;
    },
    preferredPathologist() {
      this.clinicRestrictedPathologistList = this.getClinicRestrictedPathologistList();
    },
    restrictedPathologistSelected: {
      handler(val) {
        this.$emit('input', val);
      },
      deep: true,
    },
    restrictedPathologistSelectedTemp: {
      handler(val) {
        this.$emit('tempData', val);
      },
      deep: true,
    },
  },

  methods: {
    async fetchData() {
      this.clinicRestrictedPathologistListTemp = await this.loadRestrictedPathologist();
      this.clinicRestrictedPathologistList = this.clinicRestrictedPathologistListTemp;
    },

    async loadRestrictedPathologist() {
      const params = {
        entityId: this.entityId ? this.entityId : '',
        search: '',
      };
      const { data } = this.isMyEntityView
        ? await MyEntityService.findClinicRestrictedPathologist(params)
        : await EntityService.findClinicRestrictedPathologist(params);
      return sortDropDown(data, 'text');
    },

    compareArray(arr1, array2, array3) {
      if (!array2 && !array3) {
        return arr1;
      } else if (!array2) {
        return arr1.filter((a1) => !array3.some((a3) => a1.id === a3.id));
      } else if (!array3) {
        return arr1.filter((a1) => !array2.some((a2) => a1.id === a2.userId));
      }

      return arr1.filter((a1) => !(array2.some((a2) => a1.id === a2.userId) || array3.some((a3) => a1.id === a3.id)));
    },

    getClinicRestrictedPathologistList() {
      return this.compareArray(
        this.clinicRestrictedPathologistListTemp,
        this.restrictedPathologistSelected,
        this.preferredPathologist,
      );
    },

    // check condition to disabled button add
    valRestrictedPathSelected() {
      if (this.restrictedPathologistDropdown.length == 0) {
        this.isDisableAddRestrictedPathBtn = true;
      } else {
        this.isDisableAddRestrictedPathBtn = false;
      }
      const listDropdown = this.getClinicRestrictedPathologistList();
      const listDropdownFilter = listDropdown.filter(
        (list) => !this.restrictedPathologistDropdown.some((dropdown) => list.id === dropdown.id),
      );
      this.clinicRestrictedPathologistList = listDropdownFilter;
      this.restrictedPathologistSelectedTemp = this.restrictedPathologistDropdown.concat(
        this.restrictedPathologistSelected,
      );
    },
    addRestrictedPathologists() {
      if (!this.isDisableAddRestrictedPathBtn) {
        this.restrictedPathologistDropdown.forEach((item, index) => {
          this.restrictedPathologistSelected.push({
            id: item.id,
            userId: item.id,
            displayOrder: index + 1,
            comment: this.restrictedComments,
            fullname: item.text,
            isCreatedByAdmin: this.isMyEntityView ? false : true,
          });
        });
        this.restrictedPathologistDropdown = [];
        this.restrictedComments = '';
        this.isDisableAddRestrictedPathBtn = true;
        this.clinicRestrictedPathologistList = this.getClinicRestrictedPathologistList();
      }
    },
    delRestrictedPathologists(item) {
      const idx = this.restrictedPathologistSelected.findIndex(
        (restrictedPathologistSelected) => restrictedPathologistSelected.userId === item.userId,
      );
      this.restrictedPathologistSelected.splice(idx, 1);
      this.clinicRestrictedPathologistList = this.getClinicRestrictedPathologistList();
      this.restrictedPathologistSelectedTemp = this.restrictedPathologistDropdown.concat(
        this.restrictedPathologistSelected,
      );
    },
  },
};
</script>

import { formatDateWithoutTime, fromISOToCurrentTimezone } from '@/core/helpers';
import { caseFormService } from '@/services';
import ModalConfirmSlideBlock from '@/components/Lims/modals/ModalConfirmSlideBlock';
import { CASE_STATUS } from '@/core/constants';

export default {
  components: { ModalConfirmSlideBlock },
  computed: {
    $isStatusShowModalConfirmSlideBlock() {
      return (
        this.dataEdit &&
        (this.dataEdit.status == CASE_STATUS.PATH ||
          this.dataEdit.status == CASE_STATUS.PROVISIONALLY_REPORTED ||
          this.dataEdit.status == CASE_STATUS.REPORTED)
      );
    },
  },
  methods: {
    $showModalConfirmSlideBlock(action) {
      this.confirmSlideBlockModalDescription = this.$t(
        'pages/case/CaseManagement/components/SpecimenDetail/ModalConfirmSlideBlock.description',
        {
          action: action,
          caseRef: this.dataEdit.caseReference,
          status: this.dataEdit.statusName,
        },
      );
      this.$refs.confirmSlideBlockModal.open({ action: action });
    },
    async $checkSlideInPendingAlwRequest({ specimenIndex, blockIndex, slideIds, caseId }) {
      const { data, error } = await caseFormService.checkSlideInPendingAlwRequest(
        slideIds,
        caseId,
        specimenIndex,
        blockIndex,
      );
      if (data && data.length > 0) {
        const alwRequestType = data[0].alwRequestTypeName;
        const alwRequestedDatetime = data[0].alwRequestedDate;
        let blockAndSlideName = [];
        data.forEach((item) => {
          const blockAndSlide = item.blockName + ' - ' + item.slideName;
          blockAndSlideName.push(blockAndSlide);
        });
        return {
          valid: false,
          error: this.$t('pages/case/CaseManagement/components/SpecimenDetail/DeletedSlide.error', {
            alwRequestType: alwRequestType,
            blockAndSlideName: blockAndSlideName.join(', '),
            alwRequestedDatetime: formatDateWithoutTime(fromISOToCurrentTimezone(alwRequestedDatetime)),
          }),
        };
      } else {
        return {
          valid: !error,
          error: error ? error : null,
        };
      }
    },
  },
};

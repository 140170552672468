<template>
  <ValidationObserver v-if="isReady" ref="form" v-slot="{ handleSubmit, invalid }">
    <form class="md-layout lims-form" @submit.prevent="handleSubmit()" v-if="dropdownSchema">
      <lims-block class="information-block">
        <h4 class="title" slot="blockTitle">{{ $t('blocks.tittle.dropdown') }}</h4>
        <div slot="blockContent">
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-50 md-small-size-100">
              <lims-field
                :model="formData"
                :schema="dropdownSchema.information"
                field="field"
                :is-dropdown="true"
                :dropdown-item-name="'Field'"
              >
                <v-select disabled slot="field" v-model="formData.fieldName" tabenable="yes" :tabindex="1">
                  <template #option="{ label }">{{ label }}</template>
                  <template #selected-option="{ label }">{{ label }}</template>
                </v-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-50 md-small-size-100">
              <div class="md-layout-item-div-checkbox">
                <md-checkbox
                  v-model="formData.isHide"
                  class="md-layout-item-checkbox-dropdown"
                  :disabled="isViewMode"
                  tabenable="yes"
                  >{{ $t('pages/Data/Dropdown/Forms/DropdownItemForm/label.Hidden') }}</md-checkbox
                >
              </div>
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-50 md-small-size-100">
              <lims-field
                :model="formData"
                :schema="dropdownSchema.information"
                field="itemName"
                :is-dropdown="true"
                :dropdown-item-name="'Item name'"
              >
                <md-input
                  slot="field"
                  type="text"
                  maxlength="250"
                  v-model="formData.fieldItemName"
                  :disabled="isViewMode"
                  @change="$onServerSideValidation($event, dropdownSchema._serverSideValidators.itemName)"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-10 md-small-size-100">
              <lims-field
                :model="formData"
                :schema="dropdownSchema.information"
                field="itemOrder"
                :is-dropdown="true"
                :dropdown-item-name="'Item order'"
                :maximum-of-display-order-value="maximumOfDisplayOrderValue"
              >
                <md-input
                  slot="field"
                  type="number"
                  min="1"
                  v-model="formData.displayOrder"
                  :disabled="isViewMode"
                  @keypress="isNumber($event)"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <div
              class="md-layout-item md-size-50 md-small-size-100"
              v-for="(item, index) in formData.internalFields"
              :key="index"
            >
              <lims-field
                :model="formData"
                :schema="dropdownSchema.information"
                :field="clearSpace(item.fieldName)"
                :is-dropdown="true"
                :dropdown-item-name="item.fieldName"
              >
                <v-select
                  slot="field"
                  :multiple="item.isMultiValues"
                  :options="item.optionData"
                  v-model="item.selectedItems"
                  :disabled="isViewMode"
                  tabenable="yes"
                  :tabindex="1"
                >
                  <template #option="{ label }">{{ label }}</template>
                  <template #selected-option="{ label }">{{ label }}</template>
                </v-select>
              </lims-field>
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <div
              class="md-layout-item md-size-50 md-small-size-100"
              v-for="(item, index) in formData.externalFields"
              :key="'Ext' + index"
            >
              <div v-if="item.fieldType === getDropElementType.DB_PROC">
                <lims-field
                  :model="formData"
                  :schema="dropdownSchema.information"
                  :field="clearSpace(item.fieldName)"
                  :is-dropdown="true"
                  :dropdown-item-name="item.fieldName"
                >
                  <lims-custom-select
                    v-if="item.isMultiValues"
                    slot="field"
                    :multiple="true"
                    :options="externalFieldOptions[item.externalFieldId]"
                    v-model="item.values"
                    :disabled="isViewMode"
                    tabenable="yes"
                    :tabindex="1"
                  ></lims-custom-select>
                  <lims-custom-select
                    v-else
                    slot="field"
                    :multiple="false"
                    :options="externalFieldOptions[item.externalFieldId]"
                    v-model="item.value"
                    :disabled="isViewMode"
                    tabenable="yes"
                    :tabindex="1"
                  ></lims-custom-select>
                </lims-field>
              </div>
              <div v-if="item.fieldType === getDropElementType.CHECK_BOX">
                <md-checkbox v-model="item.value" :disabled="isViewMode">{{ item.fieldName }}</md-checkbox>
              </div>
              <div
                class="md-layout-item md-size-50 md-small-size-100"
                v-if="item.fieldType === getDropElementType.INPUT_TEX_COLOR"
              >
                <lims-field
                  :model="formData"
                  :schema="dropdownSchema.information"
                  :field="clearSpace(item.fieldName)"
                  :is-dropdown="true"
                  :dropdown-item-name="item.fieldName"
                >
                  <md-input
                    slot="field"
                    type="color"
                    :value="item.value"
                    v-model="item.value"
                    :disabled="isViewMode"
                    tabenable="yes"
                  ></md-input>
                </lims-field>
              </div>
              <div v-if="item.fieldType === getDropElementType.INPUT_TEXT_NUMBER">
                <lims-field
                  :model="formData"
                  :schema="dropdownSchema.information"
                  :field="clearSpace(item.fieldName)"
                  :is-dropdown="true"
                  :dropdown-item-name="item.fieldName"
                >
                  <md-input
                    slot="field"
                    type="number"
                    min="1"
                    max="200"
                    v-model="item.value"
                    :disabled="isViewMode"
                    tabenable="yes"
                  ></md-input>
                </lims-field>
              </div>
              <div v-if="item.fieldType === getDropElementType.INPUT_TEXT && item.fieldLength < 1000">
                <lims-field
                  :model="formData"
                  :schema="dropdownSchema.information"
                  :field="clearSpace(item.fieldName)"
                  :is-dropdown="true"
                  :dropdown-item-name="item.fieldName"
                >
                  <md-input
                    slot="field"
                    type="text"
                    :maxlength="maxlengthForInputText(item.fieldName)"
                    v-model="item.value"
                    :disabled="isViewMode"
                    tabenable="yes"
                  ></md-input>
                </lims-field>
              </div>
              <div v-if="item.fieldType === getDropElementType.CURRENCY">
                <lims-field
                  :model="formData"
                  :schema="dropdownSchema.information"
                  :field="clearSpace(item.fieldName)"
                  :is-dropdown="true"
                  :dropdown-item-name="item.fieldName"
                >
                  <md-input
                    slot="field"
                    type="text"
                    :maxlength="item.fieldLength"
                    v-model="item.value"
                    :disabled="isViewMode"
                    tabenable="yes"
                  ></md-input>
                </lims-field>
              </div>
            </div>
          </div>
          <div class="md-layout lims-form-row" v-for="(item, index) in formData.externalFields" :key="'Ext1' + index">
            <div
              class="md-layout-item md-size-100 md-small-size-100"
              v-if="item.fieldType === getDropElementType.INPUT_TEXT && item.fieldLength === 1000"
            >
              <lims-field
                :model="formData"
                :schema="dropdownSchema.information"
                :field="clearSpace(item.fieldName)"
                class="textarea-field"
                :is-dropdown="true"
                :dropdown-item-name="item.fieldName"
              >
                <md-textarea
                  slot="field"
                  type="text"
                  maxlength="1000"
                  v-model="item.value"
                  :disabled="isViewMode"
                  tabenable="yes"
                ></md-textarea>
              </lims-field>
            </div>
            <div
              class="md-layout-item md-size-100 md-small-size-100"
              v-if="item.fieldType === getDropElementType.JSON && item.fieldLength === 1000"
            >
              <lims-field
                :model="formData"
                :schema="dropdownSchema.information"
                :field="clearSpace(item.fieldName)"
                class="textarea-field"
                :is-dropdown="true"
                :dropdown-item-name="item.fieldName"
              >
                <md-textarea
                  slot="field"
                  type="text"
                  maxlength="1000"
                  v-model="item.value"
                  :disabled="isViewMode"
                  tabenable="yes"
                ></md-textarea>
              </lims-field>
            </div>
          </div>
        </div>
      </lims-block>
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions">
          <LimsFormCancel />
          <md-button
            v-if="!isViewMode"
            :disabled="invalid"
            @click="onSave()"
            class="md-button md-primary lims-form-button md-theme-default"
          >
            {{ $t('global/button/button.save') }}
          </md-button>
          <LimsFormEditButton v-if="isViewMode && $canEdit()" :editUrl="$editUrl" />
          <md-button v-if="isEditMode" @click="onDelete()" class="md-danger lims-form-button">
            {{ $t('global/button/button.delete') }}
          </md-button>
        </div>
      </div>
      <!-- End action form -->
    </form>
  </ValidationObserver>
</template>
<script>
import { FormMixins, TabMixins } from '@/core/mixins';
import { APP_ROUTES, DROPDOWN_ELEMENT_TYPE, FORM_MODES } from '@/core/constants';
import { DROPDOWN_ORDER_ITEM_ERROR } from '@/core/error-constants';
import { getDropdownSchema } from '@/schemas/dropdown.schema';
import { DropdownService } from '@/services';

export default {
  name: 'dropdown-item-form',
  mixins: [FormMixins, TabMixins],
  props: {
    dropdownFieldId: {
      type: String,
      require: true,
      default: null,
    },

    dropdownItemId: {
      require: false,
      default: null,
    },

    formMode: {
      type: Number,
      require: true,
      validator: function (value) {
        // The value must match one of these strings
        return Object.values(FORM_MODES).indexOf(value) !== -1;
      },
    },
  },

  data() {
    return {
      formData: {},
      maximumOfDisplayOrderValue: null,
      dropdownSchema: null,
      externalFieldOptions: {},
      isReady: false,
    };
  },

  computed: {
    $editUrl() {
      return APP_ROUTES.DROPDOWN + '/' + this.dropdownFieldId + '/edit/' + this.dropdownItemId;
    },
    redirectUrl() {
      return APP_ROUTES.DROPDOWN + '/' + this.dropdownFieldId;
    },

    getDropElementType() {
      return DROPDOWN_ELEMENT_TYPE;
    },

    isViewMode() {
      return this.formMode === FORM_MODES.VIEW;
    },

    isEditMode() {
      return this.formMode === FORM_MODES.EDIT;
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      if (this.formMode === FORM_MODES.ADD) {
        const res = await DropdownService.getFieldsInItemFormByFieldId({
          fieldId: this.dropdownFieldId,
        });

        if (res.err) {
          return this.$alertError(res.error);
        }

        this.formData = await this.convertFromDynamicDataToStaticData(res.data);
        this.formData.displayOrder += 1;
        this.maximumOfDisplayOrderValue = this.formData.displayOrder;
        this.dropdownSchema = getDropdownSchema(this.getFieldNameForDropdownSchema(this.formData), {
          fieldId: this.dropdownFieldId,
          maxOfItemOrder: this.maximumOfDisplayOrderValue,
        });
      } else {
        if (this.formMode === FORM_MODES.EDIT || this.formMode === FORM_MODES.VIEW) {
          const res = await DropdownService.getFieldsInItemFormByItemId({
            itemId: this.dropdownItemId,
          });

          if (res.err) {
            return this.$alertError(res.error);
          }

          this.formData = await this.convertFromDynamicDataToStaticData(res.data);
          this.maximumOfDisplayOrderValue = this.formData.maximumOfDisplayOrderValue;
          this.dropdownSchema = getDropdownSchema(this.getFieldNameForDropdownSchema(this.formData), {
            fieldId: this.dropdownFieldId,
            itemId: this.dropdownItemId,
            maxOfItemOrder: this.maximumOfDisplayOrderValue,
          });
        }
        this.$setHeadingTitle(this.formData.fieldItemName);
      }
      await this.loadAllExternalFieldOptions();
      this.isReady = true;

      this.$nextTick(function () {
        this.$resetChangeDetection();
      });
    },

    async loadAllExternalFieldOptions() {
      const allExternalFieldOptionsRequests = [];
      this.formData.externalFields.map((item) => {
        if (item.fieldType === this.getDropElementType.DB_PROC) {
          allExternalFieldOptionsRequests.push(this.loadExternalFieldOptions(item.externalFieldId));
        }
      });
      const allExternalFieldOptions = await Promise.all(allExternalFieldOptionsRequests);
      const externalFieldOptions = {};
      allExternalFieldOptions.map((item) => {
        externalFieldOptions[item.externalFieldId] = item.data;
      });
      this.externalFieldOptions = externalFieldOptions;
    },

    async loadExternalFieldOptions(externalFieldId) {
      const data = await this.loadFieldOptionByExternalFieldId(externalFieldId);
      return {
        externalFieldId,
        data,
      };
    },

    clearSpace(fieldName) {
      return fieldName.replace(/\s/g, '');
    },

    getFieldNameForDropdownSchema(formData) {
      const informationDTO = {};
      formData.externalFields.forEach((item) => {
        informationDTO[this.clearSpace(item.fieldName)] = item.isMandatory ? 'required' : '';
      });

      formData.internalFields.forEach((item) => {
        informationDTO[this.clearSpace(item.fieldName)] = item.isMandatory ? 'required' : '';
      });

      return informationDTO;
    },

    async convertFromDynamicDataToStaticData(staticData) {
      const internalFields = await Promise.all(
        staticData.internalFields.map(async (item) => {
          return {
            ...item,
            optionData: await this.loadFieldOptionByFieldId(item.fieldId),
            selectedItems:
              this.formMode === FORM_MODES.EDIT || this.formMode === FORM_MODES.VIEW
                ? this.convertDataFromApiToDisplayInSelectBox(item.selectedItems)
                : [],
          };
        }),
      );
      return {
        ...staticData,
        externalFields: staticData.externalFields.map((item) => {
          if (item.fieldType === this.getDropElementType.CHECK_BOX) {
            item.value = item.value == '1' ? true : false;
          }
          return { ...item };
        }),
        internalFields,
      };
    },

    async loadFieldOptionByFieldId(fieldId) {
      const res = await DropdownService.getFieldDataByFieldId({
        fieldId: fieldId,
      });

      if (res.err) {
        return this.$alertError(res.error);
      }

      return res.data.map((item) => {
        return {
          value: item.id,
          label: item.text,
        };
      });
    },

    convertDataFromApiToDisplayInSelectBox(apiData) {
      return apiData.map((item) => {
        return {
          value: item.itemId,
          label: item.itemName,
        };
      });
    },

    async loadFieldOptionByExternalFieldId(externalFieldId) {
      const res = await DropdownService.getExternalFieldDataByFieldId({
        externalFieldStoreId: externalFieldId,
      });

      if (res.err) {
        this.$alertError(res.error);
        return [];
      }
      return res.data.map((item) => {
        return {
          value: item.id,
          label: item.text,
        };
      });
    },

    async createItemForm() {
      return await DropdownService.addItem(this.convertFormData());
    },

    async updateItemForm() {
      return await DropdownService.updateItem(this.convertFormData(), this.dropdownItemId);
    },

    convertFormData() {
      return {
        fieldId: this.formData.fieldId,
        fieldItemId: this.formData.fieldItemId,
        fieldItemName: this.formData.fieldItemName,
        isHide: this.formData.isHide,
        displayOrder: parseInt(this.formData.displayOrder),
        internalFields: this.formData.internalFields.map((item) => {
          return {
            fieldId: item.fieldId,
            selectedItemIds: this.convertDataFromSelectBoxToPushApi(item.selectedItems),
          };
        }),
        externalFields: this.formData.externalFields.map((item) => {
          let value = item && item.value ? item.value : '';
          if (item.fieldType === this.getDropElementType.CHECK_BOX) {
            value = value ? '1' : '0';
          }
          return {
            externalFieldId: item.externalFieldId,
            fieldType: item.fieldType,
            value: value,
            ValueStoreProcType: item && item.isMultiValues ? item.values : [value],
          };
        }),
      };
    },

    convertDataFromSelectBoxToPushApi(selectBoxData) {
      if (Array.isArray(selectBoxData)) {
        return selectBoxData.map((item) => {
          return item && item.value ? item.value : '';
        });
      } else {
        let temp = [];
        if (selectBoxData && selectBoxData.value) {
          temp.push(selectBoxData.value);
        }
        return temp;
      }
    },

    async onDelete() {
      const { error } = await DropdownService.deleteItem({
        fieldItemId: this.dropdownItemId,
      });

      this.handleDeleteItemResponseError(error);
    },

    handleDeleteItemResponseError(error) {
      if (error) {
        if (error === DROPDOWN_ORDER_ITEM_ERROR.ITEM_IS_USED) {
          this.$alertError(this.$t('pages/Data/Dropdown/alertMessage.isUsed'));
        } else {
          this.$alertError(error);
        }
      } else {
        this.$alertSuccess(
          this.$t('pages/Data/Dropdown/alertMessage.deleted', {
            itemName: this.deletedItemName,
          }),
        );

        this.$router.push(APP_ROUTES.DROPDOWN + '/' + this.dropdownFieldId);
      }
    },

    async onSave() {
      const isValid = await this.$refs.form.validate();
      const validateServerSideFields = this.formData?.fieldItemName
        ? await this.$onServerSideValidation(
            this.formData?.fieldItemName,
            this.dropdownSchema._serverSideValidators.itemName,
          )
        : true;

      try {
        if (isValid && validateServerSideFields) {
          if (this.formMode === FORM_MODES.ADD) {
            const res = await this.createItemForm();

            if (res.error) {
              return this.$alertError(res.error);
            }
          } else {
            if (this.formMode === FORM_MODES.EDIT) {
              const res = await this.updateItemForm();

              if (res.error) {
                return this.$alertError(res.error);
              }
            }
          }

          this.$alertSuccess(
            this.formMode === FORM_MODES.ADD
              ? this.$t(`pages/Data/Dropdown/alertMessage.created`, {
                  itemName: this.formData.fieldItemName,
                })
              : this.$t(`pages/Data/Dropdown/alertMessage.updated`, {
                  itemName: this.formData.fieldItemName,
                }),
          );
          this.onCancel();
        } else {
          this.$alertError(this.$t(`global/errors/message`));
        }
      } catch (error) {
        this.$alertError(error + ' error');
      }
    },

    isNumber(evt) {
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    onCancel() {
      this.$resetChangeDetection();
      setTimeout(() => {
        this.$router.back();
      }, 500);
    },

    $canEdit() {
      return this.$isAuthorized(['Data_DropdownManagement_Update']);
    },

    maxlengthForInputText(fieldName) {
      const fieldNameNoSpace = this.clearSpace(fieldName);
      const FIELD_LENGTH_LIMIT = {
        InsuranceReference: 50,
        BillingCode: 10,
        PartialProductCode: 10,
      };
      const DEFAULT_LENGTH_LIMIT = 250;
      if (Reflect.has(FIELD_LENGTH_LIMIT, fieldNameNoSpace)) {
        return Reflect.get(FIELD_LENGTH_LIMIT, fieldNameNoSpace);
      }
      return DEFAULT_LENGTH_LIMIT;
    },
  },
};
</script>
<style lang="scss" scoped>
.md-layout-item {
  .md-layout-item-div-checkbox {
    display: flex;
    .md-layout-item-checkbox-dropdown {
      margin-top: 45px;
    }
  }
  .md-layout-item-div-label-dropdown {
    margin-top: 44px;
  }
}
</style>

<template>
  <div class="pd-top-10">
    <h3 class="block01-title">
      {{ $t('pages/entity/components/EntityPanels/blockTitle') }}
    </h3>
    <div class="block-content">
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-50 md-small-size-100">
          <lims-field :model="panels" :schema="entitySchema.panels" field="name">
            <md-input
              slot="field"
              v-model="panels.name"
              type="text"
              :disabled="disabled"
              :class="isCheckUniquePanelName ? 'input-err' : ''"
              maxlength="100"
              tabenable="yes"
            >
            </md-input>
          </lims-field>
          <div v-if="isCheckUniquePanelName" class="form-error">
            {{ $t('pages/entity/components/EntityPanels/panelNameNotUnique') }}
          </div>
        </div>
        <div class="md-layout-item md-size-25 md-small-size-100">
          <lims-field :model="panels" :schema="entitySchema.panels" field="statusId">
            <v-select
              slot="field"
              label="fieldItemName"
              v-model="panels.statusId"
              :options="panelStatuses"
              :reduce="(status) => status.fieldItemId"
              :disabled="disabled"
              tabenable="yes"
              :tabindex="100"
            >
              <template #option="{ fieldItemName }">
                {{ fieldItemName }}
              </template>
              <template #selected-option="{ fieldItemName }">
                {{ fieldItemName }}
              </template>
            </v-select>
          </lims-field>
        </div>
      </div>
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-25 md-small-size-100">
          <lims-field :model="panels" :schema="entitySchema.panels" field="HE">
            <v-select
              multiple
              slot="field"
              label="fieldItemName"
              v-model="panels.panelItems.HE"
              :options="slideData.HE.filter((x) => !panels.panelItems.HE.some((y) => x.fieldItemId === y.fieldItemId))"
              :disabled="disabled"
              :placeholder="$t('pages/entity/components/EntityPanels/placeholder.HE')"
              tabenable="yes"
              :tabindex="100"
            >
              <template #option="{ fieldItemName }">{{ fieldItemName }}</template>
              <template #selected-option="{ fieldItemName }">{{ fieldItemName }}</template>
            </v-select>
          </lims-field>
        </div>
        <div class="md-layout-item md-size-25 md-small-size-100">
          <lims-field :model="panels" :schema="entitySchema.panels" field="SpecialStains">
            <v-select
              multiple
              slot="field"
              label="fieldItemName"
              v-model="panels.panelItems.SpecialStains"
              :options="
                slideData.SpecialStains.filter(
                  (x) => !panels.panelItems.SpecialStains.some((y) => x.fieldItemId === y.fieldItemId),
                )
              "
              :disabled="disabled"
              :placeholder="$t('pages/entity/components/EntityPanels/placeholder.SpecialStains')"
              tabenable="yes"
              :tabindex="100"
            >
              <template #option="{ fieldItemName }">{{ fieldItemName }}</template>
              <template #selected-option="{ fieldItemName }">{{ fieldItemName }}</template>
            </v-select>
          </lims-field>
        </div>
        <div class="md-layout-item md-size-25 md-small-size-100">
          <lims-field :model="panels" :schema="entitySchema.panels" field="Immunos">
            <v-select
              multiple
              slot="field"
              label="fieldItemName"
              v-model="panels.panelItems.Immunos"
              :options="
                slideData.Immunos.filter((x) => !panels.panelItems.Immunos.some((y) => x.fieldItemId === y.fieldItemId))
              "
              :disabled="disabled"
              :placeholder="$t('pages/entity/components/EntityPanels/placeholder.Immunos')"
              tabenable="yes"
              :tabindex="100"
            >
              <template #option="{ fieldItemName }">{{ fieldItemName }}</template>
              <template #selected-option="{ fieldItemName }">{{ fieldItemName }}</template>
            </v-select>
          </lims-field>
        </div>
        <div class="md-layout-item md-size-25 md-small-size-100">
          <lims-field :model="panels" :schema="entitySchema.panels" field="AddTechniques">
            <v-select
              multiple
              slot="field"
              label="fieldItemName"
              v-model="panels.panelItems.AddTechniques"
              :options="
                slideData.AddTechniques.filter(
                  (x) => !panels.panelItems.AddTechniques.some((y) => x.fieldItemId === y.fieldItemId),
                )
              "
              :disabled="disabled"
              :placeholder="$t('pages/entity/components/EntityPanels/placeholder.AddTechniques')"
              tabenable="yes"
              :tabindex="100"
            >
              <template #option="{ fieldItemName }">{{ fieldItemName }}</template>
              <template #selected-option="{ fieldItemName }">{{ fieldItemName }}</template>
            </v-select>
          </lims-field>
        </div>
        <div v-if="errCheckSlidesAlreadyExit" class="form-error">
          {{ $t('pages/entity/components/EntityPanels/slidesAlreadyExit') }}
        </div>
      </div>
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-100 md-small-size-100">
          <lims-field :model="panels" :schema="entitySchema.panels" field="comment">
            <md-input
              slot="field"
              v-model="panels.comment"
              type="text"
              :disabled="disabled"
              maxlength="250"
              tabenable="yes"
            >
            </md-input>
          </lims-field>
        </div>
      </div>
      <div class="md-layout lims-form-row addPanelBtn">
        <md-button
          @click="addPanel"
          type="button"
          class="lims-form-button md-primary"
          :disabled="disabled || !isEnableAddPAnel"
        >
          <template v-if="!currentEditItem">{{
            $t('pages/entity/components/EntityPanels/button.addPanelBtn')
          }}</template>
          <template v-else>{{ $t('pages/entity/components/EntityPanels/button.editPanelBtn') }}</template>
        </md-button>
      </div>
      <div class="md-layout lims-form-row">
        <md-table class="custom-tbl" md-card>
          <md-table-row>
            <md-table-head width="15%">
              {{ $t('pages/entity/components/EntityPanels/column.PanelName') }}
            </md-table-head>
            <md-table-head width="15%">
              {{ $t('pages/entity/components/EntityPanels/column.HAndE') }}
            </md-table-head>
            <md-table-head width="15%">
              {{ $t('pages/entity/components/EntityPanels/column.SpecialStains') }}
            </md-table-head>
            <md-table-head width="15%">
              {{ $t('pages/entity/components/EntityPanels/column.Immunos') }}
            </md-table-head>
            <md-table-head width="15%">
              {{ $t('pages/entity/components/EntityPanels/column.AdditionalTechniques') }}
            </md-table-head>
            <md-table-head width="15%">
              {{ $t('pages/entity/components/EntityPanels/column.Comment') }}
            </md-table-head>
            <md-table-head width="10%">
              {{ $t('pages/entity/components/EntityPanels/column.Status') }}
            </md-table-head>
            <md-table-head class="th-center cell-action" width="10%">
              {{ $t('global/pages/list.actions') }}
            </md-table-head>
          </md-table-row>
          <md-table-row v-for="(item, idx) in listPanels" :key="`${item.name}-${idx}`">
            <md-table-cell width="15%">{{ item.name }}</md-table-cell>
            <md-table-cell width="15%">{{
              findPanelItemName(item.panelItems, dropdownShortName.H_AND_E)
            }}</md-table-cell>
            <md-table-cell width="15%">{{
              findPanelItemName(item.panelItems, dropdownShortName.SPECIAL_STAINS)
            }}</md-table-cell>
            <md-table-cell width="15%">{{
              findPanelItemName(item.panelItems, dropdownShortName.IMMUNOS)
            }}</md-table-cell>
            <md-table-cell width="15%">{{
              findPanelItemName(item.panelItems, dropdownShortName.ADDITIONAL_TECHNIQUE)
            }}</md-table-cell>
            <md-table-cell width="15%">{{ item.comment }}</md-table-cell>
            <md-table-cell width="10%">{{ item.statusName }}</md-table-cell>
            <md-table-cell class="cell-action" width="10%">
              <span @click="!disabled ? editPanel(item) : ''"><md-icon>edit</md-icon></span>
              <span @click="!disabled ? removePanel(item) : ''"><md-icon>close</md-icon></span>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import { getEntitySchema } from '@/schemas/entities.schema';
import { DROPDOWN_SHORT_NAME } from '@/core/constants';
import { retrieveOptionByName } from '@/core/helpers';
import { mapGetters } from 'vuex';

export default {
  props: {
    selectedSlideData: {
      require: false,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.fetchData();
    this.listPanels = this.value;
  },
  watch: {
    panels: {
      handler(val) {
        const numberOfSlide = Object.values(val.panelItems).flat().length;
        const arrPanelItem = [];
        const keyPanelItem = Object.keys(val.panelItems);
        keyPanelItem.forEach((element) => arrPanelItem.push(...val.panelItems[element]));
        if (arrPanelItem.length > 0) {
          this.errCheckSlidesAlreadyExit = this.isCheckSlidesAlreadyExit(arrPanelItem);
        }
        if (
          val &&
          val.name &&
          val.statusId &&
          numberOfSlide >= 2 &&
          this.isCheckUniquePanelName == false &&
          !this.errCheckSlidesAlreadyExit
        ) {
          this.isEnableAddPAnel = true;
        } else {
          this.isEnableAddPAnel = false;
        }
      },
      deep: true,
    },
    listPanels: {
      handler(val) {
        this.$emit('input', val);
      },
      deep: true,
    },
    value: {
      handler(val) {
        this.listPanels = val;
      },
      deep: true,
    },
    'panels.panelItems': {
      handler(val) {
        const panelsSelected = [...val.HE, ...val.AddTechniques, ...val.Immunos, ...val.SpecialStains];
        this.$emit('panelsSelected', panelsSelected);
      },
      deep: true,
    },
  },
  computed: {
    entitySchema() {
      return getEntitySchema(1, this.entityResource);
    },
    slideData() {
      return {
        HE: this.selectedSlideData.filter((item) => item.fieldName === DROPDOWN_SHORT_NAME.H_AND_E),
        Immunos: this.selectedSlideData.filter((item) => item.fieldName === DROPDOWN_SHORT_NAME.IMMUNOS),
        SpecialStains: this.selectedSlideData.filter((item) => item.fieldName === DROPDOWN_SHORT_NAME.SPECIAL_STAINS),
        AddTechniques: this.selectedSlideData.filter(
          (item) => item.fieldName === DROPDOWN_SHORT_NAME.ADDITIONAL_TECHNIQUE,
        ),
      };
    },
    dropdownShortName() {
      return DROPDOWN_SHORT_NAME;
    },
    panelStatusOptions() {
      return Object.keys(this.panelStatuses);
    },

    isCheckUniquePanelName() {
      if (!this.panels.name) {
        return false;
      }
      if (this.listPanels.length > 0) {
        const element = this.listPanels.find((item) => {
          return (
            (!this.currentEditItem && item.name == this.panels.name) ||
            (this.currentEditItem && item.name == this.panels.name && this.currentEditItem.name != item.name)
          );
        });
        return typeof element === 'undefined' ? false : true;
      } else {
        return false;
      }
    },
    ...mapGetters('app/data', ['getDatasetByKey']),
  },
  data() {
    return {
      panels: {
        panelItems: {
          HE: [],
          Immunos: [],
          SpecialStains: [],
          AddTechniques: [],
        },
      },
      // panel statuses
      panelStatuses: [],
      isEnableAddPAnel: false,
      listPanels: [],
      currentEditItem: null,
      errCheckSlidesAlreadyExit: false,
      HE: [],
      Immunos: [],
      SpecialStains: [],
      AddTechniques: [],
    };
  },
  methods: {
    async fetchData() {
      this.panelStatuses = this.getDatasetByKey(DROPDOWN_SHORT_NAME.SLIDE_PANEL_STATUS);
    },
    findPanelItemName(array, key) {
      const arrayData = _.groupBy(array, 'fieldName');
      // eslint-disable-next-line security/detect-object-injection
      const keyValue = arrayData[key];
      if (!keyValue) {
        return '';
      }
      let itemValue = [];
      keyValue.forEach((e) => {
        if (this.slideData[key]?.find((item) => item.fieldItemName == e.fieldItemName)) {
          itemValue.push(e.fieldItemName);
        }
      });
      return itemValue.join(', ');
    },
    addPanel() {
      if (this.isEnableAddPAnel) {
        if (!this.currentEditItem) {
          const dataAdd = {
            statusId: this.panels.statusId,
            statusName: this.panels.statusId ? retrieveOptionByName(this.panelStatuses, this.panels.statusId) : '',
            comment: this.panels.comment,
            name: this.panels.name,
            panelItems: [
              ...this.panels.panelItems.HE,
              ...this.panels.panelItems.Immunos,
              ...this.panels.panelItems.SpecialStains,
              ...this.panels.panelItems.AddTechniques,
            ],
          };
          if (this.isCheckSlidesAlreadyExit(dataAdd.panelItems) == false) {
            this.listPanels.push(dataAdd);
          }
        } else {
          const idx = this.listPanels.findIndex((listPanel) => listPanel.name === this.currentEditItem.name);
          if (idx !== -1) {
            const arrPanelItem = [];
            let dataEdit = {};
            const keyPanelItem = Object.keys(this.panels.panelItems);
            keyPanelItem.forEach((element) => arrPanelItem.push(...this.panels.panelItems[element]));
            dataEdit = {
              statusId: this.panels.statusId,
              statusName: this.panels.statusId ? retrieveOptionByName(this.panelStatuses, this.panels.statusId) : '',
              comment: this.panels.comment,
              name: this.panels.name,
              panelItems: arrPanelItem,
            };
            if (this.isCheckSlidesAlreadyExit(dataEdit.panelItems) == false) {
              this.listPanels[idx] = { ...this.currentEditItem, ...dataEdit };
            }
          }
          this.currentEditItem = null;
        }
        this.resetForm();
      }
    },
    resetForm() {
      this.panels.name = '';
      this.panels.statusId = null;
      this.panels.panelItems.HE = [];
      this.panels.panelItems.Immunos = [];
      this.panels.panelItems.SpecialStains = [];
      this.panels.panelItems.AddTechniques = [];
      this.panels.comment = '';
    },

    editPanel(item) {
      this.resetForm();
      if (item) {
        const panelItem = _.groupBy(item.panelItems, 'fieldName');
        const panelItemArr = _.merge(this.panels.panelItems, panelItem);
        this.currentEditItem = item;
        this.panels = _.cloneDeep(this.currentEditItem);
        this.panels.panelItems = _.cloneDeep(panelItemArr);
        this.panels.statusId = item.statusId;
      }
    },
    removePanel(item) {
      const idx = this.listPanels.findIndex((listPanel) => listPanel.name === item.name);
      this.listPanels.splice(idx, 1);
    },

    isCheckSlidesAlreadyExit(data) {
      if (this.listPanels.length > 0 && data) {
        let newData = _.cloneDeep(data);
        newData.forEach((n) => {
          delete n.isFavorite;
        });
        let newItem = _.cloneDeep(this.listPanels);
        newItem.forEach((n) => {
          n.panelItems.forEach((n) => {
            delete n.isFavorite;
          });
        });
        const element = newItem.find((item) => {
          if (this.currentEditItem && item.name === this.currentEditItem.name) return false;

          return _.isEqual(
            item.panelItems.sort((a, b) => a.fieldItemId - b.fieldItemId),
            newData.sort((a, b) => a.fieldItemId - b.fieldItemId),
          );
        });
        return typeof element === 'undefined' ? false : true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style lang="scss">
.addPanelBtn {
  display: flex;
  justify-content: flex-end;
  .md-button {
    width: 200px;
    margin-right: 10px;
  }
  @media only screen and (max-width: 768px) {
    justify-content: center;
  }
}
</style>

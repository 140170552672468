import { APP_ROUTES, ROLE_STATUSES } from '@/core/constants';
import { RoleService } from '@/services';

export default {
  computed: {
    $editUrl() {
      return APP_ROUTES.ROLE_EDIT + '/' + this.roleResource.roleId;
    },
  },
  methods: {
    $redirectUrl() {
      return '';
    },
    $onDisableRole() {
      this.$refs.disableRole.open();
    },

    $onCancelDisableRole() {
      this.$refs.disableRole.close();
    },

    async $onConfirmDisableRole(roleId, roleName) {
      const { error, err, data } = await RoleService.disable(roleId);
      if (err) {
        if (error === 'inActive') {
          this.$onCancelDisableRole();
          this.$alertError(
            this.$t('pages/Account/Role/Forms/RoleForm/alert/disableError', {
              roleName: roleName,
            }),
          );
        } else {
          this.$alertError(err);
        }

        return;
      }

      if (data) {
        await this.$nextTick(function () {
          this.$router.push({ path: '/roles' });
        });
        this.$alertSuccess(
          this.$t('pages/Account/Role/Forms/RoleForm/alert/disabledSuccess', {
            roleName: roleName,
          }),
        );
      }
    },

    $onDeleteRole() {
      this.$refs.deleteRole.open();
    },

    $onCancelDeleteRole() {
      this.$refs.deleteRole.close();
    },

    async $onConfirmDeleteRole(roleId, roleName) {
      const res = await RoleService.delete(roleId);
      if (res.err) {
        this.$alertError(res.err);
      } else {
        this.$alertSuccess(
          this.$t('pages/Account/Role/Forms/RoleForm/alert/delete', {
            roleName: roleName,
          }),
        );
        setTimeout(() => {
          this.$router.push(APP_ROUTES.ROLE);
        }, 500);
      }
    },

    async $loadFeaturePermissions({ userTypeId }) {
      const { err, data } = await RoleService.getFeaturePermissions({ userTypeId });
      if (err) {
        this.$alertError(err);
      }
      if (!data) {
        return [];
      }

      const permissions = data.map((ftg) => {
        const features = ftg.features.map((ft) => {
          const restricted = [];
          Object.keys(ft).map((k) => {
            // eslint-disable-next-line security/detect-object-injection
            if (ft[k] === null) {
              restricted.push(k);
            }
          });
          return {
            ...ft,
            restricted,
          };
        });
        return {
          ...ftg,
          features: features,
        };
      });
      return permissions;
    },

    $canEdit() {
      const item = this.roleResource;
      return (
        item.roleStatusId === ROLE_STATUSES.Enable &&
        this.$isAuthorized(['Accounts_RoleManagement_Update']) &&
        item.isStatic === false
      );
    },
  },
};

<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <md-dialog-title>{{ $t('components/lims/modals/ModalSbAddSlides.title') }}</md-dialog-title>
    <div class="lims-form dialog-content list-filter">
      <md-field class="input-search">
        <md-icon>search</md-icon>
        <md-input
          v-model="searchValue"
          :placeholder="$t('components/lims/modals/ModalSbAddSlides.searchPlaceholder')"
        ></md-input>
      </md-field>
      <div class="list-slides mg-top-24">
        <md-checkbox
          v-for="(element, index) in slides"
          :key="`slides-${index}`"
          class="list-item"
          v-model="slideSelected"
          :value="element"
          >{{ element.fieldItemName }}</md-checkbox
        >
      </div>
    </div>
    <md-dialog-actions>
      <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
      <md-button @click="onConfirm" class="lims-form-button md-primary">{{
        $t('global/button/button.confirm')
      }}</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import modalMixins from '@/components/Lims/modals/modal.mixins';

const toLower = (text) => {
  return text.toString().toLowerCase();
};

const searchSlideList = (items, term) => {
  if (term && term.length >= 3) {
    return items.filter((item) => toLower(item.fieldItemName).includes(toLower(term)));
  }
  return items;
};

export default {
  name: 'modal-sb-add-slides',
  mixins: [modalMixins],
  props: {
    commonlyUsedSlides: {
      type: Array,
      required: true,
    },
  },
  created() {
    this.listSlides = this.commonlyUsedSlides.filter((item) => !item.isHide);
    this.listSlidesTmp = this.listSlides;
  },
  computed: {
    slides() {
      const existedSlideIds = this.existedSlides.map((f) => f.fieldItemId);
      return this.listSlidesTmp.filter((f) => !existedSlideIds.includes(f.fieldItemId));
    },
  },
  data() {
    return {
      searchValue: '',
      listSlides: [],
      listSlidesTmp: [],
      slideSelected: [],
      existedSlides: [],
    };
  },
  watch: {
    listSlides: {
      handler(val) {
        this.listSlidesTmp = val;
      },
      deep: true,
    },
    searchValue: {
      deep: true,
      handler: function (val) {
        this.onSearch(val);
      },
    },
  },
  methods: {
    setExistedSlides(existedSlides) {
      this.existedSlides = existedSlides;
    },
    onSearch(value) {
      this.listSlidesTmp = searchSlideList(this.listSlides, value);
    },
    onConfirm() {
      this.$emit('SlideAdded', this.slideSelected);
      this.close();
    },
    close() {
      this.isVisible = false;
      this.setExistedSlides([]);
      this.$nextTick(() => {
        this.searchValue = '';
        this.slideSelected = [];
      });
    },
  },
};
</script>

<style lang="scss">
.list-slides {
  .list-item {
    border: 1px solid #008080;
    border-radius: 5px;
    margin: 5px;
    padding: 3px 5px;
    display: inline-block;
    &.md-checked {
      background: #008080;
      .md-checkbox-label {
        color: #fff;
      }
    }
    .md-checkbox-container {
      display: none;
    }
    .md-checkbox-label {
      color: #008080;
      padding: 0;
    }
  }
}
</style>

<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
      <!-- Personal Info -->
      <lims-block class="personal-info-block">
        <h4 class="title" slot="blockTitle">{{ $t('pages/account/user/forms/personalInfoBlock') }}</h4>
        <modal-confirmation
          ref="confirmModal"
          slot="blockTitle"
          :is-wrong-password="isWrongPassword"
          @onConfirm="$onConfirmDisabled"
        ></modal-confirmation>
        <modal-has-login-confirm
          ref="hasLoginConfirmModal"
          slot="blockTitle"
          @onConfirm="$onHasLoginConfirmModal"
        ></modal-has-login-confirm>
        <modal-view-permissions
          ref="viewPermissions"
          slot="blockTitle"
          :permission-data="roleInformation"
        ></modal-view-permissions>
        <modal-input-your-telephone
          slot="blockTitle"
          ref="inputYourTelephoneModal"
          :user-id="userId"
          :verify-two-fa-mode="verifyTwoFaMode"
          :mobile-phone="telephoneMobileDataInVerify"
          @onNext="onCompleteInputNewTelephone"
        ></modal-input-your-telephone>
        <modal-input-new-email
          slot="blockTitle"
          ref="inputNewEmailModal"
          :user-id="userId"
          @onNext="onCompleteInputNewEmail"
        ></modal-input-new-email>
        <modal-verify-code
          slot="blockTitle"
          ref="verifyCodeModal"
          :option="verifyContent"
          :is-wrong-code="isWrongCode"
          @onVerify="onVerifyCode"
          @onResendCode="onResendVerifyCode"
        ></modal-verify-code>
        <modal-update-successfully
          slot="blockTitle"
          ref="verifySuccessfullyModal"
          :method="verifyMode === onUpdateEmail ? 'Email' : 'Telephone (Mobile)'"
          @onUpdateSuccessfully="onUpdateSuccessfully"
        ></modal-update-successfully>
        <div slot="blockContent">
          <LimsFormEditButton class="edit-btn" v-if="viewMode && $canEdit()" :editUrl="$editUrl" />
          <!-- new row -->
          <div class="md-layout lims-form-row" v-if="isDefaultView">
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" field="userType" :schema="userSchema.personalInfo">
                <v-select disabled slot="field" :options="userTypesList" v-model="formData.personalInfo.userType">
                  <template #option="{ label }">{{ $t(label) }}</template>
                  <template #selected-option="{ label }">{{ $t(label) }}</template>
                </v-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="role">
                <v-select
                  slot="field"
                  v-model="formData.personalInfo.roleId"
                  :options="roleList"
                  :disabled="viewMode"
                  tabenable="yes"
                  :tabindex="1"
                >
                  <template #option="{ label }">{{ label }}</template>
                  <template #selected-option="{ label }">{{ label }}</template>
                </v-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <div
                class="view-permissions"
                @click="onClickViewPermissions"
                :class="formData.personalInfo.roleId ? '' : 'disable-view-permissions'"
              >
                {{ $t('entities/user/personalInfo/viewPermissions') }}
              </div>
            </div>
          </div>
          <!-- end row -->
          <!-- new row -->
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="username">
                <lims-tooltip
                  slot="label-info"
                  :content="$t('entities/user/personalInfo/userNameTooltip')"
                ></lims-tooltip>
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.username"
                  :disabled="isEditMode || viewMode"
                  v-on:blur="
                    $onServerSideValidation($event, userSchema._serverSideValidators.username, [
                      () => $lowercase(formData.personalInfo, 'username'),
                    ])
                  "
                  type="text"
                  tabenable="yes"
                ></md-input>
                <lims-tooltip
                  slot="icon"
                  :content="$t('entities/user/personalInfo/userNameTooltipRules')"
                ></lims-tooltip>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="title">
                <v-select
                  slot="field"
                  v-model="formData.personalInfo.titleId"
                  :options="userTitles"
                  :disabled="viewMode"
                  tabenable="yes"
                  :tabindex="3"
                >
                  <template #option="{ label }">{{ label }}</template>
                  <template #selected-option="{ label }">{{ label }}</template>
                </v-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="firstName">
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.firstname"
                  type="text"
                  maxlength="250"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="lastName">
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.lastname"
                  type="text"
                  maxlength="250"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
          </div>
          <!-- end row -->
          <!-- new row -->
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="reference">
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.reference"
                  type="text"
                  maxlength="25"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="gmc">
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.gmc"
                  type="text"
                  maxlength="7"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
          </div>
          <!-- end row -->
          <!-- new row -->
          <ValidationProvider rules="required" v-slot="{ errors, failed }" :mode="$eagerMode">
            <specialty-list
              slot="field-list"
              :validation="{ errors, failed }"
              v-model="formData.personalInfo.listPathologistSpecialties"
              :disabled="viewMode"
              :viewType="viewType"
            >
              <label slot="error" v-if="errors.length > 0 && failed" class="form-error">{{
                $t(`entities/${userSchema.personalInfo.entity}/listPathologistSpecialties.required`)
              }}</label>
            </specialty-list>
          </ValidationProvider>
          <!-- new row -->
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="address1">
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.address1"
                  type="text"
                  maxlength="1000"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="address2">
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.address2"
                  type="text"
                  maxlength="1000"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
          </div>
          <!-- end row -->
          <!-- new row -->
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="town">
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.town"
                  type="text"
                  maxlength="100"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="country">
                <v-select
                  slot="field"
                  v-model="formData.personalInfo.countryId"
                  :options="countries"
                  :disabled="viewMode"
                  tabenable="yes"
                  :tabindex="14"
                >
                  <template #option="{ label }">{{ label }}</template>
                  <template #selected-option="{ label }">{{ label }}</template>
                </v-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="postCode">
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.postCode"
                  type="text"
                  maxlength="50"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
          </div>
          <!-- end row -->
          <!-- new row -->
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-50 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="email">
                <lims-tooltip slot="label-info" :content="$t('entities/user/personalInfo/emailTooltip')"></lims-tooltip>
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.email"
                  type="text"
                  v-on:blur="
                    $onServerSideValidation($event, userSchema._serverSideValidators.email, [
                      () => $lowercase(formData.personalInfo, 'email'),
                    ])
                  "
                  :placeholder="$t('entities/user/personalInfo/email.placeholder')"
                  maxlength="200"
                  :disabled="viewMode || isMyProfileView || $getUserMixins().isNotAllowedUpdatePhoneOrEmail()"
                  tabenable="yes"
                ></md-input>
              </lims-field>
              <div class="text-underline" v-if="isMyProfileView" @click="onClickUpdateEmail">
                {{ $t('pages/myAccount/profile/update/email') }}
              </div>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="telephoneOffice">
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.telephoneOffice"
                  type="text"
                  :placeholder="$t('global/placeholder.telephone')"
                  maxlength="50"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="telephoneMobile">
                <lims-tel-input
                  slot="field"
                  :disabled="viewMode || isMyProfileView || $getUserMixins().isNotAllowedUpdatePhoneOrEmail()"
                  v-model="telephoneMobileData"
                  @phoneNumberChange="
                    ($event) => $onServerSideValidation($event, userSchema._serverSideValidators.telephoneMobile)
                  "
                ></lims-tel-input>
              </lims-field>
              <div class="text-underline" v-if="isMyProfileView" @click="onClickUpdateTelephone">
                {{ $t('pages/myAccount/profile/update/telephone') }}
              </div>
            </div>
          </div>
          <!-- end row -->
          <!-- new row -->
          <div class="md-layout lims-form-row" v-if="!isMyProfileView">
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="pullSystem">
                <md-checkbox
                  slot="field"
                  v-model="formData.personalInfo.pullSystem"
                  class="lims-checkbox"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-checkbox>
              </lims-field>
            </div>
          </div>
          <!-- end row -->
        </div>
      </lims-block>
      <!-- Pathologist Leave -->
      <lims-block class="pathologist-leave-block" v-if="isDefaultView">
        <h4 class="title" slot="blockTitle">{{ $t('pages/account/user/forms/pathologist/pathLeaveBlock') }}</h4>
        <lims-tooltip
          slot="blockTitle-info"
          :content="$t('pages/account/user/forms/pathologist/pathLeave.txt')"
        ></lims-tooltip>
        <div slot="blockContent">
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-20 md-small-size-100 datepicker-field">
              <label>{{ $t('pages/account/user/forms/pathologist/pathLeave/fromDate') }}</label>
              <date-picker
                :lang="{
                  formatLocale: {
                    firstDayOfWeek: 1,
                  },
                  monthBeforeYear: false,
                }"
                slot="field"
                v-model="formData.pathologistLeave.fromDate"
                format="DD/MM/YYYY"
                :class="viewMode ? 'md-disabled' : ''"
                :disabled="viewMode"
                tabenable="yes"
              ></date-picker>
            </div>
            <div class="md-layout-item md-size-20 md-small-size-100 datepicker-field">
              <label>{{ $t('pages/account/user/forms/pathologist/pathLeave/toDate') }}</label>
              <date-picker
                :lang="{
                  formatLocale: {
                    firstDayOfWeek: 1,
                  },
                  monthBeforeYear: false,
                }"
                slot="field"
                v-model="formData.pathologistLeave.toDate"
                format="DD/MM/YYYY"
                :class="viewMode ? 'md-disabled' : ''"
                :disabled="viewMode"
                tabenable="yes"
              ></date-picker>
              <div v-if="isValidPathologitLeave" class="form-error">{{ errPathologitLeave }}</div>
            </div>
            <div class="md-layout-item md-size-40 md-small-size-100 input-field">
              <label>{{ $t('pages/account/user/forms/pathologist/pathLeave/commentLable') }}</label>
              <md-field>
                <md-input
                  v-model="formData.pathologistLeave.comment"
                  maxlength="1000"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 addLeave-btn">
              <md-button
                type="button"
                class="lims-form-button add-btn md-primary"
                :class="
                  !(formData.pathologistLeave.fromDate && formData.pathologistLeave.toDate) || isValidPathologitLeave
                    ? 'disabled'
                    : ''
                "
                @click="addPathologistLeave"
                :disabled="viewMode"
              >
                {{ $t('pages/account/user/forms/pathologist/pathLeave/addLeave') }}
              </md-button>
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <md-table class="custom-tbl tbl-drag" md-card>
              <md-table-row>
                <md-table-head width="20%">{{
                  $t('pages/account/user/forms/pathologist/pathLeave/fromDate')
                }}</md-table-head>
                <md-table-head width="20%">{{
                  $t('pages/account/user/forms/pathologist/pathLeave/toDate')
                }}</md-table-head>
                <md-table-head>{{ $t('pages/account/user/forms/pathologist/pathLeave/commentLable') }}</md-table-head>
                <md-table-head class="th-center cell-action">{{ $t('global/pages/list.actions') }}</md-table-head>
              </md-table-row>
              <md-table-row v-for="(item, index) in pathologistLeavesSorted" :key="`${index}`">
                <md-table-cell>{{ pathologistLeaveDate(item.fromDate) }}</md-table-cell>
                <md-table-cell>{{ pathologistLeaveDate(item.toDate) }}</md-table-cell>
                <md-table-cell class="cell-comment">{{ item.comment }}</md-table-cell>
                <md-table-cell class="cell-action">
                  <div @click="viewMode ? '' : delPathologistLeave(item)"><md-icon>close</md-icon></div>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </div>
        </div>
      </lims-block>
      <lab-preference
        v-model="formData.labPreferences"
        :viewMode="viewMode"
        :viewType="viewType"
        :user-id="userId"
      ></lab-preference>
      <specimen-preference
        v-model="formData.specimenPreferences"
        :viewMode="viewMode"
        :viewType="viewType"
      ></specimen-preference>
      <lims-block>
        <h4 class="title" slot="blockTitle">
          {{ $t('pages/account/user/forms/pathologist/secondOpinionBlock') }}
        </h4>
        <div slot="blockContent">
          <div class="md-layout">
            <md-checkbox
              v-model="formData.secondOpinion.isContactable"
              class="lims-checkbox"
              :disabled="viewMode"
              tabenable="yes"
              >{{ $t('pages/account/user/forms/pathologist/secondOpinion/ContactForSecondOpinion') }}</md-checkbox
            >
          </div>
          <div class="md-layout md-size-100 md-small-size-100 textarea-field">
            <label style="display: flex">
              {{ $t('pages/account/user/forms/pathologist/secondOpinion/commentLabel') }}
              <lims-tooltip
                :content="$t('pages/account/user/forms/pathologist/secondOpinion/secondOpinionTxt')"
              ></lims-tooltip>
            </label>
            <md-field>
              <md-textarea v-model="formData.secondOpinion.comment" :disabled="viewMode" tabenable="yes"></md-textarea>
            </md-field>
          </div>
        </div>
      </lims-block>
      <double-reporting
        v-model="formData.doubleReporting"
        :formMode="formMode"
        :listSpecialty="formData.personalInfo.listPathologistSpecialties"
        :data="userResourceModel"
      ></double-reporting>
      <user-notifications
        v-if="formData.notificationSettings"
        v-model="formData.notificationSettings"
        :disabled="viewMode"
        :disabledSMS="!telephoneMobileData.telephoneMobile || telephoneMobileData.telephoneMobile == ''"
      ></user-notifications>
      <lims-block v-if="isDefaultView">
        <h4 class="title" slot="blockTitle">{{ $t('pages/account/user/forms/loginBlock') }}</h4>
        <div class="md-layout" slot="blockContent">
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <md-checkbox v-model="formData.login.hasLogin" class="lims-checkbox" :disabled="viewMode" tabenable="yes">
                {{ $t('pages/account/user/forms/loginLabel') }}
              </md-checkbox>
            </div>
            <div class="md-layout-item md-size-100 md-small-size-100">
              <user-policy-actions
                v-if="!isAddMode"
                :userPolicyActions="userResource.userPolicyActions"
              ></user-policy-actions>
            </div>
          </div>
          <div v-if="userResource && userResource.lastTimeLogin" class="last-login">
            {{ $t('pages/account/user/forms/lastTimeLogin', { lastTimeLogin: getLastTimeLogin }) }}
          </div>
        </div>
      </lims-block>
      <!-- Display if the user has not completed their account -->
      <div v-if="isDefaultView" class="isNotCompleteAccount">
        <div v-if="!isAddMode && isCheckCompleteAccount">
          {{ $t('pages/account/user/forms/isNotCompleteAccount') }}
        </div>
      </div>
      <!-- Form action -->
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions">
          <LimsFormCancel :redirectUrl="$redirectUrl()" />
          <md-button v-if="canSave" @click="$onSave()" class="md-button md-primary lims-form-button md-theme-default">
            {{ $t('global/button/button.save') }}
          </md-button>
          <LimsFormEditButton v-if="viewMode && $canEdit()" :editUrl="$editUrl" />
          <md-button
            v-if="!isAddMode && isDisabledUser && isDefaultView"
            class="md-primary lims-form-button"
            @click="$onEnableUser"
            >{{ $t('global/button/button.enable') }}</md-button
          >
          <template v-if="!isMyProfileView">
            <md-button
              v-if="isEditMode && isResetPasswordUser && isDefaultView"
              class="md-danger lims-form-button"
              @click="$onResetPassword"
              >{{ $t('global/button/button.resetPassword') }}</md-button
            >
          </template>
          <md-button
            v-if="isEditMode && isEnabledUser && isDefaultView"
            class="md-danger lims-form-button"
            @click="$onDisableUser"
            >{{ $t('global/button/button.disable') }}</md-button
          >
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { FormMixins, TabMixins } from '@/core/mixins';
import UserRoleMixins from '@/pages/Account/User/user.role.mixins';
import UserMixins, { UPDATE_TWO_FA_MODE } from '@/pages/Account/User/user.mixins';
import UserService from '@/services/user.service';
import { FORM_MODES } from '@/core/constants';
import { getUserSchema } from '@/schemas/users.schema';
import UserNotifications from '../UserNotifications.vue';
import { formatDateWithoutTime, currentDay, startDay, endDay } from '@/core/helpers';
import LimsTelInput from '@/components/Lims/LimsTelInput.vue';
import ModalHasLoginConfirm from '@/components/Lims/modals/ModalHasLoginConfirm.vue';
import LabPreference from '../../Components/LabPreference.vue';
import SpecimenPreference from '../../Components/SpecimenPreference.vue';
import SpecialtyList from '../../Components/SpecialtyList.vue';
import DoubleReporting from '../../Components/DoubleReporting.vue';
import ModalViewPermissions from '@/components/Lims/modals/ModalViewPermissions';
import { VERIFY_TW0_FA_MODE } from '@/core/constants';
import { ModalInputYourTelephone } from '@/components/Lims/modals';
import { ModalInputNewEmail } from '@/components/Lims/modals';
import { ModalVerifyCode } from '@/components/Lims/modals';
import { ModalUpdateSuccessfully } from '@/components/Lims/modals';
import { MyAccountService } from '@/services';

export default {
  components: {
    UserNotifications,
    LimsTelInput,
    ModalHasLoginConfirm,
    LabPreference,
    SpecimenPreference,
    SpecialtyList,
    DoubleReporting,
    ModalViewPermissions,
    ModalInputYourTelephone,
    ModalInputNewEmail,
    ModalVerifyCode,
    ModalUpdateSuccessfully,
  },
  mixins: [FormMixins, UserMixins, UserRoleMixins, TabMixins],
  created() {
    this.fetchData();
    this.userResourceModel = this.userResource;
    this.userType = this.$store.getters['auth/userType'];
  },
  props: {
    userResource: {
      type: Object,
      require: false,
      default: null,
    },
    userId: {
      type: String,
      require: false,
      default: null,
    },
    viewMode: {
      type: Boolean,
      require: false,
      default: null,
    },
  },
  data() {
    return {
      userResourceModel: null,
      formMode: FORM_MODES.ADD,
      formData: {
        personalInfo: {
          userType: this.USERTYPES_ENUM().find((e) => e.value === this.USER_TYPES().Pathologist),
          userRole: '',
          listPathologistSpecialties: [],
        },
        pathologistLeave: {
          fromDate: null,
          toDate: null,
          comment: '',
        },
        pathologistLeaves: [],
        labPreferences: [],
        specimenPreferences: [],
        secondOpinion: {
          isContactable: false,
          comment: '',
        },
        notificationSettings: null,
        login: {
          hasLogin: false,
        },
        isDoubleReport: false,
        doubleReporting: {
          isDoubleReport: true,
          requestedPartners: [],
          acceptedPartners: [],
        },
      },
      userTitles: [],
      countries: [],
      roleList: [],
      telephoneMobileData: {},
      telephoneMobileDataInVerify: {},
      isValidPathologitLeave: false,
      errPathologitLeave: '',
      clinic: [
        {
          id: 1,
          clinicType: 'PP HCA',
          name: 'City of London Med.Centre',
        },
      ],
      roleInformation: null,
      verifyMode: null,
      verifyContent: '',
      countryCode: null,
      phoneNumber: null,
      email: null,
    };
  },
  computed: {
    userSchema: function () {
      return getUserSchema('Pathologist', this.formMode, this.userModel);
    },
    userTypesList() {
      return this.USERTYPES_ENUM();
    },
    disabledUsername() {
      return this.formMode === FORM_MODES.EDIT;
    },
    pathologistLeaveDate() {
      return formatDateWithoutTime;
    },
    pathologistLeavesSorted() {
      const tempSort = this.formData.pathologistLeaves;
      return tempSort.sort((a, b) => new Date(b.fromDate).getTime() - new Date(a.fromDate).getTime());
    },
    verifyTwoFaMode() {
      return VERIFY_TW0_FA_MODE.NEW;
    },
    onUpdateEmail() {
      return UPDATE_TWO_FA_MODE.EMAIL;
    },
    isPathologistView() {
      return this.userType === this.USER_TYPES().Pathologist;
    },
  },
  watch: {
    'formData.pathologistLeave': {
      handler(val) {
        this.$material.locale.dateFormat = 'dd/MM/yyyy';
        this.checkValidPathologitLeave(val.fromDate, val.toDate);
      },
      deep: true,
    },
    userResource: {
      handler(val) {
        this.userResourceModel = val;
      },
      deep: true,
    },
  },
  methods: {
    async fetchData() {
      this.countries = await this.loadCountries();
      this.userTitles = this.USER_TITLES();
      if (this.isDefaultView) {
        this.roleList = await this.loadRoleList(this.USER_TYPES().Pathologist);
      }
      if (this.userResource) {
        this.userModel = this.userResource;
        this.formMode = this.viewMode ? FORM_MODES.VIEW : FORM_MODES.EDIT;

        this.formData = {
          personalInfo: {
            ...this.userModel.personalInfo,
            userType: this.USERTYPES_ENUM().find((e) => e.value === this.USER_TYPES().Pathologist),
            roleId: this.roleList.find((e) => e.value === this.userModel.personalInfo.roleId),
            countryId: this.countries.find((e) => e.value === this.userModel.personalInfo.countryId),
            titleId: this.userTitles.find((e) => e.value === this.userModel.personalInfo.titleId),
            listPathologistSpecialties: this.userResource.personalInfo.listPathologistSpecialties.map((item) => {
              return {
                ...item,
                specialty: {
                  value: item.specialtyId,
                  label: item.specialtyName,
                },
                subSpecialtyValue: item.subSpecialtyValue,
              };
            }),
          },
          pathologistLeaves: this.userModel.pathologistLeaves,
          pathologistLeave: {
            fromDate: null,
            toDate: null,
            comment: '',
          },
          labPreferences: this.userModel.labPreferences,
          specimenPreferences: this.userModel.specimenPreferences,
          secondOpinion:
            Object.keys(this.userModel.secondOpinion).length > 0
              ? this.userModel.secondOpinion
              : this.formData.secondOpinion,
          login: this.userModel.login
            ? {
                hasLogin: this.userModel.login.hasLogin,
              }
            : null,
          isDoubleReport: this.userModel.isDoubleReport,
          doubleReporting: {
            isDoubleReport: this.userModel.isDoubleReport,
            requestedPartners: this.userModel.caseDoubleReports,
            acceptedPartners: this.userModel.caseDoubleReportBys,
          },
          notificationSettings: this.userModel.notificationSettings,
        };
        this.telephoneMobileData = this.$getTelephoneMobileData();
        this.telephoneMobileDataInVerify = this.$getTelephoneMobileDataInVerify();
        this.$setHeadingTitle(this.formData.personalInfo.username);
      }
      this.$nextTick(function () {
        this.$resetChangeDetection();
      });
      this.$setCountryAndMobileCodeFollowGlobalVariablesDataInAddMode();
    },
    createUser() {
      return UserService.create({
        personalInfo: {
          ...this.formData.personalInfo,
          roleId: this.formData.personalInfo.roleId.value,
          userTypeId: this.formData.personalInfo.userType.value,
          titleId: this.formData.personalInfo.titleId.value,
          countryId: this.formData.personalInfo.countryId.value,
          countryCodeMobile: this.telephoneMobileData.countryCodeMobile
            ? this.telephoneMobileData.countryCodeMobile.code
            : '',
          localeMobile: this.telephoneMobileData.countryCodeMobile
            ? this.telephoneMobileData.countryCodeMobile.locale
            : '',
          telephoneMobile: this.telephoneMobileData.telephoneMobile,
          countryIdMobile: this.telephoneMobileData.telephoneMobile
            ? this.telephoneMobileData.countryCodeMobile.countryId
            : null,
          pullSystem: this.formData.personalInfo.pullSystem || false,
          listPathologistSpecialties: this.formData.personalInfo.listPathologistSpecialties.filter(
            (e) => e.specialty !== null,
          ),
        },
        pathologistLeaves: this.formData.pathologistLeaves,
        labPreferences: this.formData.labPreferences,
        specimenPreferences: this.formData.specimenPreferences,
        secondOpinion: this.formData.secondOpinion,
        login: {
          hasLogin: this.formData.login.hasLogin,
        },
        isDoubleReport: this.formData.doubleReporting.isDoubleReport,
        caseDoubleReports: this.formData.doubleReporting.requestedPartners.filter(
          (x) => (x.statusName == 'Pending' || x.statusName == undefined) && x.partnerId != undefined,
        ),
        notificationSettings: this.formData.notificationSettings,
      });
    },
    updateUser() {
      //TODO: UPDATE PROFILE PAYLOAD MUST BE CHANGED AFTER API ALREADY UPDATED
      if (this.$isMyProfileView()) {
        return this.updateProfile();
      }
      return UserService.updateOne({
        userId: this.userId,
        personalInfo: {
          ...this.formData.personalInfo,
          roleId: this.formData.personalInfo.roleId.value,
          titleId: this.formData.personalInfo.titleId.value,
          countryId: this.formData.personalInfo.countryId.value,
          countryCodeMobile: this.telephoneMobileData.countryCodeMobile
            ? this.telephoneMobileData.countryCodeMobile.code
            : '',
          localeMobile: this.telephoneMobileData.countryCodeMobile
            ? this.telephoneMobileData.countryCodeMobile.locale
            : '',
          telephoneMobile: this.telephoneMobileData.telephoneMobile,
          countryIdMobile: this.telephoneMobileData.telephoneMobile
            ? this.telephoneMobileData.countryCodeMobile.countryId
            : null,
          pullSystem: this.formData.personalInfo.pullSystem || false,
          listPathologistSpecialties: this.formData.personalInfo.listPathologistSpecialties.filter(
            (e) => e.specialty !== null,
          ),
        },
        pathologistLeaves: this.formData.pathologistLeaves,
        labPreferences: this.formData.labPreferences,
        specimenPreferences: this.formData.specimenPreferences,
        secondOpinion: this.formData.secondOpinion,
        login: {
          hasLogin: this.formData.login.hasLogin,
        },
        isDoubleReport: this.formData.doubleReporting.isDoubleReport,
        caseDoubleReports: this.formData.doubleReporting.requestedPartners.filter(
          (x) => (x.statusName == 'Pending' || x.statusName == undefined) && x.partnerId != undefined,
        ),
        notificationSettings: this.formData.notificationSettings,
      });
    },
    updateProfile() {
      return MyAccountService.updateProfile({
        userId: this.userId,
        personalInfo: {
          ...this.formData.personalInfo,
          titleId: this.formData.personalInfo.titleId.value,
          countryId: this.formData.personalInfo.countryId.value,
          pullSystem: this.formData.personalInfo.pullSystem || false,
        },
        pathologistLeaves: this.formData.pathologistLeaves,
        labPreferences: this.formData.labPreferences,
        specimenPreferences: this.formData.specimenPreferences,
        secondOpinion: this.formData.secondOpinion,
        isDoubleReport: this.formData.doubleReporting.isDoubleReport,
        caseDoubleReports: this.formData.doubleReporting.requestedPartners.filter(
          (x) => (x.statusName == 'Pending' || x.statusName == undefined) && x.partnerId != undefined,
        ),
        notificationSettings: this.formData.notificationSettings,
      });
    },
    addPathologistLeave() {
      if (!this.isValidPathologitLeave) {
        this.formData.pathologistLeaves.push({
          fromDate: startDay(this.formData.pathologistLeave.fromDate),
          toDate: endDay(this.formData.pathologistLeave.toDate),
          comment: this.formData.pathologistLeave.comment,
        });
        this.formData.pathologistLeave = {
          fromDate: null,
          toDate: null,
          comment: '',
        };
      }
    },
    delPathologistLeave(item) {
      const id = this.formData.pathologistLeaves.indexOf(item);
      this.formData.pathologistLeaves.splice(id, 1);
    },
    checkValidPathologitLeave(fromDate, toDate) {
      if (fromDate && toDate) {
        if (startDay(fromDate) <= endDay(toDate)) {
          this.isValidPathologitLeave = false;
        } else {
          this.isValidPathologitLeave = true;
          this.errPathologitLeave = this.$t('pages/account/user/forms/pathologist/pathLeave/validDate');
        }
      } else {
        this.isValidPathologitLeave = false;
      }
      if (toDate && startDay(toDate) < currentDay()) {
        this.isValidPathologitLeave = true;
        this.errPathologitLeave = this.$t('pages/account/user/forms/pathologist/pathLeave/validFromDate');
      }
    },
    onClickViewPermissions() {
      if (this.formData.personalInfo.roleId) {
        this.$refs.viewPermissions.open();
      }
    },
  },
};
</script>
<style lang="scss">
// action add new row in Speciality
.groupBtn-for-newrow {
  display: inline-flex;
  align-items: center;
  margin-top: 25px;
}
.checkbox-popup {
  margin-top: 40px;
  @media only screen and (max-width: 959px) {
    margin-top: 10px;
  }
}
.pathologist-leave-block {
  .addLeave-btn {
    padding: 25px 0 0 0;
    display: inline-flex;
    .add-btn {
      margin: 10px 0 0;
      height: 36px;
    }
  }
}
.lab-preference-block {
  .lab-txt {
    display: block;
    ul {
      margin-top: 0;
      padding-left: 24px;
    }
  }
}
.pathologist-leave-block,
.lab-preference-block,
.specimen-reference-block {
  .md-layout-item {
    padding-left: 0;
    @media only screen and (max-width: 959px) {
      padding: 0;
    }
  }
}
</style>

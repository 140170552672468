<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
      <!-- Begin information -->
      <lims-block>
        <h4 class="title" slot="blockTitle">{{ $t('pages/policy/form/information/blockTitle') }}</h4>
        <modal-confirm-generic
          slot="blockTitle"
          ref="modalConfirmEnablePolicy"
          :title="$t('pages/policy/modal/modalConfirmEnablePolicy/title')"
          :description="$t('pages/policy/modal/modalConfirmEnablePolicy/description')"
          @onConfirm="onConfirmEnablePolicy"
        ></modal-confirm-generic>
        <modal-confirm-generic
          slot="blockTitle"
          ref="modalConfirmDeletePolicy"
          :title="$t('pages/policy/modal/modalConfirmDeletePolicy/title')"
          :description="$t('pages/policy/modal/modalConfirmDeletePolicy/description')"
          @onConfirm="onConfirmDeletePolicy"
        ></modal-confirm-generic>
        <div slot="blockContent">
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-50 md-small-size-100">
              <lims-field :model="formData" :schema="policySchema" field="title">
                <md-input
                  slot="field"
                  v-model="formData.title"
                  type="text"
                  :disabled="isViewMode"
                  @change="$onServerSideValidation($event, policySchema._serverSideValidators.title)"
                  tabenable="yes"
                >
                </md-input>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData" :schema="policySchema" field="policyTypeId">
                <v-select
                  slot="field"
                  :options="policyTypesList"
                  v-model="formData.policyTypeId"
                  :disabled="isViewMode || isEditMode"
                  label="fieldItemName"
                  tabenable="yes"
                  :tabindex="1"
                >
                  <template #option="{ fieldItemName }">{{ fieldItemName }}</template>
                  <template #selected-option="{ fieldItemName }">{{ fieldItemName }}</template>
                </v-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100 checkbox-field">
              <md-checkbox
                v-model="formData.isNeedAcceptance"
                :disabled="isViewMode || isEditMode"
                class="lims-checkbox"
                tabenable="yes"
              >
                {{ $t('entities/policy/information/needsAcceptance') }}
              </md-checkbox>
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <lims-field :model="formData" :schema="policySchema" field="comment" class="textarea-field">
                <md-textarea
                  :disabled="isViewMode"
                  slot="field"
                  v-model="formData.comment"
                  type="text"
                  tabenable="yes"
                ></md-textarea>
              </lims-field>
            </div>
          </div>
          <div v-if="isAddMode" class="md-layout lims-form-row">
            <div>
              <label class="required">
                {{ $t('entities/policy/information/policy') }}
              </label>
              <lims-tooltip slot="label-info" :content="$t('pages/policy/form/information/tooltip')"></lims-tooltip>
            </div>
            <div
              :class="requireFile && fileAttachments.length == 0 ? 'dropzone-err dropzone-wrapper' : 'dropzone-wrapper'"
            >
              <vue-dropzone
                ref="myVueDropzone"
                id="dropzone"
                :options="dropzoneOptions"
                :useCustomSlot="true"
                @vdropzone-file-added="onAddFile"
                :class="fileAttachments.length > 0 ? 'disabled' : ''"
              >
                <div class="dropzone-container">
                  <h4>{{ $t('global/vueDropzone.txt') }}</h4>
                  <md-button class="md-button md-primary lims-form-button md-theme-default">
                    {{ $t('global/button/button.chooseFile') }}
                  </md-button>
                </div>
              </vue-dropzone>
              <p class="form-error dropzone-required" v-if="requireFile && fileAttachments.length == 0">
                {{ $t('global/errors/notEmpty', { field: $t('entities/policy/information/policy') }) }}
              </p>
            </div>
            <div v-for="(item, index) in fileAttachments" :key="index" class="dropzone-file-add">
              <div class="file-item">
                <div class="icon">
                  <span @click="onRemoveFile(item)"><md-icon>close</md-icon></span>
                </div>
                <img class="img" :src="require(`@/assets/images/pdf-file.png`)" />
                <div>{{ item.name }}</div>
              </div>
            </div>
          </div>
          <div v-if="!isAddMode" class="md-layout lims-form-row">
            <div class="md-layout-item md-size-25 md-small-size-100">
              <label class="required">{{ $t('entities/policy/information/policy') }}</label>
              <p>{{ formData.policyFieldName }}</p>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <label>{{ $t('entities/policy/information/ID') }}</label>
              <p>{{ formData.policyId }}</p>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <label>{{ $t('entities/policy/information/createBy') }}</label>
              <p>{{ formData.createdBy }}</p>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <label>{{ $t('entities/policy/information/createOn') }}</label>
              <p>{{ formatDateTimeGlobal(formData.createdDate) }}</p>
            </div>
          </div>
        </div>
      </lims-block>
      <!-- Begin Policy -->
      <lims-block>
        <h4 class="title" slot="blockTitle">{{ $t('pages/policy/form/policy/blockTitle') }}</h4>
        <div slot="blockContent">
          <div v-if="pdfReviewLink" class="md-layout lims-form-row mg-top-24">
            <iframe :src="pdfReviewLink" style="width: 100%; height: 500px" frameborder="0"></iframe>
          </div>
          <div v-if="!isAddMode" class="md-layout lims-form-row mg-top-24">
            <iframe :src="policyFile" style="width: 100%; height: 500px" frameborder="0"></iframe>
          </div>
        </div>
      </lims-block>
      <!-- Begin Users with Action -->
      <lims-block v-if="!isAddMode && formData.isNeedAcceptance == true">
        <h4 class="title" slot="blockTitle">{{ $t('pages/policy/form/UsersWithAction/blockTitle') }}</h4>
        <div slot="blockContent">
          <div class="md-layout">
            <md-table v-model="formData.userWithActions" md-card md-fixed-header class="tbl-fixed-header">
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell :md-label="$t('pages/policy/form/UsersWithAction/table/nameLabel')">{{
                  item.fullname
                }}</md-table-cell>
                <md-table-cell :md-label="$t('pages/policy/form/UsersWithAction/table/actionLabel')" width="100px">{{
                  item.actionType
                }}</md-table-cell>
                <md-table-cell :md-label="$t('pages/policy/form/UsersWithAction/table/dateTimeLabel')" width="150px">{{
                  formatDateTimeGlobal(item.createdDate)
                }}</md-table-cell>
              </md-table-row>
            </md-table>
          </div>
          <div class="md-layout lims-form-row btnRight">
            <md-button
              @click="onExportUsersWidthAction(formData.policyId)"
              class="md-button md-primary lims-form-button md-theme-default"
            >
              {{ $t('pages/policy/form/button.export') }}
            </md-button>
          </div>
        </div>
      </lims-block>

      <!-- Begin Enable User with no Actions -->
      <lims-block v-if="isShowUserWithNoActionBlock">
        <h4 class="title" slot="blockTitle">{{ $t('pages/policy/form/UsersWithNoAction/blockTitle') }}</h4>
        <div slot="blockContent">
          <div class="md-layout lims-form-row">
            <div class="export-field">
              {{
                $t('pages/policy/form/UsersWidthNoAction/numberOfUser', {
                  number: policyResource ? policyResource.userWithNoActions.length : '0',
                })
              }}
              <md-button
                @click="onExportUsersWidthNoAction(formData.policyId)"
                class="md-button md-primary lims-form-button md-theme-default"
              >
                {{ $t('pages/policy/form/button.export') }}
              </md-button>
            </div>
          </div>
        </div>
      </lims-block>
      <!-- Begin action form -->
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions">
          <LimsFormCancel :redirectUrl="''" />
          <md-button
            v-if="!isViewMode"
            @click="onSave()"
            class="md-button md-primary lims-form-button md-theme-default"
          >
            {{ $t('global/button/button.save') }}
          </md-button>
          <md-button v-if="isShowEnableButton" @click="onEnable()" class="md-primary lims-form-button">
            {{ $t('global/button/button.enable') }}
          </md-button>
          <md-button v-if="isShowDeleteButton" @click="onDelete()" class="md-danger lims-form-button">
            {{ $t('global/button/button.delete') }}
          </md-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import LimsBlock from '@/components/Lims/LimsBlock.vue';
import { getPolicySchema } from '@/schemas/policy.scheme';
import { FormMixins, TabMixins } from '@/core/mixins';
import PolicyMixins from '@/pages/Policies/Policy/policy.mixin';
import policyService from '@/services/policy.service';
import { FORM_MODES } from '@/core/constants';
import { ModalConfirmGeneric } from '@/components/Lims/modals';

export default {
  components: { LimsBlock, ModalConfirmGeneric },
  mixins: [FormMixins, PolicyMixins, TabMixins],
  created: function () {
    this.$fetchData();
  },
  computed: {
    policySchema: function () {
      return getPolicySchema(this.formMode, this.policyResource);
    },
  },
  data() {
    return {
      formData: {},
      policyTypesList: [],
      dropzoneOptions: {
        url: '/',
        autoProcessQueue: false,
        acceptedFiles: 'application/pdf',
        uploadMultiple: false,
        maxFiles: 1,
        previewsContainer: false,
        init: function () {
          this.on('error', function (file) {
            if (!file.accepted) this.removeFile(file);
          });
        },
      },
      pdfReviewLink: '',
      fileAttachments: [],
      requireFile: false,
    };
  },
  methods: {
    onAddFile(file) {
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 2) {
        this.$alertError(this.$t('pages/policy/form/error/cannotPreview'));
        return;
      }
      const url = URL.createObjectURL(file);
      this.pdfReviewLink = url;
      var fileReader = new FileReader();
      fileReader.onload = () => {
        this.fileAttachments = this.$refs.myVueDropzone.dropzone.files.filter((x) => x.accepted == true);
        this.formData.policyFile = this.fileAttachments[0];
      };
      fileReader.readAsDataURL(file);
    },
    onRemoveFile(item) {
      const idx = this.fileAttachments.findIndex((fileAttachment) => fileAttachment.name === item.name);
      this.fileAttachments.splice(idx, 1);
      this.$refs.myVueDropzone.removeFile(item);
      this.pdfReviewLink = '';
      this.checkRequireFilePolicy();
    },
    async onExportUsersWidthAction(policyId) {
      const res = await policyService.downloadUserAction(policyId);
      if (res) {
        this.exportFileCSV(res, 'Users-With-Action');
      }
    },
    async onExportUsersWidthNoAction(policyId) {
      const res = await policyService.downloadUserNoAction(policyId);
      if (res) {
        this.exportFileCSV(res, 'Users-With-No-Action');
      }
    },
    exportFileCSV(response, filename) {
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.download = filename + '.csv';
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    onSave() {
      this.$refs.form.validate().then(async (success) => {
        if (success) {
          if (this.formMode === FORM_MODES.ADD && this.fileAttachments.length == 0) {
            this.$alertError(this.$t(`global/errors/message`));
            this.requireFile = true;
          } else {
            try {
              const res = this.formMode === FORM_MODES.ADD ? await this.createPolicy() : await this.updatePolicy();
              if (res.err) {
                return this.$alertError(res.err + ' error');
              }
              this.$alertSuccess(
                this.formMode === FORM_MODES.ADD
                  ? this.$t(`pages/policy/alert/create.success`, {
                      title: this.formData.title,
                    })
                  : this.$t(`pages/policy/alert/update.success`, {
                      title: this.formData.title,
                    }),
              );
              this.onCancel();
            } catch (errors) {
              this.$alertError(errors);
            }
          }
        } else {
          this.checkRequireFilePolicy();
          this.$alertError(this.$t(`global/errors/message`));
        }
      });
    },
    onCancel() {
      this.$resetChangeDetection();
      setTimeout(() => {
        this.$router.push({ path: '/policies' });
      }, 500);
    },
    checkRequireFilePolicy() {
      if (this.formMode === FORM_MODES.ADD && this.fileAttachments.length == 0) {
        this.requireFile = true;
      }
    },
    createPolicy() {
      let formData = new FormData();
      Object.keys(this.getDataFormPolicy()).forEach((key) => {
        formData.append(key, this.getDataFormPolicy()[key]);
      });
      return policyService.create(formData);
    },
    updatePolicy() {
      const dataForm = this.getDataFormPolicy();
      return policyService.updateOne({ policyId: this.policyResource.policyId, ...dataForm });
    },
    getDataFormPolicy() {
      this.data = {
        ...this.formData,
        policyTypeId: this.formData.policyTypeId.fieldItemId,
      };
      return this.data;
    },
    onEnable() {
      this.$refs.modalConfirmEnablePolicy.open();
    },
    async onConfirmEnablePolicy() {
      const res = await policyService.enablePolicy(this.formData.policyId);
      if (res.err) {
        this.$alertError(res.err);
      } else {
        this.$alertSuccess(
          this.$t(`pages/policy/alert/enable.success`, {
            title: this.formData.title,
          }),
        );
        this.onCancel();
      }
      this.$refs.modalConfirmEnablePolicy.close();
    },
    onDelete() {
      this.$refs.modalConfirmDeletePolicy.open();
    },
    async onConfirmDeletePolicy() {
      const res = await policyService.deletePolicy(this.formData.policyId);
      if (res.err) {
        this.$alertError(res.err);
      } else {
        this.$alertSuccess(
          this.$t(`pages/policy/alert/delete.success`, {
            title: this.formData.title,
          }),
        );
        this.onCancel();
      }
      this.$refs.modalConfirmDeletePolicy.close();
    },
  },
};
</script>
<style lang="scss">
.checkbox-field {
  padding-top: 27px;
  @media only screen and (max-width: 959px) {
    padding-top: 0;
  }
}
.export-field {
  display: flex;
  align-items: center;
  margin: 24px 0;
  .md-button {
    margin-left: 24px;
  }
}
</style>

<template>
  <div class="peding-case-lab-list">
    <lims-collapse-custom-title
      :wrapperClass="'case-collapse double-reporting-collapse'"
      :collapse="['']"
      icon="keyboard_arrow_down"
      :is-collapsed="totalItem == 0"
    >
      <template slot="collapseTitle">
        <div class="collapse-title-custom">
          <div class="left-side-collapse"></div>
          <div class="right-side-collapse">{{ totalItem }} cases</div>
        </div>
      </template>
      <template slot="md-collapse-pane-1">
        <md-table class="paginated-table table-striped table-hover lims-table case-list-tbl">
          <div class="table-fix-header">
            <thead>
              <md-table-row>
                <md-table-head class="checkbox-column">
                  <md-checkbox v-model="selectAll"></md-checkbox>
                </md-table-head>
                <md-table-head v-for="(col, index) in colTabl" :key="index" :class="col.class">
                  <div @mouseover="hoverIn(index)" @mouseout="hoverOut()" v-html="getColumnName(col)"></div>
                </md-table-head>
              </md-table-row>
            </thead>
            <tbody>
              <md-table-row
                v-for="(item, index) in caseList"
                :key="`case-${index}`"
                :class="checkRowHighlight(item, true)"
              >
                <md-table-cell class="checkbox-column">
                  <md-checkbox v-model="selectRow" :value="item.caseId" class="lims-checkbox"></md-checkbox>
                </md-table-cell>
                <md-table-cell class="caseRef-column">
                  {{ item.caseReference }}
                  <md-button v-if="item.caseIssues.length > 0" class="md-just-icon md-warning md-simple">
                    <issue-information :case-issues="item.caseIssues"></issue-information>
                  </md-button>
                  <br />
                  {{ item.labReference }}
                </md-table-cell>
                <md-table-cell>{{ item.patientFullname }}</md-table-cell>
                <md-table-cell>{{ item.clinicName }}<br />{{ item.clinicianName }}</md-table-cell>
                <md-table-cell>{{ item.pathologistName }}</md-table-cell>
                <md-table-cell>{{ item.specimenName }}</md-table-cell>
                <md-table-cell class="date-column">
                  {{ item.procedureDate ? formatDateTimeGlobalNotIncludeTime(item.procedureDate, true) : '' }}
                </md-table-cell>
                <md-table-cell class="date-column">
                  {{ item.reportedDate ? formatDateTimeGlobalNotIncludeTime(item.reportedDate) : '' }}
                </md-table-cell>
                <md-table-cell class="ALW-column">
                  <md-icon v-if="item.caseAlwStatusId == ALW_SO_STATUS.REQUESTED" class="status-disabled"
                    >adjust</md-icon
                  >
                  <md-icon v-if="item.caseAlwStatusId == ALW_SO_STATUS.PARTIALLY_COMPLETED" class="status-invited"
                    >adjust</md-icon
                  >
                  <md-icon v-if="item.caseAlwStatusId == ALW_SO_STATUS.COMPLETED" class="status-enabled"
                    >adjust</md-icon
                  >
                  <md-icon v-if="item.caseAlwStatusId == ALW_SO_STATUS.CANCELLED">adjust</md-icon>
                </md-table-cell>
                <md-table-cell class="SO-column">
                  <md-icon v-if="item.caseSoStatusId == ALW_SO_STATUS.REQUESTED" class="status-disabled"
                    >adjust</md-icon
                  >
                  <md-icon v-if="item.caseSoStatusId == ALW_SO_STATUS.COMPLETED" class="status-enabled">adjust</md-icon>
                  <md-icon v-if="item.caseSoStatusId == ALW_SO_STATUS.CANCELLED">adjust</md-icon>
                </md-table-cell>
                <md-table-cell class="status-column">
                  <md-icon v-if="item.statusId == STATUSES.LAB" class="black-color">hourglass_empty</md-icon>
                  <md-icon v-if="item.statusId == STATUSES.PATH" class="black-color">hourglass_top</md-icon>
                  <md-icon v-if="item.statusId == STATUSES.PROVISIONALLY_REPORTED" class="grey-color"
                    >hourglass_bottom</md-icon
                  >
                  <md-icon v-if="item.statusId == STATUSES.REPORTED" class="black-color">hourglass_bottom</md-icon>
                </md-table-cell>
                <md-table-cell class="actions-column">
                  <div v-if="isShowSearchIcon(item) && isReportRetrieve()" style="width: 25px">
                    <md-button class="md-just-icon md-info md-simple" @click.native="handleAction('view-report', item)">
                      <md-icon>search</md-icon>
                    </md-button>
                  </div>
                  <div v-if="isShowReportIcon(item)" style="width: 25px">
                    <md-button
                      class="md-just-icon md-info md-simple"
                      @click.stop="onExportAsPdf(item)"
                      :disabled="isProcessing"
                    >
                      <img class="pdf-img" :src="require(`@/assets/images/pdf-file.png`)" />
                    </md-button>
                  </div>
                </md-table-cell>
              </md-table-row>
            </tbody>
          </div>
        </md-table>
        <slot name="pagination"></slot>
      </template>
    </lims-collapse-custom-title>
  </div>
</template>
<script>
import CaseListMixin from '@/pages/Case/CaseManagement/List/caseList.mixins';

import IssueInformation from '@/pages/Case/CaseManagement/Components/IssueInformation';

export default {
  name: 'CaseListClinicUsers',
  mixins: [CaseListMixin],
  components: {
    IssueInformation,
  },
  props: {
    caseList: {
      required: false,
      default: function () {
        return [];
      },
    },
    totalItem: {
      required: false,
    },
  },
  data() {
    return {
      selectRow: [],
      bulkAction: '',
      compatibleCase: [],
      notCompatibleCase: [],
      colTabl: [
        {
          name: ['pages.case.caseManagement/list.caseRef', 'pages.case.caseManagement/list.labRef'],
          key: 'caseReference',
          sort: false,
          class: 'caseRef-column',
        },
        {
          name: 'pages.case.caseManagement/list.patient',
          key: 'patientFullname',
          sort: false,
          class: 'patient-column',
        },
        {
          name: ['pages.case.caseManagement/list.clinic', 'pages.case.caseManagement/list.clinician'],
          key: 'clinicName',
          sort: false,
          class: 'clinic-column',
        },
        {
          name: 'pages.case.caseManagement/list.pathologist',
          key: 'pathologistName',
          sort: false,
          class: 'pathologist-column',
        },
        {
          name: 'pages.case.caseManagement/list.specimenType',
          key: 'specimenName',
          sort: false,
          class: 'specimen-column',
        },
        {
          name: 'pages.case.caseManagement/list.Procedure',
          key: 'procedureDate',
          sort: false,
          class: 'date-column',
        },
        {
          name: 'pages.case.caseManagement/list.reported',
          key: 'reportedDate',
          sort: false,
          class: 'date-column',
        },
        {
          name: 'pages.case.caseManagement/list.ALW',
          key: 'caseAlwStatus',
          sort: false,
          class: 'ALW-column',
        },
        {
          name: 'pages.case.caseManagement/list.SO',
          key: 'caseSoStatus',
          sort: false,
          class: 'SO-column',
        },
        {
          name: 'pages.case.caseManagement/list.status',
          key: 'statusName',
          sort: false,
          class: 'status-column',
        },
        {
          name: 'global/pages/list.actions',
          key: 'actions',
          sort: false,
          class: 'actions-column',
        },
      ],
      hover: '',
      orderState: {
        orderField: 'caseReference',
        orderBy: 'asc',
      },
    };
  },
  watch: {
    caseList: {
      handler: function (val) {
        if (val) {
          this.selectRow = [];
        }
      },
      deep: true,
    },
  },
  computed: {
    selectAll: {
      get: function () {
        return this.caseList ? this.caseList.length == this.selectRow.length : false;
      },
      set: function (value) {
        var selected = [];
        if (value) {
          this.caseList.forEach(function (item) {
            selected.push(item.caseId);
          });
        }
        this.selectRow = selected;
      },
    },
  },
};
</script>
<style lang="scss"></style>

<template>
  <ValidationObserver ref="OcrRfUploadForm" v-slot="{ invalid }">
    <form class="md-layout lims-form">
      <lims-block>
        <h4 class="title" slot="blockTitle">{{ $t('pages/case/OcrRfUpload/BasicData/blockTitle') }}</h4>
        <modal-upload-progress
          slot="blockTitle"
          ref="uploadProgressModal"
          @onClose="onClose"
          @onNext="onNext"
        ></modal-upload-progress>
        <lims-collapse slot="blockContent" :collapse="['Case Collapse']" icon="keyboard_arrow_down">
          <template slot="md-collapse-pane-1">
            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-25 md-small-size-100">
                <lims-field :model="formData" :schema="OcrRfUploadSchema" field="clinicId">
                  <lims-single-select
                    slot="field"
                    :options="clinicList"
                    v-model="formData.clinicId"
                    reduceKey="id"
                    labelKey="text"
                    :disabled="isReadonly"
                    :placeholder="$t('entities/OcrRfUpload/information/clinicId.placeholder')"
                  ></lims-single-select>
                </lims-field>
              </div>
              <div class="md-layout-item md-size-25 md-small-size-100">
                <lims-field :model="formData" :schema="OcrRfUploadSchema" field="laboratoryId">
                  <lims-single-select
                    slot="field"
                    :options="laboratoryList"
                    v-model="formData.laboratoryId"
                    reduceKey="id"
                    labelKey="text"
                    :disabled="isReadonly || isDisabledLaboratory"
                    :placeholder="$t('entities/OcrRfUpload/information/clinicId.placeholder')"
                  ></lims-single-select>
                </lims-field>
              </div>
              <div class="md-layout-item md-size-25 md-small-size-100">
                <lims-field :model="formData" :schema="OcrRfUploadSchema" field="workStreamId">
                  <lims-single-select
                    slot="field"
                    :options="workStreamList"
                    v-model="formData.workStreamId"
                    reduceKey="id"
                    labelKey="text"
                    :disabled="isReadonly"
                    :placeholder="$t('entities/OcrRfUpload/information/clinicId.placeholder')"
                  ></lims-single-select>
                </lims-field>
              </div>
            </div>
            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-25 md-small-size-100">
                <lims-field :model="formData" :schema="OcrRfUploadSchema" field="clinicianId">
                  <lims-single-select
                    slot="field"
                    :options="clinicianList"
                    v-model="formData.clinicianId"
                    reduceKey="id"
                    labelKey="text"
                    :disabled="isReadonly"
                    :placeholder="$t('entities/OcrRfUpload/information/clinicId.placeholder')"
                  ></lims-single-select>
                </lims-field>
              </div>
              <div class="md-layout-item md-size-25 md-small-size-100">
                <lims-field :model="formData" :schema="OcrRfUploadSchema" field="technician1Id">
                  <lims-single-select
                    slot="field"
                    :options="technician1List"
                    v-model="formData.technician1Id"
                    reduceKey="id"
                    labelKey="text"
                    :disabled="isReadonly"
                    :placeholder="$t('entities/OcrRfUpload/information/clinicId.placeholder')"
                  ></lims-single-select>
                </lims-field>
              </div>
              <div class="md-layout-item md-size-25 md-small-size-100">
                <lims-field :model="formData" :schema="OcrRfUploadSchema" field="billingEntityId">
                  <lims-single-select
                    slot="field"
                    :options="billingEntityList"
                    v-model="formData.billingEntityId"
                    reduceKey="entityBillingId"
                    labelKey="fieldItemName"
                    :disabled="isReadonly"
                    :style="isGrayedOutBillingEntity ? 'background-color: rgb(248, 248, 248)' : ''"
                    :placeholder="$t('entities/OcrRfUpload/information/clinicId.placeholder')"
                  ></lims-single-select>
                </lims-field>
              </div>
            </div>
            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-25 md-small-size-50">
                <lims-field :model="formData" :schema="OcrRfUploadSchema" field="clinicType"></lims-field>
                <div class="static-text">{{ formData.clinicTypeName }}</div>
              </div>
              <div class="md-layout-item md-size-25 md-small-size-100">
                <lims-field :model="formData" :schema="OcrRfUploadSchema" field="labEntryDate">
                  <date-picker
                    :lang="{
                      formatLocale: {
                        firstDayOfWeek: 1,
                      },
                      monthBeforeYear: false,
                    }"
                    slot="field"
                    type="datetime"
                    v-model="formData.labEntryDate"
                    format="DD/MM/YYYY HH:mm:ss"
                    :time-picker-options="{ start: '00:00', step: '00:10', end: '23:50' }"
                    :disabled="isReadonly"
                    tabenable="yes"
                  ></date-picker>
                </lims-field>
              </div>
            </div>
          </template>
        </lims-collapse>
      </lims-block>

      <div class="md-layout lims-form-row" style="display: contents">
        <div class="md-layout-item md-size-100 md-small-size-100">
          <div class="dropzone-wrapper">
            <vue-dropzone
              ref="myVueDropzone"
              id="dropzone"
              :options="dropzoneOptions"
              :useCustomSlot="true"
              @vdropzone-file-added="onAddFile"
            >
              <div class="dropzone-container">
                <h4>{{ $t('pages/case/OcrRfUpload/vueDropzone.txt') }}</h4>
                <md-button :disabled="isReadonly" class="md-button md-primary lims-form-button md-theme-default">
                  {{ $t('global/button/button.chooseFile') }}
                </md-button>
              </div>
            </vue-dropzone>
            <draggable v-model="files">
              <div v-for="(item, index) in files" :key="index" class="dropzone-file-add">
                <div class="file-item-wrapper">
                  <md-icon class="check-error" v-if="item.status === 'error'">error</md-icon>
                  <md-icon class="check-circle" v-else-if="item.status === 'success'">check_circle</md-icon>
                  <md-icon class="check-circle disabled" v-else>check_circle</md-icon>
                  <div class="file-item">
                    <div class="icon">
                      <span v-if="!isReadonly" @click="onRemoveFile(item)">
                        <md-icon style="float: left">open_with</md-icon>
                        <md-icon>close</md-icon></span
                      >
                    </div>
                    <img class="img" :src="require(`@/assets/images/pdf-file.png`)" />
                    <div class="file-item-name">{{ item.name }}</div>
                  </div>
                  <div style="text-align: center">
                    <span style="padding: 50% 5px">
                      {{ index + 1 }}
                      <br />
                      <small style="font-size: 0.8em">(Batch Index: {{ item.batchIndex || 'None' }})</small>
                    </span>
                  </div>
                </div>
              </div>
            </draggable>
          </div>
        </div>
      </div>

      <div class="md-layout lims-form-row mg-top-24">
        <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions">
          <md-button class="lims-form-button" @click="onCancel()">
            {{ $t('global/button/button.cancel') }}
          </md-button>
          <md-button :disabled="invalid || disabled" class="md-button md-primary lims-form-button" @click="onSendRF()">
            {{ $t('pages/case/OcrRfUpload/button.sendRF') }}
          </md-button>
          <md-button class="md-button lims-form-button md-danger" @click.prevent="onClear()">
            {{ $t('global/button/button.clear') }}
          </md-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
/* eslint-disable security/detect-object-injection */

import LimsCollapse from '@/components/Lims/LimsCollapse';
import { getOcrRfUploadSchema } from '@/schemas/OcrRfUpload.schema';
import { caseFormService, caseListService, EntityService, ocrService } from '@/services';
import { CASE_LIST_DROPDOWN, DROPDOWN_SHORT_NAME, ENTITY_TYPES } from '@/core/constants';
import { convertDateTimeToUTCFormat, markDuplicatedObjects, sortDropDown } from '@/core/helpers';
import ModalUploadProgress from '@/components/Lims/modals/ModalUploadProgress.vue';
import { debounce } from 'lodash';
import entityService from '@/services/entity.service';
import draggable from 'vuedraggable';

export default {
  components: {
    draggable,
    LimsCollapse,
    ModalUploadProgress,
  },
  async created() {
    this.resetBatchIndex();
    this.fetchData();
  },
  computed: {
    OcrRfUploadSchema: function () {
      return getOcrRfUploadSchema(this.formMode);
    },
    disabled: function () {
      return this.isDisabled;
    },
    LIMIT_TOTAL_FILE_SIZE: function () {
      return 100 * 1024 * 1024; // 100MB => KB => B
    },
  },
  watch: {
    fileAttachments: {
      deep: true,
      handler: function (val) {
        this.isDisabled = val.length === 0;
      },
    },
    errors: {
      deep: true,
      handler: debounce(function (val) {
        if (val && val.length > 0) {
          this.showDuplicateErrors(val);
        }
      }, 500),
    },
    'formData.laboratoryId': {
      deep: true,
      handler: async function (val) {
        if (val) {
          this.clinicianList = await this.loadUsersByUserType({
            userTypeId: this.USER_TYPES().Clinician,
            entityId: val,
          });
          this.technician1List = await this.loadUsersByUserType({
            userTypeId: this.USER_TYPES().LabTechnician,
            entityId: val,
          });
        }
        this.formData.clinicianId = null;
        this.formData.technician1Id = null;
      },
    },
    'formData.clinicId': {
      handler: async function (val) {
        if (val) {
          const { error, data } = await entityService.findOne(val);
          if (error) {
            this.$alertError(error);
            return;
          }

          const clinicSelected = this.clinicList.find((item) => item.id == val);
          if (clinicSelected) {
            this.clinicType = clinicSelected.clinicTypeName;
            this.checkEntityTypeOfClinic(val);
          }

          this.workStreamList = await this.loadWorkStreamList(val);
          this.billingEntityList = await this.loadBillingEntityList(val);
          if (this.workStreamList && this.workStreamList.length == 1) {
            this.formData.workStreamId = this.workStreamList[0].id;
          } else {
            this.formData.workStreamId = null;
          }

          if (this.billingEntityList && this.billingEntityList.length == 1) {
            this.formData.billingEntityId = this.billingEntityList[0].entityBillingId;
            this.isGrayedOutBillingEntity = true;
          } else {
            this.formData.billingEntityId = null;
            this.isGrayedOutBillingEntity = false;
          }

          // otherwise
          this.formData.clinicType = data.information.clinicTypeId;
          this.formData.clinicName = data.information.entityName;
          this.formData.clinicTypeName = data.information.clinicTypeName;
        } else {
          this.formData.workStreamId = null;
          this.formData.laboratoryId = null;
          this.isDisabledLaboratory = true;
        }
      },
    },
  },
  data() {
    return {
      formData: {
        labEntryDate: '', // empty by default
        clinicId: '',
        clinicName: '',
        clinicType: '',
        clinicTypeName: '',
        laboratoryId: '',
        laboratoryName: '',
        workStreamId: '',
        workStreamName: '',
      },
      clinicType: '',
      clinicList: [],
      workStreamList: [],
      laboratoryList: [],
      laboratoryListFull: [],
      clinicianList: [],
      technician1List: [],
      billingEntityList: [],
      dropzoneOptions: {
        url: '/',
        autoProcessQueue: false,
        acceptedFiles: 'application/pdf',
        uploadMultiple: false,
        maxFiles: 999,
        previewsContainer: false,
        init: function () {
          this.on('error', function (file) {
            if (!file.accepted) this.removeFile(file);
          });
        },
      },
      fileAttachments: [],
      files: [],
      isDisabled: true,
      isReadonly: false,
      isStopped: false,
      isDisabledLaboratory: true,
      isGrayedOutBillingEntity: false,
      errors: [],
      batchIndex: 1,
    };
  },
  methods: {
    showDuplicateErrors(errors) {
      const errorMessage = '<br/>' + errors.join('<br/>');
      this.$alertError(
        this.$t('pages/case/OcrRfUpload/errors/existingName', {
          errorMessage,
        }),
        10000,
      );
      this.errors = [];
    },
    validateAttachmentFiles(attachmentFiles) {
      const markedFiles = markDuplicatedObjects(attachmentFiles, 'name');
      const errors = markedFiles
        .filter((f) => f.duplicated)
        .map((f) => {
          this.$refs.myVueDropzone.removeFile(f);
          return f.name;
        });

      const newAttachmentFiles = markedFiles.filter((f) => !f.duplicated);

      const files = newAttachmentFiles.map((f) => {
        return {
          name: f.name,
          status: 'pending',
        };
      });
      return { attachmentFiles: newAttachmentFiles, files, errors };
    },
    async fetchData() {
      const dropdownOptionsList = await caseListService.getDropdown([CASE_LIST_DROPDOWN.CLINIC]);
      this.clinicList = dropdownOptionsList[CASE_LIST_DROPDOWN.CLINIC];
      this.clinicianList = dropdownOptionsList[CASE_LIST_DROPDOWN.CLINICIAN];
      this.technician1List = dropdownOptionsList[CASE_LIST_DROPDOWN.TECHNICIAN1];
      this.billingEntityList = dropdownOptionsList[CASE_LIST_DROPDOWN.BILLING_ENTITY];
      this.laboratoryListFull = await this.loadLaboratoryList();
    },

    async loadWorkStreamList(clinicId) {
      const res = await caseFormService.getStainByEntityIdInCaseForm(clinicId, [DROPDOWN_SHORT_NAME.WORK_STREAM]);
      if (res) {
        return res.WorkStream;
      }
    },
    async loadLaboratoryList() {
      const entityIds = [ENTITY_TYPES.Laboratory].join(',');
      const { data } = await caseFormService.getEntityByEntityType({
        search: '',
        entityTypeIds: entityIds,
        dependencyEntityId: null,
      });
      return sortDropDown(data, 'text');
    },
    async loadUsersByUserType({ userTypeId, entityId }) {
      const { data } = await caseFormService.getUsersByUserType({
        search: '',
        userTypeId: userTypeId,
        entityId: entityId,
      });
      return sortDropDown(data, 'text');
    },
    async loadBillingEntityList(clinicId) {
      const { data } = await EntityService.getEntityBilling(clinicId);
      if (data) {
        return data;
      }
      return null;
    },
    checkEntityTypeOfClinic(clinicId) {
      if (clinicId) {
        if (this.clinicType && this.clinicType == 'ClinicAndLaboratory') {
          this.isDisabledLaboratory = true;
          this.formData.laboratoryId = clinicId;
          this.laboratoryList = this.clinicList;
        }
        if (this.clinicType && this.clinicType == 'Clinic') {
          this.isDisabledLaboratory = false;
          this.laboratoryList = this.laboratoryListFull;
          this.formData.laboratoryId = null;
        }
      } else {
        this.isDisabledLaboratory = true;
      }
    },

    onAddFile(file) {
      let fileReader = new FileReader();
      fileReader.onload = () => {
        const fileAttachments = [];
        this.$refs.myVueDropzone.dropzone.files.map((f) => {
          if (!f.accepted) {
            this.$refs.myVueDropzone.removeFile(f);
          } else {
            fileAttachments.push(f);
          }
        });

        const { attachmentFiles, files, errors } = this.validateAttachmentFiles(fileAttachments);
        this.fileAttachments = attachmentFiles;
        this.files = files;
        if (errors) {
          errors.map((err) => {
            if (!this.errors.includes(err)) {
              this.errors.push(err);
            }
          });
        }
      };
      fileReader.readAsDataURL(file);
    },
    onRemoveFile(item) {
      const idx = this.fileAttachments.findIndex((fileAttachment) => fileAttachment.name === item.name);
      this.$refs.myVueDropzone.removeFile(this.fileAttachments[idx]);
      const { attachmentFiles, files } = this.validateAttachmentFiles(this.$refs.myVueDropzone.dropzone.files);
      this.fileAttachments = attachmentFiles;
      this.files = files;
    },

    onCancel() {
      this.$router.back();
    },

    async onSendRF() {
      this.validateBatchIndex();
      this.$refs.OcrRfUploadForm.validate().then(async (success) => {
        if (success && this.fileAttachments && this.fileAttachments.length > 0) {
          this.isDisabled = true;
          this.isReadonly = true;
          this.$refs.myVueDropzone.disable();
          const fileAttachmentData = [];
          this.files.forEach((file) => {
            const item = this.fileAttachments.find((f) => f.name === file.name);
            if (item) {
              fileAttachmentData.push(item);
            }
          });
          this.fileAttachments = fileAttachmentData;
          this.$refs.uploadProgressModal.open({ numberOfFile: fileAttachmentData ? fileAttachmentData.length : 0 });
          // upload
          this.$refs.uploadProgressModal.setUploadStatus({ currentUploadIndex: 0 });
        } else {
          this.$alertError(this.$t(`global/errors/message`));
        }
      });
    },

    async onNext(data) {
      const { currentUploadIndex } = data;
      if (!this.isStopped && currentUploadIndex < this.fileAttachments.length) {
        const f = this.fileAttachments[currentUploadIndex];
        let formData = new FormData();
        formData.append('file', f);
        const res = await ocrService.uploadTemporaryFile(formData, {
          batchIndex: this.batchIndex,
          lastModified: f.lastModified,
        });
        if (res.err) {
          this.files[currentUploadIndex].status = 'error';
        } else {
          this.files[currentUploadIndex].status = 'success';
          this.files[currentUploadIndex].attachFile = res.data;
          Reflect.set(this.files[currentUploadIndex], 'batchIndex', res.data.batchIndex);
          Reflect.set(this.files[currentUploadIndex], 'lastModified', f.lastModified);
          this.batchIndex += 1;
        }
        this.$refs.uploadProgressModal.setUploadStatus({ currentUploadIndex: currentUploadIndex + 1 });
      }
    },

    async onClose() {
      this.resetBatchIndex();
      // prevent click send rf
      this.isDisabled = true;
      // stop upload
      this.isStopped = true;
      // finally
      const { labEntryDate, ...formData } = this.formData;
      // only send valid file
      const attachFiles = this.files
        .filter((f) => f.status === 'success')
        .map((f) => {
          return {
            ...f.attachFile,
            lastModified: f.lastModified,
            batchIndex: f.batchIndex,
          };
        });
      const isBatchIndexValid = this.validateBatchIndexWithTotalFile(attachFiles);
      if (isBatchIndexValid) {
        const { err } = await ocrService.uploadRfs({
          attachFiles,
          ...formData,
          labEntryDate: labEntryDate ? convertDateTimeToUTCFormat(labEntryDate) : null,
          laboratoryName: this.laboratoryList.find((item) => item.id == this.formData.laboratoryId)?.text,
          workStreamName: this.workStreamList.find((item) => item.id == this.formData.workStreamId)?.text,
          clinicianName: this.clinicianList.find((item) => item.id == this.formData.clinicianId)?.text,
          technician1Name: this.technician1List.find((item) => item.id == this.formData.technician1Id)?.text,
          billingEntityName: this.billingEntityList.find(
            (item) => item.entityBillingId == this.formData.billingEntityId,
          )?.fieldItemName,
        });
        if (err) {
          this.$alertServerError(err);
        }
      }
    },

    onClear() {
      this.$refs.myVueDropzone.removeAllFiles();
      this.fileAttachments = [];
      this.files = [];
      this.formData = {
        labEntryDate: '',
        clinicId: '',
        clinicName: '',
        clinicType: '',
        clinicTypeName: '',
        laboratoryId: '',
        laboratoryName: '',
        workStreamId: '',
        workStreamName: '',
      };
      this.isDisabled = false;
      this.isReadonly = false;
      this.isStopped = false;
      this.$refs.OcrRfUploadForm.reset();
      this.$refs.myVueDropzone.enable();
      this.resetBatchIndex();
    },

    /**
     * Reset batch index when:
     * 1. Right after user clicks Upload RF or leaving the page.
     * 2. Right after user clicks onCancel
     * 3. Right after user clicks onClear
     * 4. Right after closing modal
     */
    resetBatchIndex() {
      this.batchIndex = 1;
    },
    /**
     * Validate if batchindex starts at 1 when uploading at step 1 (upload-attachfiles).
     * If not, reset batchindex and start uploading
     */
    validateBatchIndex() {
      const currentBatchIndex = this.batchIndex;
      if (currentBatchIndex !== 1) {
        this.$alertWarning(
          `Current batchIndex: ${currentBatchIndex}. So we will reset batchIndex to 1 then start the process!`,
        );
      }
      // always reset batch index
      this.resetBatchIndex();
    },
    /**
     * At step 2 (upload-rfs),
     * validate if total file < batch index,
     * throw an error and prevent uploading
     */
    validateBatchIndexWithTotalFile(attachmentFiles) {
      const totalFile = attachmentFiles.length;
      const errors = [];
      attachmentFiles.map((f) => {
        if (f.batchIndex > totalFile) {
          errors.push(`${f.fileName} with batchIndex=${f.batchIndex} has invalid batchIndex!`);
        }
      });
      if (errors.length > 0) {
        const errorMessage = errors.join('\n');
        this.$alertError(errorMessage);
        return false;
      }
      return true;
    },
    beforeRouteLeave(to, from, next) {
      this.resetBatchIndex();
      next(true);
    },
  },
};
</script>

<style></style>

import { cloneDeep } from 'lodash';

const CaseFormDTO = {
  firstName: 'required',
  lastName: 'required',
  sexId: 'required',
  dob: 'required|lessThanOrEqualCurrentDate:checkDateLessOrEqualCurrentDate|dobLessThanProcedureDate',
  hospitalReference: '',
  laboratoryReference: '',
  procedureDate: 'required|lessThanOrEqualCurrentDate:checkDateLessOrEqualCurrentDate|procedureDateGreaterThanDob',
  urgent: '',
  laboratoryId: 'required',
  clinicId: 'required',
  clinicianId: 'required',
  technician1Id: 'required',
  specimenTypeId: 'required',
  pathologistId: '',
  noOfSpecimens: '',
  slideIds: '',
  workStreamIds: 'required',
};

const CaseFormSchema = {
  entity: 'case/SimpleBooking/form',
  fields: {
    ...CaseFormDTO,
  },
  commonData: {
    entity: 'case/SimpleBooking/form/commonData',
    fields: {
      clinicAndLaboratory: '',
      clinicId: '',
      laboratoryId: '',
      labEntry: '',
      labEntryTime: '',
      technician1Id: '',
      clinicianId: '',
      slideIds: '',
      specimenTypeId: '',
      specimenDetails: '',
      clinicalDetails: '',
      macroDescription: '',
      workStreamId: '',
    },
  },
};

const prepareCaseFormSchema = (caseFormSchema, params) => {
  const schema = cloneDeep(caseFormSchema);

  if (params) {
    schema.fields['dob'] = `${schema.fields.dob}:${JSON.stringify({
      validateDateTime: params.isDobValid,
    })}`;
  }
  if (params) {
    schema.fields.procedureDate = `${schema.fields.procedureDate}:${JSON.stringify({
      validateDateTime: params.isProcedureDateValid,
    })}`;
  }

  return schema;
};

export const getSimpleBookingSchema = (params) => {
  return prepareCaseFormSchema(CaseFormSchema, params);
};

<template>
  <stats-card header-color="primary">
    <template slot="header">
      <div class="card-icon">
        <md-icon>add</md-icon>
      </div>
      <p class="category">{{ $t('pages/Dashboard/Components/OutstandingALWRequestsStats/title') }}</p>
    </template>

    <template slot="footer">
      <div class="group-stats-wrapper">
        <div class="group-stats">
          <div class="group-stats-item">
            <p class="category">{{ $t('pages/Dashboard/Components/OutstandingALWRequestsStats/rescan') }}</p>
            <h3 @click="rescanNavigate()">
              <span>{{ data.rescan }}</span>
            </h3>
          </div>
          <div class="group-stats-item">
            <p class="category">{{ $t('pages/Dashboard/Components/OutstandingALWRequestsStats/new') }}</p>
            <h3 @click="newNavigate()">
              <span>{{ data.new }}</span>
            </h3>
          </div>
        </div>
        <div class="stats">
          <md-icon>date_range</md-icon>
          {{ data.time }}
        </div>
      </div>
    </template>
  </stats-card>
</template>

<script>
import { ALW_SO_STATUS, DROPDOWN_SHORT_NAME } from '@/core/constants';
import StatsMixins from './stats.mixins';
import { DropdownService } from '@/services';
export default {
  props: {
    data: {
      ALWRequestType: '',
      ALWRequestNewId: '',
      ALWRequestRescanId: '',
    },
  },
  mixins: [StatsMixins],

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      const dropdownOptions = await DropdownService.getDropdownByShortNames([DROPDOWN_SHORT_NAME.CaseAWLRequestType]);
      this.ALWRequestType = dropdownOptions[DROPDOWN_SHORT_NAME.CaseAWLRequestType];
      if (this.ALWRequestType) {
        this.ALWRequestNewId = this.ALWRequestType.find((x) => x.fieldItemName == 'New').fieldItemId;
        this.ALWRequestRescanId = this.ALWRequestType.find((x) => x.fieldItemName == 'Rescan').fieldItemId;
      }
    },
    newNavigate() {
      this.$navigateToList({
        path: 'cases/alw-management',
        query: { AlwRequestStatusIds: ALW_SO_STATUS.REQUESTED, AlwRequestTypeIds: this.ALWRequestNewId },
      });
    },
    rescanNavigate() {
      this.$navigateToList({
        path: 'cases/alw-management',
        query: { AlwRequestStatusIds: ALW_SO_STATUS.REQUESTED, AlwRequestTypeIds: this.ALWRequestRescanId },
      });
    },
  },
};
</script>

<style></style>

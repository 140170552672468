<template>
  <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" class="lims-modal">
    <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
    <div class="lims-form dialog-content">
      <p class="text-center">{{ this.content }}</p>
    </div>
    <md-dialog-actions>
      <md-button @click="onClickBackToCaseList" class="lims-form-button md-primary">{{
        $t('global/button/button.backToList')
      }}</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import modalMixins from '@/components/Lims/modals/modal.mixins';
import { APP_ROUTES } from '@/core/constants';

export default {
  mixins: [modalMixins],
  props: {
    bulkDiagnosisMode: {
      type: Boolean,
    },
  },
  data() {
    return {
      content: null,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.content = this.bulkDiagnosisMode
        ? this.$t('pages/account/user/components/modalNoMoreCaseToReview/dialogContentBulkDiagnosis')
        : this.$t('pages/account/user/components/modalNoMoreCaseToReview/dialogContent');
    },
    onClickBackToCaseList() {
      this.$router.push(APP_ROUTES.CASE);
    },
  },
};
</script>
<style lang="scss"></style>

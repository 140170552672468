import Vue from 'vue';
import Vuex from 'vuex';

import app from './modules/app';
import auth from './modules/auth';
import config from './modules/config';
import user from './modules/user';
import personalisedReport from './modules/personlised-report/personalised-report';
import dataSet from './modules/data-set/data-set';
import caseForm from './modules/case-form';
import itemList from './modules/item-list';
import caseData from './modules/case-data';
import sb from './modules/sb';
import reviewCase from './modules/review';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    app,
    auth,
    config,
    user,
    personalisedReport,
    dataSet,
    sb,
    caseForm,
    itemList,
    caseData,
    reviewCase,
  },
  strict: debug,
});

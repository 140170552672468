<template>
  <div class="sb-case-list">
    <slot name="pagination"></slot>
    <div class="sb-case-card" v-for="c in cases" :key="c.caseId">
      <div class="sb-case-card-disabled" v-show="c.locked"></div>
      <div class="card-infor" v-if="c">
        <div class="card-infor-header">
          <h2 class="card-title">
            {{ c.caseReference }} <br />
            {{ countSlides(c) }}
          </h2>
          <div class="card-actions">
            <div class="action-item" @click="toggleHide(c)">
              <md-icon>{{ c.hidden ? 'visibility' : 'visibility_off' }}</md-icon>
              {{ $t(`case/sb/caseItemList/${c.hidden ? 'visibility' : 'visibility_off'}`) }}
            </div>
            <div class="action-item" @click="toggleLock(c)">
              <md-icon>{{ c.locked ? 'lock_open' : 'lock' }}</md-icon>
              {{ $t(`case/sb/caseItemList/${c.locked ? 'lock_open' : 'lock'}`) }}
            </div>
          </div>
        </div>
        <ul class="card-infor-content">
          <li>
            <md-icon>science</md-icon>
            {{ $t('case/sb/caseItemList/labRef', { labRef: c.laboratoryReference }) }}
          </li>
          <li>
            <md-icon>medication</md-icon>
            {{ $t('case/sb/caseItemList/hospitalRef', { hospitalRef: c.hospitalReference }) }}
          </li>
          <li>
            <md-icon>person</md-icon>
            {{ $t('case/sb/caseItemList/patientName', { patientName: `${c.firstName} ${c.lastName}` }) }}
          </li>
          <li>
            <md-icon>face_retouching_natural</md-icon>
            {{
              $t('case/sb/caseItemList/pathologist', {
                pathologist: c.pathologistId
                  ? c.pathologistName
                  : c.pathologistTypeName === 'Pull'
                  ? c.pathologistTypeName
                  : '',
              })
            }}
          </li>
          <li>
            <md-icon>find_in_page</md-icon>
            {{ $t('case/sb/caseItemList/specimen', { specimen: specimenName(c) }) }}
          </li>
        </ul>
      </div>
      <div class="card-body">
        <panel-block
          :disabled="lockedCases[c.caseId]"
          :commonlyUsedSlides="commonlyUsedSlides"
          @onSaveSpecimen="onSaveSpecimen"
          :caseObjectData="c"
        ></panel-block>
      </div>
    </div>
    <slot name="pagination"></slot>
  </div>
</template>
<script>
import PanelBlock from '@/pages/Case/CaseManagement/SB/Component/PanelBlock.vue';
import debounce from 'lodash/debounce';
import { mapActions } from 'vuex';
import { APP_EVENTS, CASE_STATUS, newAppEvent } from '@/core/constants';
import { caseListService } from '@/services';
import { generateSpecimenBlockName } from '../../CaseSpecimen.mixins';

export default {
  components: {
    PanelBlock,
  },
  props: {
    casesData: {
      type: Array,
      required: true,
    },
    commonlyUsedSlides: {
      type: Array,
      required: true,
    },
    caseIds: {
      type: Array,
      required: true,
    },
  },
  computed: {},
  created() {
    this.cases = this.casesData;
  },
  watch: {
    casesData: {
      deep: true,
      handler: function (val) {
        if (val) {
          this.$set(this, 'cases', val);
        }
      },
    },
    saveCases: {
      deep: true,
      handler: debounce(function (val) {
        if (val.length > 0) {
          this.saveCasesData(val);
        }
      }, 500),
    },
  },

  data() {
    return {
      cases: null,
      saveCases: [],
      hiddenCases: {},
      lockedCases: {},
    };
  },
  methods: {
    specimenName(c) {
      if (c.caseSpecimens.length > 0) {
        return c.caseSpecimens[0].specimentTypeName;
      }
      return '';
    },
    ...mapActions('app/event', ['addEvent']),
    saveCasesData(cases) {
      // reset save cases
      const payload = {
        queryModel: {
          selectAll: false,
          searchQuery: {
            pageSize: this.caseIds.length,
            pageNumber: 1,
            sort: 'caseReference:asc',
          },
          caseIds: this.caseIds,
        },
        updateModel: {
          cases,
        },
      };
      caseListService.saveSBCase(payload).then(
        (res) => {
          this.saveCases = [];
          // reload cases to binding new data
          this.addEvent(newAppEvent(APP_EVENTS.EVT_ON_SB_ON_REFRESH, { refresh: true, res }));
        },
        (error) => {
          this.$alertError(error);
        },
      );
    },

    onSaveSpecimen(payload) {
      const { caseId, caseSpecimens } = payload;
      const currentCase = this.casesData.find((c) => c.caseId === caseId);
      // transform caseSpecimens before save
      const savedData = caseSpecimens.map((specimen, specimenIndex) => {
        let caseSpecimenBlocks = specimen.caseSpecimenBlocks || [];
        if (caseSpecimenBlocks.length > 0) {
          caseSpecimenBlocks = caseSpecimenBlocks.map((specimenBlock, blockIndex) => {
            const namingRule = currentCase.namingRule;
            const block = generateSpecimenBlockName(specimenIndex, blockIndex, namingRule);
            return {
              ...specimenBlock,
              block,
            };
          });
        }
        return {
          ...specimen,
          caseSpecimenBlocks,
        };
      });

      this.saveCases.push({
        caseId,
        caseSpecimens: savedData,
      });
    },
    countSlides(c) {
      const { caseSpecimens } = c;

      // each block have blockFieldItems
      let totalSlides = 0;
      caseSpecimens.map((specimen) => {
        specimen.caseSpecimenBlocks.map((block) => {
          if (block.blockFieldItems) {
            if (c.status == CASE_STATUS.REPORTED) {
              totalSlides += block.blockFieldItems.length;
            } else {
              const blockNotHide = block.blockFieldItems.filter((item) => item.isHide == false);
              totalSlides += blockNotHide.length;
            }
          }
        });
      });

      return totalSlides > 1 ? `${totalSlides} slides` : `${totalSlides} slide`;
    },
    toggleHide(c) {
      // trigger search
      c.hidden = !c.hidden;
      this.addEvent(newAppEvent(APP_EVENTS.EVT_ON_SB_ON_SEARCH, {}));
      // if (this.hiddenCases[caseId]) {
      //   this.$set(this.hiddenCases, caseId, false);
      // } else {
      //   this.$set(this.hiddenCases, caseId, true);
      // }
    },
    toggleLock(c) {
      c.locked = !c.locked;
      this.addEvent(newAppEvent(APP_EVENTS.EVT_ON_SB_ON_SEARCH, {}));
      // if (this.lockedCases[caseId]) {
      //   this.$set(this.lockedCases, caseId, false);
      // } else {
      //   this.$set(this.lockedCases, caseId, true);
      // }
    },
  },
};
</script>
<style lang="scss">
.sb-case-list {
  padding-right: 20px;
  margin-bottom: 20px;
  .card-body {
    overflow-x: auto;
  }
}
</style>

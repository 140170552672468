import httpClient, { BASE_URL } from './httpClient';

const RESOURCE = 'path-cases';

export default {
  getCaseDocumentLink({ caseDocumentId, fileNameInStore }) {
    let url = `${BASE_URL}/${RESOURCE}/get-case-documents`;
    if (caseDocumentId) {
      url += `?caseDocumentId=${caseDocumentId}`;
    }
    if (fileNameInStore) {
      url += `?fileNameInStore=${fileNameInStore}`;
    }
    return url;
  },
  getCaseDocuments({ caseDocumentId, fileNameInStore }) {
    const params = {};
    if (caseDocumentId) {
      params['caseDocumentId'] = caseDocumentId;
    }
    if (fileNameInStore) {
      params['fileNameInStore'] = fileNameInStore;
    }
    return httpClient.get(`/${RESOURCE}/get-case-url-document`, params);
  },
  getCaseSpecimenBlocks({ caseId }) {
    const params = {};
    if (caseId) {
      params['caseId'] = caseId;
    }
    return httpClient.get(`/${RESOURCE}/get-casespecimenblocks-by-caseId`, params);
  },
  getStains({ caseId }) {
    const params = {};
    if (caseId) {
      params['caseId'] = caseId;
    }
    return httpClient.get(`/${RESOURCE}/get-alwrequest-stain-by-caseId`, params);
  },
  getAlwRequestBlockStain({ caseId, laboratoryId }) {
    const params = {};
    if (caseId) {
      params['caseId'] = caseId;
    }
    if (laboratoryId) {
      params['laboratoryId'] = laboratoryId;
    }
    // return httpClient.get(`/${RESOURCE}/get-alwrequest-block-stain-by-caseId`, params);
    return httpClient.get(`/cases/responded-slides`, params);
  },
  createCaseAlwRequest(caseId, data) {
    return httpClient.put(`/${RESOURCE}/create-case-alwrequest/${caseId}`, data);
  },
  createCaseAlwRequestRescan(caseId, data) {
    return httpClient.put(`/${RESOURCE}/create-case-alwrequest-rescan/${caseId}`, data);
  },
  deleteCaseALWRequests(caseId, data, rowVersion) {
    return httpClient.deleteWithPayload(`/${RESOURCE}/delete-casealwrequests/${caseId}`, data, {
      rowVersion: rowVersion,
    });
  },
  getSoRequestPathologistUser(data) {
    return httpClient.post(`/${RESOURCE}/get-so-request-pathologist-user`, data);
  },
  createCaseSoRequest(caseId, data) {
    return httpClient.put(`/${RESOURCE}/create-case-sorequest/${caseId}`, data);
  },
  deletecaseSORequests(caseId, data, rowVersion) {
    return httpClient.deleteWithPayload(`/${RESOURCE}/delete-casesorequests/${caseId}`, data, {
      rowVersion: rowVersion,
    });
  },
  updateFurtherOptionsForPathCase(caseId, data, rowVersion) {
    return httpClient.put(`/${RESOURCE}/update-futher-options/${caseId}?rowVersion=${rowVersion}`, data);
  },
  checkAuthoriseCase({ caseId }) {
    const params = {};
    if (caseId) {
      params['caseId'] = caseId;
    }
    return httpClient.get(`/${RESOURCE}/get-case-authorise-status`, params);
  },
  getPathologistUsersReallocate({ pathologistUserId, specimenTypeId, clinicianId, clinicId, laboratoryId }) {
    const params = {};
    if (pathologistUserId) {
      params['pathologistUserId'] = pathologistUserId;
    }
    if (specimenTypeId) {
      params['specimenTypeId'] = specimenTypeId;
    }
    if (clinicianId) {
      params['clinicianId'] = clinicianId;
    }
    if (clinicId) {
      params['clinicId'] = clinicId;
    }
    if (laboratoryId) {
      params['laboratoryId'] = laboratoryId;
    }
    return httpClient.get(`/${RESOURCE}/get-pathologist-users-reallocate`, params);
  },
  reCallToLabPathStatus(caseId, comment, password) {
    return httpClient.put(`/${RESOURCE}/recall-case-from-path-lab/${caseId}`, {
      comment: comment,
      password: password,
    });
  },
  reCallToPathFromReport(caseId, comment, password) {
    return httpClient.put(`/${RESOURCE}/recall-case-from-report-path/${caseId}`, {
      comment: comment,
      password: password,
    });
  },
  reCallToLabProvisionallyReportedStatus(caseId, comment, password) {
    return httpClient.put(`/${RESOURCE}/recall-case-from-report-path/${caseId}`, {
      comment: comment,
      password: password,
    });
  },
  retrieveCase(caseId, password) {
    return httpClient.put(`/${RESOURCE}/retrieve-case/${caseId}`, {
      password: password,
    });
  },
  reallocateCase(caseId, comment, userId, pathologistType, password) {
    return httpClient.put(`/${RESOURCE}/reallocate-case/${caseId}`, {
      comment: comment,
      userId: userId,
      pathologistType: pathologistType,
      password: password,
    });
  },
  authoriseCase(caseId, password, casePersonalizedReportId, isConfictSnomed) {
    return httpClient.put(`/${RESOURCE}/authorise-case/${caseId}`, {
      password: password,
      casePersonalizedReportId: casePersonalizedReportId,
      isConfictSnomed: isConfictSnomed,
    });
  },
  authoriseProvisionallyCase(caseId, password, casePersonalizedReportId, isConfictSnomed) {
    return httpClient.put(`/${RESOURCE}/provisionally-authorise-case/${caseId}`, {
      password: password,
      casePersonalizedReportId: casePersonalizedReportId,
      isConfictSnomed: isConfictSnomed,
    });
  },
  updateCaseDiagnosis(caseId, diagnosisData) {
    return httpClient.put(`/${RESOURCE}/update-case-diagnosis/${caseId}`, diagnosisData);
  },
  updateCaseSupplementaryReport(caseId, supplementaryReportData) {
    return httpClient.put(`/${RESOURCE}/update-case-supplementary-report/${caseId}`, supplementaryReportData);
  },
  submitSORequest(casesorequestId, respondeComment) {
    return httpClient.put(`/${RESOURCE}/complete-case-sorequest/${casesorequestId}?respondeComment=${respondeComment}`);
  },
  cancelSORequest(caseSoRequestId, reason) {
    return httpClient.put(`/${RESOURCE}/cancel-case-sorequest/${caseSoRequestId}?reason=${reason}`);
  },
  pickCasesFromPull(caseId) {
    return httpClient.post(`/${RESOURCE}/pick-cases-from-pull`, caseId);
  },
  previewCase(caseId) {
    return `${BASE_URL}/${RESOURCE}/preview-case/${caseId}`;
  },
  agreeDoubleReport(responseId, comment) {
    const params = {};
    if (comment) {
      params['comment'] = comment;
    }
    return httpClient.put(`/${RESOURCE}/agree-doublereport-response/${responseId}`, {}, params);
  },
  disagreeDoubleReport(responseId, comment) {
    const params = {};
    if (comment) {
      params['comment'] = comment;
    }
    return httpClient.put(`/${RESOURCE}/disagree-doublereport-response/${responseId}`, {}, params);
  },
  returnToPull(caseId, reason) {
    return httpClient.put(`/${RESOURCE}/return-pull-case/${caseId}`, {
      comment: reason,
    });
  },
  deleteCaseSoRequestsPathUsers(casesorequestId, data) {
    return httpClient.deleteWithPayload(`${RESOURCE}/delete-sorequest-pathusers/${casesorequestId}`, data);
  },
};

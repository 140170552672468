// import Vue from 'vue';

import Vue from 'vue';

// initial state
const state = () => ({
  caseRefHasSelectedBlocksPassLab: {},
  selectedBlocks: {},
  isLoading: false,
  dropAreaClass: '',
});

// helpers
const countNumberOfSelectedBlocks = (selectedBlocks) => {
  let nOfSelectedBlocks = 0;
  Object.values(selectedBlocks).map((v) => {
    nOfSelectedBlocks += v.length;
  });
  return nOfSelectedBlocks;
};
// getters
const getters = {
  dropAreaClass: (state) => {
    const nOfSelectedBlocks = countNumberOfSelectedBlocks(state.selectedBlocks);
    return state.isLoading || nOfSelectedBlocks === 0 ? 'disabled' : '';
  },
  sbCTAClass: (state) => {
    return state.isLoading ? 'disabled' : '';
  },
  isSBActionDisabled: (state) => {
    return state.isLoading;
  },
  $selectedBlocks: (state) => {
    return state.selectedBlocks;
  },
  numberOfSelectedBlocks: (state) => {
    const { selectedBlocks } = state;
    let count = 0;
    Object.values(selectedBlocks).map((v) => {
      count += v.length;
    });
    return count;
  },
  caseRefHasSelectedBlocksPassLab: (state) => {
    const { caseRefHasSelectedBlocksPassLab } = state;
    let arrCaseRef = [];
    Object.entries(caseRefHasSelectedBlocksPassLab).forEach(([key, value]) => {
      if (value > 0) {
        arrCaseRef.push(key);
      }
    });
    return arrCaseRef;
  },
};

// actions
const actions = {
  $resetSelectedBlocks({ commit }) {
    commit('resetSelectedBlocks');
  },
  $updateSelectedBlocks({ commit }, { caseId, blocks }) {
    commit('updateSelectedBlocks', { caseId, blocks });
  },
  updateCaseRefHasSelectedPassLab({ commit }, { caseReference, count }) {
    commit('updateCaseRefHasSelectedPassLab', { caseReference, count });
  },
  $enableIsLoading({ commit }) {
    commit('setIsLoadingState', { isLoading: true });
  },
  $disableIsLoading({ commit }) {
    setTimeout(() => {
      commit('setIsLoadingState', { isLoading: false });
    }, 250);
  },
};

// mutations
const mutations = {
  updateSelectedBlocks(state, { caseId, blocks }) {
    Vue.set(state.selectedBlocks, caseId, blocks);
  },
  updateCaseRefHasSelectedPassLab(state, { caseReference, count }) {
    Vue.set(state.caseRefHasSelectedBlocksPassLab, caseReference, count);
  },
  resetSelectedBlocks(state) {
    state.selectedBlocks = {};
    state.caseRefHasSelectedBlocksPassLab = {};
  },
  setIsLoadingState(state, { isLoading }) {
    state.isLoading = isLoading;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
      <!-- display validate action message -->
      <template v-if="viewMode && isValidateUser">
        <md-toolbar class="md-dense md-info action-message">
          <div v-for="(item, index) in formData.pendingActionValidations" :key="index">
            <template
              v-if="
                item.pendingActionTypeId == pendingActionType.Created ||
                item.pendingActionTypeId == pendingActionType.EnableUser ||
                item.pendingActionTypeId == pendingActionType.DisableUser
              "
            >
              {{ $t(`global/message/actionMessage`, actionMessageVariable(item)) }}
            </template>
            <template
              v-if="
                item.pendingActionTypeId == pendingActionType.EnableHasLogin ||
                item.pendingActionTypeId == pendingActionType.DisableHasLogin
              "
            >
              {{ $t(`global/message/actionMessage.hasLogin`, actionMessageVariable(item)) }}
            </template>
            <template
              v-if="
                item.pendingActionTypeId == pendingActionType.ChangeEmail ||
                item.pendingActionTypeId == pendingActionType.ChangePhoneNumber
              "
            >
              {{ $t(`global/message/actionMessage.manyActions`, actionMessageVariable(item)) }}
            </template>
          </div>
        </md-toolbar>
      </template>
      <!-- personal infor -->
      <lims-block class="personal-info-block">
        <h4 class="title" slot="blockTitle">
          {{ $t('pages/account/user/forms/personalInfoBlock') }}
        </h4>
        <modal-confirmation
          ref="confirmModal"
          slot="blockTitle"
          :is-wrong-password="isWrongPassword"
          @onConfirm="$onConfirmDisabled"
          :isMyEntityView="isMyEntityView"
        ></modal-confirmation>
        <modal-confirm-generic
          slot="blockTitle"
          ref="confirmEnableUserModal"
          :title="$t('pages/myentity/form/confirmEnableUserModal/title')"
          :description="$t('pages/myentity/form/confirmEnableUserModal/content')"
          @onConfirm="$onConfirmEnable"
        >
        </modal-confirm-generic>
        <modal-validate-user
          ref="validateUserModal"
          slot="blockTitle"
          :actionMessage="actionMessageVariable"
          :pendingAction="formData.pendingActionValidations"
          @onApprove="$onApproveValidate"
          @onReject="$onRejectValidate"
        ></modal-validate-user>
        <modal-has-login-confirm
          ref="hasLoginConfirmModal"
          slot="blockTitle"
          @onConfirm="$onHasLoginConfirmModal"
        ></modal-has-login-confirm>
        <modal-view-permissions
          ref="viewPermissions"
          slot="blockTitle"
          :permission-data="roleInformation"
        ></modal-view-permissions>
        <modal-input-your-telephone
          slot="blockTitle"
          ref="inputYourTelephoneModal"
          :user-id="userId"
          :verify-two-fa-mode="verifyTwoFaMode"
          :mobile-phone="telephoneMobileDataInVerify"
          @onNext="onCompleteInputNewTelephone"
        ></modal-input-your-telephone>
        <modal-input-new-email
          slot="blockTitle"
          ref="inputNewEmailModal"
          :user-id="userId"
          @onNext="onCompleteInputNewEmail"
        ></modal-input-new-email>
        <modal-verify-code
          slot="blockTitle"
          ref="verifyCodeModal"
          :option="verifyContent"
          :is-wrong-code="isWrongCode"
          @onVerify="onVerifyCode"
          @onResendCode="onResendVerifyCode"
        ></modal-verify-code>
        <modal-update-successfully
          slot="blockTitle"
          ref="verifySuccessfullyModal"
          :method="verifyMode === onUpdateEmail ? 'Email' : 'Telephone (Mobile)'"
          @onUpdateSuccessfully="onUpdateSuccessfully"
        ></modal-update-successfully>
        <modal-confirm-update-my-entity-user
          slot="blockTitle"
          ref="confirmUpdateMyEntityUserModal"
          :actionChange="dataEditChange"
          @onConfirm="$onConfirmUpdateMyEntityUser"
        ></modal-confirm-update-my-entity-user>
        <modal-confirm-create-new-user-my-entity
          slot="blockTitle"
          ref="confirmCreateNewUserMyEntityModal"
          @onConfirm="$onConfirmCreateNewUserMyEntity"
        ></modal-confirm-create-new-user-my-entity>
        <div slot="blockContent">
          <LimsFormEditButton class="edit-btn" v-if="viewMode && $canEdit()" :editUrl="$editUrl" />
          <!-- new row -->
          <div class="md-layout lims-form-row" v-if="isDefaultView || isMyEntityView">
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" field="userType" :schema="userSchema.personalInfo">
                <v-select disabled slot="field" :options="userTypesList" v-model="formData.personalInfo.userType">
                  <template #option="{ label }">{{ $t(label) }}</template>
                  <template #selected-option="{ label }">{{ $t(label) }}</template>
                </v-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="role">
                <v-select
                  slot="field"
                  v-model="formData.personalInfo.roleId"
                  :options="roleList"
                  :disabled="viewMode"
                  tabenable="yes"
                  :tabindex="1"
                >
                  <template #option="{ label }">{{ label }}</template>
                  <template #selected-option="{ label }">{{ label }}</template>
                </v-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <div
                class="view-permissions"
                @click="onClickViewPermissions"
                :class="formData.personalInfo.roleId ? '' : 'disable-view-permissions'"
              >
                {{ $t('entities/user/personalInfo/viewPermissions') }}
              </div>
            </div>
          </div>
          <!-- end row -->
          <!-- new row -->
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="username">
                <lims-tooltip
                  slot="label-info"
                  :content="$t('entities/user/personalInfo/userNameTooltip')"
                ></lims-tooltip>
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.username"
                  type="text"
                  :disabled="isEditMode || viewMode"
                  v-on:blur="
                    $onServerSideValidation($event, userSchema._serverSideValidators.username, [
                      () => $lowercase(formData.personalInfo, 'username'),
                    ])
                  "
                  tabenable="yes"
                ></md-input>
                <lims-tooltip
                  slot="icon"
                  :content="$t('entities/user/personalInfo/userNameTooltipRules')"
                ></lims-tooltip>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="title">
                <v-select
                  slot="field"
                  v-model="formData.personalInfo.titleId"
                  :options="userTitles"
                  :disabled="viewMode"
                  tabenable="yes"
                  :tabindex="3"
                >
                  <template #option="{ label }">{{ label }}</template>
                  <template #selected-option="{ label }">{{ label }}</template>
                </v-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="firstName">
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.firstname"
                  type="text"
                  maxlength="250"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="lastName">
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.lastname"
                  type="text"
                  maxlength="250"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
          </div>
          <!-- end row -->
          <!-- new row -->
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="address1">
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.address1"
                  type="text"
                  maxlength="1000"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="address2">
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.address2"
                  type="text"
                  maxlength="1000"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
          </div>
          <!-- end row -->
          <!-- new row -->
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="town">
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.town"
                  type="text"
                  maxlength="100"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="country">
                <v-select
                  slot="field"
                  v-model="formData.personalInfo.countryId"
                  :options="countries"
                  :disabled="viewMode"
                  tabenable="yes"
                  :tabindex="9"
                >
                  <template #option="{ label }">{{ label }}</template>
                  <template #selected-option="{ label }">{{ label }}</template>
                </v-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="postCode">
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.postCode"
                  type="text"
                  maxlength="50"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
          </div>
          <!-- end row -->
          <!-- new row -->
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-50 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="email">
                <lims-tooltip slot="label-info" :content="$t('entities/user/personalInfo/emailTooltip')"></lims-tooltip>
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.email"
                  type="text"
                  v-on:blur="
                    $onServerSideValidation($event, userSchema._serverSideValidators.email, [
                      () => $lowercase(formData.personalInfo, 'email'),
                    ])
                  "
                  :placeholder="$t('entities/user/personalInfo/email.placeholder')"
                  maxlength="200"
                  :disabled="
                    viewMode ||
                    isMyProfileView ||
                    $getUserMixins().isNotAllowedUpdatePhoneOrEmail() ||
                    $getUserMixins().isNotAllowedUpdatePhoneOrEmailMyEntityMode()
                  "
                  tabenable="yes"
                ></md-input>
              </lims-field>
              <div class="text-underline" v-if="isMyProfileView" @click="onClickUpdateEmail">
                {{ $t('pages/myAccount/profile/update/email') }}
              </div>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="telephoneOffice">
                <md-input
                  slot="field"
                  v-model="formData.personalInfo.telephoneOffice"
                  type="text"
                  :placeholder="$t('global/placeholder.telephone')"
                  maxlength="50"
                  :disabled="viewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.personalInfo" :schema="userSchema.personalInfo" field="telephoneMobile">
                <lims-tel-input
                  slot="field"
                  :disabled="
                    viewMode ||
                    isMyProfileView ||
                    $getUserMixins().isNotAllowedUpdatePhoneOrEmail() ||
                    $getUserMixins().isNotAllowedUpdatePhoneOrEmailMyEntityMode()
                  "
                  v-model="telephoneMobileData"
                  @phoneNumberChange="
                    ($event) => $onServerSideValidation($event, userSchema._serverSideValidators.telephoneMobile)
                  "
                ></lims-tel-input>
              </lims-field>
              <div class="text-underline" v-if="isMyProfileView" @click="onClickUpdateTelephone">
                {{ $t('pages/myAccount/profile/update/telephone') }}
              </div>
            </div>
          </div>
          <!-- end row -->
        </div>
      </lims-block>
      <!-- Laboratory Info -->
      <lims-block class="laboratory-info-block" v-if="!isMyEntityView || formMode === ADD_MODE">
        <h4 class="title" slot="blockTitle">
          {{ $t('pages/account/user/forms/labTechnician/laboratoryInforBlock') }}
        </h4>
        <div slot="blockContent">
          <div class="md-layout" v-if="isDefaultView || isAddMode">
            <div class="md-layout-item md-size-50 md-small-size-100 selectClinic">
              <lims-field
                :model="formData.personalInfo"
                field="availableLaboratories"
                :schema="userSchema.personalInfo"
              >
                <v-select
                  slot="field"
                  :options="laboratoryInfo"
                  v-model="formData.laboratoryInfo"
                  tabenable="yes"
                  :tabindex="22"
                  :disabled="viewMode"
                >
                  <template #option="{ label }">{{ label }}</template>
                  <template #selected-option="{ label }">{{ label }}</template>
                </v-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-100" v-if="formData.laboratoryInfo">
              {{ $t('entities/user/personalInfo/laboratoryInfo/address') }}
              {{ showAddressInformation(formData.laboratoryInfo) }}
            </div>
            <div class="md-layout-item md-size-100" v-if="formData.laboratoryInfo">
              {{ $t('entities/user/personalInfo/laboratoryInfo/email') }} {{ formData.laboratoryInfo.email }}
            </div>
          </div>
          <div class="md-layout" v-if="isMyProfileView && userResource.laboratoryInfo">
            <div class="md-layout-item md-size-100">
              {{ userResource.laboratoryInfo.entityName }}
            </div>
            <div class="md-layout-item md-size-100">
              {{ $t('entities/user/personalInfo/laboratoryInfo/address') }}
              {{ showAddressInformation(userResource.laboratoryInfo) }}
            </div>
            <div class="md-layout-item md-size-100">
              {{ $t('entities/user/personalInfo/laboratoryInfo/email') }} {{ userResource.laboratoryInfo.email }}
            </div>
          </div>
        </div>
      </lims-block>
      <!-- Notification -->
      <user-notifications
        v-if="formData.notificationSettings"
        v-model="formData.notificationSettings"
        :disabled="viewMode"
        :disabledSMS="!telephoneMobileData.telephoneMobile || telephoneMobileData.telephoneMobile == ''"
      ></user-notifications>
      <!-- Login -->
      <lims-block v-if="isDefaultView || isMyEntityView">
        <h4 class="title" slot="blockTitle">{{ $t('pages/account/user/forms/loginBlock') }}</h4>
        <div class="md-layout" slot="blockContent">
          <div class="md-layout lims-form-row">
            <div
              class="md-layout-item md-size-100 md-small-size-100"
              :class="isCheckPendingAction ? 'hightlight-field-validation' : ''"
            >
              <md-checkbox v-model="formData.login.hasLogin" class="lims-checkbox" :disabled="viewMode" tabenable="yes">
                {{ $t('pages/account/user/forms/loginLabel') }}
              </md-checkbox>
            </div>
            <div class="md-layout-item md-size-100 md-small-size-100">
              <user-policy-actions
                v-if="!isAddMode"
                :userPolicyActions="userResource.userPolicyActions"
              ></user-policy-actions>
            </div>
            <div v-if="userResource && userResource.lastTimeLogin" class="last-login">
              {{ $t('pages/account/user/forms/lastTimeLogin', { lastTimeLogin: getLastTimeLogin }) }}
            </div>
          </div>
        </div>
      </lims-block>
      <!-- Display if the user has not completed their account -->
      <div class="isNotCompleteAccount" v-if="!isMyProfileView">
        <div v-if="!isAddMode && isCheckCompleteAccount">
          {{ $t('pages/account/user/forms/isNotCompleteAccount') }}
        </div>
      </div>
      <!-- Form action -->
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions">
          <LimsFormCancel :redirectUrl="$redirectUrl()" />
          <md-button v-if="canSave" @click="$onSave()" class="md-button md-primary lims-form-button md-theme-default">
            {{ $t('global/button/button.save') }}
          </md-button>
          <LimsFormEditButton v-if="viewMode && $canEdit()" :editUrl="$editUrl" />
          <md-button
            v-if="!isAddMode && isDisabledUser && !isMyProfileView"
            class="md-primary lims-form-button"
            @click="$onEnableUser"
            >{{ $t('global/button/button.enable') }}</md-button
          >
          <md-button
            v-if="viewMode && isValidateUser && isDefaultView"
            class="md-primary lims-form-button"
            @click="$onOpenModalValidateUser"
            >{{ $t('global/button/button.validate') }}</md-button
          >
          <template v-if="!isMyProfileView">
            <md-button
              v-if="isEditMode && isResetPasswordUser && !isMyProfileView"
              class="md-danger lims-form-button"
              @click="$onResetPassword"
              >{{ $t('global/button/button.resetPassword') }}</md-button
            >
          </template>
          <md-button
            v-if="!viewMode && isEnabledUser && !isMyProfileView"
            class="md-danger lims-form-button"
            @click="$onDisableUser"
            >{{ $t('global/button/button.disable') }}</md-button
          >
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { FormMixins, TabMixins } from '@/core/mixins';
import UserMixins, { UPDATE_TWO_FA_MODE } from '@/pages/Account/User/user.mixins';
import UserRoleMixins from '@/pages/Account/User/user.role.mixins';
import UserService from '@/services/user.service';
import { FORM_MODES, PENDING_ACTION_TYPE, PENDING_ACTION_TYPE_ENUM, ENTITY_TYPES } from '@/core/constants';
import { getUserSchema } from '@/schemas/users.schema';
import UserNotifications from '../UserNotifications.vue';
import ModalValidateUser from '@/components/Lims/modals/ModalValidateUser.vue';
import ModalHasLoginConfirm from '@/components/Lims/modals/ModalHasLoginConfirm.vue';
import LimsTelInput from '@/components/Lims/LimsTelInput.vue';
import ModalViewPermissions from '@/components/Lims/modals/ModalViewPermissions';
import ModalConfirmUpdateMyEntityUser from '@/components/Lims/modals/ModalConfirmUpdateMyEntityUser.vue';
import { MyEntityService } from '@/services';
import { VERIFY_TW0_FA_MODE } from '@/core/constants';
import { ModalInputYourTelephone } from '@/components/Lims/modals';
import { ModalInputNewEmail } from '@/components/Lims/modals';
import { ModalVerifyCode } from '@/components/Lims/modals';
import { ModalUpdateSuccessfully } from '@/components/Lims/modals';
import { MyAccountService } from '@/services';
import { DateTimeHelper } from '@/core/helpers';
import ModalConfirmCreateNewUserMyEntity from '@/components/Lims/modals/ModalConfirmCreateNewUserMyEntity';

const ENTITY_IDS = ENTITY_TYPES.Laboratory + ',' + ENTITY_TYPES.ClinicLaboratory;

export default {
  components: {
    ModalConfirmCreateNewUserMyEntity,
    UserNotifications,
    ModalValidateUser,
    LimsTelInput,
    ModalHasLoginConfirm,
    ModalViewPermissions,
    ModalInputYourTelephone,
    ModalInputNewEmail,
    ModalVerifyCode,
    ModalUpdateSuccessfully,
    ModalConfirmUpdateMyEntityUser,
  },
  mixins: [FormMixins, UserMixins, UserRoleMixins, TabMixins],
  created() {
    this.fetchData();
  },
  props: {
    userResource: {
      type: Object,
      require: false,
      default: null,
    },
    userId: {
      type: String,
      require: false,
      default: null,
    },
    viewMode: {
      type: Boolean,
      require: false,
      default: null,
    },
  },
  data() {
    return {
      formMode: FORM_MODES.ADD,
      formData: {
        personalInfo: {
          userType: this.USERTYPES_ENUM().find((e) => e.value === this.USER_TYPES().LabTechnician),
          userRole: '',
        },
        login: {
          hasLogin: false,
        },
        laboratoryInfo: null,
        //fake data
        notificationSettings: null,
      },
      userTitles: [],
      countries: [],
      roleList: [],
      telephoneMobileData: {},
      telephoneMobileDataInVerify: {},
      laboratoryInfo: [],
      roleInformation: null,
      verifyMode: null,
      verifyContent: '',
      countryCode: null,
      phoneNumber: null,
      email: null,
    };
  },
  computed: {
    userSchema: function () {
      return getUserSchema('LabTechnician', this.formMode, this.userModel);
    },
    userTypesList() {
      return this.USERTYPES_ENUM();
    },
    disabledUsername() {
      return this.formMode === FORM_MODES.EDIT;
    },
    pendingActionType() {
      return PENDING_ACTION_TYPE;
    },
    isCheckPendingAction() {
      let isCheck = false;
      if (this.formData.pendingActionValidations && this.formData.pendingActionValidations.length > 0) {
        Object.values(this.formData.pendingActionValidations).forEach(function (val) {
          if (
            val.pendingActionTypeId == PENDING_ACTION_TYPE.EnableHasLogin ||
            val.pendingActionTypeId == PENDING_ACTION_TYPE.DisableHasLogin
          ) {
            isCheck = true;
          } else {
            isCheck = false;
          }
        });
      }
      return isCheck;
    },
    isCheckActionHasLogin() {
      let isCheck = false;
      if (this.formData.pendingActionValidations && this.formData.pendingActionValidations.length > 0) {
        Object.values(this.formData.pendingActionValidations).forEach(function (val) {
          if (val.pendingActionTypeId === PENDING_ACTION_TYPE.EnableHasLogin) {
            isCheck = true;
          } else if (val.pendingActionTypeId === PENDING_ACTION_TYPE.DisableHasLogin) {
            isCheck = false;
          } else {
            isCheck = false;
          }
        });
      }
      return isCheck;
    },
    verifyTwoFaMode() {
      return VERIFY_TW0_FA_MODE.NEW;
    },
    onUpdateEmail() {
      return UPDATE_TWO_FA_MODE.EMAIL;
    },
  },
  methods: {
    async fetchData() {
      this.countries = await this.loadCountries();
      this.userTitles = this.USER_TITLES();
      if (this.isDefaultView || this.isMyEntityView) {
        this.roleList = await this.loadRoleList(this.USER_TYPES().LabTechnician);
      }
      this.laboratoryInfo = await this.loadLaboratoryInfo(ENTITY_IDS);
      if (this.formMode === FORM_MODES.ADD && this.laboratoryInfo && this.laboratoryInfo.length === 1) {
        this.formData.laboratoryInfo = this.laboratoryInfo[0];
      }
      if (this.userResource) {
        this.userModel = this.userResource;
        this.formMode = this.viewMode ? FORM_MODES.VIEW : FORM_MODES.EDIT;
        this.formData = {
          personalInfo: {
            ...this.userModel.personalInfo,
            userType: this.USERTYPES_ENUM().find((e) => e.value === this.USER_TYPES().LabTechnician),
            roleId: this.roleList.find((e) => e.value === this.userModel.personalInfo.roleId),
            countryId: this.countries.find((e) => e.value === this.userModel.personalInfo.countryId),
            titleId: this.userTitles.find((e) => e.value === this.userModel.personalInfo.titleId),
          },
          pendingActionValidations: {
            ...this.userModel.pendingActionValidations,
          },
          login: this.userModel.login
            ? {
                hasLogin: this.userModel.pendingActionValidations
                  ? this.isCheckActionHasLogin
                  : this.userModel.login.hasLogin,
              }
            : null,
          laboratoryInfo:
            this.userModel.laboratoryInfo && this.laboratoryInfo
              ? this.laboratoryInfo.find((e) => e.value === this.userModel.laboratoryInfo.entityId)
              : null,
          notificationSettings: this.userModel.notificationSettings,
        };
        this.telephoneMobileData = this.$getTelephoneMobileData();
        this.telephoneMobileDataInVerify = this.$getTelephoneMobileDataInVerify();
        this.$setHeadingTitle(this.formData.personalInfo.username);
      }
      this.$nextTick(function () {
        this.$resetChangeDetection();
      });
      this.$setCountryAndMobileCodeFollowGlobalVariablesDataInAddMode();
    },
    async loadLaboratoryInfo(entityIds) {
      let responseData;
      if (this.isDefaultView || this.isMyProfileView) {
        responseData = await UserService.getLaboratoryInfo(entityIds);
        return responseData.data.map((item) => {
          return {
            value: item.entityId,
            label: item.entityName,
            address1: item.address1,
            address2: item.address2,
            town: item.town,
            country: item.country,
            email: item.email,
          };
        });
      } else {
        if (this.isMyEntityView && this.formMode === FORM_MODES.ADD) {
          responseData = await MyEntityService.getLaboratoryInfo();
          return responseData.data.map((item) => {
            return {
              value: item.entityId,
              label: item.entityName,
              address1: item.address1,
              address2: item.address2,
              town: item.town,
              country: item.country,
              email: item.email,
            };
          });
        }
      }
    },
    showAddressInformation(laboratoryInfo) {
      const { address1, address2, town, country } = laboratoryInfo;
      let addressInformation = address1;

      if (address2 && address2.length > 0) {
        addressInformation += ', ' + address2;
      }
      if (town && town.length > 0) {
        addressInformation += ', ' + town;
      }
      if (country && country.length > 0) {
        addressInformation += ', ' + country;
      }
      return addressInformation;
    },
    createUser() {
      if (this.$isMyEntityView()) {
        return MyEntityService.create({
          userId: this.userId,
          ...this.formDataMyEntity(),
        });
      }
      return UserService.create(this.formDataUser());
    },
    updateUser() {
      //TODO: UPDATE PROFILE PAYLOAD MUST BE CHANGED AFTER API ALREADY UPDATED
      if (this.$isMyProfileView()) {
        return this.updateProfile();
      } else if (this.$isMyEntityView()) {
        return MyEntityService.updateOneMyEntityUser({
          userId: this.userId,
          ...this.formDataMyEntity(),
        });
      }
      return UserService.updateOne({
        userId: this.userId,
        ...this.formDataUser(),
      });
    },
    formDataUser() {
      return {
        personalInfo: {
          ...this.formData.personalInfo,
          roleId: this.formData.personalInfo.roleId.value,
          userTypeId: this.formData.personalInfo.userType.value,
          titleId: this.formData.personalInfo.titleId.value,
          countryId: this.formData.personalInfo.countryId.value,
          countryCodeMobile: this.telephoneMobileData.countryCodeMobile
            ? this.telephoneMobileData.countryCodeMobile.code
            : '',
          localeMobile: this.telephoneMobileData.countryCodeMobile
            ? this.telephoneMobileData.countryCodeMobile.locale
            : '',
          telephoneMobile: this.telephoneMobileData.telephoneMobile,
          countryIdMobile: this.telephoneMobileData.telephoneMobile
            ? this.telephoneMobileData.countryCodeMobile.countryId
            : null,
        },
        login: {
          hasLogin: this.formData.login.hasLogin,
        },
        laboratoryInfo: {
          entityId: this.formData.laboratoryInfo.value,
        },
        notificationSettings: this.formData.notificationSettings,
      };
    },
    formDataMyEntity() {
      return {
        personalInfo: {
          ...this.formData.personalInfo,
          roleId: this.formData.personalInfo.roleId.value,
          userTypeId: this.formData.personalInfo.userType.value,
          titleId: this.formData.personalInfo.titleId.value,
          countryId: this.formData.personalInfo.countryId.value,
          countryCodeMobile: this.telephoneMobileData.countryCodeMobile
            ? this.telephoneMobileData.countryCodeMobile.code
            : '',
          localeMobile: this.telephoneMobileData.countryCodeMobile
            ? this.telephoneMobileData.countryCodeMobile.locale
            : '',
          telephoneMobile: this.telephoneMobileData.telephoneMobile,
          countryIdMobile: this.telephoneMobileData.telephoneMobile
            ? this.telephoneMobileData.countryCodeMobile.countryId
            : null,
        },
        login: {
          hasLogin: this.formData.login.hasLogin,
        },
        laboratoryInfo: {
          entityId: this.formData?.laboratoryInfo?.value,
        },
        notificationSettings: this.formData.notificationSettings,
      };
    },
    updateProfile() {
      return MyAccountService.updateProfile({
        userId: this.userId,
        personalInfo: {
          ...this.formData.personalInfo,
          titleId: this.formData.personalInfo.titleId.value,
          countryId: this.formData.personalInfo.countryId.value,
          telephoneMobile: this.telephoneMobileData.telephoneMobile,
        },
        notificationSettings: this.formData.notificationSettings,
      });
    },
    userTypeOption(userTypeId) {
      return this.USERTYPES_ENUM().find((e) => e.value === userTypeId);
    },
    onClickViewPermissions() {
      if (this.formData.personalInfo.roleId) {
        this.$refs.viewPermissions.open();
      }
    },
    actionMessageVariable(item) {
      const { timezoneValue, dateTimeAppFormat } = this.$store.getters['config/$appConfig'];
      const createdDate = DateTimeHelper.formatDateTimeGlobal(item.createdDate, {
        timezoneValue,
        dateTimeAppFormat,
      });
      return {
        action: item.pendingActionTypeId
          ? this.$t(PENDING_ACTION_TYPE_ENUM.find((e) => e.id === item.pendingActionTypeId)?.text)
          : '',
        updatedValue: this.isCheckActionHasLogin ? 'true' : 'false',
        userType: item.userTypeName,
        userName: item.username,
        newUpdatedValue: item.newUpdatedValue,
        createdDate: createdDate,
      };
    },
  },
};
</script>
<style lang="scss">
.laboratory-info-block .md-layout-item {
  padding: 0;
}
</style>

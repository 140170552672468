<template>
  <div>
    <md-table class="paginated-table table-striped table-hover lims-table case-list-tbl">
      <md-table-toolbar>
        <slot name="toolbar"></slot>
      </md-table-toolbar>
      <div class="table-fix-header">
        <thead>
          <md-table-row>
            <md-table-head v-for="(col, index) in colTabl" :key="index">
              <div @mouseover="hoverIn(index)" @mouseout="hoverOut()" v-html="getColumnName(col)"></div>
            </md-table-head>
          </md-table-row>
        </thead>
        <tbody>
          <md-table-row v-for="(item, index) in caseList" :key="`case-${index}`" :class="checkRowHighlight(item)">
            <md-table-cell class="caseRef-labRef-column"
              >{{ item.caseReference }} <br />
              {{ item.labReference }}</md-table-cell
            >
            <md-table-cell>{{ item.firstName + ' ' + item.lastName }}</md-table-cell>
            <md-table-cell>{{ item.pathologistName }}</md-table-cell>
            <md-table-cell>{{ item.clinicianName }}</md-table-cell>
            <md-table-cell
              >{{ item.clinicName }} <br />
              {{ item.clinicType }}</md-table-cell
            >
            <md-table-cell>{{ item.specimentTypeName }}</md-table-cell>
            <md-table-cell class="date-column">{{
              item.labEntryDate ? formatDateTimeGlobalNotIncludeTime(item.labEntryDate) : ''
            }}</md-table-cell>
            <md-table-cell class="date-column">{{
              item.reportedDate ? formatDateTimeGlobalNotIncludeTime(item.reportedDate) : ''
            }}</md-table-cell>
            <md-table-cell class="status-column">
              <md-icon v-if="item.status == 'Reported'" class="black-color">hourglass_bottom</md-icon>
            </md-table-cell>
            <md-table-cell class="actions-column">
              <div class="cell-action-inner">
                <div style="width: 25px">
                  <md-button
                    class="md-just-icon md-info md-simple"
                    @click.stop="onShowDocumentViewerInSearchSimilar(item)"
                  >
                    <md-icon>visibility</md-icon>
                  </md-button>
                </div>
                <div style="width: 25px">
                  <md-button
                    class="md-just-icon md-info md-simple"
                    @click.stop="onExportAsPdf(item)"
                    :disabled="isProcessing"
                  >
                    <img class="pdf-img" :src="require(`@/assets/images/pdf-file.png`)" />
                  </md-button>
                </div>
              </div>
            </md-table-cell>
          </md-table-row>
        </tbody>
      </div>
    </md-table>
  </div>
</template>
<script>
import CaseListMixin from '@/pages/Case/CaseManagement/List/caseList.mixins';

export default {
  name: 'CaseListAdmin',
  mixins: [CaseListMixin],
  props: {
    caseList: {
      required: false,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      colTabl: [
        {
          name: 'pages.case.caseManagement/list.caseRefAndLabRef',
          sort: false,
          class: 'caseRef-labRef-column',
        },
        {
          name: 'pages.case.caseManagement/list.patient',
          key: 'patientFullname',
          sort: false,
          class: 'patient-column',
        },
        {
          name: 'pages.case.caseManagement/list.pathologist',
          key: 'pathologistName',
          sort: false,
          class: 'pathologist-column',
        },
        {
          name: 'pages.case.caseManagement/list.clinician',
          key: 'clinicianName',
          sort: false,
          class: 'clinician-column',
        },
        {
          name: 'pages.case.caseManagement/list.clinicAndClinicType',
          key: 'clinicName',
          sort: false,
          class: 'clinic-column',
        },

        {
          name: 'pages.case.alwManagement/list.specimenType',
          key: 'specimenType',
          sort: false,
          class: 'specimenType-column',
        },
        {
          name: 'pages.case.caseManagement/list.labEntry',
          key: 'procedureLabEntry',
          sort: false,
          class: 'date-column',
        },
        {
          name: 'pages.case.caseManagement/list.reported',
          key: 'reportedDate',
          sort: false,
          class: 'date-column',
        },
        {
          name: 'pages.case.caseManagement/list.status',
          key: 'statusName',
          sort: false,
          class: 'status-column',
        },
        {
          name: 'global/pages/list.actions',
          key: 'actions',
          sort: false,
          class: 'actions-column',
        },
      ],
      hover: '',
      orderState: {
        orderField: 'caseReference',
        orderBy: 'asc',
      },
    };
  },
  methods: {
    onShowDocumentViewerInSearchSimilar(caseDocuments) {
      this.$emit('openDocumentViewer', caseDocuments);
    },
  },
};
</script>
<style lang="scss"></style>

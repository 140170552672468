<template>
  <lims-block class="c-block01">
    <h4 class="title" slot="blockTitle">
      {{ $t('pages/entity/components/preferredPathologist/blockTitle') }}
    </h4>
    <div slot="blockContent">
      <div class="md-layout" v-if="isDefaultView">
        <div class="md-layout-item md-size-50 md-small-size-100">
          <label>{{ $t('pages/entity/components/preferredPathologist/selectLabel') }}</label>
          <v-select
            multiple
            slot="field"
            label="text"
            v-model="preferredPathologistDropdown"
            :options="clinicPreferredPathologistList"
            :disabled="disabled"
            :placeholder="$t('pages/entity/components/preferredPathologist/placeholder.selectPathologist')"
            @input="valPreferredPathSelected"
            tabenable="yes"
            :tabindex="1"
          >
            <template #option="{ text }">
              {{ text }}
            </template>
            <template #selected-option="{ text }">
              {{ text }}
            </template>
          </v-select>
        </div>
        <div class="md-layout-item md-size-50 md-small-size-100">
          <md-button
            type="button"
            class="lims-form-button block01-btn md-primary"
            :disabled="disabled || isDisableAddPreferredPathBtn"
            @click="addPreferredPathologist"
          >
            {{ $t('pages/entity/components/preferredPathologist/button.addPreferredPathologist') }}
          </md-button>
        </div>
      </div>
      <div class="md-layout lims-form-row">
        <md-table class="custom-tbl" md-card>
          <md-table-row>
            <md-table-head width="40%">
              {{ $t('pages/entity/components/preferredPathologist/column.Name') }}
            </md-table-head>
            <md-table-head>
              {{ $t('pages/entity/components/preferredPathologist/column.Specialty') }}
            </md-table-head>
            <md-table-head v-if="isDefaultView" class="th-center cell-action">
              {{ $t('global/pages/list.actions') }}
            </md-table-head>
          </md-table-row>
          <md-table-row v-for="(item, idx) in preferredPathologistSelected" :key="`${item.value}-${idx}`">
            <md-table-cell class="cell-clinicType">{{ item.fullname }}</md-table-cell>
            <md-table-cell>{{ item.specialty }}</md-table-cell>
            <md-table-cell v-if="isDefaultView" class="cell-action">
              <span @click="!disabled ? delPreferredPathologist(item) : ''"><md-icon>close</md-icon></span>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </div>
    </div>
  </lims-block>
</template>
<script>
import { EntityService } from '@/services';
import { sortDropDown } from '@/core/helpers';
export default {
  name: 'PreferredPathologist',
  created() {
    this.fetchData();
  },
  props: {
    entityId: {
      type: String,
      require: true,
    },
    value: {
      type: Array,
      require: true,
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false,
    },
    isDefaultView: {
      type: Boolean,
      require: false,
    },
    restrictedPathologist: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {
      preferredPathologistDropdown: [],
      clinicPreferredPathologistList: [],
      isDisableAddPreferredPathBtn: true,
      preferredPathologistSelected: [],
      preferredPathologistSelectedTemp: [],
    };
  },

  watch: {
    entityId() {
      this.fetchData();
    },
    value() {
      this.preferredPathologistSelected = this.value;
    },
    restrictedPathologist() {
      this.clinicPreferredPathologistList = this.getClinicPreferredPathologistList();
    },
    preferredPathologistSelected: {
      handler(val) {
        this.$emit('input', val);
      },
      deep: true,
    },
    preferredPathologistSelectedTemp: {
      handler(val) {
        this.$emit('tempData', val);
      },
      deep: true,
    },
  },

  methods: {
    async fetchData() {
      this.clinicPreferredPathologistListTemp = await this.loadPreferredPathologist();
      this.clinicPreferredPathologistList = this.clinicPreferredPathologistListTemp;
    },

    async loadPreferredPathologist() {
      const { data } = await EntityService.findClinicPreferredPathologist({
        entityId: this.entityId ? this.entityId : '',
        search: '',
      });
      return sortDropDown(data, 'text');
    },

    compareArray(arr1, array2, array3) {
      if (!array2 && !array3) {
        return arr1;
      } else if (!array2) {
        return arr1.filter((a1) => !array3.some((a3) => a1.id === a3.id));
      } else if (!array3) {
        return arr1.filter((a1) => !array2.some((a2) => a1.id === a2.userId));
      }

      return arr1.filter((a1) => !(array2.some((a2) => a1.id === a2.userId) || array3.some((a3) => a1.id === a3.id)));
    },

    getClinicPreferredPathologistList() {
      return this.compareArray(
        this.clinicPreferredPathologistListTemp,
        this.preferredPathologistSelected,
        this.restrictedPathologist,
      );
    },

    //check condition to disabled button add
    valPreferredPathSelected() {
      if (this.preferredPathologistDropdown.length == 0) {
        this.isDisableAddPreferredPathBtn = true;
      } else {
        this.isDisableAddPreferredPathBtn = false;
      }

      const listDropdown = this.getClinicPreferredPathologistList();
      const listDropdownFilter = listDropdown.filter(
        (list) => !this.preferredPathologistDropdown.some((dropdown) => list.id === dropdown.id),
      );
      this.clinicPreferredPathologistList = listDropdownFilter;
      this.preferredPathologistSelectedTemp = this.preferredPathologistDropdown.concat(
        this.preferredPathologistSelected,
      );
    },

    addPreferredPathologist() {
      if (!this.isDisableAddPreferredPathBtn) {
        this.preferredPathologistDropdown.forEach((item) => {
          this.preferredPathologistSelected.push({
            id: item.id,
            userId: item.id,
            fullname: item.text,
            specialty: item.specialty,
          });
        });
        this.preferredPathologistDropdown = [];
        this.isDisableAddPreferredPathBtn = true;
        this.clinicPreferredPathologistList = this.getClinicPreferredPathologistList();
      }
    },
    delPreferredPathologist(item) {
      const idx = this.preferredPathologistSelected.findIndex(
        (preferredPathologistSelected) => preferredPathologistSelected.userId === item.userId,
      );
      this.preferredPathologistSelected.splice(idx, 1);
      this.clinicPreferredPathologistList = this.getClinicPreferredPathologistList();
      this.preferredPathologistSelectedTemp = this.preferredPathologistDropdown.concat(
        this.preferredPathologistSelected,
      );
    },
  },
};
</script>

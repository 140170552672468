<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
      <modal-confirmation ref="confirmModal" @onConfirm="$onConfirmDisabled" :typeEntity="true"></modal-confirmation>
      <!-- begin information block -->
      <lims-block>
        <h4 class="title" slot="blockTitle">{{ $t('pages/entity/forms/blockTitle.Information') }}</h4>
        <div slot="blockContent">
          <LimsFormEditButton class="edit-btn" v-if="isViewMode && $canEdit()" :editUrl="$editUrl" />
          <!-- new row -->
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-25 md-small-size-100" v-if="isDefaultView">
              <lims-field :model="formData.information" :schema="entitySchema.information" field="entityTypeId">
                <lims-single-select
                  slot="field"
                  :translated="false"
                  v-model="formData.information.entityTypeId"
                  :options="entityTypeList"
                  disabled
                  tabenable="yes"
                ></lims-single-select>
              </lims-field>
            </div>
          </div>
          <!-- end row -->
          <!-- begin row -->
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-50 md-small-size-100">
              <lims-field :model="formData.information" :schema="entitySchema.information" field="entityName">
                <md-input
                  slot="field"
                  v-model="formData.information.entityName"
                  type="text"
                  :disabled="isViewMode"
                  maxlength="250"
                  @change="$onServerSideValidation($event, entitySchema._serverSideValidators.entityName)"
                  tabenable="yes"
                >
                </md-input>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100" v-if="isDefaultView">
              <lims-field :model="formData.information" :schema="entitySchema.information" field="labBillingReference">
                <md-input
                  slot="field"
                  v-model="formData.information.labBillingReference"
                  type="text"
                  maxlength="100"
                  :disabled="isViewMode"
                  @change="$onServerSideValidation($event, entitySchema._serverSideValidators.labBillingReference)"
                  tabenable="yes"
                >
                </md-input>
              </lims-field>
            </div>
          </div>
          <!-- end row -->
          <!-- new row -->
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100">
              <lims-field :model="formData.information" :schema="entitySchema.information" field="address1">
                <md-input
                  slot="field"
                  v-model="formData.information.address1"
                  type="text"
                  maxlength="1000"
                  :disabled="isViewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
              <lims-field :model="formData.information" :schema="entitySchema.information" field="address2">
                <md-input
                  slot="field"
                  v-model="formData.information.address2"
                  type="text"
                  maxlength="1000"
                  :disabled="isViewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
          </div>
          <!-- end row -->
          <!-- new row -->
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.information" :schema="entitySchema.information" field="town">
                <md-input
                  slot="field"
                  v-model="formData.information.town"
                  type="text"
                  maxlength="100"
                  :disabled="isViewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.information" :schema="entitySchema.information" field="countryId">
                <lims-single-select
                  slot="field"
                  v-model="formData.information.countryId"
                  :options="countries"
                  :disabled="isViewMode"
                  tabenable="yes"
                ></lims-single-select>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.information" :schema="entitySchema.information" field="postalCode">
                <md-input
                  slot="field"
                  v-model="formData.information.postalCode"
                  type="text"
                  maxlength="20"
                  :disabled="isViewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
          </div>
          <!-- end row -->
          <!-- new row -->
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-50 md-small-size-100">
              <lims-field :model="formData.information" :schema="entitySchema.information" field="email">
                <md-input
                  slot="field"
                  v-model="formData.information.email"
                  type="text"
                  @change="$lowercase(formData.information, 'email')"
                  maxlength="200"
                  :disabled="isViewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <lims-field :model="formData.information" :schema="entitySchema.information" field="telephoneOffice">
                <md-input
                  slot="field"
                  v-model="formData.information.telephoneOffice"
                  :placeholder="$t('global/placeholder.telephone')"
                  type="text"
                  maxlength="15"
                  :disabled="isViewMode"
                  tabenable="yes"
                ></md-input>
              </lims-field>
            </div>
          </div>
          <!-- end row -->
          <!-- new row -->
          <div class="md-layout lims-form-row" v-if="isDefaultView">
            <div class="md-layout-item md-size-25 md-small-size-100">
              <md-checkbox
                :disabled="isViewMode"
                v-model="formData.information.canBookCase"
                class="lims-checkbox"
                tabenable="yes"
              >
                {{ $t('entities/entity/information/canBookCase') }}
              </md-checkbox>
            </div>
            <div class="md-layout-item md-size-25 md-small-size-100">
              <md-checkbox
                :disabled="isViewMode"
                v-model="formData.information.canProcessAlw"
                class="lims-checkbox"
                tabenable="yes"
              >
                {{ $t('entities/entity/information/canProcessAlw') }}
              </md-checkbox>
            </div>
          </div>
          <!-- end row -->
          <!-- new row -->
          <div class="md-layout lims-form-row" v-if="isDefaultView">
            <div class="md-layout-item md-size-25 md-small-size-100">
              <md-checkbox
                :disabled="isViewMode"
                v-model="formData.information.canAllowCustomStain"
                @change="changeValueCustomStain"
                class="lims-checkbox"
                tabenable="yes"
              >
                {{ $t('entities/entity/information/canAllowCustomStain') }}
              </md-checkbox>
            </div>
          </div>
          <!-- end row -->
          <!-- begin row -->
          <div class="md-layout lims-form-row" v-if="isDefaultView">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <lims-field
                :model="formData.information"
                :schema="entitySchema.information"
                field="comment"
                class="textarea-field"
              >
                <md-textarea
                  :disabled="isViewMode"
                  slot="field"
                  v-model="formData.information.comment"
                  type="text"
                  maxlength="1000"
                  tabenable="yes"
                ></md-textarea>
              </lims-field>
            </div>
          </div>
          <!-- end row -->
        </div>
      </lims-block>
      <!-- Begin user block - temporary hide-->
      <entity-user-list
        v-if="false"
        :entityTypeId="entityTypeId"
        v-model="formData.addedUsers"
        :disabled="isViewMode"
        :isMyEntityView="isMyEntityView"
      ></entity-user-list>
      <!-- End user block -->
      <!-- Begin slide and panel -->
      <slide-and-panel
        v-if="formData.slideAndPanel"
        v-model="formData.slideAndPanel"
        :disabled="isViewMode"
      ></slide-and-panel>
      <!-- Begin restriction form -->
      <lims-block class="c-block01">
        <h4 class="title" slot="blockTitle">
          {{ $t('pages/entity/forms/laboratory/restriction/blockTitle') }}
        </h4>
        <div slot="blockContent">
          <!-- Clinician -->
          <div class="p-clinician">
            <h3 class="block01-title">{{ $t('pages/entity/forms/laboratory/restriction/clinician/blockTitle') }}</h3>
            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-50 md-small-size-100">
                <label>{{ $t('pages/entity/forms/laboratory/restriction/clinician/selectLabel') }}</label>
                <v-select
                  multiple
                  slot="field-outside"
                  v-model="labRestrictedClinicianDropdown"
                  :options="labRestrictedClinicianList"
                  :placeholder="$t('pages/entity/forms/laboratory/restriction/clinician/placeholder.selectClinician')"
                  :disabled="isViewMode"
                  @input="valClinicianSelected"
                  tabenable="yes"
                  :tabindex="1"
                >
                  <template #option="{ label }">{{ label }}</template>
                  <template #selected-option="{ label }">{{ label }}</template>
                </v-select>
              </div>
              <div class="md-layout-item md-size-50 md-small-size-100">
                <md-button
                  type="button"
                  class="lims-form-button block01-btn md-primary"
                  :disabled="isViewMode || isDisableAddClinicianBtn"
                  @click="addLabRestrictedClinician"
                >
                  {{ $t('pages/entity/forms/laboratory/restriction/clinician/button.addRestrictedClinician') }}
                </md-button>
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-size-100 md-small-size-100 textarea-field">
                <label>{{ $t('pages/entity/forms/laboratory/restriction/Comments') }}</label>
                <md-field>
                  <md-textarea
                    v-model="clinicianComments"
                    :disabled="isViewMode"
                    maxlength="250"
                    tabenable="yes"
                  ></md-textarea>
                </md-field>
              </div>
            </div>

            <!-- table Clinician -->
            <div class="md-layout lims-form-row">
              <md-table class="custom-tbl" md-card>
                <md-table-row>
                  <md-table-head width="30%">
                    {{ $t('pages/entity/forms/laboratory/restriction/column.Name') }}
                  </md-table-head>
                  <md-table-head>
                    {{ $t('pages/entity/forms/laboratory/restriction/column.Comment') }}
                  </md-table-head>
                  <md-table-head class="th-center cell-action">
                    {{ $t('global/pages/list.actions') }}
                  </md-table-head>
                </md-table-row>
                <md-table-row
                  v-for="(item, idx) in formData.labRestriction.labRestrictedClinicians"
                  :key="`${item.value}-${idx}`"
                >
                  <md-table-cell class="cell-clinicType">{{ item.fullname }}</md-table-cell>
                  <md-table-cell>
                    <template v-if="!isMyEntityView">{{ item.comment }}</template>
                    <template v-else>{{
                      !item.isCreatedByAdmin
                        ? item.comment
                        : $t('pages/entity/components/restrictedCommentCreatedByAdmin')
                    }}</template>
                  </md-table-cell>
                  <md-table-cell class="cell-action">
                    <div v-if="!isMyEntityView" @click="!isViewMode ? delLabRestrictedClinician(item) : ''">
                      <md-icon>close</md-icon>
                    </div>
                    <div v-else>
                      <template v-if="!item.isCreatedByAdmin">
                        <div @click="!isViewMode ? delLabRestrictedClinician(item) : ''"><md-icon>close</md-icon></div>
                      </template>
                    </div>
                  </md-table-cell>
                </md-table-row>
              </md-table>
            </div>
          </div>

          <!-- Clinic -->
          <div class="p-clinic">
            <h3 class="block01-title">{{ $t('pages/entity/forms/laboratory/restriction/clinic/blockTitle') }}</h3>
            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-50 md-small-size-100">
                <label>{{ $t('pages/entity/forms/laboratory/restriction/clinic/selectLabel') }}</label>
                <v-select
                  multiple
                  slot="field-outside"
                  v-model="labRestrictedClinicDropdown"
                  :options="labRestrictedClinicList"
                  :placeholder="$t('pages/entity/forms/laboratory/restriction/clinic/placeholder.selectClinic')"
                  :disabled="isViewMode"
                  @input="valClinicSelected"
                >
                  <template #option="{ label }">{{ label }}</template>
                  <template #selected-option="{ label }">{{ label }}</template>
                </v-select>
              </div>
              <div class="md-layout-item md-size-50 md-small-size-100">
                <md-button
                  type="button"
                  class="lims-form-button block01-btn md-primary"
                  :disabled="isViewMode || isDisableAddClinicBtn"
                  @click="addLabRestrictedClinic"
                >
                  {{ $t('pages/entity/forms/laboratory/restriction/clinic/button.addRestrictedClinic') }}
                </md-button>
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-size-100 md-small-size-100 textarea-field">
                <label>{{ $t('pages/entity/forms/laboratory/restriction/Comments') }}</label>
                <md-field>
                  <md-textarea v-model="clinicComments" :disabled="isViewMode" maxlength="250"></md-textarea>
                </md-field>
              </div>
            </div>

            <!-- table Clinic -->
            <div class="md-layout lims-form-row">
              <md-table class="custom-tbl" md-card>
                <md-table-row>
                  <md-table-head width="30%">
                    {{ $t('pages/entity/forms/laboratory/restriction/column.Name') }}
                  </md-table-head>
                  <md-table-head>
                    {{ $t('pages/entity/forms/laboratory/restriction/column.Comment') }}
                  </md-table-head>
                  <md-table-head class="th-center cell-action">
                    {{ $t('global/pages/list.actions') }}
                  </md-table-head>
                </md-table-row>
                <md-table-row
                  v-for="(item, idx) in formData.labRestriction.labRestrictedClinics"
                  :key="`${item.value}-${idx}`"
                >
                  <md-table-cell class="cell-clinicType">{{ item.clinicName }}</md-table-cell>
                  <md-table-cell>
                    <template v-if="!isMyEntityView">{{ item.comment }}</template>
                    <template v-else>{{
                      !item.isCreatedByAdmin
                        ? item.comment
                        : $t('pages/entity/components/restrictedCommentCreatedByAdmin')
                    }}</template>
                  </md-table-cell>
                  <md-table-cell class="cell-action">
                    <div v-if="!isMyEntityView" @click="!isViewMode ? delLabRestrictedClinic(item) : ''">
                      <md-icon>close</md-icon>
                    </div>
                    <div v-else>
                      <template v-if="!item.isCreatedByAdmin">
                        <div @click="!isViewMode ? delLabRestrictedClinic(item) : ''"><md-icon>close</md-icon></div>
                      </template>
                    </div>
                  </md-table-cell>
                </md-table-row>
              </md-table>
            </div>
          </div>

          <!-- Pathologist -->
          <div class="p-pathologist">
            <h3 class="block01-title">
              {{ $t('pages/entity/forms/laboratory/restriction/pathologist/blockTitle') }}
            </h3>
            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-50 md-small-size-100">
                <label>{{ $t('pages/entity/forms/laboratory/restriction/pathologist/selectLabel') }}</label>
                <v-select
                  multiple
                  slot="field-outside"
                  v-model="labRestrictedPathologistDropdown"
                  :options="labRestrictedPathologistList"
                  :placeholder="
                    $t('pages/entity/forms/laboratory/restriction/pathologist/placeholder.selectPathologist')
                  "
                  :disabled="isViewMode"
                  @input="valPathSelected"
                >
                  <template #option="{ label }">{{ label }}</template>
                  <template #selected-option="{ label }">{{ label }}</template>
                </v-select>
              </div>
              <div class="md-layout-item md-size-50 md-small-size-100">
                <md-button
                  type="button"
                  class="lims-form-button block01-btn md-primary"
                  @click="addLabRestrictedPathologist"
                  :disabled="isViewMode || isDisableAddPathologistBtn"
                >
                  {{ $t('pages/entity/forms/laboratory/restriction/pathologist/button.addRestrictedPathologist') }}
                </md-button>
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-size-100 md-small-size-100 textarea-field">
                <label>{{ $t('pages/entity/forms/laboratory/restriction/Comments') }}</label>
                <md-field>
                  <md-textarea v-model="pathologistComments" :disabled="isViewMode" maxlength="250"></md-textarea>
                </md-field>
              </div>
            </div>

            <!-- table Pathologist -->
            <div class="md-layout lims-form-row">
              <md-table class="custom-tbl" md-card>
                <md-table-row>
                  <md-table-head width="30%">
                    {{ $t('pages/entity/forms/laboratory/restriction/column.Name') }}
                  </md-table-head>
                  <md-table-head>
                    {{ $t('pages/entity/forms/laboratory/restriction/column.Comment') }}
                  </md-table-head>
                  <md-table-head class="th-center cell-action">
                    {{ $t('global/pages/list.actions') }}
                  </md-table-head>
                </md-table-row>
                <md-table-row
                  v-for="(item, idx) in formData.labRestriction.labRestrictedPathologists"
                  :key="`${item.value}-${idx}`"
                >
                  <md-table-cell class="cell-clinicType">{{ item.fullname }}</md-table-cell>
                  <md-table-cell>
                    <template v-if="!isMyEntityView">{{ item.comment }}</template>
                    <template v-else>{{
                      !item.isCreatedByAdmin
                        ? item.comment
                        : $t('pages/entity/components/restrictedCommentCreatedByAdmin')
                    }}</template>
                  </md-table-cell>
                  <md-table-cell class="cell-action">
                    <div v-if="!isMyEntityView" @click="!isViewMode ? delLabRestrictedPathologist(item) : ''">
                      <md-icon>close</md-icon>
                    </div>
                    <div v-else>
                      <template v-if="!item.isCreatedByAdmin">
                        <div @click="!isViewMode ? delLabRestrictedPathologist(item) : ''">
                          <md-icon>close</md-icon>
                        </div>
                      </template>
                    </div>
                  </md-table-cell>
                </md-table-row>
              </md-table>
            </div>
          </div>
        </div>
      </lims-block>
      <!-- End restriction form -->

      <!-- Begin Display option form -->
      <display-option v-model="formData.labDisplayOptions" :disabled="isViewMode"></display-option>

      <!-- Begin action form -->
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-100 md-small-size-100 lims-form-actions">
          <LimsFormCancel :redirectUrl="isMyEntityView && isLabTechnicianView ? '/' : ''" />
          <md-button
            v-if="!isViewMode"
            @click="$onSave()"
            class="md-button md-primary lims-form-button md-theme-default"
          >
            {{ $t('global/button/button.save') }}
          </md-button>
          <LimsFormEditButton v-if="isViewMode && $canEdit()" :editUrl="$editUrl" />
          <md-button v-if="!isAddMode && isDisabledEntity" class="md-primary lims-form-button" @click="$onEnableEntity">
            {{ $t('global/button/button.enable') }}
          </md-button>
          <md-button v-if="isEditMode && isEnabledEntity" class="md-danger lims-form-button" @click="$onDisableEntity">
            {{ $t('global/button/button.disable') }}
          </md-button>
        </div>
      </div>
      <!-- End action form -->
    </form>
  </ValidationObserver>
</template>

<script>
import EntityMixins from '@/pages/Entity/entity.mixins';
import { FormMixins, TabMixins } from '@/core/mixins';
import { ENTITY_TYPES, ENTITY_TYPE_ENUMS } from '@/core/constants';
import { getEntitySchema } from '@/schemas/entities.schema';
import { EntityService, MyEntityService } from '@/services';
import { sortDropDown } from '@/core/helpers';
const ENTITY_TYPE = ENTITY_TYPES.Laboratory;

export default {
  name: 'LimsEntityClinic',
  mixins: [FormMixins, EntityMixins, TabMixins],
  created: function () {
    this.$fetchData(ENTITY_TYPE);
    this.loadData();
  },
  data() {
    return {
      labRestrictedClinicianDropdown: [],
      labRestrictedClinicianList: [],
      isDisableAddClinicianBtn: true,
      clinicianComments: '',
      labRestrictedClinicDropdown: [],
      labRestrictedClinicList: [],
      isDisableAddClinicBtn: true,
      clinicComments: '',
      labRestrictedPathologistDropdown: [],
      labRestrictedPathologistList: [],
      isDisableAddPathologistBtn: true,
      pathologistComments: '',
    };
  },
  computed: {
    entitySchema() {
      return getEntitySchema(ENTITY_TYPE, this.entityResource);
    },
    entityTypeList() {
      return ENTITY_TYPE_ENUMS;
    },
    entityTypeId() {
      return ENTITY_TYPE;
    },
  },
  methods: {
    async loadData() {
      this.labRestrictedClinicianListTemp = await this.loadLabRestrictedClinician();
      if (this.entityResource && this.entityResource.labRestriction.labRestrictedClinicians.length > 0) {
        this.labRestrictedClinicianList = this.compareArray(
          this.labRestrictedClinicianListTemp,
          this.entityResource.labRestriction.labRestrictedClinicians,
        );
      } else {
        this.labRestrictedClinicianList = this.labRestrictedClinicianListTemp;
      }

      this.labRestrictedClinicListTemp = await this.loadLabRestrictedClinic();
      if (this.entityResource && this.entityResource.labRestriction.labRestrictedClinics.length > 0) {
        this.labRestrictedClinicList = this.labRestrictedClinicListTemp.filter(
          (a1) => !this.entityResource.labRestriction.labRestrictedClinics.some((a2) => a1.value === a2.clinicId),
        );
      } else {
        this.labRestrictedClinicList = this.labRestrictedClinicListTemp;
      }

      this.labRestrictedPathologistListTemp = await this.loadLabRestrictedPathologist();
      if (this.entityResource && this.entityResource.labRestriction.labRestrictedPathologists.length > 0) {
        this.labRestrictedPathologistList = this.compareArray(
          this.labRestrictedPathologistListTemp,
          this.entityResource.labRestriction.labRestrictedPathologists,
        );
      } else {
        this.labRestrictedPathologistList = this.labRestrictedPathologistListTemp;
      }
    },

    /*------------------ load data Restricted Clinician Dropdown --------------*/
    async loadLabRestrictedClinician() {
      const params = {
        entityId: '',
        search: '',
      };

      const { data } = this.isMyEntityView
        ? await MyEntityService.findLabRestrictedClinician(params)
        : await EntityService.findLabRestrictedClinician(params);
      return sortDropDown(
        data.map((item) => {
          return {
            value: item.id,
            label: item.text,
            userId: item.id,
          };
        }),
      );
    },

    /*------------------ load data Restricted Clinic Dropdown --------------*/
    async loadLabRestrictedClinic() {
      const params = {
        entityId: '',
        search: '',
      };
      const { data } = this.isMyEntityView
        ? await MyEntityService.findLabRestrictedClinic(params)
        : await EntityService.findLabRestrictedClinic(params);
      if (this.isMyEntityView) {
        return sortDropDown(
          data?.result.map((item) => {
            return {
              value: item.id,
              label: item.text,
              clinicId: item.id,
            };
          }),
        );
      } else {
        return sortDropDown(
          data.map((item) => {
            return {
              value: item.id,
              label: item.text,
              clinicId: item.id,
            };
          }),
        );
      }
    },

    /*------------------ load data Restricted Pathologist Dropdown --------------*/
    async loadLabRestrictedPathologist() {
      const params = {
        entityId: '',
        search: '',
      };
      const { data } = this.isMyEntityView
        ? await MyEntityService.findLabRestrictedPathologist(params)
        : await EntityService.findLabRestrictedPathologist(params);
      return sortDropDown(
        data.map((item) => {
          return {
            value: item.id,
            label: item.text,
            userId: item.id,
          };
        }),
      );
    },

    compareArray(arr1, array2) {
      return arr1.filter((a1) => !array2.some((a2) => a1.value === a2.userId));
    },

    /*------------------ Restricted Clinician  --------------*/
    valClinicianSelected() {
      if (this.labRestrictedClinicianDropdown.length == 0) {
        this.isDisableAddClinicianBtn = true;
      } else {
        this.isDisableAddClinicianBtn = false;
      }
      this.labRestrictedClinicianList = this.compareArray(
        this.labRestrictedClinicianList,
        this.labRestrictedClinicianDropdown,
      );
    },

    addLabRestrictedClinician() {
      if (!this.isDisableAddClinicianBtn) {
        this.labRestrictedClinicianDropdown.forEach((item, index) => {
          this.formData.labRestriction.labRestrictedClinicians.push({
            userId: item.value,
            displayOrder: index + 1,
            comment: this.clinicianComments,
            fullname: item.label,
          });
        });
        (this.labRestrictedClinicianDropdown = []),
          (this.clinicianComments = ''),
          (this.isDisableAddClinicianBtn = true);

        this.labRestrictedClinicianList = this.compareArray(
          this.labRestrictedClinicianListTemp,
          this.formData.labRestriction.labRestrictedClinicians,
        );
      }
    },

    delLabRestrictedClinician(item) {
      const idx = this.formData.labRestriction.labRestrictedClinicians.findIndex(
        (labRestrictedClinician) => labRestrictedClinician.userId === item.userId,
      );
      this.formData.labRestriction.labRestrictedClinicians.splice(idx, 1);

      this.labRestrictedClinicianList = this.compareArray(
        this.labRestrictedClinicianListTemp,
        this.formData.labRestriction.labRestrictedClinicians,
      );
    },

    /*------------------ Restricted Clinic  --------------*/

    valClinicSelected() {
      if (this.labRestrictedClinicDropdown.length == 0) {
        this.isDisableAddClinicBtn = true;
      } else {
        this.isDisableAddClinicBtn = false;
      }
      this.labRestrictedClinicList = this.labRestrictedClinicList.filter(
        (a1) => !this.labRestrictedClinicDropdown.some((a2) => a1.value === a2.clinicId),
      );
    },
    addLabRestrictedClinic() {
      if (!this.isDisableAddClinicBtn) {
        this.labRestrictedClinicDropdown.forEach((item, index) => {
          this.formData.labRestriction.labRestrictedClinics.push({
            clinicId: item.value,
            displayOrder: index + 1,
            comment: this.clinicComments,
            clinicName: item.label,
          });
        });
        (this.labRestrictedClinicDropdown = []), (this.clinicComments = ''), (this.isDisableAddClinicBtn = true);

        this.labRestrictedClinicList = this.labRestrictedClinicListTemp.filter(
          (a1) => !this.formData.labRestriction.labRestrictedClinics.some((a2) => a1.value === a2.clinicId),
        );
      }
    },

    delLabRestrictedClinic(item) {
      const idx = this.formData.labRestriction.labRestrictedClinics.findIndex(
        (labRestrictedClinic) => labRestrictedClinic.clinicId === item.clinic,
      );
      this.formData.labRestriction.labRestrictedClinics.splice(idx, 1);
      this.labRestrictedClinicList = this.labRestrictedClinicListTemp.filter(
        (a1) => !this.formData.labRestriction.labRestrictedClinics.some((a2) => a1.value === a2.clinicId),
      );
    },

    /*------------------ Restricted Pathologist  --------------*/
    valPathSelected() {
      if (this.labRestrictedPathologistDropdown.length == 0) {
        this.isDisableAddPathologistBtn = true;
      } else {
        this.isDisableAddPathologistBtn = false;
      }
      this.labRestrictedPathologistList = this.compareArray(
        this.labRestrictedPathologistList,
        this.labRestrictedPathologistDropdown,
      );
    },

    addLabRestrictedPathologist() {
      if (!this.isDisableAddPathologistBtn) {
        this.labRestrictedPathologistDropdown.forEach((item, index) => {
          this.formData.labRestriction.labRestrictedPathologists.push({
            userId: item.value,
            displayOrder: index + 1,
            comment: this.pathologistComments,
            fullname: item.label,
          });
        });
        (this.labRestrictedPathologistDropdown = []),
          (this.pathologistComments = ''),
          (this.isDisableAddPathologistBtn = true);

        this.labRestrictedPathologistList = this.compareArray(
          this.labRestrictedPathologistListTemp,
          this.formData.labRestriction.labRestrictedPathologists,
        );
      }
    },

    delLabRestrictedPathologist(item) {
      const idx = this.formData.labRestriction.labRestrictedPathologists.findIndex(
        (labRestrictedPathologist) => labRestrictedPathologist.userId === item.userId,
      );
      this.formData.labRestriction.labRestrictedPathologists.splice(idx, 1);

      this.labRestrictedPathologistList = this.compareArray(
        this.labRestrictedPathologistListTemp,
        this.formData.labRestriction.labRestrictedPathologists,
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <lims-block :cardText="false" class="list-page case-list">
    <div slot="blockContent" v-if="dataSource">
      <div class="toolbar-row">
        <div class="field-search">
          <advance-search
            :dataSource="dataSource"
            v-model="query.filters"
            :defaultValues="defaultValues"
            :fields="fields"
            @onResetFilters="onResetFilters"
          >
            <lims-row-per-page
              slot="rowsPerPage"
              :pageSize="query.pagination.pageSize"
              @pageSizeChanged="$pageSizeChanged"
            />
          </advance-search>
        </div>
      </div>
      <div class="md-layout lims-form-row">
        <legend-search></legend-search>
      </div>
      <lims-pagination :total-item="totalItem" :pagination="query.pagination"> </lims-pagination>
      <slide-list
        @action="onRowAction"
        @onExportTemplate="onExportTemplate"
        :slideList="items"
        @onReloadCaseSlide="onReloadCaseSlide"
      >
        <lims-pagination
          slot="pagination"
          ref="pager"
          :total-item="totalItem"
          :pagination="query.pagination"
        ></lims-pagination>
      </slide-list>
    </div>
  </lims-block>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { ConfigMixins, ListWithFilterMixins } from '@/core/mixins';
import { slideQuery } from '@/query';
import { buildFilterFromQuery, buildFilterQueryParams } from '@/query/utils';

import cloneDeep from 'lodash/cloneDeep';

import AdvanceSearch from '@/pages/Case/SlideManagement/List/AdvanceSearch.vue';
import LegendSearch from '@/pages/Case/SlideManagement/List/LegendSearch.vue';
import SlideList from '@/pages/Case/SlideManagement/List/SlideList.vue';
import LimsRowPerPage from '@/components/Lims/LimsRowPerPage';
import LimsPagination from '@/components/Lims/LimsPagination';
import {
  FORM_ACTIONS,
  NUMBER_OF_CHARACTERS_SEARCH,
  DROPDOWN_SHORT_NAME,
  CASE_LIST_DROPDOWN,
  EXPORT_TEMPLATE,
  EXPORT_FILENAME,
} from '@/core/constants';
import { caseSlideService, caseListService, DropdownService, caseFormService } from '@/services';
import { downloadBlob, extractFileNameFromResponseHeader, isDeepEqual, sortDropDown } from '@/core/helpers';
import { mappingLocationFlagOptionsToObject } from './slide.mixins';
import {
  FF_CaseStatusIds,
  FF_ClinicianIds,
  FF_ClinicIds,
  FF_ClinicTypeIds,
  FF_LaboratoryIds,
  FF_PathologistIds,
  FF_SlideBlocks,
  FF_SlideIds,
  FF_SlideTypes,
  FF_SpecimenTypeIds,
} from '@/query/filter-fields';

export default {
  components: {
    AdvanceSearch,
    LegendSearch,
    SlideList,
    LimsRowPerPage,
    LimsPagination,
  },
  mixins: [ConfigMixins, ListWithFilterMixins],
  async created() {
    const dataSource = await this.fetchData();
    this.$set(this, 'dataSource', dataSource);
    this.initState();
  },
  computed: {
    fields() {
      return slideQuery.filters.fields;
    },
    defaultValues() {
      return slideQuery.filters.defaultValues;
    },
    ...mapGetters('app/data', ['getDatasetByKey']),
  },
  data() {
    return {
      items: [],
      totalItem: null,
      query: null,
      dataSource: null,
    };
  },
  watch: {},
  methods: {
    // common methods
    ...mapActions('app/event', ['removeEvent']),
    ...mapActions('app/data', ['updateDataset']),
    async fetchData() {
      const [
        dropDownOptions,
        caseDropDownOptions,
        slideTypeList,
        slideListSource,
        locationFlagOptions,
        specimenTypeSource,
      ] = await Promise.all([
        DropdownService.getDropdownByShortNames([
          DROPDOWN_SHORT_NAME.CLINIC_TYPE,
          DROPDOWN_SHORT_NAME.SPICEMEN_TYPE,
          DROPDOWN_SHORT_NAME.CASE_SPECIMEN_BLOCK,
        ]),
        caseListService.getDropdown(
          [
            CASE_LIST_DROPDOWN.CASE_STATUS,
            CASE_LIST_DROPDOWN.CLINIC,
            CASE_LIST_DROPDOWN.CLINICIAN,
            CASE_LIST_DROPDOWN.LABORATORY,
            CASE_LIST_DROPDOWN.PATHOLOGIST,
          ],
          true,
        ),
        this.loadSlideTypeList(),
        this.loadSlideList(),
        caseSlideService.getSlideLocationFlags(),
        this.mappingSpecimenTypeList(),
      ]);
      // mapping dataSource here
      const dataSource = {};
      Reflect.set(dataSource, FF_ClinicTypeIds, dropDownOptions[DROPDOWN_SHORT_NAME.CLINIC_TYPE]);
      //Reflect.set(dataSource, FF_SpecimenTypeIds, dropDownOptions[DROPDOWN_SHORT_NAME.SPICEMEN_TYPE]);
      Reflect.set(dataSource, FF_SlideBlocks, dropDownOptions[DROPDOWN_SHORT_NAME.CASE_SPECIMEN_BLOCK]);
      Reflect.set(dataSource, FF_CaseStatusIds, caseDropDownOptions[CASE_LIST_DROPDOWN.CASE_STATUS]);
      Reflect.set(dataSource, FF_ClinicIds, sortDropDown(caseDropDownOptions[CASE_LIST_DROPDOWN.CLINIC], 'text'));
      Reflect.set(dataSource, FF_ClinicianIds, sortDropDown(caseDropDownOptions[CASE_LIST_DROPDOWN.CLINICIAN], 'text'));
      Reflect.set(
        dataSource,
        FF_LaboratoryIds,
        sortDropDown(caseDropDownOptions[CASE_LIST_DROPDOWN.LABORATORY], 'text'),
      );
      Reflect.set(
        dataSource,
        FF_PathologistIds,
        sortDropDown(caseDropDownOptions[CASE_LIST_DROPDOWN.PATHOLOGIST], 'text'),
      );
      Reflect.set(dataSource, FF_SlideIds, slideListSource.options);
      Reflect.set(dataSource, `group${FF_SlideIds}`, slideListSource.groupOptions);
      Reflect.set(dataSource, FF_SlideTypes, slideTypeList);
      Reflect.set(
        dataSource,
        DROPDOWN_SHORT_NAME.CASE_SLIDE_LOCATION_FLAG,
        Reflect.get(locationFlagOptions, DROPDOWN_SHORT_NAME.CASE_SLIDE_LOCATION_FLAG),
      );
      Reflect.set(dataSource, FF_SpecimenTypeIds, specimenTypeSource.options);
      Reflect.set(dataSource, `group${FF_SpecimenTypeIds}`, specimenTypeSource.groupOptions);

      // save datasource to state
      this.updateDataset({
        ...dropDownOptions,
        ...caseDropDownOptions,
        ...dataSource,
        ...locationFlagOptions,
      });

      return dataSource;
    },
    async loadSlideList() {
      const data = await caseFormService.getStainByEntityId(null, [
        DROPDOWN_SHORT_NAME.H_AND_E,
        DROPDOWN_SHORT_NAME.IMMUNOS,
        DROPDOWN_SHORT_NAME.SPECIAL_STAINS,
        DROPDOWN_SHORT_NAME.ADDITIONAL_TECHNIQUE,
      ]);
      // unify data
      const options = [];
      const groupOptions = data.map((item) => {
        const { items, ...rest } = item;
        const groupItems = items.map((slide) => {
          const { fieldItemName, fieldItemId, ...restItems } = slide;
          return {
            value: fieldItemId,
            label: fieldItemName,
            ...restItems,
          };
        });
        options.push(...groupItems);
        return {
          groupItems,
          ...rest,
        };
      });
      return {
        groupOptions,
        options,
      };
    },

    async loadSlideTypeList() {
      const data = await caseFormService.getStainByEntityId(null, [DROPDOWN_SHORT_NAME.CASE_SLIDE_TYPE]);
      return data[0].items.map((item) => {
        return {
          value: item.id,
          label: item.text,
        };
      });
    },

    async mappingSpecimenTypeList() {
      const groupOptions = [];
      const options = [];

      const specimenTypeListReq = await DropdownService.getDropdownByShortNameTemporary([
        DROPDOWN_SHORT_NAME.SPECIMEN_TYPE_PER_WORK_STREAM,
      ]);
      const specimenTypeList = specimenTypeListReq.data || [];

      specimenTypeList.forEach((specimenTypeItem) => {
        const { itemId, itemName, items } = specimenTypeItem;
        let fullPropItemList = items.map((item) => {
          return {
            ...item,
            value: `${item.fieldItemId}_${itemId}`,
            label: item.fieldItemName,
          };
        });
        groupOptions.push({
          itemName,
          groupItems: fullPropItemList,
        });
      });
      groupOptions.forEach((i) => {
        options.push(...i.groupItems);
      });
      return { groupOptions, options };
    },

    initState() {
      const queryParams = this.$route.query;
      const filters = buildFilterFromQuery(slideQuery.filters, queryParams);
      const sort = buildFilterFromQuery(slideQuery.sort, queryParams);
      const pagination = buildFilterFromQuery(slideQuery.pagination, {
        pageSize: this.$appConfig.itemPerPageDefault,
        ...queryParams,
      });
      const query = { filters, pagination, sort };
      this.query = query;
    },

    async search(queryObject) {
      const queryObjNew = cloneDeep(queryObject);
      if (
        queryObjNew.filters.search.length > 0 &&
        queryObjNew.filters.search.length < NUMBER_OF_CHARACTERS_SEARCH &&
        !queryObjNew.filters.onSearch
      ) {
        return;
      }

      const locationFlagOptions = Reflect.get(this.dataSource, DROPDOWN_SHORT_NAME.CASE_SLIDE_LOCATION_FLAG);
      const LOCATION_FLAG = mappingLocationFlagOptionsToObject(locationFlagOptions, true);

      const { error, data } = await caseSlideService.getCaseSlideList(
        {
          filters: buildFilterQueryParams(queryObject.filters),
          pagination: buildFilterQueryParams({
            pageSize: queryObject.pagination.pageSize,
            pageNumber: queryObject.pagination.pageNumber,
          }),
        },
        LOCATION_FLAG,
      );
      if (error) {
        this.$alertErrorServerSide(error);
        return;
      }
      this.items = data.slides.items;

      this.totalItem = data.slides.pagination.totalItems;

      // update router
      const query = {
        ...buildFilterQueryParams(queryObject.filters),
        ...buildFilterQueryParams(queryObject.pagination),
      };
      if (!isDeepEqual(query, this.$route.query)) {
        this.$router
          .push({
            query,
          })
          .catch((error) => {
            if (
              error.name === 'NavigationDuplicated' &&
              error.message.includes('Avoided redundant navigation to current location')
            ) {
              history.pushState({}, '', new URL(new URL(window.location)));
            }
          });
      }
    },
    onResetFilters() {
      this.query.filters = cloneDeep(slideQuery.filters.defaultValues);
    },
    onRowAction({ action, item }) {
      const params = {
        slideId: item.slide.fieldItemId,
        caseSpecimenBlockId: item.caseSpecimenBlock.caseSpecimenBlockId,
        CaseAlwRequestId: item.caseAlwRequestId ? item.caseAlwRequestId : 'null',
        CaseSpecimenId: item.caseSpecimenBlock.caseSpecimenId,
        SlideTypeName: item.typeName,
      };
      if (action === FORM_ACTIONS.EDIT) {
        this.$router.push({
          name: 'SlideEdit',
          params,
        });
      }
      if (action === FORM_ACTIONS.VIEW) {
        this.$router.push({
          name: 'SlideView',
          params,
        });
      }
    },

    onReloadCaseSlide() {
      this.search(this.query);
    },

    async onExportTemplate({ template, dataExport }) {
      if (this.selectedRows && this.selectedRows.length === 0) {
        this.$alertWarning(this.$t('pages/case/CaseManagement/List/ExportAction/SelectAtLeastOneCase'));
      } else {
        const locationFlagOptions = Reflect.get(this.dataSource, DROPDOWN_SHORT_NAME.CASE_SLIDE_LOCATION_FLAG);
        const LOCATION_FLAG = mappingLocationFlagOptionsToObject(locationFlagOptions, true);
        const queryObject = cloneDeep(this.query);
        const queryParamsToExports = {
          filters: buildFilterQueryParams(queryObject.filters),
          pagination: buildFilterQueryParams({
            pageSize: queryObject.pagination.pageSize,
            pageNumber: queryObject.pagination.pageNumber,
          }),
        };

        if (template && template == EXPORT_TEMPLATE.SlideP1000Pathologist) {
          const res = await caseSlideService.exports(
            'slides/export-slides-p1000-path',
            queryParamsToExports,
            dataExport,
            LOCATION_FLAG,
          );
          const fileName = extractFileNameFromResponseHeader(res.headers);
          if (res.err) {
            const responseError = (await res.data.text()) ? JSON.parse(await res.data.text()) : null;
            if (responseError && responseError.error === 'Too many slides to export!') {            
              return this.$alertError(this.$t('pages/case/CaseManagement/List/ExportAction/SlideP1000/ExceedError'));
            }
            return this.$alertError(responseError?.error ? responseError.error : res.err);
          } else {
            if (res.data) {
              downloadBlob(res.data, fileName || EXPORT_FILENAME.SLIDE_P1000_PATHOLOGIST);
              this.$alertSuccess(this.$t('pages/case/CaseManagement/List/ExportAction/SlideP1000Pathologist/Success'));
            }
          }
        }
        if (template && template == EXPORT_TEMPLATE.SlideP1000Clinic) {
          const res = await caseSlideService.exports(
            'slides/export-slides-p1000-clinic',
            queryParamsToExports,
            dataExport,
            LOCATION_FLAG,
          );
          const fileName = extractFileNameFromResponseHeader(res.headers);
          if (res.err) {
            const responseError = (await res.data.text()) ? JSON.parse(await res.data.text()) : null;
            if (responseError && responseError.error === 'Too many slides to export!') {            
              return this.$alertError(this.$t('pages/case/CaseManagement/List/ExportAction/SlideP1000/ExceedError'));
            }
            return this.$alertError(responseError?.error ? responseError.error : res.err);
          } else {
            if (res.data) {
              downloadBlob(res.data, fileName || EXPORT_FILENAME.SLIDE_P1000_CLINIC);
              this.$alertSuccess(this.$t('pages/case/CaseManagement/List/ExportAction/SlideP1000Clinic/Success'));
            }
          }
        }
        if (template && template == EXPORT_TEMPLATE.SlideP1000CaseReference) {
          const res = await caseSlideService.exports(
            'slides/export-slides-p1000-case-reference',
            queryParamsToExports,
            dataExport,
            LOCATION_FLAG,
          );
          const fileName = extractFileNameFromResponseHeader(res.headers);
          if (res.err) {
            const responseError = (await res.data.text()) ? JSON.parse(await res.data.text()) : null;
            if (responseError && responseError.error === 'Too many slides to export!') {            
              return this.$alertError(this.$t('pages/case/CaseManagement/List/ExportAction/SlideP1000/ExceedError'));
            }
            return this.$alertError(responseError?.error ? responseError.error : res.err);
          } else {
            if (res.data) {
              downloadBlob(res.data, fileName || EXPORT_FILENAME.SLIDE_P1000_CASE_REFERENCE);
              this.$alertSuccess(
                this.$t('pages/case/CaseManagement/List/ExportAction/SlideP1000CaseReference/Success'),
              );
            }
          }
        }
        if (template && template == EXPORT_TEMPLATE.ReturnsEmail) {
          if (queryObject.filters.SlideReturnToClinicDate) {
            const res = await caseSlideService.exports(
              'slides/export-returns-email',
              queryParamsToExports,
              dataExport,
              LOCATION_FLAG,
            );
            const fileName = extractFileNameFromResponseHeader(res.headers);
            if (res.err) {
              const responseError = (await res.data.text()) ? JSON.parse(await res.data.text()) : null;
              return this.$alertError(responseError?.error ? responseError.error : res.err);
            } else {
              if (res.data) {
                downloadBlob(res.data, fileName || EXPORT_FILENAME.RETURNS_EMAIL);
                this.$alertSuccess(this.$t('pages/case/CaseManagement/List/ExportAction/ReturnsEmail/Success'));
              }
            }
          } else {
            this.$alertWarning(this.$t('pages/case/CaseManagement/List/ExportAction/SelectReturnedToClinicDate'));
          }
        }
        if (template && template == EXPORT_TEMPLATE.ReturnsPrint) {
          if (queryObject.filters.SlideReturnToClinicDate) {
            const res = await caseSlideService.exports(
              'slides/export-returns-print',
              queryParamsToExports,
              dataExport,
              LOCATION_FLAG,
            );
            const fileName = extractFileNameFromResponseHeader(res.headers);
            if (res.err) {
              const responseError = (await res.data.text()) ? JSON.parse(await res.data.text()) : null;
              return this.$alertError(responseError?.error ? responseError.error : res.err);
            } else {
              if (res.data) {
                downloadBlob(res.data, fileName || EXPORT_FILENAME.RETURNS_PRINT);
                this.$alertSuccess(this.$t('pages/case/CaseManagement/List/ExportAction/ReturnsPrint/Success'));
              }
            }
          } else {
            this.$alertWarning(this.$t('pages/case/CaseManagement/List/ExportAction/SelectReturnedToClinicDate'));
          }
        }
        if (template && template == EXPORT_TEMPLATE.ReturnsSummary) {
          const res = await caseSlideService.exports(
            'slides/export-returns-summary',
            queryParamsToExports,
            dataExport,
            LOCATION_FLAG,
          );
          const fileName = extractFileNameFromResponseHeader(res.headers);
          if (res.err) {
            const responseError = (await res.data.text()) ? JSON.parse(await res.data.text()) : null;
            return this.$alertError(responseError?.error ? responseError.error : res.err);
          } else {
            if (res.data) {
              downloadBlob(res.data, fileName || EXPORT_FILENAME.RETURNS_SUMMARY);
              this.$alertSuccess(this.$t('pages/case/CaseManagement/List/ExportAction/ReturnsSummary/Success'));
            }
          }
        }
      }
    },
  },
};
</script>
<style lang="scss"></style>

import DropDown from './components/Dropdown.vue';
import { ValidationProvider } from 'vee-validate';
import { ValidationObserver } from 'vee-validate';

// External plugins
import vSelect from 'vue-select';
// vSelect.props.components.default = () => ({
//   Deselect: {
//     render: (createElement) => createElement('span', '❌'),
//   },
//   OpenIndicator: {
//     render: (createElement) => createElement('span', '🔽'),
//   },
// });
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import VueSplit from 'vue-splitjs';
// Lims Components
import LimsBlock from './components/Lims/LimsBlock.vue';
import LimsFormError from './components/Lims/LimsFormError.vue';
import LimsField from './components/Lims/LimsField.vue';
import LimsSingleSelect from '@/components/Lims/LimsSingleSelect.vue';
import LimsCustomSelect from '@/components/Lims/LimsCustomSelect.vue';
import LimsFormCancel from '@/components/Lims/LimsFormCancel.vue';
import LimsTooltip from '@/components/Lims/LimsTooltip.vue';
import LimsCollapseCustomTitle from '@/components/Lims/LimsCollapseCustomTitle.vue';
import LimsDatePicker from '@/components/Lims/LimsDatePicker.vue';
/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component('drop-down', DropDown);
    Vue.component('ValidationProvider', ValidationProvider);
    Vue.component('ValidationObserver', ValidationObserver);
    Vue.component('v-select', vSelect);
    Vue.component('multiselect', Multiselect);
    Vue.component('vue-dropzone', vue2Dropzone);
    Vue.component('date-picker', DatePicker);
    // Lims Components
    Vue.component('lims-block', LimsBlock);
    Vue.component('lims-form-error', LimsFormError);
    Vue.component('lims-field', LimsField);
    Vue.component('lims-single-select', LimsSingleSelect);
    Vue.component('lims-custom-select', LimsCustomSelect);
    Vue.component('lims-form-cancel', LimsFormCancel);
    Vue.component('lims-tooltip', LimsTooltip);
    Vue.component('lims-collapse-custom-title', LimsCollapseCustomTitle);
    Vue.component('vue-split', VueSplit);
    Vue.component('lims-date-picker', LimsDatePicker);
  },
};

export default GlobalComponents;

<template>
  <div>
    <modal-print-label-preview slot="blockTitle" ref="printLabelPreviewModal"></modal-print-label-preview>
    <modal-bulk-action-commom
      ref="bulkActionCommonModal"
      slot="blockTitle"
      :bulkActionSelect="bulkAction"
      :compatibleCase="compatibleCase"
      :notCompatibleCase="notCompatibleCase"
      @onCompletedAction="clearSelectedData"
    ></modal-bulk-action-commom>
    <modal-bulk-action-problem
      ref="bulkActionProblemModal"
      slot="blockTitle"
      :bulkActionSelect="bulkAction"
      :compatibleCase="compatibleCase"
      :notCompatibleCase="notCompatibleCase"
      @onContinue="onContinue"
    ></modal-bulk-action-problem>
    <modal-discard-tissue
      v-if="bulkAction"
      ref="discardTissueModal"
      slot="blockTitle"
      :bulkActionSelect="bulkAction"
      :compatibleCase="compatibleCase"
      @onCompletedAction="clearSelectedData"
    ></modal-discard-tissue>
    <div class="toolbar-row">
      <div>
        <md-checkbox v-model="isSelectAllPageMode" class="lims-checkbox">{{
          $t('pages/case/CaseManagement/masterCheckBox/isSelectAllPageMode')
        }}</md-checkbox>
        <md-checkbox v-model="isSelectCurrentPageMode" class="lims-checkbox">{{
          $t('pages/case/CaseManagement/masterCheckBox/isSelectCurrentPageMode')
        }}</md-checkbox>
      </div>
      <div v-if="!isCaseOfInterest && caseList && caseList.length > 0" class="select-action">
        <v-select
          class="action-dropdown"
          v-model="bulkAction"
          :options="bulkActionHasPermission"
          :reduce="(item) => item.value"
          :placeholder="$t('pages/case/CaseManagement/actionDropdown/selectAction.placeholder')"
        >
          <template #option="{ label }">{{ $t(label) }}</template>
          <template #selected-option="{ label }">{{ $t(label) }}</template>
        </v-select>
        <md-button @click="onApply" class="apply-btn" :disabled="isBulkActionApplyDisabled || isProcessingBulkAction">{{
          $t('global/button/button.apply')
        }}</md-button>
      </div>
      <div
        v-if="caseList && caseList.length > 0 && this.$isAuthorized(['Case_CaseExport_Retrieve'])"
        class="select-action export-template"
      >
        <v-select
          class="action-dropdown"
          v-model="templateExport"
          :options="exportTemplateList"
          :reduce="(item) => item.value"
          :placeholder="$t('pages/case/CaseManagement/actionDropdown/exportTemplate.placeholder')"
        >
          <template #option="{ label }">{{ $t(label) }}</template>
          <template #selected-option="{ label }">{{ $t(label) }}</template>
        </v-select>
        <md-button
          @click="onExportTemplate(templateExport)"
          :disabled="!templateExport || isProcessingExportTemplate"
          class="md-button lims-form-button md-theme-default apply-btn"
          >{{ $t('global/button/button.export') }}</md-button
        >
      </div>
    </div>
    <md-table class="paginated-table table-striped table-hover lims-table case-list-tbl">
      <div class="table-fix-header">
        <thead>
          <md-table-row>
            <md-table-head class="checkbox-column">
              <md-checkbox v-model="selectAll" :indeterminate="isIndeterminate"></md-checkbox>
            </md-table-head>
            <md-table-head v-for="(col, index) in colTabl" :key="index" :class="col.class">
              <div @mouseover="hoverIn(index)" @mouseout="hoverOut()" v-html="getColumnName(col)"></div>
            </md-table-head>
          </md-table-row>
        </thead>
        <tbody>
          <md-table-row
            v-for="(item, index) in caseList"
            :key="`case-${index}`"
            v-long-press="300"
            @click.native="onNavigate($event, item)"
            @long-press-start="onPlusStart"
            @long-press-stop="onPlusStop"
            :class="checkRowHighlight(item)"
          >
            <md-table-cell class="checkbox-column">
              <md-checkbox
                v-model="selectRow"
                @change="$onSelectItem(selectRow, item.caseId)"
                :value="item.caseId"
                class="lims-checkbox"
              ></md-checkbox>
            </md-table-cell>
            <md-table-cell class="caseRef-column">
              {{ item.caseReference }}
              <md-button v-if="item.caseIssues.length > 0" class="md-just-icon md-warning md-simple">
                <issue-information :case-issues="item.caseIssues"></issue-information>
              </md-button>
              <br />
              {{ item.labReference }}
            </md-table-cell>
            <md-table-cell>{{ item.patientFullname }}</md-table-cell>
            <md-table-cell>{{ item.clinicName }}<br />{{ item.clinicianName }}</md-table-cell>
            <md-table-cell>{{ item.pathologistName }}</md-table-cell>
            <md-table-cell>{{ item.specimenName }}</md-table-cell>
            <md-table-cell class="date-column">
              {{ item.procedureDate ? formatDateTimeGlobalNotIncludeTime(item.procedureDate, true) : '' }}
            </md-table-cell>
            <md-table-cell class="date-column">
              {{ item.procedureLabEntry ? formatDateTimeGlobalNotIncludeTime(item.procedureLabEntry) : '' }}
            </md-table-cell>
            <md-table-cell class="status-column">
              <md-icon v-if="item.statusId == STATUSES.LAB" class="black-color">hourglass_empty</md-icon>
              <md-icon v-if="item.statusId == STATUSES.PATH" class="black-color">hourglass_top</md-icon>
              <md-icon v-if="item.statusId == STATUSES.PROVISIONALLY_REPORTED" class="grey-color"
                >hourglass_bottom</md-icon
              >
              <md-icon v-if="item.statusId == STATUSES.REPORTED" class="black-color">hourglass_bottom</md-icon>
            </md-table-cell>
            <md-table-cell class="actions-column">
              <div class="cell-action-inner">
                <div style="width: 25px">
                  <md-button class="md-just-icon md-info md-simple" @click.native="handleAction('view', item)">
                    <md-icon>visibility</md-icon>
                  </md-button>
                </div>
                <div v-if="isShowEditIcon(item)" style="width: 25px">
                  <md-button class="md-just-icon md-warning md-simple" @click.native="handleAction('edit', item)">
                    <md-icon>edit</md-icon>
                  </md-button>
                </div>
                <div v-if="isShowDeleteIcon(item)" style="width: 25px">
                  <md-button class="md-just-icon md-danger md-simple" @click.stop="handleAction('delete', item)">
                    <md-icon>delete_forever</md-icon>
                  </md-button>
                </div>
              </div>
            </md-table-cell>
          </md-table-row>
        </tbody>
      </div>
    </md-table>
    <slot name="pagination"></slot>
  </div>
</template>
<script>
import { ModalBulkActionCommom, ModalBulkActionProblem } from '@/components/Lims/modals';
import ModalDiscardTissue from '@/components/Lims/modals/ModalDiscardTissue.vue';
import CaseListMixin from '@/pages/Case/CaseManagement/List/caseList.mixins';
import { BULK_ACTION, EXPORT_TEMPLATE } from '@/core/constants';
import IssueInformation from '@/pages/Case/CaseManagement/Components/IssueInformation';
import { ModalPrintLabelPreview } from '@/components/Lims/modals';
import { MasterCheckBoxMixins } from '@/core/mixins';
import { LIST_NAMES } from '@/store/modules/item-list';
import { mapGetters } from 'vuex';

export default {
  name: 'CaseListLabTechnician',
  mixins: [CaseListMixin, MasterCheckBoxMixins],
  components: {
    IssueInformation,
    ModalBulkActionCommom,
    ModalBulkActionProblem,
    ModalDiscardTissue,
    ModalPrintLabelPreview,
  },
  props: {
    isCaseOfInterest: {
      require: false,
      type: Boolean,
    },
    caseList: {
      required: false,
      default: function () {
        return [];
      },
    },
    totalItem: {
      require: false,
      default: 0,
    },
  },
  data() {
    return {
      listName: LIST_NAMES.CASE_LIST_LABTECHNICIAN,
      selectRow: [],
      selectRowInfo: [],
      bulkAction: '',
      compatibleCase: [],
      notCompatibleCase: {},
      templateExport: '',
      colTabl: [
        {
          name: ['pages.case.caseManagement/list.caseRef', 'pages.case.caseManagement/list.labRef'],
          key: 'caseReference',
          sort: false,
          class: 'caseRef-column',
        },
        {
          name: 'pages.case.caseManagement/list.patient',
          key: 'patientFullname',
          sort: false,
          class: 'patient-column',
        },
        {
          name: ['pages.case.caseManagement/list.clinic', 'pages.case.caseManagement/list.clinician'],
          key: 'clinicName',
          sort: false,
          class: 'clinic-column',
        },
        {
          name: 'pages.case.caseManagement/list.pathologist',
          key: 'pathologistName',
          sort: false,
          class: 'pathologist-column',
        },
        {
          name: 'pages.case.caseManagement/list.specimenType',
          key: 'specimenName',
          sort: false,
          class: 'specimen-column',
        },
        {
          name: 'pages.case.caseManagement/list.Procedure',
          key: 'procedureDate',
          sort: false,
          class: 'date-column',
        },
        {
          name: 'pages.case.caseManagement/list.labEntry',
          key: 'procedureLabEntry',
          sort: false,
          class: 'date-column',
        },
        {
          name: 'pages.case.caseManagement/list.status',
          key: 'statusName',
          sort: false,
          class: 'status-column',
        },
        {
          name: 'global/pages/list.actions',
          key: 'actions',
          sort: false,
          class: 'actions-column',
        },
      ],
      hover: '',
      orderState: {
        orderField: 'caseReference',
        orderBy: 'asc',
      },
      bulkActionList: [
        {
          value: BULK_ACTION.UploadRF,
          label: 'entities/case/bulkActionName.UploadRF',
          permission: ['Case_CaseForm_Update'],
        },
        {
          value: BULK_ACTION.RemoveRF,
          label: 'entities/case/bulkActionName.RemoveRF',
          permission: ['Case_CaseForm_Update'],
        },
        {
          value: BULK_ACTION.ReviewCase,
          label: 'entities/case/bulkActionName.ReviewCase',
          permission: ['Case_CaseForm_Validate'],
        },
        {
          value: BULK_ACTION.SBImport,
          label: 'entities/case/bulkActionName.SBImport',
          permission: ['Case_CaseForm_Update'],
        },
        {
          value: BULK_ACTION.DiscardTissue,
          label: 'entities/case/bulkActionName.DiscardTissue',
          permission: ['Case_CaseForm_Update'],
        },
      ],
      exportTemplateList: [
        {
          value: EXPORT_TEMPLATE.CompleteFile,
          label: 'entities/case/exportTemplateName.CompleteFile',
        },
        {
          value: EXPORT_TEMPLATE.MinimalFile,
          label: 'entities/case/exportTemplateName.MinimalFile',
        },
        {
          value: EXPORT_TEMPLATE.LabStatsFile,
          label: 'entities/case/exportTemplateName.LabStatsFile',
        },
        {
          value: EXPORT_TEMPLATE.TissueRemaining,
          label: 'entities/case/exportTemplateName.TissueRemaining',
        },
        {
          value: EXPORT_TEMPLATE.PrintLabels,
          label: 'entities/case/exportTemplateName.PrintLabels',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('itemList', [LIST_NAMES.CASE_LIST_LABTECHNICIAN]),
    bulkActionHasPermission() {
      let listHasPermission = [];
      this.bulkActionList.forEach((item) => {
        if (this.$isAuthorized(item.permission)) {
          listHasPermission.push(item);
        }
      });
      return listHasPermission;
    },
  },
  watch: {
    [LIST_NAMES.CASE_LIST_LABTECHNICIAN]: {
      deep: true,
      handler: function (listState) {
        this.$toggleSelectAllCheckbox(listState);
      },
    },
    templateExport: {
      handler: function (val) {
        if (val && this.selectRow && this.selectRow.length === 0) {
          this.selectAll = true;
        }
      },
      deep: true,
    },
  },
  methods: {},
};
</script>

<style lang="scss"></style>

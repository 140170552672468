import { StatsCard, AnimatedNumber } from '@/components';
import { mapGetters } from 'vuex';

export default {
  components: {
    StatsCard,
    AnimatedNumber,
  },
  data() {
    return {
      userType: '',
    };
  },
  created() {
    this.userType = this.$store.getters['auth/userType'];
  },

  computed: {
    isAdminView() {
      return this.userType === this.USER_TYPES().Administrator;
    },
    isLabTechnicianView() {
      return this.userType === this.USER_TYPES().LabTechnician;
    },
    isPathologistView() {
      return this.userType === this.USER_TYPES().Pathologist;
    },
    ...mapGetters('config', ['$appConfig']),
  },
  methods: {
    $navigateToList(url) {
      const { path, query } = url;
      const pageSize = this.$appConfig.itemPerPageDefault;
      const pageNumber = 1;
      this.$router.push({ path, query: { ...query, pageSize, pageNumber } });
    },
  },
};

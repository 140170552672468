<template>
  <div>
    <modal-view-request ref="viewRequestModal" @onApprove="onApprove" @onReject="onReject"></modal-view-request>
    <modal-approve-request
      ref="aproveRequestModal"
      :dataView="dataView"
      @onContinue="approveRequest"
    ></modal-approve-request>
    <modal-reject-request
      ref="rejectRequestModal"
      :dataView="dataView"
      @onReject="rejectRequest"
    ></modal-reject-request>
    <modal-bulk-action-problem-request
      ref="bulkActionProblemRequestModal"
      :list-item-validated="listItemValidated"
      @onContinue="onContinue"
    ></modal-bulk-action-problem-request>
    <modal-bulk-action-request
      ref="bulkActionRequestModal"
      :bulkActionSelect="bulkAction"
      :bulk-action-item="itemValidatedSuccess"
      @validated="onValidateCompleted"
    ></modal-bulk-action-request>
    <md-table class="paginated-table table-striped table-hover lims-table case-list-tbl request-list">
      <md-table-toolbar>
        <slot name="toolbar"></slot>
        <div class="toolbar-row select-action" v-if="requestList && requestList.length > 0">
          <v-select
            class="action-dropdown"
            v-model="bulkAction"
            :options="$translateSelectOptions(bulkActionList)"
            :reduce="(item) => item.label"
            :placeholder="$t('pages/case/CaseManagement/actionDropdown/selectAction.placeholder')"
          >
            <template #option="{ label }">{{ label }}</template>
            <template #selected-option="{ label }">{{ label }}</template>
          </v-select>
          <md-button
            @click="onApply"
            class="md-button lims-form-button md-theme-default apply-btn"
            :disabled="selectRow.length === 0 || !bulkAction"
            >{{ $t('global/button/button.apply') }}</md-button
          >
        </div>
      </md-table-toolbar>
      <div class="table-fix-header">
        <thead>
          <md-table-row>
            <md-table-head class="checkbox-column">
              <md-checkbox v-model="selectAll"></md-checkbox>
            </md-table-head>
            <md-table-head v-for="(col, index) in colTabl" :key="index" :class="col.class">
              <div>{{ $t(col.name) }}</div>
            </md-table-head>
          </md-table-row>
        </thead>
        <tbody>
          <md-table-row
            v-for="(item, index) in requestList"
            :key="`request-${index}`"
            v-long-press="300"
            @click.native="onNavigate($event, item)"
            @long-press-start="onPlusStart"
            @long-press-stop="onPlusStop"
          >
            <md-table-cell class="checkbox-column">
              <md-checkbox
                v-if="item.status === 'Requested'"
                v-model="selectRow"
                :value="item"
                class="lims-checkbox"
              ></md-checkbox>
            </md-table-cell>
            <md-table-cell>{{ item.caseReference }}</md-table-cell>
            <md-table-cell>{{ item.labRef }}</md-table-cell>
            <md-table-cell>{{ item.requesterTypeName }}</md-table-cell>
            <md-table-cell>{{ item.requesterName }}</md-table-cell>
            <md-table-cell>{{ item.requestDate ? formatDateTime(item.requestDate) : '' }}</md-table-cell>
            <md-table-cell class="comment-column">
              <md-button class="md-just-icon md-simple">
                <md-icon>info</md-icon>
                <md-tooltip class="tooltip-align-left">{{ item.comment }}</md-tooltip>
              </md-button>
            </md-table-cell>
            <md-table-cell class="status-column">
              <md-icon
                :class="[
                  { 'status-invited': item.status === 'Requested' },
                  { 'status-enabled': item.status === 'Approved' },
                  { 'status-disabled': item.status === 'Rejected' },
                ]"
                >adjust</md-icon
              >
            </md-table-cell>
            <md-table-cell class="cell-action">
              <div class="cell-action-inner">
                <div style="width: 30px">
                  <md-button class="md-just-icon md-info md-simple" @click.native="onView(item)">
                    <md-icon>visibility</md-icon>
                  </md-button>
                </div>
                <div style="width: 30px">
                  <md-button
                    v-if="isShowApproveIcon(item)"
                    class="md-just-icon md-warning md-simple"
                    @click.stop="onApprove(item)"
                  >
                    <md-icon>check</md-icon>
                  </md-button>
                </div>
                <div style="width: 30px">
                  <md-button
                    v-if="isShowRejectIcon(item)"
                    class="md-just-icon md-danger md-simple"
                    @click.stop="onReject(item)"
                  >
                    <md-icon>close</md-icon>
                  </md-button>
                </div>
              </div>
            </md-table-cell>
          </md-table-row>
        </tbody>
      </div>
    </md-table>
  </div>
</template>

<script>
import { FormMixins } from '@/core/mixins';
import { DateTimeHelper } from '@/core/helpers';
import { BULK_ACTION } from '@/core/constants';
import ModalViewRequest from '@/components/Lims/modals/ModalViewRequest.vue';
import ModalApproveRequest from '@/components/Lims/modals/ModalApproveRequest.vue';
import ModalRejectRequest from '@/components/Lims/modals/ModalRejectRequest.vue';
import ModalBulkActionProblemRequest from '@/components/Lims/modals/ModalBulkActionProblemRequest.vue';
import ModalBulkActionRequest from '@/components/Lims/modals/ModalBulkActionRequest.vue';
import { caseAnonymiseRequestService } from '@/services';

export default {
  mixins: [FormMixins],
  components: {
    ModalViewRequest,
    ModalApproveRequest,
    ModalRejectRequest,
    ModalBulkActionProblemRequest,
    ModalBulkActionRequest,
  },
  props: {
    requestList: {
      required: false,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      longPressMode: false,
      plusInterval: null,
      selectRow: [],
      itemValidatedSuccess: [],
      bulkAction: '',
      dataView: '',
      listItemValidated: [],
      password: null,
      colTabl: [
        {
          name: 'pages.request/list.caseRef',
          key: 'caseRef',
          sort: false,
          class: 'caseRef-column',
        },
        {
          name: 'pages.request/list.labRef',
          key: 'labRef',
          sort: false,
          class: 'labRef-column',
        },
        {
          name: 'pages.request/list.requesterType',
          key: 'requesterType',
          sort: false,
          class: 'requesterType-column',
        },
        {
          name: 'pages.request/list.requester',
          key: 'requester',
          sort: false,
          class: 'requester-column',
        },
        {
          name: 'pages.request/list.requestDate',
          key: 'requestDate',
          sort: false,
          class: 'requestDate-column',
        },
        {
          name: 'pages.request/list.comment',
          key: 'comment',
          sort: false,
          class: 'comment-column',
        },
        {
          name: 'global/pages/list.status',
          key: 'statu',
          sort: false,
          class: 'status-column',
        },
        {
          name: 'global/pages/list.actions',
          key: 'actions',
          sort: false,
          class: 'actions-column',
        },
      ],
      bulkActionList: [
        {
          value: BULK_ACTION.Approve,
          label: 'entities/case/bulkActionName.Approve',
        },
        {
          value: BULK_ACTION.Reject,
          label: 'entities/case/bulkActionName.Reject',
        },
      ],
    };
  },
  computed: {
    selectAll: {
      get: function () {
        return this.requestList ? this.totalItemCanBeSelect === this.selectRow.length : false;
      },
      set: function (value) {
        var selected = [];
        if (value) {
          this.requestList.forEach(function (item) {
            if (item.status === 'Requested') {
              selected.push(item);
            }
          });
        }
        this.selectRow = selected;
      },
    },
    totalItemCanBeSelect() {
      return this.requestList.filter((item) => item.status === 'Requested').length;
    },
  },
  watch: {
    requestList: {
      handler: function (val) {
        if (val) {
          this.selectRow = [];
        }
      },
      deep: true,
    },
  },
  methods: {
    onNavigate(e, item) {
      if (!this.longPressMode) {
        e.preventDefault();
        this.onView(item);
      }
    },
    onPlusStart() {
      this.plusInterval = setInterval(() => {
        this.longPressMode = true;
      }, 100);
    },
    onPlusStop() {
      setTimeout(() => {
        this.longPressMode = false;
      }, 500);
      clearInterval(this.plusInterval);
    },
    formatDateTime(date) {
      const { timezoneValue, dateTimeAppFormat } = this.$store.getters['config/$appConfig'];
      return DateTimeHelper.formatDateTimeGlobal(date, {
        timezoneValue,
        dateTimeAppFormat,
      });
    },
    isShowApproveIcon(item) {
      return item.status === 'Requested';
    },
    isShowRejectIcon(item) {
      return item.status === 'Requested';
    },
    onView(item) {
      this.$refs.viewRequestModal.open(item);
      this.dataView = item;
    },
    async onApply() {
      if (this.selectRow) {
        let requestIds = [];
        this.selectRow.forEach((item) => {
          requestIds.push(item.caseAnonymiseRequestId);
        });
        const { error, data } = await caseAnonymiseRequestService.validate(requestIds, this.bulkAction);
        if (error) {
          this.$alertErrorServerSide(error);
        } else {
          if (data) {
            if (data.listFailure && data.listFailure.length > 0) {
              this.listItemValidated = data;
              this.$refs.bulkActionProblemRequestModal.open();
            } else {
              if (data.listSuccess && data.listSuccess.length > 0) {
                this.itemValidatedSuccess = data.listSuccess;
                this.$refs.bulkActionRequestModal.open();
              }
            }
          }
        }
      }
    },
    onApprove(item) {
      if (item) {
        this.dataView = item;
      }
      this.$refs.aproveRequestModal.open();
    },
    async approveRequest({ password, dataView }) {
      this.$refs.aproveRequestModal.close();
      const { error, data } = await caseAnonymiseRequestService.approve(dataView.caseAnonymiseRequestId, password);
      if (error) {
        this.$alertErrorServerSide(error);
      } else {
        if (data) {
          this.$alertSuccess(
            this.$t('components/lims/modals/ModalApproveRequest.approveSuccess', {
              userType: dataView.requesterTypeName,
              name: dataView.requesterName,
            }),
          );
        }
        setTimeout(() => {
          this.$router.go();
        }, 1000);
      }
    },
    onReject(item) {
      if (item) {
        this.dataView = item;
      }
      this.$refs.rejectRequestModal.open();
    },
    async rejectRequest({ comment, password, dataView }) {
      this.$refs.rejectRequestModal.close();
      const { error, data } = await caseAnonymiseRequestService.reject(
        comment,
        dataView.caseAnonymiseRequestId,
        password,
      );
      if (error) {
        this.$alertErrorServerSide(error);
      } else {
        if (data) {
          this.$alertSuccess(
            this.$t('components/lims/modals/ModalRejectRequest.rejectSuccess', {
              userType: dataView.requesterTypeName,
              name: dataView.requesterName,
            }),
          );
          setTimeout(() => {
            this.$router.go();
          }, 1000);
        }
      }
    },
    async onContinue(listItemValidatedSuccess) {
      this.$refs.bulkActionRequestModal.close();
      this.itemValidatedSuccess = listItemValidatedSuccess;
      this.$refs.bulkActionRequestModal.open();
    },
    onValidateCompleted() {
      this.$refs.bulkActionRequestModal.close();
      this.$alertSuccess(
        this.$t('components/ModalBulkActionCommom.completeActionSuccess', {
          bulkAction: this.bulkAction,
        }),
      );
      setTimeout(() => {
        this.$router.go();
      }, 1000);
    },
  },
};
</script>
<style lang="scss"></style>

<template>
  <ValidationObserver ref="form">
    <md-dialog :md-close-on-esc="true" :md-active.sync="isVisible" @md-clicked-outside="close" class="lims-modal">
      <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
      <md-dialog-title>{{ $t('components/lims/modals/ModalConfirmation.title') }}</md-dialog-title>
      <div
        class="text-center mg-top-24"
        v-html="$t('components/lims/modals/ModalReCallToLab.actionCannotBeUndone')"
      ></div>
      <div class="text-center mg-top-24" v-html="$t('components/lims/modals/ModalReCallToLab.provideReason')"></div>
      <div class="lims-form dialog-content">
        <lims-field class="textarea-field" :model="formData" :schema="checkCodeSchema" field="reason">
          <md-textarea slot="field" v-model="formData.reason" maxlength="1000"></md-textarea>
        </lims-field>
        <lims-field :model="formData" :schema="checkCodeSchema" field="passwordForCase">
          <md-input slot="field" ref="confirmPassword" v-model="formData.password" type="password"></md-input>
        </lims-field>
      </div>
      <md-dialog-actions>
        <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
        <md-button
          @click="onRecallToLab"
          :disabled="!formData.password || !formData.reason || isProcessing"
          class="lims-form-button md-danger"
          >{{ $t('global/button/button.recallToLab') }}</md-button
        >
      </md-dialog-actions>
    </md-dialog>
  </ValidationObserver>
</template>

<script>
import modalMixins from './modal.mixins';
import { getCheckCodeSchema } from '@/schemas/check-code.schema';

export default {
  mixins: [modalMixins],
  data() {
    return {
      formData: {
        reason: '',
      },
    };
  },
  computed: {
    checkCodeSchema() {
      return getCheckCodeSchema(this.isWrongPasswordError);
    },
  },
  methods: {
    onRecallToLab() {
      this.isProcessing = true;
      this.$emit('onRecallToLab', this.formData);
    },
    close() {
      this.isVisible = false;
      this.$nextTick(() => {
        this.formData = {
          reason: '',
        };
      });
    },
  },
};
</script>

<template>
  <div @mouseover="onHoverTooltip">
    <md-icon>report_problem</md-icon>
    <md-tooltip
      mdDirection="bottom"
      class="tooltip-align-left tooltip-modal"
      v-html="issueInformationData"
    ></md-tooltip>
  </div>
</template>

<script>
import CaseListMixin from '@/pages/Case/CaseManagement/List/caseList.mixins';

export default {
  name: 'IssueInformation',
  mixins: [CaseListMixin],
  props: {
    caseIssues: {
      type: Array,
      require: false,
    },
  },

  data() {
    return {
      issueInformationData: '',
    };
  },

  methods: {
    async onHoverTooltip() {
      if (!this.issueInformationData) {
        this.caseIssues.forEach((caseIssue) => {
          this.issueInformationData +=
            caseIssue.issueName + ' - ' + this.formatDateTimeGlobalNotIncludeTime(caseIssue.reportedAt) + '<br>';
        });
      }
    },
  },
};
</script>

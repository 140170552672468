var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('modal-document-viewer-resize',{ref:"documentViewerResizeModal",on:{"onResize":_vm.onResizeDocumentViewer}}),_c('div',{staticClass:"tab-container"},[_c('label',[_c('input',{attrs:{"type":"radio","name":"tab"}}),_c('span',[_vm._v(_vm._s(_vm.$t('pages/case/CaseManagement/components/DocumentViewer/tabSlide')))])]),_c('label',[_c('input',{attrs:{"type":"radio","name":"tab","checked":""}}),_c('span',[_vm._v(_vm._s(_vm.$t('pages/case/CaseManagement/components/DocumentViewer/tabDocument')))])]),_c('div',{staticClass:"tab-content"},[_c('div',{staticClass:"tab-content-item document-item",attrs:{"id":"c1"}},[(_vm.slideList.length > 0)?_c('div',{staticStyle:{"height":"100%"}},[_c('div',{staticClass:"select-document"},[_c('v-select',{attrs:{"options":_vm.slideList,"label":"title"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var title = ref.title;
return [_vm._v(_vm._s(title))]}},{key:"selected-option",fn:function(ref){
var title = ref.title;
return [_vm._v(_vm._s(title))]}}],null,false,529208845),model:{value:(_vm.slideItem),callback:function ($$v) {_vm.slideItem=$$v},expression:"slideItem"}})],1),_c('div',{staticClass:"slide-cont"},[_c('p',{staticClass:"staticTextInSlideView"},[_vm._v(" "+_vm._s(_vm.$t('pages/case/CaseManagement/components/DocumentViewer/staticTextInSlideView'))+" ")])]),_c('div',{staticClass:"control-button"},[_c('div',{staticClass:"icon-control",on:{"click":function($event){return _vm.onOpenBrowserTab(_vm.slideItem.link)}}},[_c('md-icon',[_vm._v("north_east")])],1)])]):_c('div',{staticClass:"no-slide"},[_vm._v(_vm._s(_vm.$t('pages/case/CaseManagement/components/DocumentViewer/noSlide')))])]),_c('div',{staticClass:"tab-content-item document-item",attrs:{"id":"c2"}},[_c('div',{staticClass:"select-document"},[_c('v-select',{attrs:{"options":_vm.documentList,"label":"fileName"},on:{"input":_vm.valDocumentView},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var title = ref.title;
return [_vm._v(_vm._s(title))]}},{key:"selected-option",fn:function(ref){
var title = ref.title;
return [_vm._v(_vm._s(title))]}}]),model:{value:(_vm.documentItem),callback:function ($$v) {_vm.documentItem=$$v},expression:"documentItem"}})],1),_c('div',{staticClass:"document-cont"},[_c('iframe',{attrs:{"src":_vm.$myPdfViewer(_vm.documentFile),"width":"100%","height":"100%"}})]),_c('div',{staticClass:"control-button"},[_c('div',{staticClass:"icon-control",on:{"click":function($event){return _vm.onOpenBrowserTab(_vm.documentFile.fileUrl)}}},[_c('md-icon',[_vm._v("north_east")])],1)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
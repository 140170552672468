var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{staticClass:"md-layout lims-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit()}}},[_c('md-dialog',{staticClass:"lims-modal modal-report-issue",attrs:{"md-close-on-esc":true,"md-active":_vm.isVisible},on:{"update:mdActive":function($event){_vm.isVisible=$event},"update:md-active":function($event){_vm.isVisible=$event},"md-clicked-outside":_vm.onClose}},[_c('div',{staticClass:"dialog-icon-close",on:{"click":_vm.onClose}},[_c('md-icon',[_vm._v("close")])],1),_c('md-dialog-title'),_c('div',{staticClass:"dialog-content"},[_c('div',{staticClass:"md-layout lims-form-row issue-field"},[_c('div',{staticClass:"md-layout-item md-size-100 md-small-size-100"},[_c('lims-field',{attrs:{"model":_vm.formData,"schema":_vm.schema,"field":"issue"}},[_c('v-select',{staticClass:"not-item-in-v-select-error",attrs:{"slot":"field-outside","options":_vm.issueCaseList,"reduce":function (item) { return item.issueId; },"label":"issueName","placeholder":_vm.$t('modals/ModalReportIssue.placeholderIssue')},slot:"field-outside",scopedSlots:_vm._u([{key:"option",fn:function(ref){
var issueName = ref.issueName;
return [_vm._v(_vm._s(issueName))]}},{key:"selected-option",fn:function(ref){
var issueName = ref.issueName;
return [_vm._v(_vm._s(issueName))]}}],null,true),model:{value:(_vm.formData.issueId),callback:function ($$v) {_vm.$set(_vm.formData, "issueId", $$v)},expression:"formData.issueId"}})],1)],1),(!_vm.issuePreventUpdateStatusIsNone())?_c('div',{staticClass:"md-layout-item md-size-100 md-small-size-100 issue-prevent-update-status"},[_vm._v(" "+_vm._s(_vm.$t('modals/ModalReportIssue.note', { status: _vm.status, }))+" ")]):_vm._e()]),(_vm.formData.issueId && _vm.issueFieldIsNone())?_c('div',{staticClass:"md-layout lims-form-row mark-as-closed"},[_c('div',{staticClass:"md-layout-item md-size-50 md-small-size-100"},[_c('md-checkbox',{staticClass:"lims-checkbox checkbox-field",model:{value:(_vm.formData.isMarkIssueAsClose),callback:function ($$v) {_vm.$set(_vm.formData, "isMarkIssueAsClose", $$v)},expression:"formData.isMarkIssueAsClose"}},[_vm._v(" "+_vm._s(_vm.$t('entities/modals/ModalSelectEntityType/markIssueAsClosed'))+" ")])],1)]):_vm._e(),(_vm.formData.issueId && !_vm.issueFieldIsNone())?_c('div',{staticClass:"md-layout lims-form-row to-respond"},[_c('div',{staticClass:"md-layout-item md-size-100 md-small-size-100"},[_c('lims-field',{attrs:{"model":_vm.formData,"schema":_vm.schema,"field":"toRespond"}},[_c('v-select',{staticClass:"not-item-in-v-select-error",attrs:{"slot":"field-outside","multiple":"","options":_vm.userTypeList,"placeholder":_vm.$t('modals/ModalReportIssue.placeholderToRespond')},slot:"field-outside",scopedSlots:_vm._u([{key:"option",fn:function(ref){
var label = ref.label;
return [_vm._v(_vm._s(label))]}},{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_vm._v(_vm._s(label))]}}],null,true),model:{value:(_vm.formData.toRespond),callback:function ($$v) {_vm.$set(_vm.formData, "toRespond", $$v)},expression:"formData.toRespond"}})],1)],1)]):_vm._e(),_c('div',{staticClass:"md-layout lims-form-row"},[_c('div',{staticClass:"md-layout-item md-size-100 md-small-size-100 dialog-texterea-field"},[_c('lims-field',{attrs:{"model":_vm.formData,"schema":_vm.schema,"field":"comment"}},[_c('md-textarea',{attrs:{"slot":"field","type":"text"},slot:"field",model:{value:(_vm.formData.commentOnIssue),callback:function ($$v) {_vm.$set(_vm.formData, "commentOnIssue", $$v)},expression:"formData.commentOnIssue"}})],1)],1)])]),_c('md-dialog-actions',[_c('md-button',{staticClass:"lims-form-button",on:{"click":_vm.onClose}},[_vm._v(_vm._s(_vm.$t('global/button/button.cancel')))]),_c('md-button',{staticClass:"lims-form-button md-danger",attrs:{"disabled":invalid || _vm.isProcessing},on:{"click":_vm.onCreateIssue}},[_vm._v(_vm._s(_vm.$t('pages/case/CaseManagement/components/issueLog/button.create')))])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
    <modal-concurrency-issue
      ref="concurrencyIssueModal"
      :name-of-block="'Update Issue'"
      @onContinue="$onConcurrencyIssueContinue"
    ></modal-concurrency-issue>
    <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
      <md-dialog
        :md-close-on-esc="true"
        :md-active.sync="isVisible"
        @md-clicked-outside="close"
        class="lims-modal modal-report-issue"
      >
        <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
        <md-dialog-title></md-dialog-title>
        <div class="dialog-content">
          <div class="md-layout lims-form-row issue-field">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <lims-field :model="formData" :schema="schema" field="issue">
                <v-select
                  slot="field-outside"
                  v-model="formData.issueId"
                  :options="issueCaseList"
                  :reduce="(item) => item.issueId"
                  label="issueName"
                  disabled
                >
                  <template #option="{ issueName }">{{ issueName }}</template>
                  <template #selected-option="{ issueName }">{{ issueName }}</template>
                </v-select>
              </lims-field>
            </div>
            <div
              class="md-layout-item md-size-100 md-small-size-100 issue-prevent-update-status"
              v-if="!issuePreventUpdateStatusIsNone()"
            >
              {{
                $t('modals/ModalReportIssue.note', {
                  status: status,
                })
              }}
            </div>
          </div>
          <div class="md-layout lims-form-row mark-as-closed" v-if="formData.issueId && issueFieldIsNone()">
            <div class="md-layout-item md-size-50 md-small-size-100">
              <md-checkbox v-model="formData.isMarkIssueAsClose" class="lims-checkbox checkbox-field">
                {{ $t('entities/modals/ModalSelectEntityType/markIssueAsClosed') }}
              </md-checkbox>
            </div>
          </div>
          <div class="md-layout lims-form-row to-respond" v-if="formData.issueId && !issueFieldIsNone()">
            <div class="md-layout-item md-size-100 md-small-size-100">
              <lims-field :model="formData" :schema="schema" field="toRespond">
                <v-select
                  multiple
                  :disabled="formData.status === 'Pending Review'"
                  class="not-item-in-v-select-error"
                  slot="field-outside"
                  v-model="formData.toRespond"
                  :options="userTypeList"
                  :placeholder="$t('modals/ModalReportIssue.placeholderToRespond')"
                >
                  <template #option="{ label }">{{ label }}</template>
                  <template #selected-option="{ label }">{{ label }}</template>
                </v-select>
              </lims-field>
            </div>
          </div>
          <div class="md-layout lims-form-row">
            <div class="md-layout-item md-size-100 md-small-size-100 dialog-texterea-field">
              <lims-field :model="formData" :schema="schema" field="comment">
                <md-textarea
                  :disabled="formData.status === 'Pending Review'"
                  slot="field"
                  v-model="formData.commentOnIssue"
                  type="text"
                ></md-textarea>
              </lims-field>
            </div>
          </div>
          <div v-if="formData.status === 'Pending Review'">
            <div class="md-layout lims-form-row" v-for="(item, index) in formData.caseIssueFields" :key="index">
              <div class="md-layout-item md-size-100 md-small-size-100" v-if="item.uiControl === 'Datepicker'">
                <lims-field class="md-field-after-none" :model="formData" :schema="schema" field="dob"></lims-field>
                <div class="static-text">
                  {{ formData.dob }}
                </div>
              </div>
              <div
                class="md-layout-item md-size-100 md-small-size-100"
                v-if="item.uiControl === 'Dropdown Menu' && item.caseIssueValueName === 'Billing Entity'"
              >
                <lims-field class="md-field-after-none" :model="formData" :schema="schema" field="billingEntity">
                </lims-field>
                <div class="static-text">{{ formData.billingEntity }}</div>
              </div>
              <div
                class="md-layout-item md-size-100 md-small-size-100"
                v-if="item.uiControl === 'Dropdown Menu' && item.caseIssueValueName === 'Country'"
              >
                <lims-field class="md-field-after-none" :model="formData" :schema="schema" field="country">
                </lims-field>
                <div class="static-text">{{ formData.country }}</div>
              </div>
              <div
                class="md-layout-item md-size-100 md-small-size-100"
                v-if="item.uiControl === 'Dropdown Menu' && item.caseIssueValueName === 'Insurance Company'"
              >
                <lims-field class="md-field-after-none" :model="formData" :schema="schema" field="insuranceCompany">
                </lims-field>
                <div class="static-text">{{ formData.insuranceCompany }}</div>
              </div>
              <div
                class="md-layout-item md-size-100 md-small-size-100"
                v-if="
                  item.uiControl === 'Dropdown Menu' && item.caseIssueValueName === 'Specimen Type (of 1st specimen)'
                "
              >
                <lims-field class="md-field-after-none" :model="formData" :schema="schema" field="specimenType">
                </lims-field>
                <div class="static-text">{{ formData.specimenType }}</div>
              </div>
              <div
                class="md-layout-item md-size-100 md-small-size-100"
                v-if="item.uiControl === 'Text Input' && item.caseIssueValueName === 'Insurance Authorisation Number'"
              >
                <lims-field
                  class="md-field-after-none"
                  :model="formData"
                  :schema="schema"
                  field="insuranceAuthorisationNumber"
                >
                </lims-field>
                <div class="static-text">{{ formData.insuranceAuthorisationNumber }}</div>
              </div>
              <div
                class="md-layout-item md-size-100 md-small-size-100"
                v-if="item.uiControl === 'Text Input' && item.caseIssueValueName === 'Insurance Number'"
              >
                <lims-field class="md-field-after-none" :model="formData" :schema="schema" field="insuranceNumber">
                </lims-field>
                <div class="static-text">{{ formData.insuranceNumber }}</div>
              </div>
              <div
                class="md-layout-item md-size-100 md-small-size-100"
                v-if="item.uiControl === 'Text Input' && item.caseIssueValueName === 'Insurance Note'"
              >
                <lims-field
                  class="md-field-after-none"
                  :model="formData"
                  :schema="schema"
                  field="insuranceNote"
                ></lims-field>
                <div class="static-text">{{ formData.insuranceNote }}</div>
              </div>
              <div
                class="md-layout-item md-size-100 md-small-size-100"
                v-if="item.uiControl === 'Text Input' && item.caseIssueValueName === 'Postal Code'"
              >
                <lims-field class="md-field-after-none" :model="formData" :schema="schema" field="postalCode">
                </lims-field>
                <div class="static-text">{{ formData.postalCode }}</div>
              </div>
              <div
                class="md-layout-item md-size-100 md-small-size-100"
                v-if="item.uiControl === 'Text Input' && item.caseIssueValueName === 'Town'"
              >
                <lims-field class="md-field-after-none" :model="formData" :schema="schema" field="town"> </lims-field>
                <div class="static-text">{{ formData.town }}</div>
              </div>
              <div
                class="md-layout-item md-size-100 md-small-size-100"
                v-if="item.uiControl === 'Textarea' && item.caseIssueValueName === 'Address 1'"
              >
                <lims-field class="md-field-after-none" :model="formData" :schema="schema" field="address1">
                </lims-field>
                <div class="static-text">{{ formData.address1 }}</div>
              </div>
              <div
                class="md-layout-item md-size-50 md-small-size-100"
                v-if="item.uiControl === 'Textarea' && item.caseIssueValueName === 'Address 2'"
              >
                <lims-field class="md-field-after-none" :model="formData" :schema="schema" field="address2">
                </lims-field>
                <div class="static-text">{{ formData.address2 }}</div>
              </div>
            </div>
          </div>
          <div class="md-layout lims-form-row" v-if="issueData.status === 'Pending Review'">
            <div class="md-layout-item md-size-100 md-small-size-100 dialog-texterea-field">
              <lims-field :model="formData" :schema="schema" field="commentOnIssueResponse">
                <md-textarea disabled slot="field" v-model="formData.commentOnResponse" type="text"></md-textarea>
              </lims-field>
            </div>
          </div>
        </div>
        <md-dialog-actions>
          <md-button v-if="issueData.status !== 'Pending Review'" @click="close" class="lims-form-button">{{
            $t('global/button/button.cancel')
          }}</md-button>
          <md-button
            v-if="issueData.status !== 'Pending Review'"
            @click="onSave"
            :disabled="invalid || isProcessing"
            class="lims-form-button md-primary"
            >{{ $t('global/button/button.save') }}</md-button
          >
          <md-button v-if="issueData.status === 'Pending Review'" @click="close" class="lims-form-button">{{
            $t('global/button/button.back')
          }}</md-button>
          <md-button
            v-if="issueData.status === 'Pending Review'"
            @click="onAcceptAndCloseIssue"
            :disabled="invalid || isProcessing"
            class="lims-form-button md-danger"
            >{{ $t('global/button/button.acceptAndCloseIssue') }}</md-button
          >
          <md-button
            v-if="issueData.status === 'Pending Review'"
            @click="onReject"
            :disabled="invalid || isProcessing"
            class="lims-form-button md-primary"
            >{{ $t('global/button/button.reject') }}</md-button
          >
        </md-dialog-actions>
      </md-dialog>
    </form>
  </ValidationObserver>
</template>

<script>
import modalMixins from './modal.mixins';
import { CaseIssueMixins } from '@/pages/Case/CaseManagement/Case.mixins';
import { DROPDOWN_SHORT_NAME, ENTITY_TYPE_ENUMS, FORM_MODES, CASE_FORM_BLOCK } from '@/core/constants';
import { CaseIssueService } from '@/services';
import { mapActions, mapGetters } from 'vuex';
import dropdownService from '@/services/dropdown.service';
import ModalConcurrencyIssue from '@/components/Lims/modals/ModalConcurrencyIssue';
import CaseBlockMixins from '@/pages/Case/CaseManagement/CaseBlock.mixins';

export default {
  components: { ModalConcurrencyIssue },
  mixins: [modalMixins, CaseIssueMixins, CaseBlockMixins],
  props: {
    formMode: {
      type: Number,
      require: true,
      validator: function (value) {
        // The value must match one of these strings
        return Object.values(FORM_MODES).indexOf(value) !== -1;
      },
    },
    issueCaseList: {
      type: Array,
      required: false,
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
    issueData: {
      type: Object,
      required: true,
    },
    dataEdit: {
      required: false,
    },
  },
  data() {
    return {
      notifyList: [],
      respondList: [],
      billingEntityList: [],
      countries: [],
      insuranceCompanyList: [],
      specimenTypeList: [],
      blockId: CASE_FORM_BLOCK.BLOCK_ISSUE_LOG,
    };
  },
  watch: {
    issueData() {
      const findItemWithIssueId = this.issueCaseList.find(
        (issueCase) => issueCase.issueId === this.issueData.issue.issueId,
      );
      if (findItemWithIssueId) {
        const userTypeList = findItemWithIssueId.toRespondUserTypes;
        if (userTypeList) {
          this.userTypeList = userTypeList.map((userType) => {
            return {
              value: userType.id,
              label: userType.text,
            };
          });
        }
      }
      this.formData = {
        caseIssueId: this.issueData.caseIssueId,
        issueId: this.issueData.issue.issueId,
        caseIssueFields: this.issueData.caseIssueFields,
        status: this.issueData.status,
        isMarkIssueAsClose: this.issueData.isMarkIssueAsClose,
        toRespond: this.userTypeList?.filter((userTypeItem) =>
          this.issueData.toRespond.toRespondUserTypeIds.includes(userTypeItem.value),
        ),
        commentOnIssue: this.issueData.comment,
        commentOnResponse: this.issueData.commentOnResponse,
      };
      this.billingEntityList = this.getDatasetByKey(DROPDOWN_SHORT_NAME.BILLING_ENTITY);
      this.countries = this.getDatasetByKey(DROPDOWN_SHORT_NAME.COUNTRY);
      this.insuranceCompanyList = this.getDatasetByKey(DROPDOWN_SHORT_NAME.INSURANCE_COMPANY);
      this.specimenTypeList = this.getDatasetByKey(DROPDOWN_SHORT_NAME.SPICEMEN_TYPE);
      if (this.issueData.status === 'Pending Review') {
        this.formData.caseIssueFields.forEach((caseIssue) => {
          this.$setFormDataCaseIssue(caseIssue);
        });
      }
    },
  },
  computed: {
    ...mapGetters('app/data', ['getDatasetByKey']),
    schema() {
      return {
        entity: 'modals/ModalSelectEntityType',
        fields: {
          issue: 'required',
          toRespond: 'required',
          comment: '',
          dob: '',
          billingEntity: 'required',
          commentOnIssueResponse: '',
          country: '',
          insuranceCompany: '',
          specimenType: '',
          insuranceAuthorisationNumber: '',
          insuranceNumber: '',
          insuranceNote: '',
          postalCode: '',
          town: '',
          address1: '',
          address2: '',
        },
      };
    },
    selectOptions() {
      return ENTITY_TYPE_ENUMS;
    },
  },
  methods: {
    ...mapActions('app/data', ['updateDataset']),
    async prepareData() {
      const dropdownOptions = await dropdownService.getDropdownByShortNames([
        DROPDOWN_SHORT_NAME.COUNTRY,
        DROPDOWN_SHORT_NAME.SPICEMEN_TYPE,
        DROPDOWN_SHORT_NAME.INSURANCE_COMPANY,
        DROPDOWN_SHORT_NAME.BILLING_ENTITY,
      ]);
      this.updateDataset({
        ...dropdownOptions,
      });
    },
    onAcceptAndCloseIssue() {
      this.isProcessing = true;
      this.$emit('onAcceptAndCloseIssue', {
        caseIssueId: this.issueData.caseIssueId,
        issueName: this.issueData.issue.issueName,
        respondedAt: this.issueData.respondedAt,
      });
    },
    onReject() {
      this.isProcessing = true;
      this.$emit('onRejectIssue', {
        caseIssueId: this.issueData.caseIssueId,
        issueName: this.issueData.issue.issueName,
        respondedAt: '',
      });
    },
    async onSave(overWrite = false) {
      if (this.formMode === FORM_MODES.ADD) {
        if (this.formData.issueId) {
          this.$emit('onUpdateIssue', {
            ...this.createIssueData(),
          });
        }
      } else {
        if (this.formMode === FORM_MODES.EDIT) {
          this.isProcessing = true;
          const rowVersion = this.getRowVersionByCaseId(this.formData.caseIssueId);
          const dataForm = this.issueDataEditMode();
          const res = await CaseIssueService.updateCaseIssue(this.formData.caseIssueId, {
            ...dataForm,
            rowVersion: overWrite ? null : rowVersion,
          });
          this.isProcessing = false;
          this.$onAfterSaveHandler({
            res,
            dataEdit: this.dataEdit,
          });
          this.$emit('onUpdateIssue', {
            ...this.issueDataEditMode(true),
          });
        }
      }
    },
  },
};
</script>
<style lang="scss">
.modal-report-issue .checkbox-field {
  @media only screen and (max-width: 959px) {
    padding-top: 0;
  }
}
.md-field-after-none {
  .md-field:after,
  .md-field:before {
    display: none;
  }
}
</style>

import { isArray } from 'lodash';
import httpClient from './httpClient';
import { endDay, startDay } from '@/core/helpers';

const transformParamsIntoQuery = ({ filters, pagination }) => {
  const { pageNumber, pageSize } = pagination;
  const userQueryParams = {};

  Object.entries(filters).forEach(([key, value]) => {
    let values = [];
    switch (key) {
      case 'search':
        userQueryParams['Filters.Search'] = value;
        break;
      case 'AlwRespondDate':
        values = value ? (isArray(value) ? value : value.split(',')) : [];
        if (value && values.length > 0) {
          userQueryParams['Filters.AdvanceSearch.AlwRespondDateFrom'] = startDay(values[0]);
          userQueryParams['Filters.AdvanceSearch.AlwRespondDateTo'] = endDay(values[1]);
        }
        break;
      case 'AlwRequestDate':
        values = value ? (isArray(value) ? value : value.split(',')) : [];
        if (value && values.length > 0) {
          userQueryParams['Filters.AdvanceSearch.AlwRequestDateFrom'] = startDay(values[0]);
          userQueryParams['Filters.AdvanceSearch.AlwRequestDateTo'] = endDay(values[1]);
        }
        break;
      default:
        if (
          value &&
          !['AlwRespondDateFrom', 'AlwRespondDateTo', 'AlwRequestDateFrom', 'AlwRequestDateTo'].includes(key)
        ) {
          userQueryParams['Filters.AdvanceSearch.' + key] = value;
        }
        break;
    }
  });

  if (pageNumber) {
    userQueryParams['PageNumber'] = pageNumber;
  }
  if (pageSize) {
    userQueryParams['PageSize'] = pageSize;
  }

  return userQueryParams;
};

const RESOURCE = 'cases';
export default {
  findOne(caseALWRequestId) {
    return httpClient.get(`/${RESOURCE}/alw-request/${caseALWRequestId}`);
  },
  updateRespondAlw(data = {}) {
    return httpClient.put(`/${RESOURCE}/alw-request`, data);
  },
  updateBulkAlwRequest(data = {}) {
    return httpClient.put(`/${RESOURCE}/alw-request/bulk-update`, data);
  },
  checkValidAlwRequest(data = {}) {
    return httpClient.post(`/${RESOURCE}/alw-request/bulk-validate`, data);
  },
  cancelAlwRequest(data = {}) {
    return httpClient.post(`/${RESOURCE}/alw-request/bulk-cancel`, data);
  },
  getAlwCaseList({ filters, pagination }) {
    const userQueryParams = transformParamsIntoQuery({ filters, pagination });
    return httpClient.get(`/${RESOURCE}/alw-request`, userQueryParams);
  },
  exportAlwRequest(payload, { filters, pagination }) {
    const userQueryParams = transformParamsIntoQuery({ filters, pagination });
    return httpClient.postBlob(`/${RESOURCE}/export/alw-request/export-alw`, payload, userQueryParams);
  },

  getAlwRequestBatch(caseId) {
    return httpClient.get(`/${RESOURCE}/alw-request/batch/${caseId}`);
  },
};

<template>
  <div>
    <div class="lims-form">
      <div class="md-layout lims-form-row">
        <div :class="requireFile && fileAttachments.length == 0 ? 'dropzone-err dropzone-wrapper' : 'dropzone-wrapper'">
          <vue-dropzone
            ref="myVueDropzone"
            id="dropzone"
            :options="dropzoneOptions"
            :useCustomSlot="true"
            @vdropzone-file-added="onAddFile"
          >
            <div class="dropzone-container">
              <h4>{{ $t('global/vueDropzone.txt') }}</h4>
              <md-button class="md-button md-primary lims-form-button md-theme-default">
                {{ $t('global/button/button.chooseFile') }}
              </md-button>
            </div>
          </vue-dropzone>
        </div>
        <div v-for="(item, index) in fileAttachments" :key="index" class="dropzone-file-add">
          <div class="file-item">
            <div class="icon">
              <span @click="onRemoveFile(item)"><md-icon>close</md-icon></span>
            </div>
            <img class="img" :src="require(`@/assets/images/pdf-file.png`)" />
            <div class="file-item-name">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="md-layout lims-form-row">
        <div class="md-layout-item md-size-100 md-small-size-100 lims-form-rf-upload-actions">
          <md-button @click="onCancel()" class="lims-form-button md-theme-default">
            {{ $t('pages/case/CaseManagement/component/RFUpload/button.backToCaseList') }}
          </md-button>
          <md-button
            @click="onUploadFile()"
            class="md-primary lims-form-button md-theme-default md-theme-default"
            :disabled="fileAttachments.length === 0"
          >
            {{ $t('pages/case/CaseManagement/component/RFUpload/button.uploadRF') }}
          </md-button>
          <md-button
            @click="onClearDoc()"
            class="md-danger lims-form-button md-theme-default md-theme-default"
            :disabled="fileAttachments.length === 0"
          >
            {{ $t('global/button/button.clear') }}
          </md-button>
        </div>
      </div>
      <md-content>
        <div class="md-theme-default md-layout">
          <div
            v-for="(item, index) in itemCases"
            :key="index"
            class="md-layout-item md-size-20 md-small-size-50 md-xsmall-size-100 md-medium-size-33"
          >
            <md-card>
              <md-card-content>
                <div class="md-card-content-case-rf-upload">
                  <div class="md-card-title-case-ref">
                    <i>{{ item.caseReference }}</i>
                  </div>
                  <div>
                    <i
                      >{{ $t('pages/case/CaseManagement/component/RFUpload/label.labRef') }}: {{ item.labReference }}</i
                    >
                  </div>
                  <div>
                    <i>{{ $t('pages/case/CaseManagement/component/RFUpload/label.lab') }}: {{ item.laboratoryName }}</i>
                  </div>
                  <div>
                    <i
                      >{{ $t('pages/case/CaseManagement/component/RFUpload/label.clinician') }}:
                      {{ item.clinicianName }}</i
                    >
                  </div>
                  <div>
                    <i
                      >{{ $t('pages/case/CaseManagement/component/RFUpload/label.hospital') }}: {{ item.clinicName }}</i
                    >
                  </div>
                  <div>
                    <i
                      >{{ $t('pages/case/CaseManagement/component/RFUpload/label.path') }}:
                      {{ item.pathologistName }}</i
                    >
                  </div>
                </div>
                <md-checkbox
                  class="lims-checkbox"
                  :disabled="true"
                  style="position: absolute; right: 0 !important; top: 0"
                ></md-checkbox>
              </md-card-content>
            </md-card>
          </div>
        </div>
      </md-content>
    </div>
  </div>
</template>

<script>
import { FormMixins } from '@/core/mixins';
import { APP_ROUTES } from '@/core/constants';
import { caseUploadRfService } from '@/services';

export default {
  mixins: [FormMixins],
  created: function () {
    this.initState();
  },
  data() {
    return {
      formData: {},
      selectedRows: [],
      itemCases: [],
      dropzoneOptions: {
        url: '/',
        autoProcessQueue: false,
        acceptedFiles: 'application/pdf',
        uploadMultiple: false,
        maxFiles: 100,
        previewsContainer: false,
        init: function () {
          this.on('error', function (file) {
            if (!file.accepted) this.removeFile(file);
          });
        },
      },
      fileAttachments: [],
      requireFile: false,
      hasUploadFile: true,
    };
  },

  methods: {
    async onAddFile(file) {
      if (this.fileAttachments.filter((x) => x.name == file.name).length > 0) {
        this.$alertError(
          this.$t('pages/case/CaseManagement/component/RFUpload/error.uploadSameFileName', {
            fileName: file.name,
          }),
        );
        this.$refs.myVueDropzone.removeFile(file);
        return;
      }
      var fileReader = new FileReader();
      fileReader.onload = () => {
        this.fileAttachments = this.$refs.myVueDropzone.dropzone.files.filter((x) => x.accepted == true);
        this.formData.fileAdd = this.fileAttachments;
      };
      fileReader.readAsDataURL(file);
    },

    onRemoveFile(item) {
      const idx = this.fileAttachments.findIndex((fileAttachment) => fileAttachment.name === item.name);
      this.fileAttachments.splice(idx, 1);
      this.$refs.myVueDropzone.removeFile(item);
      this.pdfReviewLink = '';
    },

    onClearDoc() {
      this.$refs.myVueDropzone.removeAllFiles();
      this.fileAttachments.splice(0);
    },
    async onUploadFile() {
      try {
        if (this.fileAttachments && (this.fileAttachments !== undefined || this.fileAttachments.length > 0)) {
          let formData = new FormData();
          this.formData.fileAdd.forEach((item) => {
            formData.append('files', item);
          });
          const res = await caseUploadRfService.uploadTemporaryFile(formData);
          if (res.err) {
            return this.$alertErrorServerSide(res.err);
          } else {
            const dataFile = res.data;
            const fileAttachments = [];
            dataFile.forEach((item) => {
              fileAttachments.push(item);
            });
            this.fileAttachments = fileAttachments;
            const caseData = {
              cases: this.itemCases.map((c) => {
                return {
                  caseId: c.caseId,
                  caseRef: c.caseReference,
                  labRef: c.labReference,
                };
              }),
              fileNames: this.fileAttachments.map((c) => {
                return c.fileName;
              }),
            };
            const responseCheckCase = await caseUploadRfService.checkValidRfCase(caseData);
            if (responseCheckCase.err) {
              return this.$$alertErrorServerSide(responseCheckCase.err);
            } else {
              sessionStorage.setItem('caseRFCheckData', JSON.stringify(responseCheckCase.data));
              sessionStorage.setItem('caseRfFileResponse', JSON.stringify(this.fileAttachments));
              sessionStorage.setItem('caseItems', JSON.stringify(this.itemCases));
              this.$router.push({ path: APP_ROUTES.RF_UPLOAD_CASE_CHECK });
            }
          }
        }
      } catch (errors) {
        this.$alertError(errors);
      }
    },

    async initState() {
      const caseRFList = JSON.parse(sessionStorage.getItem('caseRfUpload'));
      this.itemCases = caseRFList;
    },

    onCancel() {
      this.$resetChangeDetection();
      setTimeout(() => {
        this.$router.push({ path: '/cases' });
      }, 500);
    },
  },
};
</script>
<style lang="scss">
.dropzone-file-add {
  width: auto;
  margin: 15px 5px 0 5px;
  display: inline-flex;
  .file-item {
    width: 120px;
    font-size: 12px;
    > .file-item-name {
      min-height: 4rem;
      line-height: 1.2rem;
    }
  }
}
.lims-form {
  .lims-form-rf-upload-actions {
    &.case-block-action {
      text-align: right;
    }
    .lims-form-button {
      margin: 0.5rem;
      width: 10rem;
    }
  }
}
</style>

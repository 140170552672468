<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
    <md-dialog
      :md-close-on-esc="true"
      :md-active.sync="isVisible"
      @md-clicked-outside="close"
      class="lims-modal large-modal modal-create-alw-request"
    >
      <div class="dialog-icon-close" @click="close"><md-icon>close</md-icon></div>
      <md-dialog-title>{{ $t('components/ModalCreateAlwRequest.newAlwRequests') }} </md-dialog-title>
      <form class="md-layout lims-form" @submit.prevent="handleSubmit()">
        <lims-block class="block-not-title">
          <div slot="blockContent">
            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-50 md-small-size-100">
                <lims-field :model="formData" :schema="schema" field="caseSpecimenBlockIds">
                  <multiselect
                    slot="field"
                    v-model="formData.caseSpecimenBlockIds"
                    :options="caseSpecimenBlockList"
                    :multiple="true"
                    :show-labels="false"
                    group-values="nodes"
                    group-label="text"
                    track-by="text"
                    label="text"
                    :hide-selected="true"
                    :group-select="true"
                    :close-on-select="true"
                    :placeholder="$t('components/ModalCaseBatchChange.placeholderCaseSpecimenBlock')"
                    @input="caseSpecimenBlockVal"
                  >
                  </multiselect>
                </lims-field>
              </div>
              <div class="md-layout-item md-size-50 md-small-size-100">
                <lims-field :model="formData" :schema="schema" field="stainIds" class="textarea-field">
                  <multiselect
                    slot="field"
                    v-model="formData.stainIds"
                    :options="specialStainList"
                    :multiple="true"
                    :show-labels="false"
                    group-values="items"
                    group-label="itemName"
                    :placeholder="$t('components/ModalCaseBatchChange.placeholderSpecialStain')"
                    track-by="fieldItemId"
                    label="fieldItemName"
                    :hide-selected="true"
                    :group-select="false"
                  >
                  </multiselect>
                </lims-field>
              </div>
            </div>

            <div class="md-layout lims-form-row">
              <div class="md-layout-item md-size-100 md-small-size-100">
                <lims-field class="textarea-field" :model="formData" :schema="schema" field="comment">
                  <md-textarea
                    slot="field"
                    v-model="formData.comment"
                    :placeholder="$t('components/ModalCreateAlwRequest.placeholderComment')"
                  ></md-textarea>
                </lims-field>
              </div>
            </div>
          </div>
        </lims-block>
        <md-dialog-actions>
          <md-button @click="close" class="lims-form-button">{{ $t('global/button/button.cancel') }}</md-button>
          <md-button @click="onCreate" :disabled="invalid || isProcessing" class="lims-form-button md-primary">{{
            $t('global/button/button.create')
          }}</md-button>
        </md-dialog-actions>
      </form>
    </md-dialog>
  </ValidationObserver>
</template>

<script>
import modalMixins from './modal.mixins';
import { caseFormService, pathCaseService } from '@/services';
import { mapActions, mapGetters } from 'vuex';
import { APP_EVENTS, newAppEvent, DROPDOWN_SHORT_NAME, USER_TYPE } from '@/core/constants';
import { filterDropdownListByHiddenField } from '@/core/helpers';

export default {
  mixins: [modalMixins],
  props: {
    caseId: {
      type: String,
      require: false,
    },
    laboratoryId: {
      type: String,
      require: true,
    },
    isPauseCountReturnToPull: {
      type: Boolean,
      require: false,
    },
  },
  created: function () {},
  data() {
    return {
      formData: {
        alwRequestTypeId: 0,
        caseSpecimenBlockIds: '',
        stainIds: '',
        comment: '',
      },
      data: {},
      caseSpecimenBlockList: [],
      specialStainList: [],
      rowVersion: null,
    };
  },
  computed: {
    schema() {
      return {
        entity: 'modals/ModalCreateAlwRequest',
        fields: {
          caseSpecimenBlockIds: 'required',
          stainIds: 'required',
          comment: '',
        },
      };
    },
    ...mapGetters('app/data', ['getDatasetByKey']),
  },
  methods: {
    ...mapActions('app/event', ['addEvent']),

    async open() {
      this.isVisible = true;
      this.caseSpecimenBlockList = await this.loadCaseSpecimenBlocks({ caseId: this.caseId });
    },

    async loadCaseSpecimenBlocks(caseId) {
      const { data } = await pathCaseService.getCaseSpecimenBlocks(caseId);
      return [
        {
          text: 'Select All',
          nodes: data,
        },
      ];
    },

    async loadSpecialStainList(caseSpecimenBlockIds) {
      const dropdownStainOptions = await caseFormService.getNewAlwRequestSlides({
        caseId: this.caseId,
        caseSpecimenBlockIds: caseSpecimenBlockIds,
        entityId: this.laboratoryId,
        shortNames: [
          DROPDOWN_SHORT_NAME.H_AND_E,
          DROPDOWN_SHORT_NAME.IMMUNOS,
          DROPDOWN_SHORT_NAME.SPECIAL_STAINS,
          DROPDOWN_SHORT_NAME.ADDITIONAL_TECHNIQUE,
        ],
      });

      let specialStainArr = [];
      const specialStainListNotHiddenField = dropdownStainOptions.reduce((i, value) => {
        const listItem = {
          ...value,
          items: filterDropdownListByHiddenField(value.items),
        };
        if (listItem.items.length > 0) {
          specialStainArr.push(listItem);
        }
        return specialStainArr;
      }, 0);

      return specialStainListNotHiddenField;
    },

    async caseSpecimenBlockVal(val) {
      if (val && val.length > 0) {
        const blockSelectedIds = val ? [val.map((x) => x.id)].join(',') : null;
        this.specialStainList = await this.loadSpecialStainList(blockSelectedIds);
      } else {
        this.specialStainList = [];
      }
    },

    dataCaseAlwRequest() {
      const specialStainsData = [];
      const specimenBlockData = [];
      const specialStainSelect = this.formData.stainIds;
      const specimenBlockSelect = this.formData.caseSpecimenBlockIds;
      if (specialStainSelect && specialStainSelect.length > 0) {
        specialStainSelect.forEach(function (item) {
          specialStainsData.push(item.fieldItemId);
        });
      }
      if (specimenBlockSelect && specimenBlockSelect.length > 0) {
        specimenBlockSelect.forEach(function (item) {
          specimenBlockData.push(item.id);
        });
      }
      return {
        ...this.formData,
        stainIds: specialStainsData,
        caseSpecimenBlockIds: specimenBlockData,
      };
    },
    getSlideBlockString(stringError) {
      const err = stringError.split('_').pop();
      const mapObj = {
        ',': ' - ',
        ';': ', ',
      };
      const stringErr = err.replaceAll(/\b(?:,|;)\b/gi, (matched) => mapObj[matched]);
      return stringErr;
    },
    onCreate() {
      this.userType = this.$store.getters['auth/userType'];
      this.$refs.form.validate().then(async (success) => {
        if (success) {
          this.isProcessing = true;
          try {
            const dataForm = this.dataCaseAlwRequest();
            let res = await pathCaseService.createCaseAlwRequest(this.caseId, dataForm);
            this.isProcessing = false;
            if (res.error) {
              const error = res.error.split('|');
              let errDuplicate = error.find((item) => item.includes('DuplicateALWRequest'));
              let errExisted = error.find((item) => item.includes('ExistedALWResponded'));
              let errDuplicatedOriginalSlide = error.find((item) =>
                item.includes('DuplicatedOriginalSlideForALWRequest'),
              );
              if (errDuplicate || errExisted || errDuplicatedOriginalSlide) {
                let errDuplicateString = errDuplicate
                  ? '<li>' +
                    this.$t('components/ModalCreateAlwRequest/newAlwRequests/error/duplicate') +
                    this.getSlideBlockString(errDuplicate) +
                    '</li>'
                  : '';
                let errExistedString = errExisted
                  ? '<li>' +
                    this.$t('components/ModalCreateAlwRequest/newAlwRequests/error/existing') +
                    this.getSlideBlockString(errExisted) +
                    '</li>'
                  : '';
                let errDuplicatedOriginalSlideString = errDuplicatedOriginalSlide
                  ? '<li>' +
                    this.$t('components/ModalCreateAlwRequest/newAlwRequests/error/duplicateOriginalSlide') +
                    this.getSlideBlockString(errDuplicatedOriginalSlide) +
                    '</li>'
                  : '';
                let stringError =
                  '<ul>' + errDuplicateString + errExistedString + errDuplicatedOriginalSlideString + '</ul>';
                this.$alertError(
                  this.$t('components/ModalCreateAlwRequest/newAlwRequests/error', {
                    error: this.getSlideBlockString(stringError),
                  }),
                );
              }
              if (!errDuplicate && !errExisted && !errDuplicatedOriginalSlide) {
                return this.$alertError(res.err);
              }
            } else {
              this.close();
              this.$alertSuccess(this.$t('components/ModalCreateAlwRequest.newAlwRequests.created.successMessage'));
              if (this.userType === USER_TYPE.Pathologist && !this.isPauseCountReturnToPull) {
                this.addEvent(
                  newAppEvent(APP_EVENTS.EVT_ON_CASE_HIDE_COUNT_DOWN, {
                    onHideCountDown: true,
                  }),
                );
              }
              this.addEvent(
                newAppEvent(APP_EVENTS.EVT_ON_CREATED_ALW_REQUEST, {
                  caseId: this.caseId,
                }),
              );
            }
          } catch (errors) {
            this.isProcessing = false;
            this.$alertError(errors);
          }
        } else {
          this.isProcessing = false;
          this.$alertError(this.$t(`global/errors/message`));
        }
      });
    },
    setRequestTypeId(value) {
      this.formData.alwRequestTypeId = value;
    },
    close() {
      this.isVisible = false;
      this.$nextTick(() => {
        this.formData = {
          caseSpecimenBlockIds: '',
          stainIds: '',
          comment: '',
        };
        this.caseSpecimenBlockList = [];
        this.specialStainList = [];
      });
    },
  },
};
</script>

<style lang="scss">
.modal-create-alw-request {
  .multiselect {
    margin: 10px 0 10px 0;
  }
}
</style>
